import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  ViewChild,
  Renderer
} from "@angular/core";
import { S3filesService, PagerService } from "../../../../../shared/services";
//import { Observable } from 'rxjs/Observable';
import { UploadFiles } from "../../../../../shared/models/UploadFiles";
import { GoogleReviewDTO } from "../../../../../shared/models/GoogleReviewDTO";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import * as $ from "jquery";
import { Observable, Subscription } from "rxjs/Rx";
import { MwWriteReviewService } from "../../../mw-home";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";
import { GoogleReviewService } from "../../../../../shared/services/api";
import { GoogleReview } from "../../../../../shared/models";

@Component({
  selector: "mw-admin-video",
  templateUrl: "./mw-admin-video.component.html",
  styleUrls: ["./mw-admin-video.component.scss"]
  //changeDetection: ChangeDetectionStrategy.Default
})
export class MwAdminVideoComponent implements OnInit {
  public radioButtonForm: FormGroup;
  public statusReview: string;
  @ViewChild("target")
  public target: ElementRef;

  @ViewChild("videoController")
  public videoController: ElementRef;

  public imageSource: any;

  public status = 0;
  public reviews: GoogleReviewDTO[];
  public notViewed: GoogleReviewDTO[];
  public approved: GoogleReviewDTO[];
  public denied: GoogleReviewDTO[];
  private allItems: any[];
  private currentTab = 0;
  pager: any = {};
  pagedItems: any[];
  pageFromCount: number;
  pageToCount: number;

  pagerApproved: any = {};
  pagedApproved: GoogleReviewDTO[];
  pagedNotViewed: modelReview[];
  pagerDenied: any = {};
  pagedDenied: GoogleReviewDTO[];

  uploaded: boolean;

  // drawTimer = null;
  // private sub: Subscription;
  private subscription: Subscription;

  videoPageSize:number = 10;
  currentPage:number = 1;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private s3filesService: S3filesService,
    private _GoogleReviewDTOService: GoogleReviewService,
    private _pagerService: PagerService,
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer,
    private _mwWriteReviewService: MwWriteReviewService,
    private sanitizer: DomSanitizer,
    private _titleService: Title
  ) {}

  ngOnInit() {
    this._titleService.setTitle("Admin - Video Management");

    this.initForm();
    this.currentPage = 1;
    this.Reviews(0 , this.videoPageSize , this.currentPage);
  }

  public initScreenshot() {
    var canvas = $(".canvas");
  }

  //  public startScreenshot(event,review) {
  //    if (this.drawTimer == null) {

  //     console.log(event) ;

  //      this.drawTimer = Observable.timer(100, 1000);
  //      this.sub = this.drawTimer.subscribe(t => this.grabScreenshot(event.target, review));
  //    }
  //  }

  public startCollect(selectedVideo) {
    // selectedVideo.setAttribute('crossorigin','anonymous');
    // selectedVideo.load();
    selectedVideo.play();
  }

  public collectSnapShots(event, review) {
    review.videoThumbnails = [];

    // Total thumb count
    let thumbCount = 5;
    let videoDuration = event.srcElement.duration;

    let drawTimer = null;
    let sub: Subscription;

    drawTimer = Observable.timer(0, 1000);

    sub = drawTimer.subscribe(t => {
      this.grabScreenshot(event.target, review);

      //   if(event.srcElement.currentTime > 6) {
      //     var canvas = $(".canvas");
      //     sub.unsubscribe();
      //     drawTimer = null
      //   }

      // event.srcElement.currentTime += 1   ;

      if (review.videoThumbnails.length > 8) {
        var canvas = $(".canvas");
        sub.unsubscribe();
        drawTimer = null;
      }
    });
  }

  public grabScreenshot(video, review): boolean {
    var $canvas = $(video)
      .siblings(".canvas")
      .first();
    if ($canvas.length == 1) {
      var canvas = $canvas[0];
      var ctx = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      var dataUrl = canvas.toDataURL("image/png");
      review.videoThumbnails.push(dataUrl);
      // console.log(dataUrl) ;

      return true;
    }
  }

  //  public stopScreenshot() {
  //    var canvas = $(".canvas");
  //    this.sub.unsubscribe();
  //    this.drawTimer = null
  //   }

  getclicked(event) {
    // debugger;
    //  this.sub.unsubscribe();
    //  this.drawTimer = null
    var selectedImageName = "test.png";
    var BUCKET = environment.s3.Bucket.toString();
    var fileName = selectedImageName;
    var imageUrl = environment.s3.Url + BUCKET + "/" + selectedImageName;
    this._mwWriteReviewService.uploadfile(event.currentTarget.src, fileName);
  }

  saveThumb(review: modelReview) {
    // console.log('review') ;
    // console.log(review) ;

    review.resultMessage = "";

    var imageUrl = review.videoUrl;
    var extension = imageUrl.split(".").pop();

    var fileName =
      imageUrl.substring(0, imageUrl.lastIndexOf(extension)) + "png";

    fileName = fileName.replace(
      environment.s3.Url + environment.s3.Bucket + "/",
      ""
    );

    // console.log('fileName') ;
    // console.log(fileName) ;

    // console.log('review.selectedThumbnail,') ;
    // console.log(review.selectedThumbnail) ;

    let buf = new Buffer(
      review.selectedThumbnail.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    var result = this._mwWriteReviewService.uploadImageFile(buf, fileName);

    review.resultMessage = "Thumbnail has successfully been uploaded";
    review.thumbUploaded = true;
  }

  selectThumb(event, review: modelReview) {
    review.selectedThumbnail = event.currentTarget.src;
  }

  initForm() {
    this.radioButtonForm = this.fb.group({
      videoReviewStatus: ["undefined"]
    });
  }

  getStatus() {
    const arr = this._GoogleReviewDTOService.Get().map(x => {
      x.data;
      this.reviews = x.data.filter(
        x => x.videoUrl !== null && x.videoUrl !== undefined
      );
      return this.fb.control(x);
    });
  }

  public uploadedVideos: Observable<Array<UploadFiles>>;
  
  listOfVideos() {
    this.uploadedVideos = this.s3filesService.getFiles();
  }

  public Reviews(videoReviewStatus: number , pageSize: number ,pageNumber: number) {
    this._GoogleReviewDTOService.GetByPage(videoReviewStatus, pageSize, pageNumber).subscribe(x => {
      
      // debugger;

      // console.log( x.data);

      if(x.data.length==0) {
          this.currentPage -= 1;
          return;
      }


      this.reviews = x.data.filter(
        x => x.videoUrl !== null && x.videoUrl !== undefined
      ).sort(x=> x.id);

      this.reviews.forEach(x=> {
         let videoUrls = x.videoUrl.split('/');
         let videoUrl = videoUrls[videoUrls.length-1];
         if(videoUrl.length > 17) {
           videoUrl = videoUrl.substr(17);
         }
        x.videoName = videoUrl;
      });


      // this.notViewed = this.reviews.filter(
      //   x =>
      //     x.videoReviewStatus == null ||
      //     x.videoReviewStatus == undefined ||
      //     x.videoReviewStatus == 0
      // );
      // this.approved = this.reviews.filter(x => x.videoReviewStatus == 1);
      // this.denied = this.reviews.filter(x => x.videoReviewStatus == 2);

      if(videoReviewStatus == 0) {
        this.notViewed = this.reviews;

        this.pagedNotViewed = this.notViewed as modelReview[];
        for (var pg of this.pagedNotViewed) {
          pg.videoThumbnails = [];
        }

        
      }
      else if(videoReviewStatus == 1) {
        this.approved = this.reviews;
        this.pagedApproved = this.approved;

      }
      else if(videoReviewStatus == 2) {
        this.denied = this.reviews;
        this.pagedDenied = this.denied;
      }


      // console.log( this.notViewed);


      // this.setPage(1);
    });
  }

  public setVideo(videoUrl: string): any {
    // let timer = TimerObservable.create(1000, 1000);
    // this.subscription = timer.subscribe(t => {
    //   var safeHtml = this.sanitizer.bypassSecurityTrustHtml(
    //     '<video  width="100%" height="100%" controls > <source src="'+ this.s3filesService.getSignedUrl( videoUrl ) +'" type="video/mp4">  </video>'
    //   );

    this.subscription = Observable.timer(0, 1000).subscribe(t => {
      var safeHtml = this.sanitizer.bypassSecurityTrustHtml(
        '<video  width="100%" height="100%" controls > <source src="' +
          this.s3filesService.getSignedUrl(videoUrl) +
          '" type="video/mp4">  </video>'
      );

      this.subscription.unsubscribe();

      return safeHtml;
    });
  }

  setPage(page: number) {
    if (page < 1) {
      return;
    }

    this.currentPage = page;
    this.Reviews(this.currentTab, this.videoPageSize , page);
    
  }

  statusChange(review, e, i, currentStatus) {
    // sender notView

    review.videoReviewStatus = e.value;

    review.videoThumbnails = [];
    review.selectedThumbnail = "" ;
    
    this._GoogleReviewDTOService.UpdateVideoReviewStatus(review).subscribe(x => {
      x;
      // console.log(x);

      this.Reviews(currentStatus, this.videoPageSize , this.currentPage);

     
    });
  }

  onTabClick(event) {
    this.currentTab = event;
    //this.setPage(1);

    this.Reviews(this.currentTab , this.videoPageSize , 1);
  }

  getSignedUrl(url: string): string {
    var result = this.s3filesService.getSignedUrl(url);
    return result;
  }
}

class modelReview extends GoogleReviewDTO {
  public videoThumbnails: any[] = [];
  public selectedThumbnail: any;
  public resultMessage: string = "";
  public thumbUploaded: boolean = false;
}
