import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { EmailValidator } from '../../../../shared/forms/validators';
import { MwFormControlComponent } from '../../../../shared/components';
import { MapwordsStore } from '../../../../shared/store';
import { ClientsService } from '../../../../shared/services/api';


@Component({
  selector: 'mw-recover',
  templateUrl: './mw-recover.component.html',
  styleUrls: ['./mw-recover.component.scss']
})
export class MwAuthRecoverComponent implements OnInit, AfterViewInit {

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;

  public token: string;

  public displayRecover = true;

  public recoverForm: FormGroup;

  public passwordForm: FormGroup;

  public isEmailExists = false;

  constructor(
    private _store: MapwordsStore,
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleService: Title,
    private _clientsService: ClientsService,
  ) { }

  ngOnInit() {
    this._titleService.setTitle('Recover Account - Mapwords');
    this.token = this._route.snapshot.params['token'];
    if (this.token) {
      this.displayRecover = false;
      this.initPasswordForm();
    } else {
      this.initRecoverForm();
    }
  }

  ngAfterViewInit() {
    this.formControls.forEach(x => x.formDirty = false);
  }

  public submitRecover(): void {
    //console.log(this.recoverForm.get('email').value);
    const email = this.recoverForm.get('email').value;
   
      this._clientsService
      .GetUniqueEmail(email)
      .subscribe(x => {       
        this.isEmailExists = x.data.answer;
        //console.log(this.isEmailExists);
        if (this.isEmailExists === true) {
          this._store.alerts.add({
            title: 'Not A Member',
            message: 'Email not found.',
            execute: () => {
              // this._router.navigateByUrl('/auth/sign-up');
            }
          });
        } else {
          if (this.recoverForm.valid) {
            this._store.auth.recover(this.recoverForm.value.email);
          } else {
            this.formControls.forEach(x => x.formDirty = true);
          }
        }
      });
      //console.log(this.isEmailExists);
      
    // if (this.recoverForm.valid) {
    //   this._store.auth.recover(this.recoverForm.value.email);
    // } else {
    //   this.formControls.forEach(x => x.formDirty = true);
    // }
  }

  public submitPassword(): void {
    if (this.passwordForm.valid) {
      this._store.auth.recoverPassword(this.token, this.passwordForm.value.password,
        x => {
          this._router.navigateByUrl('/auth/sign-in');
        }, () => {
          this._router.navigateByUrl('/auth/recover');
        });
    } else {
      this.formControls.forEach(x => x.formDirty = true);
    }
  }

  private initRecoverForm(): void {
    this.recoverForm = this._fb.group({
      email: [
        '', 
        [
          Validators.required, EmailValidator
        ]
    ]
    });
  }

  private initPasswordForm(): void {
    this.passwordForm = this._fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16)
        ]
      ],
      confirmPassword: [
        '', 
        Validators.required
      ],
    }, {
        // validator: this.passwordMatcher
        validator: this.passwordMatchValidator
      });
  }
  // private passwordMatcher(control: AbstractControl): { [key: string]: boolean } {
  //   const password = control.get('password');
  //   const confirm = control.get('confirmPassword');
  //   // tslint:disable-next-line:curly
  //   if (!password || !confirm) return null;
  //   return password.value === confirm.value ? null : { nomatch: true };
  // }  
  passwordMatchValidator(g: FormGroup) {
    if (g.get('confirmPassword').value.length > 0) {
      return g.get('password').value === g.get('confirmPassword').value
        ? null
        : { mismatch: true };
  }  
  }
}
