import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'mw-ratings',
	template: `
    <i [class]="star" *ngFor="let star of getStarts(stars)" [ngStyle]="{color: color}"></i>
    <span class="star-number" *ngIf="shownumber" [ngStyle]="{color: color}">{{stars}}</span>
  `,
	styleUrls: ['./mw-ratings.component.scss']
})
export class MwRatingsComponent implements OnInit {

	@Input()
	public stars: number = 0;
	@Input()
	public color: string;
	@Input()
	public shownumber: boolean = true;

	constructor() { }

	ngOnInit() { }

	/**
	 * Used for each comment for generate the right stars classes to display the stars elements.
	 * @param {number} Reputation
	 * @returns {Array<string>}
	 * @memberOf MwRatingsComponent
	 */
	public getStarts(reputation: number): string[] {
		let star: string = 'ion-android-star',
			starHalf: string = 'ion-android-star-half',
			emptyStar: string = 'ion-android-star-outline',
			emptyStars: number = Math.floor(reputation),
			stars: Array<string> = [];
		for (let index = 1; index < 6; index++) {
			if (index <= emptyStars) {
				stars.push(star);
			} else if ((index - 1 - reputation) % 1 !== 0 && index - 1 <= emptyStars) {
				stars.push(starHalf);
			} else {
				stars.push(emptyStar);
			}
		}
		return stars;
	}
}
