import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Client, IFClient, ProfessionalSkill, IFProfessionalSkill } from '../../../../../shared/models';
import { ClientsService } from '../../../../../shared/services/api';

@Component({
  selector: 'mw-user-work-education',
  templateUrl: './mw-user-work-education.component.html',
  styleUrls: ['./mw-user-work-education.component.scss']
})
export class UserWorkEducationComponent implements OnInit, OnChanges {

  @Input()
  public client: Client;

  public fWork: FormGroup;

  public get professionalSkills(): FormArray {
    return <FormArray>this.fWork.get('professionalSkills');
  }

  constructor(
    private _fb: FormBuilder,
    private _clientService: ClientsService
  ) { }

  public ngOnInit(): void {
    this.initForm(this.client);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.client.professionalSkills = changes['client'].currentValue.professionalSkills || [];
    this.initForm(this.client);
  }

  public submit() {
    // console.log(this.fWork.value);
    if (this.fWork.valid) {
      this._clientService.PutWork(this.fWork.value)
        .subscribe(x => {
          this.client.workName = x.data.workName;
          this.client.workTitle = x.data.workTitle;
          this.client.professionalSkills = x.data.professionalSkills;
        });
    }
  }

  public validateKey(event: KeyboardEvent): boolean {
    // if (String.fromCharCode(event.keyCode).match(/[a-z0]/g) == null) {
    if (String.fromCharCode(event.keyCode).match(/[a-zA-Z0 ]/g) == null) {
      return false;
    }
  }

  public toggleInput(saved: boolean): void {
    if (saved) {
      this.submit();
    } else {
      this.initForm(this.client);
    }
  }

  public addSkill(skill: string): void {

    if(!skill) {
      return;
    }

    if (skill.length > 0) {
      this.professionalSkills.push(
        this._fb.group(<IFProfessionalSkill>{
          name: skill,
          id: 0,
          active: true
        })
      );
    }
  }

  public removeSkill(index: number): void {
    const skill = <ProfessionalSkill>this.professionalSkills.at(index).value;
    if (skill.id) {
      skill.active = false;
    } else {
      this.professionalSkills.removeAt(index);
    }
    // console.log(this.professionalSkills.value)
  }

  private initForm(client: Client): void {
    this.client = client;
    this.fWork = this._fb.group(<IFClient>{
      workName: [client.workName, [Validators.minLength(2), Validators.maxLength(30)]],
      workTitle: [client.workTitle, [Validators.minLength(2), Validators.maxLength(30)]],
      professionalSkills: this._fb.array(this.formProfessionalSkills())
    });
  }

  private formProfessionalSkills(): FormGroup[] {
    const list = [];
    if (this.client.professionalSkills !== null) {
      this.client.professionalSkills.forEach(x => {
        list.push(this._fb.group(<IFProfessionalSkill>{
          name: [x.name],
          active: [x.active],
          addedDate: [x.addedDate],
          id: x.id
        }));
      });
      return list;
    }
  }

}
