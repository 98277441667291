import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MapwordsStore } from '../../../../../shared/store';

@Component({
  selector: 'mw-listing-verification',
  template: `
    <div class="mw-verify-container" fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner fxFlex="40px"></mat-spinner>
      <div class="text" fxFlex="50px">
        Verifying listing...
      </div>
    </div>
  `,
  styles: [`
    .mw-verify-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      height: calc(50vh - 50px);
    }
    mat-spinner {
      margin-bottom: 20px;
    }
    .text {
      font-size: 16px;
      color: #999;
    }
  `]
})
export class MwListingVerificationComponent implements OnInit {

  public token: string;

  constructor(
    public store: MapwordsStore,
    public _route: ActivatedRoute,
    public _router: Router
  ) { }

  ngOnInit() {}

}
