
import { ChallengeStatusTypes } from './ChallengeStatusTypes';
import { Client } from './Client';
import { GoogleReplies } from './GoogleReplies';


export interface IGoogleReview  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    message?: string;
    stars?: number;
    challengeStatus?: ChallengeStatusTypes;
    qucontact?: boolean;
    quoffer?: boolean;
    qudiscuss?: boolean;
    qurefund?: boolean;
    quresolve?: boolean;
    qupay?: number;
    qufriendemployee?: boolean;
    qugiftofferreview?: boolean;
    quencouragereview?: boolean;
    qu1stvisit?: boolean;
    imageUrl?: string;
    placeId?: string;
    client?: Client;
    clientId?: number;
    replies?: GoogleReplies[];
    videoUrl?: string;
    videoReviewStatus: number;
    likes?: number;
    dislikes?: number;
    //thumbs?: ThumbsGoogleReview[];
    selectedLikeType?: number;
}
export class GoogleReview  implements IGoogleReview {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public message: string;
    public stars: number;
    public challengeStatus: ChallengeStatusTypes;
    public qucontact: boolean;
    public quoffer: boolean;
    public qudiscuss: boolean;
    public qurefund: boolean;
    public quresolve: boolean;
    public qupay: number;
    public qufriendemployee: boolean;
    public qugiftofferreview: boolean;
    public quencouragereview: boolean;
    public qu1stvisit: boolean;
    public imageUrl: string;
    public placeId: string;
    public client: Client;
    public clientId: number;
    public replies: GoogleReplies[];
    public videoUrl: string;
    public videoReviewStatus: number;
    public likes: number;
    public dislikes: number;
    //public thumbs: ThumbsGoogleReview[];
    public selectedLikeType: number;

    constructor(obj?: IGoogleReview) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.message = obj.message !== undefined ? obj.message : null;
        this.stars = obj.stars !== undefined ? obj.stars : null;
        this.challengeStatus = obj.challengeStatus !== undefined ? obj.challengeStatus : null;
        this.qucontact = obj.qucontact !== undefined ? obj.qucontact : null;
        this.quoffer = obj.quoffer !== undefined ? obj.quoffer : null;
        this.qudiscuss = obj.qudiscuss !== undefined ? obj.qudiscuss : null;
        this.qurefund = obj.qurefund !== undefined ? obj.qurefund : null;
        this.quresolve = obj.quresolve !== undefined ? obj.quresolve : null;
        this.qupay = obj.qupay !== undefined ? obj.qupay : null;
        this.qufriendemployee = obj.qufriendemployee !== undefined ? obj.qufriendemployee : null;
        this.qugiftofferreview = obj.qugiftofferreview !== undefined ? obj.qugiftofferreview : null;
        this.quencouragereview = obj.quencouragereview !== undefined ? obj.quencouragereview : null;
        this.qu1stvisit = obj.qu1stvisit !== undefined ? obj.qu1stvisit : null;
        this.imageUrl = obj.imageUrl !== undefined ? obj.imageUrl : null;
        this.placeId = obj.placeId !== undefined ? obj.placeId : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
        this.replies = obj.replies !== undefined ? obj.replies : null;
        this.videoUrl = obj.videoUrl !== undefined ? obj.videoUrl : null;
        this.videoReviewStatus = obj.videoReviewStatus !== undefined ? obj.videoReviewStatus : null;
        this.likes = obj.likes !== undefined ? obj.likes: null;
        this.dislikes = obj.dislikes !== undefined ? obj.dislikes: null;
        //this.thumbs = obj.thumbs !== undefined ? obj.thumbs : null;       
        this.selectedLikeType = obj.selectedLikeType !== undefined ? obj.selectedLikeType:null; 
    }
}
// For reactive forms intended
export interface IFGoogleReview  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    message?: any; 
    /**
    * Is number
    */
    stars?: any; 
    /**
    * Is ChallengeStatusTypes
    */
    challengeStatus?: any; 
    /**
    * Is boolean
    */
    qucontact?: any; 
    /**
    * Is boolean
    */
    quoffer?: any; 
    /**
    * Is boolean
    */
    qudiscuss?: any; 
    /**
    * Is boolean
    */
    qurefund?: any; 
    /**
    * Is boolean
    */
    quresolve?: any; 
    /**
    * Is number
    */
    qupay?: any; 
    /**
    * Is boolean
    */
    qufriendemployee?: any; 
    /**
    * Is boolean
    */
    qugiftofferreview?: any; 
    /**
    * Is boolean
    */
    quencouragereview?: any; 
    /**
    * Is boolean
    */
    qu1stvisit?: any; 
    /**
    * Is string
    */
    imageUrl?: any; 
    /**
    * Is string
    */
    placeId?: any; 
    /**
    * Is Client
    */
    client?: any; 
    /**
    * Is number
    */
    clientId?: any; 
    /**
    * Is GoogleReplies[]
    */
    replies?: any; 
    videoUrl?: any;
    videoReviewStatus: number;
    //thumbs?: any;
    likes?: any;
    dislikes?: any;
    selectedLikeType?: any;
}
