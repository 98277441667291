import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class AuthHttpService extends Http {

  constructor(
    private _router: Router,
    backend: XHRBackend,
    defaultOptions: RequestOptions) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).catch((error: Response) => {

      // Emre
      // console.log("error") ;
      // console.log(error) ;


      if (error.status === 401 || error.status === 403) {
        localStorage.clear();
        this._router.navigateByUrl(`/auth/sign-in?redirect=${this._router.url}&reason=sessionExpired`);
      }

      return Observable.throw(error);


    });
  }
}
