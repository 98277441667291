import { Component, OnInit, ViewChildren, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MwFormControlComponent } from '../../../../../shared/components/mw-formcontrol/mw-formcontrol.component';
import { MapwordsStore } from '../../../../../shared/store/mapwords.store';
import { Router } from '@angular/router';
import { ClientsService } from '../../../../../shared/services/api';
import { IFClient, Client } from '../../../../../shared/models/Client';
import { Listing } from '../../../../../shared/models/Listing';
import { CookieService } from '../../../../../../node_modules/ngx-cookie-service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'mw-agent-generate-form',
  templateUrl: './mw-agent-generate-form.component.html',
  styleUrls: ['./mw-agent-generate-form.component.scss']
})
export class MwAgentGenerateFormComponent implements OnInit {
  public fClient: FormGroup;
  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;

  @Input() client: Listing;

  @Output() tempClient: EventEmitter<any> = new EventEmitter();

  public userNameAvailable = false;
  public emailAvailable = true;

  public submitted = false;

  //public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  public emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  public unamePattern = '^[a-z0-9_-]{2,20}$';
  public fnamePattern = '^[a-zA-Z]{2,20}$';
  public lnamePattern = '^[a-zA-Z]{2,20}$';
  public show: boolean;

  public listings: Listing[] = [] ;
  public emailRelatedClient: Client;

  public lastValidatedEmail = '' ;

  public bShowAll = false;

  public toolTipForListings = '' ;

  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 

  constructor(
    private _fb: FormBuilder,
    private store: MapwordsStore,
    private _clientsService: ClientsService,
    private _router: Router,
    private cookieService: CookieService 
  ) {}

  ngOnInit() {
    this.cookieExpire.setTime(this.cookieTime);
    
    this.initSignupForm();
    this.show = false;
  }

  private initSignupForm(): void {
    this.fClient = this._fb.group(<IFClient>{
      // username: [
      //   '',
      //   [Validators.required, Validators.minLength(2), Validators.maxLength(20)]
      // ],
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      //email: ['', [Validators.required, EmailValidator.validate]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern), this.emailCheck.bind(this)]],
      password: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(16)]
      ],
      isRegisteredByAgent: [true]
    });
  }

  public submit(): void {
    //console.log(this.fClient.controls['email'].value);
    //console.log(this.fClient.get('email').value);
    this.submitted = true;
    if (this.fClient.valid) {
      this.store.client.signUp(this.fClient.value, () => {
        // console.log(this.fClient.value);
        // console.log('Success');
        // this.fClient.reset();
        this.cookieService.set(
          'clientSessionId',
          this.fClient.controls['email'].value
          , this.cookieExpire ,'/');
        const clientSessionId = this.cookieService.get('clientSessionId');
        //console.log(clientSessionId);
        this.setClientListings(this.fClient.get('email').value);   
        
        this.tempClient.emit(clientSessionId);
      });
    } else {
      this.formControls.forEach(x => (x.formDirty = true));
    }
  } 

  public usernameCheck(): void {
    //console.log(this.fClient.get('username').value);
    if (this.fClient.get('username').valid) {
      this._clientsService
        .GetUniqueUsername(this.fClient.get('username').value)
        .subscribe(x => {
          this.userNameAvailable = x.data.answer;
          // if (this.userNameAvailable === false) {
          //   this.store.alerts.add({
          //     title: 'Existing Username',
          //     message: 'Username already exists',
          //     execute: () => {
          //       location.reload();
          //     }
          //   });
          // }
        });
    }
  }

  public emailCheck(control: FormControl) {
    const email = control.value;

    if(email === this.lastValidatedEmail)
    {
      return;
    }

    this.lastValidatedEmail = email;
    this.listings = [] ;
    this.toolTipForListings = '' ;
    this.emailRelatedClient = null;

    this._clientsService
        .GetUniqueEmail(email)
        .subscribe(x => {        
          if (x.data.answer) {
            this.emailAvailable = true;   
            return;
          }
          else{
            // console.log('XXXXX333 setClientListings');
            this.setClientListings(this.fClient.get('email').value);            
          }
          // this.emailAvailable = x.data.answer;

        });  
  }

  // Sean
  public password = '';
  public generatePassword() {
    this.password = this.randomPassword(6);
  }

  public randomPassword(length) {
    const chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890';
    let pass = '';
    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  public setClientListings(email: string): void {

    this.toolTipForListings = '' ;
    

    if (this.fClient.get('email').valid) {

      this.emailRelatedClient = null;
      this.listings = [];

      this._clientsService
        .GetListingForExistingClient(email)
        .subscribe(x => {
          this.listings = x.data.listings;
          this.emailRelatedClient = x.data.client;

          this.cookieService.set(
            'clientSessionId',
            email
            , this.cookieExpire,'/' );


          this.listings.forEach(element => {
            this.toolTipForListings +=  element.name + '  , ';
          });

          if(this.toolTipForListings.length>0) {
            this.toolTipForListings = this.toolTipForListings.substring(0, this.toolTipForListings.length-2) ;
          }

          // console.log(this.listings);
          // console.log(this.emailRelatedClient);

          } ,
          err => {
            console.log(err);
          }
        );
    }
  }

  public showAll() : void {
    this.bShowAll = ! this.bShowAll;
  }
}