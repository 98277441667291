

export enum PriceTypes {
    s,
    ss,
    sss,
    ssss,
    
}

