import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { CurrentAddressApiModel } from '../../models/CurrentAddressApiModel';
import { CurrentPositionApiModel } from '../../models/CurrentPositionApiModel';
import { BaseRestService } from './base.service';
import { environment } from '../../../environments/environment';
import { CurrentPositionGoogleApiModel } from '../../models/CurrentPositionGoogleApiModel';
import { ErrorResolver } from '../../../app/common/app-error-resolver';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class LocationService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /**
    *   Lookup Address by Lat and Long
    *   @return Address
    *   @param lat - Lat
    *   @param lng - Long
    */
    public GetCurrentAddress(lat: number , lng: number ): Observable<RG<CurrentAddressApiModel>> {
    
        const strURL = 'api/v1/location?lat=' + lat.toString() + '&lng=' + lng.toString();

        return this.get( strURL);
    }


    
    /**
    *   Lookup Address 
    *   @return ip
    */
   public GetLocationFromIp( ): Observable<RG<CurrentAddressApiModel>> {

    const strURL = 'api/v1/iplocation';

    return this.get(strURL);
   
    }
 


    /**
    *   Lookup Lat and Long by City and State
    *   @return
    *   @param city - City
    *   @param state - State
    */
    public GetLocation(city: string , state: string ): Observable<RG<CurrentPositionApiModel>> {

        return this.get(`api/v1/location/geo?city=${city}&state=${state}`);
    }

    /**
    *   Helping typescript
    *   @return
    *   @param model -
    */
    public SetProperties(model: CurrentAddressApiModel , model1: CurrentPositionApiModel ): Observable<RG<string>> {
       return this.post(`api/location/`, JSON.stringify({ model: model, model1: model1 }) );
    }
}

