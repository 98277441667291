import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { MapwordsStore } from '../../store';
import { when, reaction, toJS } from 'mobx';
import { IAlertViewModel as AlertModel, ActionTypes } from '../../viewmodels';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { MwAlertComponent } from './mw-alert.component';

export enum KEY_CODE {
  Enter = 13,
  Esc = 27
}

@Component({
  selector: 'mw-alert-manager',
  templateUrl: './mw-alert-manager.component.html',
  styleUrls: ['./mw-alert-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class MwAlertManagerComponent implements OnInit {

  public alert: AlertModel;
  public show = false;
  public pressedButton = '';

  constructor(
    public store: MapwordsStore,
    private _change: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit() { 
  
    this.reactToNewAlerts(); 
  
  }

  public showAlerts(): void {

    this.alert = _.first(this.store.alerts.activedAlerts);
    this._change.markForCheck(); // Push change
  }

  public action(action: ActionTypes): void {


    if (this.alert.execute === undefined) {
      this.alert.execute = () => {};
    }
    this.store.alerts.execute(this.alert, action);
  }

  private reactToNewAlerts(): void {
    reaction(
      () => this.store.alerts.activedAlerts,
      () => this.showAlerts()
    );
  }

  public closeAlert() {

  }

  // @HostListener('document:keydown', ['$event'])
  // public handleKeyBoardEvent(event: KeyboardEvent): void {
  //   if (event.keyCode === KEY_CODE.Enter) {
  //     this.action(2);
  //   }

  //   if (event.keyCode === KEY_CODE.Esc) {
  //     this.action(0);
  //   }

  // }

}
