import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { ListingServices } from '../../models/ListingServices';
import { BaseRestService } from './base.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class ListingServicesService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get all the services of a Listing
    *   @return An array of ListingService objects. 
    *   @param listId - The ID of Listing(int)
    */
    public GetByListingId(listId: number ): Observable<RG<ListingServices[]>> {
        
        return this.get( `api/v1/listingservices?listId=${listId}`);
    }

    /** 
    *   Helping typescript
    *   @return  
    *   @param model - 
    */
    public SetProperties(model: ListingServices ): Observable<RG<string>> {
        
        return this.post(`api/listingServices/`, JSON.stringify(model) );
    }
}

