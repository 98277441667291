import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';

import { Listing, GetListingBySearchTermRouteModel as Filter } from '../../../../../shared/models';
import { ActionTypes } from '../../../../../shared/viewmodels';
import { ListingResolver, S3filesService } from '../../../../../shared/services';
import { environment } from '../../../../../environments/environment';
import { MapwordsStore } from '../../../../../shared/store';

@Component({
  selector: 'mw-agent-search-list',
  templateUrl: './mw-agent-search-list.component.html',
  styleUrls: ['./mw-agent-search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwAgentSearchListComponent implements OnInit, OnDestroy {
  public TitleLength: string;

  openOrClose: string;

  @Input()
  public pageposition = 1;

  @Input()
  public pagelimit = 10;

  public listings: Listing[] = [];

  public imageOrigin = environment.imagesOrigin;

  public listing$Subs: Subscription;
  public params$Subs: Subscription;

  //public claimIsOpen = false;

  open() {
    if (this.openOrClose === 'inactive') {
      this.openOrClose = 'active'
    } else if (this.openOrClose === 'active') {
        this.openOrClose = 'inactive'
    } else {
        this.openOrClose = 'inactive'
    }
  }

  constructor(
    public store: MapwordsStore,
    private _listingResolver: ListingResolver,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private s3filesService: S3filesService
  ) { }

  ngOnInit() {
    this.listing$Subs = this._route.data.subscribe(x => {
      if (window.innerHeight < 800) {
        this.TitleLength = '30';
      } else {
        this.TitleLength = '55';
      }

      //console.log(x['listing']);
      const pagination: any = x['listing']['pagination'];
      this.store.search.updatePageLimit(+pagination.pages);
      this.store.search.updateResultsCount(+pagination.queryCount);
      const results: Listing[] = x['listing']['data'];

      if (results.length === 0) {
        console.log('No results add alert');
        this.store.alerts.add({
          id: 1,
          title: 'No results',
          // tslint:disable-next-line:max-line-length
          message: 'No Results Found',
          okLabel: 'Greater Distance',
          cancelLabel: 'Cancel',
          hasCancel: true,
          execute: (action: ActionTypes) => {
            if (action === ActionTypes.ok) {
              // const filter = this.store.search.cloneFilter;
              // filter.distance = +filter.distance + 10;
              // this.store.search.updateFilter(filter);

              this._router.navigate(['/cp/agent', {
                outlets: {
                  'agentSearch':
                    [
                      this.store.search.filter.searchterm,
                      this.store.search.filter.latitude,
                      this.store.search.filter.longitude,
                      this.store.search.filter.distance + 10,
                      this.store.search.filter.price,
                      this.store.search.filter.rating,
                      this.store.search.filter.open,
                      1
                    ],
                  'agentListing': null
                }
              }
              ]);
            }
          }
        });
        return;
      }
      this.store.search.searchListings(results);
      this.listings = this.store.search.listingResults;
      this._titleService.setTitle(`Searching ${this.store.search.filter.searchterm} - Mapwords`);
    });

    this.params$Subs = this._route.params.subscribe((x: Filter) => {
      if (x.searchterm === null) { return; }
      this._listingResolver.filterUrlChange(x);
    });

  }

  ngOnDestroy() {
    this.listing$Subs.unsubscribe();
    this.params$Subs.unsubscribe();
  }

  public loadListing(id: number, place_id: string): void {
    // console.log('Load Listing');
    // console.log(this.store.search.listingResults) ;
    // console.log(id);
    // console.log(place_id);
    
    this._router.navigate(['/cp/agent', { outlets: {'agentListing': [id, place_id] } }]);
    


  }

  public hoverListing(listing: Listing, active: boolean): void {
    if (listing.active && !this.store.searchListing.detailIsOpen) {
      this.store.search.hoverListing(listing.googlePlaceId);
    }
  }


  public getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }



}
