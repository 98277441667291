import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchResolver, ListingResolver, URLListingResolver } from '../../../shared/services';

import { MwHomeComponent } from './mw-home.component';
import { MwDetailPreviewComponent, MwListingPopComponent, MwPhotoPopComponent, Mw360PopComponent, MwMenuPopComponent } from '.';

const homeRoutes: Routes = [
  {
    path: 'home',  component: MwHomeComponent, children: [
      {
        path: ':searchterm/:latitude/:longitude/:distance/:price/:rating/:open/:page/:order',
        component: MwDetailPreviewComponent, outlet: 'search',
        resolve: { listings: SearchResolver }
      },
      {
        path: ':id/:placeid', component: MwListingPopComponent, outlet: 'listing',
        resolve: { listing: ListingResolver }
      },
      {
        path: ':id/:placeid', component: Mw360PopComponent, outlet: 'streetview',
        resolve: { listing: ListingResolver }
      },
      {
        path: ':id/:placeid', component: MwPhotoPopComponent, outlet: 'photo',
        resolve: { listing: ListingResolver }
      },
      {
          path: ':id/:placeid', component: MwMenuPopComponent, outlet: 'menu',
          resolve: { listing: ListingResolver }
      }
    ] 
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(homeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class MwHomeRoutingModule { }

export const routedComponents = [
  MwListingPopComponent,
  MwDetailPreviewComponent,
  Mw360PopComponent,
  MwPhotoPopComponent,
  MwMenuPopComponent
];
