import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IListing, Listing, GetListingBySearchTermRouteModel as Filter, RG } from '../models';
import { ListingsService } from './api';
import { MapwordsStore } from '../store';
import { Observable } from 'rxjs/Observable';
import { ErrorResolver } from '../../app/common/app-error-resolver';

@Injectable()
export class AgentSearchResolver implements Resolve<RG<IListing[]>> {

  constructor(
    private _listingsService: ListingsService,
    private _router: Router,
    public store: MapwordsStore) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // console.log('resolve') ;

    const filter = new Filter({
      searchterm: route.params['searchterm'],
      distance: +route.params['distance'],
      latitude: +route.params['latitude'],
      longitude: +route.params['longitude'],
      open: route.params['open'],
      price: route.params['price'],
      rating: route.params['rating']
    });

    // console.log(route.params['page']) ;

    const page = +route.params['page'];
    const results$ = this._listingsService.AgentGetListingBySearchTerm(filter, page);
    return results$.catch(e => {
      //   this.store.alerts.add({
      //     title: 'Oops',
      //     message: 'Unable to complete the operation at this time, please try again later.',
      //     execute: () => {
      //       location.reload();
      //     }
      //   });
      // return Observable.empty();
      return new ErrorResolver().resolve(e);
    });
  }

}
