
import { Menus_Sections } from './Menus_Sections';

export interface IMenus  {
    Id?: number;
    Active?: boolean;
    addedDate?: Date;
    ListingId?: number;
    GooglePlaceId?: string;
    menu_Title?: string;
    menu_OrderBy?: number;
    ClientId?: number;
    menuSections?: Menus_Sections[];    
}
export class Menus implements IMenus {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public ListingId: number;
    public GooglePlaceId: string;
    public menu_Title: string;
    public menu_OrderBy: number;
    public ClientId: number;
    public menuSections: Menus_Sections[];   
    constructor(obj?: IMenus) {        
        this.id = obj.Id !== undefined ? obj.Id : null;
        this.active = obj.Active !== undefined ? obj.Active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.ListingId = obj.ListingId !== undefined ? obj.ListingId : null;
        this.GooglePlaceId = obj.GooglePlaceId !== undefined ? obj.GooglePlaceId : null;
        this.menu_Title = obj.menu_Title !== undefined ? obj.menu_Title : null;
        this.menu_OrderBy = obj.menu_OrderBy !== undefined ? obj.menu_OrderBy : null;
        this.menuSections = obj.menuSections !== undefined ? obj.menuSections : null;             
    }
}
// For reactive forms intended
export interface IFMenus  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is number
    */
    ListingId?: any; 
    /**
    * Is string
    */
    GooglePlaceId?: any; 
    /**
    * Is string
    */
    menu_Title?: any; 
    /**
    * Is number
    */
    menu_OrderBy?: any; 
    /**
    * Is number
    */
    ClientId?: any; 
    /**
    * Is Menus_SectionsObject
    */
    menuSections?: any;     
}
