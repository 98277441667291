import { Component, OnInit, ContentChildren, QueryList, AfterViewInit } from '@angular/core';
import { MwTabComponent } from './mw-tab.component';

@Component({
  selector: 'mw-tab-group',
  template: `
    <div class="mw-tab-group">
      <div class="mw-tabs-wrapper">
        <div class="mw-tabs-title">
          <ng-container *ngFor="let tab of tabs; let i = index">
            <div class="mw-tab" (click)="activeTab(i)" [class.mw-tab-active]="tab.active">
              {{tab.name}}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mw-tabs-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./mw-tab-group.component.scss']
})
export class MwTabGroupComponent implements OnInit, AfterViewInit {

  @ContentChildren(MwTabComponent)
  public tabs: QueryList<MwTabComponent>;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.tabs.forEach(x => x.active = false);
    this.tabs.first.active = true;
  }

  public activeTab(index: number): void {
    this.tabs.forEach(x => x.active = false);
    this.tabs.toArray()[index].active = true;
  }

}
