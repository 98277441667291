import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { Category } from '../../models/Category';
import { MasterCategory } from '../../models/MasterCategory';
import { BaseRestService } from './base.service';
import { Service } from '../../models/Service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class CategoriesService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get all Master Categories
    *   @return An Array of MasterCategory objects 
    */
    public GetMaster(): Observable<RG<MasterCategory[]>> {
       
        return this.get( `api/v1/categories/master`);

    }

    /** 
    *   Get Master Category by Name
    *   @return An Array of MasterCategory objects 
    *   @param name - The Name to search with
    */
    public GetbyMasterCategoryName(name: string ): Observable<RG<MasterCategory[]>> {
      
        return this.get( `api/v1/categories/master/${name}`);
    }

    /** 
    *   Get all the Categories
    *   @return An array of Categories objects. 
    */
    public GetRegular(page: number  = 1): Observable<RG<Category[]>> {
        
        return this.get( `api/v1/categories/regular/${page || 1}`);
    }

    /** 
    *   Get Listing Categories by Name
    *   @return An Array of MasterCategory objects 
    *   @param name - The name to look for
    */
    public GetbyCategoryName(name: string ): Observable<RG<Category[]>> {
       
        return this.get(`api/v1/categories?name=${name}`);
          
    }

    /** 
    *   Get Listing Category and MasterCategory
    *   @return New Listing Category object 
    */
    public postCategory(d: Category , s: MasterCategory ): Observable<RG<Category[]>> {
       
        return this.post( `api/categories/`, JSON.stringify({ d: d, s: s }) );
            
    }

    /** 
    *   Get Listing Categories by Name
    *   @return An Array of MasterCategory objects 
    *   @param name - The name to look for
    */

    public GetbyServiceName(name: string ): Observable<RG<Service[]>> {       
        return this.get(`api/v1/services?name=${name}`);      
    }
}

