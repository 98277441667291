import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Listing } from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
import { S3filesService } from '../../../../shared/services';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Component({
  moduleId: module.id,
  selector: 'mw-listing-map-preview',
  templateUrl: './mw-listing-map-preview.component.html',
  styleUrls: ['./mw-listing-map-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwListingMapPreviewComponent implements OnInit {

  public imageOrigin = environment.imagesOrigin;

  constructor(
    public store: MapwordsStore,
    private _router: Router,
    private s3filesService: S3filesService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
  }

  public loadListing(id: number, place_id: string): void {
    // this.listingActive.googlePlaceId = place_id;
    // console.log('Load Listing');
    this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }])
      .then(() => {
      });
  }

  public openInfoWindow(listing: Listing): void {
    if (listing.active) {
      this.store.search.hoverListing(listing.googlePlaceId);
    }
  }

  public getAvatar(avatar: string): string {
    
    var result = this.s3filesService.getSignedUrl(avatar);

    return result;
  }

  public url(param: string): string{
    return param;
  }

}
