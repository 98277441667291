import { MwWriteReviewService } from './mw-write-review/mw-write-review.service';
import { MwClaimListingService } from './mw-claim-listing/mw-claim-listing.service';
import { MwSearchService } from './mw-search/mw-search.service';
import { MwListingMapPreviewService } from './mw-listing-map-preview/mw-listing-map-preview.service';
import { MwSearchComponent } from './mw-search/mw-search.component';
import { MwQuickSearchComponent } from './mw-quick-search/mw-quick-search.component';
import { MwWriteReviewComponent } from './mw-write-review/mw-write-review.component';
import { MwListingMapPreviewComponent } from './mw-listing-map-preview/mw-listing-map-preview.component';
import { MwClaimListingComponent } from './mw-claim-listing/mw-claim-listing.component';
import { MwListingPopComponent } from './mw-listing-pop/mw-listing-pop.component';
import { MwPinCodeComponent } from './mw-phone-verification/mw-phone-verification.component';
import { MwDetailPreviewComponent } from './mw-detail-preview/mw-detail-preview.component';
import { MwListingReviewComponent } from './mw-listing-review/mw-listing-review.component';
import { Mw360PopComponent } from './mw-360-pop/mw-360-pop.component';
import { MwPhotoPopComponent } from './mw-photo-pop/mw-photo-pop.component';
import { MwMenuPopComponent } from './mw-menu-pop/mw-menu-pop.component';
import { MwSharePopComponent } from './mw-share-pop/mw-share-pop.component';
import { MwAdsListingComponent } from './mw-ads-listing/mw-ads-listing.component';
import { MwBusinessPartnersComponent } from './mw-business-partners/mw-business-partners.component';
import { MwCompleteProfilePopComponent } from './mw-complete-profile-pop/mw-complete-profile-pop.component';

// Services always goes first than components

export * from './mw-write-review/mw-write-review.service';
export * from './mw-search/mw-search.service';
export * from './mw-claim-listing/mw-claim-listing.service';
export * from './mw-listing-map-preview/mw-listing-map-preview.service';

// Then Components
export * from './mw-write-review/mw-write-review.component';
export * from './mw-listing-pop/mw-listing-pop.component';
export * from './mw-search/mw-search.component';
export * from './mw-quick-search/mw-quick-search.component';
export * from './mw-listing-map-preview/mw-listing-map-preview.component';
export * from './mw-claim-listing/mw-claim-listing.component';
export * from './mw-phone-verification/mw-phone-verification.component';
export * from './mw-listing-review/mw-listing-review.component';
export * from './mw-detail-preview/mw-detail-preview.component';
export * from './mw-share-pop/mw-share-pop.component';
export * from './mw-360-pop/mw-360-pop.component';
export * from './mw-photo-pop/mw-photo-pop.component';
export * from './mw-menu-pop/mw-menu-pop.component';
export * from './mw-ads-listing/mw-ads-listing.component';
export * from './mw-business-partners/mw-business-partners.component';
export * from './mw-complete-profile-pop/mw-complete-profile-pop.component';

// import {
//   MwWriteReviewService,
//   MwClaimListingService,
//   MwSearchService,
//   MwListingMapPreviewService
// } from './';

// import {
//   MwSearchComponent,
//   MwQuickSearchComponent,
//   MwWriteReviewComponent,
//   MwListingMapPreviewComponent,
//   MwClaimListingComponent,
//   MwDetailPreviewComponent,
//   MwListingPopComponent,
//   MwListingReviewComponent,
//   MwSharePopComponent,
//   Mw360PopComponent,
//   MwPhotoPopComponent,
//   MwPinCodeComponent,
//   MwMenuPopComponent,
//   MwAdsListingComponent,
//   MwBusinessPartnersComponent,
//   MwCompleteProfilePopComponent
// } from './';

export const HomeServices = [
  MwWriteReviewService,
  MwClaimListingService,
  MwSearchService,
  MwListingMapPreviewService
];

export const HomeComponents = [
  MwSearchComponent,
  MwQuickSearchComponent,
  // MwWriteReviewComponent,
  MwListingMapPreviewComponent,
  MwClaimListingComponent,
  MwPinCodeComponent,
  MwDetailPreviewComponent,
  MwListingPopComponent,
  MwListingReviewComponent,
  Mw360PopComponent,
  MwPhotoPopComponent,
  MwMenuPopComponent,
  MwSharePopComponent,
  MwAdsListingComponent,
  MwBusinessPartnersComponent,
  MwCompleteProfilePopComponent
];

export const HomeEntryComponents = [
  // MwClaimListingComponent,
  // MwWriteReviewComponent
];
