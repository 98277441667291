// tslint:disable-next-line:max-line-length
import { Component, OnInit, QueryList, ViewContainerRef, ViewChildren, ViewChild, Input, AfterViewInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Listing, IFListing, IFListingTime, IFBusinessAddress, IFListingContact, IFListingConfigs, IFPayments,
  IFListingServices, IFCategoryListing, IFMasterCategoryListing, MasterCategory, Category, ListingTime
} from '../../../../../shared/models';
import { ListingsService, CategoriesService } from '../../../../../shared/services/api';
import { environment } from '../../../../../environments/environment';
import * as _ from 'lodash';
import { listDays } from '../../../../../shared/viewmodels';
import { MwImageEditorService, MwMapComponent } from '../../../../../shared/components';
import { ArrayValidator } from '../../../../../shared/forms/validators';
import { MapwordsStore } from '../../../../../shared/store';
import { google } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'mw-billing',
  templateUrl: './mw-billing.component.html',
  styleUrls: ['./mw-billing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MwBillingComponent implements OnInit, OnChanges, AfterViewInit {

  public imageOrigin = environment.imagesOrigin;

  sortedData;

  @Input()
  public listing: Listing;

  @ViewChild(MwMapComponent)
  public map: MwMapComponent;

  public fListing: FormGroup;

  public get services(): FormArray {
    return <FormArray>this.fListing.get('services');
  }

  public get hours(): any[] {
    const list = _.orderBy(<Array<any>>this.fListing.get('listingHours').value, ['dayName']);
    return list;
  }

  public get listingHoursForm(): FormArray {
    return <FormArray>this.fListing.get('listingHours');
  }

  public get categories(): FormArray {
    return <FormArray>this.fListing.get('categories');
  }

  public get masterCategories(): FormArray {
    return <FormArray>this.fListing.get('masterCategories');
  }

  public get listingHours(): ListingTime[] {
    const list = _.orderBy(this.listing.listingHours, ['dayName']);
    return list;
  }

  public cardType = [
    {
      name: 'America Express',
      value: 0,
    },
    {
      name: 'Discover',
      value: 1,
    },
    {
      name: 'MasterCard',
      value: 2
    },
    {
      name: 'Visa',
      value: 3
    },
    {
      name: 'PayPal',
      value: 4
    }
  ];

  public listDays: { name, value }[] = listDays;

  public dayhold;

  public indx1;

  public indx2;

  public newTimes: ListingTime[] [];

  public masterCategoriesCatalog: { name, value }[] = [];

  constructor(
    private _fb: FormBuilder,
    private _listingsService: ListingsService,
    private _categoriesService: CategoriesService,
    private _editor: MwImageEditorService,
    private _viewRef: ViewContainerRef,
    public store: MapwordsStore,
    private _router: Router
  ) { }

  public ngOnInit() {
    if (this.listing.ownerId !== this.store.auth.user.id) {
      this._router.navigateByUrl('/cp/user');
    }

    this.indx1 = -1;
    this.indx2 = 0;
    this.listing.listingHours.forEach( x => {
      if (this.dayhold !== x.dayName) {
          this.dayhold = x.dayName;
      } else {
        x.dayName = 7;
      }
    });

    this.initForm();
    this.getMasterCategoriesCatalog();

  }

  ngAfterViewInit() {
    if (this.map) {
      this.map.api.getNativeMap().then(map => {
        map.addListener('idle', () => {
          google.maps.event.trigger(map, 'resize');
          map.setCenter({ lat: this.listing.businessAddress.latitude, lng: this.listing.businessAddress.longitude });
        });
      });
    }
  }

  public validateKey(event: KeyboardEvent): boolean {
    if (String.fromCharCode(event.keyCode).match(/[a-z0-9]/g) == null) {
      return false;
    }
  }

  public ngOnChanges() {
    this.initForm();
  }

  public submitForm(): void {
    if (this.fListing.valid) {
      this.fListing.value.masterCategories = this.fListing.value.masterCategories.filter(x => x.removeFinal !== true);
      this.fListing.value.categories = this.fListing.value.categories.filter(x => x.removeFinal !== true);
      this.fListing.value.services = this.fListing.value.services.filter(x => x.removeFinal !== true);
      this._listingsService.Put(this.fListing.value)
        .subscribe(x => {
          this.listing = x.data;
          this.initForm();
          this.imageOrigin = environment.imagesOrigin;
        });
    }
  }

  public toggleInput(isSaved: boolean) {
    if (isSaved) {
      this.submitForm();
    } else {
      this.imageOrigin = environment.imagesOrigin;
      this.initForm();
    }
  }

  public saveHours(hours: ListingTime[]): void {
    //console.log(hours)
    const formHours = <FormArray>this.fListing.get('listingHours');
    // tslint:disable-next-line:no-shadowed-variable
    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }

    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

      // if( !(hours[index].start && hours[index].end) && !hours[index].closed){
      //   hours.splice(index,1);
      // }
    }

    this.listing.listingHours = hours;
    let index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });

    this.submitForm();

    // tslint:disable-next-line:no-shadowed-variable
    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }

    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

      // if( !(hours[index].start && hours[index].end) && !hours[index].closed){
      //   hours.splice(index,1);
      // }
    }
    
    this.listing.listingHours = hours;
    index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });


  }

  public editPhoto(): void {
    this._editor.open(this.imageOrigin + this.listing.avatar, this._viewRef)
      .subscribe(x => {
        if (x === '' || x === undefined || x === null) { return; }
        this.imageOrigin = '';
        this.fListing.value.avatar = x;
        this.fListing.get('avatar').setValue(x);
      });
  }

  public addToList(value: any, listName: string): void {
    if (value === undefined) { return; }
    if (value.id === undefined || value.id === '') { return; }
    const list = <FormArray>this.fListing.get(listName);
    let object: AbstractControl;
    switch (listName) {
      case 'services':
        let serviceName: string = value.id;
        serviceName = serviceName.trim().replace(/\s+/g, ' ').toLowerCase();
        object = list.controls.find(x => x.value.service === serviceName);
        value.name = serviceName;
        value.id = null;
        break;
      case 'categories':
        object = list.controls.find(x => x.value.name === value.id);
        break;
      case 'listingHours':
        const hour = <ListingTime>value;
        object = list.controls.find((x: { value: IFListingTime }) =>
          x.value.start === hour.start && x.value.end === hour.end && x.value.closed === hour.closed && x.value.dayName === +hour.id);
        if (hour.closed) {
          list.controls.forEach(x => {
            if (x.value.dayName === +hour.id) {
              x.value.active = false;
            }
          });
        }
        break;
      case 'masterCategories':
        object = list.controls.find(x => x.value.masterCategoryId === +value.id);
        break;
    }
    if (object) {
      object.value.active = true;
    } else {
      this.pushToList(value, listName, true);
    }
  }

  public removeFromList(index: any, listName: string, mod = '') {
    const list = <FormArray>this.fListing.get(listName);
    if (listName === 'listingHours') {
      let item;
      if (mod === 'special') {
        item = list.controls.find(x =>
          x.value.specialDateName === index.specialDateName &&
          x.value.specialDate === index.specialDate);
      } else {
        item = list.controls.find(x =>
          x.value.end === index.end &&
          x.value.start === index.start &&
          x.value.dayName === index.dayName);
      }

      if (item.value.id) {
        item.value.active = false;
      } else {
        index = list.controls.findIndex(x =>
          x.value.end === index.end &&
          x.value.start === index.start &&
          x.value.dayName === index.dayName);
        list.removeAt(index);
      }
      return;
    }
    const object = list.at(index);
    if (object.value.id) {
      object.value.active = false;
      list.push(this._fb.control({ active: false, removeFinal: true }));
    } else {
      list.removeAt(index);
    }
  }

  public myListFormatter(data: Category): string {
    const html = `<span>${data.description}</span>`;
    return html;
  }

  public searchRegularCategories = (term: string) => {
    return this._categoriesService.GetbyCategoryName(term).map(x => x.data);
  }

  private initForm(): void {
    this.fListing = this._fb.group(<IFListing>{
      avatar: this.listing.avatar,
      active: this.listing.active,
      businessAddressId: this.listing.businessAddressId,
      contactId: this.listing.contactId,
      addedDate: this.listing.addedDate,
      displayName: this.listing.displayName,
      googlePlaceId: this.listing.googlePlaceId,
      icon: this.listing.icon,
      id: this.listing.id,
      introduction: this.listing.introduction,
      listingConfigsId: this.listing.listingConfigsId,
      name: this.listing.name,
      overallStars: this.listing.overallStars,
      price: this.listing.price,
      monthlyPrice: this.listing.monthlyPrice,
      rankingOrder: this.listing.rankingOrder,
      openNow: this.listing.openNow,
      paymentsId: this.listing.paymentsId,
      ownerId: this.listing.ownerId,
      businessAddress: this._fb.group(<IFBusinessAddress>{
        active: this.listing.active,
        addedDate: this.listing.businessAddress.addedDate,
        city: this.listing.businessAddress.city,
        country: this.listing.businessAddress.country,
        id: this.listing.businessAddress.id,
        latitude: this.listing.businessAddress.latitude,
        longitude: this.listing.businessAddress.longitude,
        state: this.listing.businessAddress.state,
        street: this.listing.businessAddress.street,
        suiteNumber: this.listing.businessAddress.suiteNumber,
        zipCode: this.listing.businessAddress.zipCode
      }),
      payments: this._fb.group(<IFPayments>{
        active: this.listing.payments.active,
        addedDate: this.listing.addedDate,
        amex: this.listing.payments.amex,
        cash: this.listing.payments.cash,
        check: this.listing.payments.check,
        discover: this.listing.payments.discover,
        id: this.listing.payments.id,
        masterCard: this.listing.payments.masterCard,
        visa: this.listing.payments.visa
      }),
      listingConfigs: this._fb.group(<IFListingConfigs>{
        active: this.listing.listingConfigs.active,
        addedDate: this.listing.listingConfigs.addedDate,
        businessClosed: this.listing.listingConfigs.businessClosed,
        homeBasedBusiness: this.listing.listingConfigs.homeBasedBusiness,
        id: this.listing.listingConfigs.id,
        showAddress: this.listing.listingConfigs.showAddress
      }),
      contact: this._fb.group(<IFListingContact>{
        active: true,
        addedDate: this.listing.contact.addedDate,
        contactName: this.listing.contact.contactName,
        faxNumber: this.listing.contact.faxNumber,
        id: this.listing.contact.id,
        mobileNumber: this.listing.contact.mobileNumber,
        phoneNumber: this.listing.contact.phoneNumber,
        publicEmail: this.listing.contact.publicEmail,
        tollFreeNumber: this.listing.contact.tollFreeNumber,
        website: this.listing.contact.website
      }),

      services: this._fb.array(this.setFormServices(), ArrayValidator.maxLength(20, true)),
      listingHours: this._fb.array(this.setFormHours()),
      categories: this._fb.array(this.setFormCategories(), ArrayValidator.maxLength(3, true)),
      masterCategories: this._fb.array(this.setFormMasterCategories(), ArrayValidator.maxLength(1, true))
    });
  }

  private setFormServices(): FormGroup[] {
    const list = [];
    this.listing.services.forEach(x => {
      list.push(this.pushServices(x));
    });
    return list;
  }

  private setFormHours(): FormGroup[] {
    const list = [];
    this.listing.listingHours.forEach(x => {
      list.push(this.pushHours(x));
    });
    return list;
  }

  private setFormCategories(): FormGroup[] {
    const list = [];
    this.listing.categories.forEach(x => {
      list.push(this.pushCategories(x));
    });
    return list;
  }

  private setFormMasterCategories(): FormGroup[] {
    const list = [];
    this.listing.masterCategories.forEach(x => {
      list.push(this.pushMasterCategories(x));
    });
    return list;
  }

  private pushToList(x: any, listName: string, isNew = false): void {
    // Everything here is new
    switch (listName) {
      case 'services':
        this.services.push(this.pushServices(x));
        break;
      case 'categories':
        this._categoriesService.GetbyCategoryName(x.id)
          .subscribe(c => {
            this.categories.push(this.pushCategories(c.data[0], true));
          });
        break;
      case 'listingHours':
        this.listingHoursForm.push(this.pushHours(x, true));
        break;
      case 'masterCategories':
        this.masterCategories.push(this.pushMasterCategories(x, true));
        break;
    }
  }

  private pushServices(x: any): FormGroup {
    if (x.name) {
      x.service = x.name;
    }
    return this._fb.group(<IFListingServices>{
      active: true,
      addedDate: x.addedDate,
      id: x.id,
      listingId: this.listing.id,
      service: x.service,
      name: x.name
    });
  }

  private pushHours(x: any, isNew = false): FormGroup {
    return this._fb.group(<IFListingTime>{
      active: x.active,
      addedDate: x.addedDate,
      closed: x.closed,
      dayName: isNew ? +x.id : +x.dayName,
      end: x.end,
      id: isNew ? 0 : +x.id,
      isSpecialHour: x.isSpecialHour,
      listingId: this.listing.id,
      specialDate: x.specialDate,
      specialDateName: x.specialDateName,
      start: x.start
    });
  }

  private pushCategories(x: any, isNew = false): FormGroup {
    // Needs this logic cause is a many to many relationship object
    if (!isNew) {
      return this._fb.group(<IFCategoryListing>{
        active: true,
        addedDate: x.addedDate,
        id: x.id,
        categoryId: x.categoryId,
        listingId: x.listingId,
        name: x.category.description
      });
    } else {
      // Means that I need to create a new one
      return this._fb.group(<IFCategoryListing>{
        active: true,
        categoryId: x.id,
        listingId: this.listing.id,
        name: x.description
      });
    }
  }

  private pushMasterCategories(x: any, isNew = false): FormGroup {
    // Needs this logic cause is a many to many relationship object
    if (!isNew) {
      return this._fb.group(<IFMasterCategoryListing>{
        active: x.active,
        addedDate: x.addedDate,
        id: x.id,
        listingId: x.listingId,
        masterCategoryId: x.masterCategoryId,
        name: x.masterCategory.description
      });
    } else {
      // Means that I need to create a new one
      x = this.masterCategoriesCatalog.find(d => d.value === +x.id);
      return this._fb.group(<IFMasterCategoryListing>{
        active: true,
        listingId: this.listing.id,
        masterCategoryId: x.value,
        name: x.name
      });
    }
  }

  private getMasterCategoriesCatalog(): void {
    this._categoriesService.GetMaster()
      .subscribe(x => {
        x.data.forEach(c => {
          this.masterCategoriesCatalog.push({
            name: c.description,
            value: c.id
          });
        });
      });
  }

}
