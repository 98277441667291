/// <reference types="@types/googlemaps" />
import {
  Component, OnChanges, SimpleChange, OnInit, ViewEncapsulation, ViewChild, Input, Output, EventEmitter,
  OnDestroy, ElementRef, AfterViewInit, NgZone, ChangeDetectionStrategy
} from '@angular/core';
import { IMwSquareSelector } from '../../../../../shared/components';
import { mdistance, mprices, mhours, mratings, morder } from '../../../../../shared/viewmodels';
import { ActivatedRoute, Router, NavigationStart, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GetListingBySearchTermRouteModel as Filter } from '../../../../../shared/models';
import { ListingResolver } from '../../../../../shared/services';
import { LocationService } from '../../../../../shared/services/api';
import { MapwordsStore } from '../../../../../shared/store';
import { reaction, toJS } from 'mobx';
import { stat } from 'fs';
import { MapsAPILoader } from '@agm/core';
import { DeviceDetectorService } from '../../../../../../node_modules/ngx-device-detector';
import { CookieService } from '../../../../../../node_modules/ngx-cookie-service';

@Component({
  selector: 'mw-agent-search',
  templateUrl: './mw-agent-search.component.html',
  styleUrls: ['./mw-agent-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwAgentSearchComponent implements OnInit, OnDestroy {
 
  @Input()
  showSearch = false;

  @Output()
  public search = new EventEmitter<any>();

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('searchTermInput')
  public searchTermElementRef: ElementRef;

  @ViewChild('bodySearch')
  public bodySearchElementRef: ElementRef;

  public filter: Filter = new Filter({});

  public menuBottom = true;

  public locationString = '';

  public showLoading = false;

  public distance: IMwSquareSelector[] = mdistance;

  public prices: IMwSquareSelector[] = mprices;

  public ratings: IMwSquareSelector[] = mratings;

  public hours: IMwSquareSelector[] = mhours;

  public order: IMwSquareSelector[] = morder;

  public filtersOpen = false;

  public showFilter = false;

  public locationOpen = true;

  public numberOfListings: number;

  public autocomplete: any;

  public filster$Subs: Subscription;

  public router$Subs: Subscription;

  public locationOpenTimeout: any;

  public showPagination = false;

  public lat: number;

  public lng: number;

  public url: string;

  public orderByString: string;

  constructor(
    public store: MapwordsStore,
    private _listingResolver: ListingResolver,
    private _router: Router,
    private _mapsApiLoader: MapsAPILoader,
    private _locationService: LocationService,
    private _ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private _deviceService: DeviceDetectorService,
    private _cookieService: CookieService
  ) {}

  async ngOnInit() {
    this.store.searchListing.BottomMenu = false;
    this.storeChanges()
    this.setOrderbyString();


    // console.log('search comp - vici text') ;
    // console.log(localStorage.getItem('viciPhone') ) ;


    // Vici dialer
    if(this._cookieService.get('viciPhone')) {


      this.showFilter = true;
      this.showPagination = true;
      this.menuBottom = true;
      this.filtersOpen = true;
      this.store.searchListing.BottomMenu = true;
      this.store.searchListing.searchBody = 'search-body no-pagination';
      
      var searchterm = this._cookieService.get('viciPhone');
      var city =  this._cookieService.get('viciCity');
      var state =  this._cookieService.get('viciState');
      

      var result = await this._locationService.GetLocation( city, state).toPromise();
      

      const filter = this.store.search.cloneFilter;
      filter.latitude = result.data.latitude;
      filter.longitude = result.data.longitude;
      filter.searchterm = searchterm;
      filter.distance = 50;
      filter.latitude = result.data.latitude;
      filter.longitude = result.data.longitude;
           


      this.store.search.updateFilter(filter);
      this.locationString = `${city}, ${state}`;
      this.locationOpen = true;


            
    this._cookieService.delete('viciState') ;
    this._cookieService.delete('viciCity') ;
    this._cookieService.delete('viciPhone') ;


      this._router.navigate(['/cp/agent', {
        outlets: {
          'agentSearch':
            [
              this.store.search.filter.searchterm,
              this.store.search.filter.latitude,
              this.store.search.filter.longitude,
              this.store.search.filter.distance,
              this.store.search.filter.price,
              this.store.search.filter.rating,
              this.store.search.filter.open,
              1
            ],
          'agentListing': null
        }
      }
      ]);

      




      
      // this._cookieService.delete('viciState') ;
      // this._cookieService.delete('viciCity') ;
      // this._cookieService.delete('viciPhone') ;
      
      // console.log('this.filter') ;
      // console.log(this.filter ) ;

      // const sterm = this.filter.searchterm
      // this.filter = this.store.search.cloneFilter;
      // this.filter.searchterm = sterm;
      // this.store.search.updateFilter(this.filter);


      // this._router.navigate(['/cp/agent', {
      //   outlets: {
      //     'agentSearch':
      //       [
      //         this.store.search.filter.searchterm,
      //         this.store.search.filter.latitude,
      //         this.store.search.filter.longitude,
      //         this.store.search.filter.distance,
      //         this.store.search.filter.price,
      //         this.store.search.filter.rating,
      //         this.store.search.filter.open,
      //         1
      //       ],
      //     'agentListing': null
      //   }
      // }
      // ]);

      // return;


      // End Vici dialer


    }
    else {


    


                    this.filster$Subs = this._listingResolver.filter$.subscribe(x => {
                      if (this.filter.latitude !== x.latitude && this.filter.longitude !== x.longitude) {
                        if (isNaN(x.latitude) || isNaN(x.longitude)) {
                          this.activatedRoute.params.subscribe((params: Params) => {
                            this.lat = +params['latitude'];
                            this.lng = +params['longitude'];
                            this.store.search.filter.latitude = this.lat;
                            this.store.search.filter.longitude = this.lng;
                            this.filter.searchterm = params['searchterm'];
                          });
                        } else {
                          this.lat = x.latitude;
                          this.lng = x.longitude;
                          this.showFilter = true;
                          this.showPagination = true;
                          this.store.searchListing.BottomMenu = false;
                          this.store.searchListing.searchBody = 'search-body no-pagination';
                          this.filter.searchterm = x.searchterm;
                        }
                        this._locationService.GetCurrentAddress(this.lat, this.lng).subscribe(c => {
                          this.locationString = `${c.data.city}, ${c.data.state}`;
                        });
                      }
                    });

                    if (this.locationString === '') {
                      if (isNaN(this.store.search.filter.latitude) || isNaN(this.store.search.filter.longitude)) {
                        this.activatedRoute.params.subscribe((params: Params) => {
                          this.lat = +params['latitude'];
                          this.lng = +params['longitude'];
                          this.store.search.filter.latitude = this.lat;
                          this.store.search.filter.longitude = this.lng;
                          this.filter.latitude = this.lat;
                          this.filter.longitude = this.lng;
                          this.store.searchListing.BottomMenu = false;
                        });
                      }

                      // console.log('Init')
                      // tslint:disable-next-line:max-line-length
                      if (this.filter.latitude !== this.store.search.filter.latitude && !isNaN(this.filter.latitude) && this.filter.longitude !== this.store.search.filter.longitude && !isNaN(this.filter.longitude)) {
                        this._locationService.GetCurrentAddress(this.store.search.filter.latitude, this.store.search.filter.longitude).subscribe(c => {
                          this.locationString = `${c.data.city}, ${c.data.state}`;
                        });
                      }
                    }
                    // console.log(this.store.searchListing.BottomMenu);

                    this.router$Subs = this._router.events.subscribe((x: NavigationStart) => {
                      //console.log('x = ' + x.url);
                      this.url = x.url;
                      //console.log(this.url);
                      if (x.url === '/home') {
                        this.showFilter = false;
                        this.showPagination = true;
                        this.menuBottom = false;

                        this.filter.searchterm = '';

                        this.store.searchListing.BottomMenu = false;
                        this.store.searchListing.searchBody = 'search-body no-pagination';
                      } else if (x.url === '/' || x.url === '/cp') {
                        this.showFilter = false;
                        this.showPagination = true;
                        this.menuBottom = true;

                        this.filter.searchterm = '';
                        this.filtersOpen = false;

                        this.store.searchListing.BottomMenu = false;
                        this.store.searchListing.searchBody = 'search-body no-pagination';
                      } else {
                        this.showFilter = true;
                        this.showPagination = true;
                        this.menuBottom = false;

                        this.store.searchListing.BottomMenu = false;
                        this.store.searchListing.searchBody = 'search-body pagination';
                        this.showSearch = true;
                      }
                    });


    }
  }

  ngOnDestroy() {

    try {
    this.filster$Subs.unsubscribe();
    this.router$Subs.unsubscribe();
    }
    catch{}

    // console.log(this.autocomplete);
  }

  public storeChanges() {

    if (this.filter.open != null) {
      reaction(() => toJS(this.store.search.filter), x => {
        // console.log(x)
        this.filter = x;

        if (isNaN(x.latitude) || isNaN(x.longitude)) {
          this.activatedRoute.params.subscribe((params: Params) => {
            this.lat = +params['latitude'];
            this.lng = +params['latitude'];
            this.store.search.filter.latitude = this.lat;
            this.store.search.filter.longitude = this.lng;
          });
        } else {
          this.lat = x.latitude;
          this.lng = x.longitude;
        }
        this._locationService.GetCurrentAddress(this.lat, this.lng)
          // tslint:disable-next-line:no-shadowed-variable
          .subscribe((x) => {
            this.locationString = `${x.data.city}, ${x.data.state}`;
            // console.log('Change Location Name');
          });
      });
      reaction(() => this.store.search.page, page => {
        this.bodySearchElementRef.nativeElement.scrollTop = 0;
      });
      this.filter = this.store.search.cloneFilter;
      this.distance.find(x => x.value === +this.filter.distance).active = true;
      this.prices.find(x => x.value === +this.filter.price).active = true;
      this.ratings.find(x => x.value === +this.filter.rating).active = true;
      this.hours.find(x => x.value === (this.filter.open.toString() === 'true')).active = true;
      this.order.find(x => x.value === (this.filter.open.toString() === 'true')).active = true;
    }
  }

  public initGooglePlaces(): void {

    this._mapsApiLoader.load().then(() => {
      if (!this.autocomplete) {
        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ['(cities)']
        });
        this._ngZone.run(() => {
          this.autocomplete.addListener('place_changed', () => {

            const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.locationString = place.address_components[0].long_name + ', ' + place['address_components'][2]['short_name'];
           
            this.store.search.filter.latitude = place.geometry.location.lat();
            this.store.search.filter.longitude = place.geometry.location.lng();

            this.searchAction(false, 1);

            // this._router.navigate(['/cp/agent', { outlets: { agentListing: null } }]);

            // const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
            // if (place.geometry === undefined || place.geometry === null) {
            //   return;
            // }
            // this.locationString = place.address_components[0].long_name + ', ' + place['address_components'][2]['short_name'];
            // const filter = this.store.search.cloneFilter;
            // filter.latitude = place.geometry.location.lat();
            // filter.longitude = place.geometry.location.lng();
            // console.log('Init-Google');
            // const sterm =  this.searchTermElementRef.nativeElement.value ;
            // this.store.search.filter.searchterm = sterm ;            
            // this.store.search.updateFilter(filter);
            // this.locationOpen = true;
            // this.filtersOpen = false;

            // if(sterm) {
            //     this._router.navigate(['/cp/agent', {
            //       outlets: {
            //         'agentSearch':
            //           [
            //             sterm,
            //             this.store.search.filter.latitude,
            //             this.store.search.filter.longitude,
            //             this.store.search.filter.distance,
            //             this.store.search.filter.price,
            //             this.store.search.filter.rating,
            //             this.store.search.filter.open,
            //             this.store.search.page
            //           ],
            //         'agentListing': null
            //       }
            //     }
            //   ]);
            // }

          });
        });
      }
    });
    
  }

  public cleanLocation(): void {
    this.locationString = '';
  }

  public searchAction(reset: boolean, loadPage: number): void {

    if (this.filter.searchterm !== '' && reset === true) {
      this.filter.distance = 5;
      this.filter.price = 0;
      this.filter.rating = 0;
      this.filter.order = 0;
      this.filter.open = false;
      this.distance.forEach(x => x.active = false);
      this.prices.forEach(x => x.active = false);
      this.ratings.forEach(x => x.active = false);
      this.hours.forEach(x => x.active = false);
      this.order.forEach(x => x.active = false);
      this.prices.find(x => x.value === +this.filter.price).active = true;
      this.ratings.find(x => x.value === +this.filter.rating).active = true;
      this.hours.find(x => x.value === (this.filter.open.toString() === 'true')).active = true;

      if(this.order.find(x => x.value === +this.filter.order) != undefined) {
        this.order.find(x => x.value === +this.filter.order).active = true;
      }
    }

    // this.store.search.updateFilter(this.filter);
    // this.locationOpen = true;
    // console.log('Update Filter');

    const sterm =  this.searchTermElementRef.nativeElement.value ;
    if (sterm == "") {
      return;
    }

    
    this.filter = this.store.search.cloneFilter;
    this.filter.searchterm = sterm.trim() ;
       
    this.store.search.updateFilter(this.filter);
   
    this.store.search.page = loadPage ;
    this.store.search.updatePage(loadPage);

    this._router.navigate(['/cp/agent', {
      outlets: {
        'agentSearch':
          [
            this.store.search.filter.searchterm,
            this.store.search.filter.latitude,
            this.store.search.filter.longitude,
            this.store.search.filter.distance,
            this.store.search.filter.price,
            this.store.search.filter.rating,
            this.store.search.filter.open,
            loadPage // this.store.search.page
          ],
        'agentListing': null
      }
    }
    ]);




    this.filtersOpen = false;
  }

  public useCurrentLocation(): void {
    console.log('GET LOCATION SEARCH COMPONENT');

    if(this._deviceService.isDesktop() ) {
      this.setLocationFromIp();
    }
    else {

        navigator.geolocation.getCurrentPosition(x => {
          //console.log(x, x.coords.latitude, x.coords.longitude);
          this._locationService.GetCurrentAddress(x.coords.latitude, x.coords.longitude)
            .subscribe(c => {
              const filter = this.store.search.cloneFilter;
              filter.latitude = x.coords.latitude;
              filter.longitude = x.coords.longitude;
              this.store.search.updateFilter(filter);
              this.locationString = `${c.data.city}, ${c.data.state}`;
              this.locationOpen = true;
            });
        },
        (error) => {
          console.log('Geolocation service: ' + error.message);

          this.setLocationFromIp();
        });
     
    }
  }


  public setLocationFromIp () {

    // this._locationService.GetIp().subscribe (ipData => {

    //   console.log('external ip');
    //   console.log(ipData.ip);
    // });

    this._locationService.GetLocationFromIp()
      .subscribe(c => {

          const filter = this.store.search.cloneFilter;
          filter.latitude = c.data.latitude;
          filter.longitude = c.data.longitude;

          this.store.search.updateFilter(filter);
          this.locationString = `${c.data.city}, ${c.data.state}`;
          this.locationOpen = true;
        
      });

  }

  public toggleLocationFilter(action: boolean): void {
    if (!action) {
      this.locationOpenTimeout = setTimeout(() => {
        // console.log('closing')
        this.locationOpen = true;
      }, 500);
    } else {
      clearTimeout(this.locationOpenTimeout);
      this.locationOpen = true;
      this.bodySearchElementRef.nativeElement.scrollTop = 0;
    }
  }

  public heardEnter(e): void {
    
    if (e.keyCode === 13) {
      // console.log('heardEnter') ;
      // console.log(this.searchTermElementRef.nativeElement.value) ;

      const sterm =  this.searchTermElementRef.nativeElement.value ;
      this.filter = this.store.search.cloneFilter;
      this.filter.searchterm = sterm ;
      this.searchTermElementRef.nativeElement.blur();
      
      this.store.search.updateFilter(this.filter);
     
  
      this._router.navigate(['/cp/agent', {
        outlets: {
          'agentSearch':
            [
              this.store.search.filter.searchterm,
              this.store.search.filter.latitude,
              this.store.search.filter.longitude,
              this.store.search.filter.distance,
              this.store.search.filter.price,
              this.store.search.filter.rating,
              this.store.search.filter.open,
              this.store.search.page
            ],
          'agentListing': null
        }
      }
      ]);

    }
  }

  public changeOrderBy(prop: string, order: string): void {
    this.store.search.orderListingResults(prop, order);
    this.setOrderbyString();
  }

  public setOrderbyString(): void {
    this.orderByString = 'Select Order';
    if (this.store.search.orderResultsBy.order === 'asc') {
      this.orderByString = 'A-Z';
    } else {
      if (this.store.search.orderResultsBy.prop === 'Distance') {
        this.orderByString = 'Distance';
      } else if (this.store.search.orderResultsBy.prop === 'overallStars') {
        this.orderByString = 'Ratings';
      } else if (this.store.search.orderResultsBy.prop === 'price') {
        this.orderByString = 'Price';
      } else if (this.store.search.orderResultsBy.prop === 'Order') {
        this.orderByString = 'Order';
      }
    }
  }

  public toggleFilter(): void {
    this.filtersOpen = !this.filtersOpen;
    this.bodySearchElementRef.nativeElement.scrollTop = 0;
    this.store.searchListing.searchBody = this.filtersOpen
    ? 'search-body pagination filter-open' : 'search-body pagination filter-closed'
  }

  public toggleLocation(): void {
    this.locationOpen = !this.locationOpen;
    this.bodySearchElementRef.nativeElement.scrollTop = 0;
    this.store.searchListing.searchBody = this.locationOpen
    ? 'search-body pagination location-open' : 'search-body pagination location-closed'
  }

  public menuBottomContainer(): void {
    if (this.store.search.listingsResultsNumber > 0) {
      this.menuBottom = false;
    }
  }
}

