import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Listing } from '../../../../shared/models';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { MwClaimListingComponent } from './mw-claim-listing.component';

@Injectable()
export class MwClaimListingService {

  constructor(private _dialog: MatDialog) { }

  public claim(listing: Listing, viewContainerRef: ViewContainerRef): Observable<Listing> {
    let dialogRef: MatDialogRef<MwClaimListingComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;
    config.width = '650px';
    config.height = '100%';
    dialogRef = this._dialog.open(MwClaimListingComponent, config);
    dialogRef.componentInstance.listing = listing;
    return dialogRef.afterClosed();
  }

}
