import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer{
   public parse(url: string): UrlTree {

    try {
        if(! url.startsWith('/home') && ! url.startsWith('/auth') && ! url.startsWith('/cp')) {
           url = url.toLowerCase() ;
        }   
    }
    catch(error) {
        
    }

        return super.parse(url);

   }
}