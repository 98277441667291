import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mw-tab',
  template: `
    <ng-content *ngIf="active"></ng-content>
  `
})
export class MwTabComponent implements OnInit {

  public active = false;

  @Input()
  public name: string;

  constructor() { }

  ngOnInit() { }

}
