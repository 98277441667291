import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { ListingTime } from '../../models/ListingTime';
import { BaseRestService } from './base.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class ListingHoursService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get the openning hours for a claimed listings
    *   @return An array of ListingTime 
    *   @param listid - The ID of the Listing
    */
    public GetByListingId(listid: number ): Observable<RG<ListingTime[]>> {
       
        return this.get(`api/v1/listinghours?listid=${listid}`);
    }

    /** 
    *   Add A ListingTime object to a Listing.
    *   @return ListingTime object 
    *   @param model - A ListingTime object
    */
    public PostListTime(model: ListingTime ): Observable<RG<ListingTime>> {
        
        return this.post( `api/v1/listinghours`, JSON.stringify(model) );
    }

    /** 
    *   Add an array of ListingTime objects
    *   @return An array of ListingTime 
    *   @param model - 
    */
    public PostListTimes(model: ListingTime[] ): Observable<RG<ListingTime[]>> {
       
        return this.post( `api/v1/listinghours`, JSON.stringify(model) );
    }

    /** 
    *   Edit a Listing Hours Object
    *   @return A Listing Hours Object 
    *   @param model - A Listing Hours Object
    */
    public PutListTimes(model: ListingTime[] ): Observable<RG<ListingTime[]>> {
        
        return this.put(`api/v1/listinghours`, JSON.stringify(model) );
    }
}

