import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { DynamicTagModel } from '../../models/DynamicTagModel';
// import * as offlinedata  from '../../../assets/data/dynamictags.json';
import { BaseRestService } from './base.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';


@Injectable()
export class DyanmicTagService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

   
    /** 
    *   Get meta data 
    *   @return DynamicTagModel
    *   @param url - The url for the DynamicTagModel (String)
    *   @param tag - The tag for the DynamicTagModel (String)
    */
    public GetDynamicTags(url: string  = "", tag: string  = ""): Observable<RG<DynamicTagModel[]>> {

        return this.get(`api/v1/DynamicTag?url=${url || ""}&tag=${tag || ""}`)
            ._catch((error: any) => {
                
                // var data1 = this.getJSON(url, tag) ;
                // var result = Observable.of(new RG<DynamicTagModel[]>(null, null, data1.data));

                // return result;
                return null;
            });

    }



    // public getJSON(url: string  = "", tag: string  = ""): RG<DynamicTagModel[]>  {

    //     var data = (<any>offlinedata).filter(x=> x.url == url && x.tag == tag) ;

    //     var result = new RG<DynamicTagModel[]>(null,null,data) ;

    //      return result;          


    // }

}

