import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})

export class phonePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === null || value === ''){
            return 'No phone available';
          }

          if(value.indexOf("(") == 0)
            {
                return value;
            }

           var viewVal = value.trim().replace(/^\+/, '');
           viewVal = viewVal.replace("(", "");
           viewVal = viewVal.replace(")", "");
           viewVal = viewVal.replace("-", "");
           viewVal = viewVal.replace(" ", "");
           var area, number; 
           
           switch (viewVal.length)
           {
               case 11: // +CPPP####### -> CCC (PP) ###-####
                return value;

               case 12: // +CCCPP####### -> CCC (PP) ###-####
               return value;

               case 1:
               case 2: 
               case 3: 
                area = viewVal;
                break;

                default:
                    area = viewVal.slice(0,3); 
                    number = viewVal.slice(3);
           }

           if (number)
            {
                if (number.length > 3)
                    {
                        number = number.slice(0,3) + '-' + number.slice(3,7);
                    }
                    else 
                    {
                        number = number; 
                    }
                    return ("(" + area + ")" + number).trim().slice(0,13); 
            }
            else 
            {
                return "(" +area + ")";
            }
      }
}
