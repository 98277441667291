import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { ListingPhoto } from '../../models/ListingPhoto';
import { BaseRestService } from './base.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class PhotosService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get all the photos on the system.
    *   @return An array of Photos. 
    */
    public Get(): Observable<RG<ListingPhoto[]>> {
        
        return this.get(`api/v1/listingphotos`);
    }

    /** 
    *   Get a single photo.
    *   @return An ListingPhoto object. 
    *   @param id - The ID of the Photo.
    */
    public GetById(id: number ): Observable<RG<ListingPhoto>> {
       
        return this.get(`api/v1/listingphotos/${id}`);
    }

    /** 
    *   Get all the photos for a listing
    *   @return An array of ListingPhoto objects. 
    *   @param listingid - The ID of the Listing
    *   @param place_id - The Place_id of the Listing (string)
    */
    public GetByListingID(listingid: number , place_id: string ): Observable<RG<ListingPhoto[]>> {
        
        return this.get(`api/v1/listingphotos/list?listingid=${listingid}&place_id=${place_id}`);
    }

    /** 
    *   Get all the client Photos
    *   @return An array of ListingPhoto objects. 
    *   @param clientId - The ID of the Client
    */
    public GetByClientId(clientId: number  = 0): Observable<RG<ListingPhoto[]>> {
        
        return this.get(`api/v1/photos?clientId=${clientId || 0}`);
    }

    /** 
    *   Just to help typescript
    *   @return  
    *   @param model - 
    */
    public SetProperties(model: ListingPhoto ): Observable<RG<ListingPhoto>> {
        
        return this.post(`api/photos/`, JSON.stringify(model) );
    }
}

