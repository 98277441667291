import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayname'
})

export class DayNamePipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', ''];
    return days[value];
  }
}
