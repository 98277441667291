
export * from './mw-listing-details/mw-listing-details.component';
export * from './mw-search-preferences/mw-search-preferences.component';
export * from './mw-review-messages/mw-review-messages.component';
export * from './mw-enhance-listing/mw-enhance-listing.component';
export * from './mw-billing/mw-billing.component';
export * from './mw-listing-verification/mw-listing-verification.component';
export * from '../../mw-home/mw-listing-review/mw-listing-review.component';
export * from './mw-listing.component';

import { MwListingComponent } from './mw-listing.component';
import { MwListingDetailsComponent } from './mw-listing-details/mw-listing-details.component';
import { MwSearchPreferencesComponent } from './mw-search-preferences/mw-search-preferences.component';
import { MwReviewMessagesComponent } from './mw-review-messages/mw-review-messages.component';
import { MwEnhanceListingComponent } from './mw-enhance-listing/mw-enhance-listing.component';
import { MwBillingComponent } from './mw-billing/mw-billing.component';
import { MwListingVerificationComponent } from './mw-listing-verification/mw-listing-verification.component';
import { MwPaymentHistoryComponent } from './mw-billing/mw-payment-history/mw-payment-history.component';
import { MwListingReviewComponent } from './mw-listing-review/mw-listing-review.component';


export const listingComponents = [
  MwListingComponent,
  MwListingDetailsComponent,
  MwSearchPreferencesComponent,
  MwReviewMessagesComponent,
  MwEnhanceListingComponent,
  MwBillingComponent,
  MwPaymentHistoryComponent,
  MwListingVerificationComponent,
  MwListingReviewComponent
];
