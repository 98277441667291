import { Injectable, ViewContainerRef } from '@angular/core';
// import { MdDialog, MdDialogRef, MdDialogConfig } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { MwImageEditorComponent } from './mw-image-editor.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class MwImageEditorService {

  constructor(
    private _dialog: MatDialog
  ) { }

  public open(imageUrl: string, view: ViewContainerRef): Observable<string> {
    let dialogRef: MatDialogRef<MwImageEditorComponent>;
    const config = new MatDialogConfig();
    // config.viewContainerRef = viewContainerRef;
    config.width = '450px';
    dialogRef = this._dialog.open(MwImageEditorComponent, config);
    dialogRef.componentInstance.imageUrl = imageUrl;
    return dialogRef.afterClosed();
  }

}
