import { Component,  OnInit, 
         OnDestroy,  AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ListingsService } from '../../../../shared/services/api';
import { Listing} from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
import { toJS } from 'mobx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenModel } from '../../../../shared/models/TokenModel';

@Component({
    selector: 'mw-phone-verification',
    templateUrl: './mw-phone-verification.component.html',
    styleUrls: ['./mw-phone-verification.component.scss']
})

export class MwPinCodeComponent implements OnInit, OnDestroy, AfterViewInit {

    subscription: Subscription;

    public listing: Listing;

    public dialogOpened = false;

    public token;

    userForm = new FormGroup({
        pincode: new FormControl('', Validators.required)
    });

    constructor(
        public _route: ActivatedRoute,
        public _router: Router,
        private _listingsService: ListingsService,
        public store: MapwordsStore) {
    }

    ngOnInit() {
        this.listing = toJS(this.store.searchListing.listing);
        this.subscription = this._listingsService.GetPin(this.listing).subscribe(
            data => {
            this.listing = data.data;
            }
        );
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {

    }

    get pinCode(): any {
        return this.userForm.get('pincode');
    }

    public closeVerification(): void {
        this.store.searchListing.verifyIsOpen = false;
    }

    public phoneVerify(): void {

        var value = this.userForm.get('pincode').value;
        var listingId = this.store.searchListing.listing.id;
        var model = new TokenModel();
        model.pin= value;
        model.listingId= listingId;

        if (value) {
            this.store.listing.verifyListing(model, listingId => {
                this.store.searchListing.verifyIsOpen = false;
                //console.log(value);
                // console.log(listingId);

                this._router.navigateByUrl('/cp/user/listing/' + listingId);
            });
        }
    }
}
