import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { ClientsService } from '../../../../shared/services/api';
import { Listing, Client, Address } from '../../../../shared/models';
import { environment } from '../../../../environments/environment';
import { S3filesService } from '../../../../shared/services';


@Component({
  selector: 'mw-listing',
  templateUrl: './mw-listing.component.html',
  styleUrls: ['./mw-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MwListingComponent implements OnInit {

  public listing: Listing;
  public imageOrigin = environment.imagesOrigin;
  
  constructor(
    private _clientService: ClientsService,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private s3filesService: S3filesService
  ) {}

  public ngOnInit() {
    this._route.data.subscribe(
      x => {
        this.listing = x['cpListing']['data'];

        if(this.listing) {
          if(this.listing.avatar) {
            this.listing.avatar.indexOf('http') > -1 ? this.listing.signedAvatar =  this.getAvatar(this.listing.avatar) : this.listing.signedAvatar = this.imageOrigin+ this.listing.avatar ;
          }
        }

        //console.log(this.listing);
        this._titleService.setTitle(`${this.listing.name}  - Mapwords`);
      });
  }

  private getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }

  /**
  * Runs when detects that was a tab change
  * @memberOf BusinessComponent
  */
  public tabChange(): void {
    // console.log(this.userProfile.toggles);
    // this.userProfile.toggles.forEach(t => t.active = false);
  }

}
