
import { Client } from './Client';
import { ProfessionalSkill } from './ProfessionalSkill';


export interface IClientProfessionalSkill  {
    id?: number;
    addedDate?: Date;
    active?: boolean;
    client?: Client;
    clientId?: number;
    professionalSkill?: ProfessionalSkill;
    professionalSkillId?: number;
}
export class ClientProfessionalSkill  implements IClientProfessionalSkill {
    public id: number;
    public addedDate: Date;
    public active: boolean;
    public client: Client;
    public clientId: number;
    public professionalSkill: ProfessionalSkill;
    public professionalSkillId: number;
    constructor(obj?: IClientProfessionalSkill) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
        this.professionalSkill = obj.professionalSkill !== undefined ? obj.professionalSkill : null;
        this.professionalSkillId = obj.professionalSkillId !== undefined ? obj.professionalSkillId : null;
    }
}
// For reactive forms intended
export interface IFClientProfessionalSkill  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Client
    */
    client?: any; 
    /**
    * Is number
    */
    clientId?: any; 
    /**
    * Is ProfessionalSkill
    */
    professionalSkill?: any; 
    /**
    * Is number
    */
    professionalSkillId?: any; 
}
