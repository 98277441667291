import {
  Component,
  ViewContainerRef,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit,
  HostListener,
  Input
} from "@angular/core";
// import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
// import {ModalGalleryModule} from 'angular-modal-gallery';
import { SafeUrl, Title } from "@angular/platform-browser";
import { Subscription } from "rxjs/Subscription";
import { Listing, ListingTime } from "../../../../shared/models";
import { S3filesService } from "../../../../shared/services";
import { environment } from "../../../../environments/environment";
import { MapwordsStore } from "../../../../shared/store";
// import {Angular2ImageGalleryModule} from "./../../../angular2-image-gallery";

@Component({
  selector: "mw-photo-pop",
  templateUrl: "./mw-photo-pop.component.html",
  styleUrls: ["./mw-photo-pop.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwPhotoPopComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener("document:keydown", ["$event"]) handleKeyboardEvent(event) {
    let x = event.keyCode;
    if (x === 37) {
      this.goPrevious();
    }
    if (x === 39) {
      this.goNext();
    }
  }

  @Input("photoGaleryIndexUrl")
  public photoGaleryIndexUrl: string = "";

  public listing: Listing;

  public url: SafeUrl;

  public todayNumber;
  public showModal = false;
  public showSlide = true;
  public today: ListingTime = new ListingTime({});

  public d360image = false;
  public menuimage = false;
  public photoId = 0;
  public currentId = 0;
  public photoCount = 0;

  public photoURL: string[] = [];
  public photoGallery = "";
  public image = "";

  public routerSubs: Subscription;

  constructor(
    public store: MapwordsStore,
    private s3filesService: S3filesService
  ) {}

  ngOnInit() {
    // console.log(this.store.searchListing.photoIsOpen);

    this.showModal = true;

    this.storeChanged();
    // this.routerSubs = this._route.data.subscribe(x => {
    //   this.store.searchListing.selectListing(x['listing']['data']);
    //   this.showModal = true;
    //   this.store.search.hoverListing(x['listing']['data']['googlePlaceId']);
    // });

    const d = new Date();
    this.todayNumber = d.getDay();


    if (this.photoGaleryIndexUrl != "" && this.photoURL.length > 0) {
      let index = 0;

      this.photoURL.forEach(url => {
        if (url == this.photoGaleryIndexUrl) {
          this.currentId = index + 1;
        }
        index += 1;
      });

      this.currentId = this.currentId == 1 ? this.photoURL.length : this.currentId ;
      this.photoId = this.currentId-1;
      this.ShowSlide(this.currentId-1);
    }
  }

  ngOnDestroy() {
    // this.routerSubs.unsubscribe();
  }

  public storeChanged() {
    // console.log('Store has changed Photo');
    // console.log(this.store.searchListing.listing);
    if (this.store.searchListing.listing) {
      this.listing = this.store.searchListing.listing;
      const re = /assets/gi;
      this.photoId = 0;
      this.photoCount = 0;
      this.currentId = 1;
      this.photoURL = [];
      // debugger;
      for (const picture of this.listing.photos) {
        // if (picture.imageUrl.search(re) === -1) {
        if (picture.imageUrl) {
          if (this.store.searchListing.D360 != false) {
            this.photoCount++;
            this.photoGallery = "360 Degree View";
            this.photoURL.push(this.getSignedUrl(picture.imageUrl));
            if (this.image === "") {
              this.image = this.getSignedUrl(picture.imageUrl);
            }
          } else if (this.store.searchListing.D360 === false) {
            this.photoCount++;
            this.photoGallery = "Services";
            // console.log(this.image);
            // console.log(picture.imageUrl);
            if (picture.googleReviewId !== 0) {
              this.image = this.getSignedUrl(picture.imageUrl);
            } else {
              if (picture.imageUrl.indexOf("http") === 0) {
                this.image = this.getSignedUrl(picture.imageUrl);
              } else {
                this.image =
                  environment.imagesOrigin +
                  "assets/images/listings/" +
                  picture.imageUrl;
              }
            }
            this.photoURL.push(this.image);
          }
        }
      }
      this.image = this.photoURL[0];
    }
    // return this.listing = this.store.searchListing.listing;
  }

  public goBack(): void {
    this.store.searchListing.photoIsOpen = false;
  }

  public BrowseAll(): void {
    this.showSlide = false;
  }

  public ShowSlide(i): void {
    this.showSlide = true;
    this.image = this.photoURL[i];
    this.photoId = i;
    this.currentId = i + 1;
  }

  public keyPressed(event) {
    if (event.keyCode === 37) {
      // console.log(`Pressed ${event.key}!`);
    } else if (event.keyCode === 39) {
      // console.log(`Pressed ${event.key}!`);
    }
  }

  public goPrevious(): void {
    if (this.photoId > 0) {
      this.photoId--;
      this.currentId--;
      this.image = this.photoURL[this.photoId];
    }
  }

  public goNext(): void {
    if (this.photoId < this.photoURL.length - 1) {
      this.photoId++;
      this.currentId++;
      this.image = this.photoURL[this.photoId];
    }
  }

  ngAfterViewInit() {}

  public getSignedUrl(img: string): string {
    var result = this.s3filesService.getSignedUrl(img);

    // console.log('img');
    // console.log(img);
    // console.log('result');
    // console.log(result);

    return result;
  }
}
