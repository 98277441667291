import { AfterViewInit, Component, OnInit, Input, OnChanges, QueryList, SimpleChanges, ViewChildren, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from '../../../../../shared/services/api';
import { Client, IFClient, IFClientRelationshipClient, ClientRelationshipClient, RelationshipType, RelationshipStatus } from '../../../../../shared/models';
import { MapwordsStore } from '../../../../../shared/store';
import { MwFormControlComponent } from '../../../../../shared/components';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { EmailValidator } from '../../../../../shared/forms/validators';
import { Observable } from 'rxjs/Observable';
import { isObject } from 'rxjs/internal/util/isObject';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { S3filesService } from '../../../../../shared/services';

@Component({
  selector: 'mw-user-relationships',
  templateUrl: './mw-user-relationships.component.html',
  styleUrls: ['./mw-user-relationships.component.scss'],
  changeDetection:ChangeDetectionStrategy.Default
})

export class MwUserRelationshipsComponent implements OnInit, OnChanges, AfterViewInit {

  selectedClient: Client;

  @Input()
  public client: Client;

  public fRelationships: FormGroup;
  public relationshipsControl = new FormControl();

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;

  public userNameAvailable = false;

  public imageOrigin: string = environment.imagesOrigin;



  public clientList: Observable<Client[]> ;

  public get relationships(): FormArray {
    return <FormArray>this.fRelationships.get('relationships');
  }

  public get relationshipStatus(): string {
    return RelationshipStatus[this.client.relationshipStatus].split('_').join(' ');
  }

  public relationshipTypes = RelationshipType;
  public relationshipStatus1 = RelationshipStatus;
  
  public translateType(type: number): string {
    return RelationshipType[type].split('_').join(' ');
  }

  public usernameCheck(event: KeyboardEvent): void {
    if (this.fRelationships.get('username').valid) {
      this._clientsService.GetUniqueUsername(this.fRelationships.get('username').value)
        .subscribe(
          x => this.userNameAvailable = x.data.answer
        );
    }
  }



  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _clientsService: ClientsService,
    private _titleService: Title,
    private s3filesService: S3filesService
  ) { }

  public ngOnInit() {
    this.initForm(this.client);
    //console.log(this.client.relationships);


    this.relationshipsControl.valueChanges.subscribe( (x) => {
      if(x==undefined || x==null || x=='' || isObject(x) ) {
        return;
      }
      else {
        this.clientList = this._clientsService.GetBySearchTerm(x).map(x => x.data);
      }
    });


  }

  ngAfterViewInit() {
    this.formControls.forEach(x => x.formDirty = false);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.client.relationships = this.client.relationships || [];
    this.client = changes['client'].currentValue;
    this.initForm(this.client);
  }

  public validateKey(event: KeyboardEvent): boolean {
    // if (String.fromCharCode(event.keyCode).match(/[a-z0]/g) == null) {
    if (String.fromCharCode(event.keyCode).match(/[a-zA-Z0 ]/g) == null) {
      return false;
    }
  }

  public submit(): void {
    //console.log(this.fRelationships.valid);
    this._clientsService.PutRelationShip(this.fRelationships.value)
      .subscribe(x => {
        this.client.relationships = x.data.relationships || [];
        this.client.relationshipStatus = x.data.relationshipStatus;
        this.initForm(this.client);
      });
  }

  public addRelationship(obj:any , clientObj: MatAutocomplete): void {

    if(!obj) {
      return;
    }

    if(!obj.value) {
      return;
    }

    if(!clientObj) {
      return;
    }

    let type: RelationshipType;
    type = obj.value;

    var selectedItem= clientObj.options.filter(x=>x.selected==true);

    if(selectedItem.length == 0) {
      return;
    }

    this.selectedClient = selectedItem[0].value;
    console.log(`${this.selectedClient.firstName} ${this.selectedClient.lastName} ` + type);


    if (this.selectedClient && type) {
      const relationship = <ClientRelationshipClient>this.relationships.value.find(x => x.secondClientId == this.selectedClient.id);
      if (relationship) {
        relationship.active = true;
      } else {
        console.log(`${this.selectedClient.firstName} ${this.selectedClient.lastName} ` + type);


        this.relationships.push(this._fb.group(<IFClientRelationshipClient>{
          active: true,
          secondClientId: this.selectedClient.id,
          confirmed: false,
          type: type,
          id: 0,
          fullName: `${this.selectedClient.firstName} ${this.selectedClient.lastName}`,
          email: this.selectedClient.email,
          avatar: this.selectedClient.avatar
        }));
      }
    }
  }

  public removeRelationship(index: number): void {
    const relationship = this.relationships.at(index);
    // if (relationship.value.id) {
    //   relationship.value.active = false;
    // } else {
      this.relationships.removeAt(index);
    //}
  }

  public toggleInput(saved: boolean): void {
    if (saved) {
      this.submit();
    } else {
      this.initForm(this.client);
    }
  }

  private initForm(client: Client): void {
    this.fRelationships = this._fb.group(<IFClient>{
      relationshipStatus: [JSON.stringify(this.client.relationshipStatus)],
      relationships: this._fb.array(this.setRelationshipsForm),
      username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
    });
  }

  private get setRelationshipsForm(): FormGroup[] {
    const list = [];
    if (this.client.relationships !== null) {
      this.client.relationships.forEach(x => {
        if (x.secondClient) {
          list.push(this._fb.group(<IFClientRelationshipClient> {
            active: x.active,
            secondClientId: x.secondClientId,
            clientId: x.clientId,
            confirmed: x.confirmed,
            secondClient: x.secondClient,
            addedDate: x.addedDate,
            type: x.type,
            id: x.id,
            email: x.secondClient.email,
            fullName: `${x.secondClient.firstName} ${x.secondClient.lastName}`,
            avatar:  x.secondClient.avatar 
          }))
        };
      });
    }
    return list;
  }

  public searchClients = (term: string) => {
    return this._clientsService.GetBySearchTerm(term).map(x => x.data);
  }

  public listFormatter(data: Client): string {
    if (data.email !== undefined) {
      if (data.firstName === undefined || data.lastName === undefined) {
        const email = `<span>${data.email}</span>`
        return email;
      } else {
        const fullName = `<span>${data.firstName} ${data.lastName}</span>`;
        return fullName;
      }
    }
    return;
  }

  public displayFn(client?: Client): string | undefined {
    if(client) {
      return client.fullName;
    }

    return undefined;
  }

  public getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }

}
