import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, difference } from 'lodash';

import { ListingTime, IFListingTime } from '../../models';
import { HoursMode } from '../mw-hours/mw-hours.modes';
import { MapwordsStore } from '../../store';

@Component({
  selector: 'mw-hours-agent',
  templateUrl: './mw-hours-agent.component.html',
  styleUrls: ['./mw-hours-agent.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MwHoursAgentComponent implements OnInit {

  @Input()
  public readonly hours: Array<ListingTime>;

  @Input()
  public readonly listingId: number;

  @Output()
  public readonly save: EventEmitter<ListingTime[]>;

  @Output()
  public readonly close: EventEmitter<any>;

  @Output()
  public readonly change: EventEmitter<ListingTime[]>;

  public hoursMode: HoursMode;

  public hoursForm: FormGroup;

  public days: Array<number>;

  public isValid = true;

  constructor(
    private _fb: FormBuilder,
    private store: MapwordsStore
  ) {
    // this.hours = cloneDeep(this.hours);
    this.save = new EventEmitter<ListingTime[]>();
    this.change = new EventEmitter<ListingTime[]>();
    this.close = new EventEmitter<any>();
    this.days = [0, 1, 2, 3, 4, 5, 6];
    this.chooseMode();
  }

  ngOnInit() {
    this.initForm();
  }

  public submit(): void {
    const hoursList: Array<ListingTime> = [];
    const daysFailed: Array<string> = [];
    this.weekDaysForm.controls.forEach(x => {
      const hours = <FormArray>x.get('hours');
      hours.controls.forEach(g => {
        // if (g.value.end && g.value.start) {
        //   if (g.value.id) {
        //     hoursList.push(g.value);
        //   } else {
        //     if (g.value.isNew === true && g.value.closed === false) {
        //       hoursList.push(g.value);
        //     }
        //   }
        // } else {
        //   x.get('opened').setValue(false);
        //   daysFailed.push(x.value.day);
        // }
        hoursList.push(g.value);
      });
    });

    if (daysFailed.length > 0) {
      // this.store.alerts.add({
      //   title: 'Notice',
      //   message: `The next days were invalid and we changed the status to closed: ${daysFailed.join(', ')},
      //  please edit again if you want to change this.`
      // });
    }

    this.Validate(hoursList);
    
    this.save.emit(hoursList);

    // setTimeout(() => this.initForm(), 1000);
  }

  public cancel(): void {
    this.close.emit();
  }

  public get weekDaysForm(): FormArray {
    return <FormArray>this.hoursForm.get('weekdays');
  }

  public toggleCloseDay(dayNumber: number, open: boolean): void {


    const day = this.weekDaysForm.controls.find(x => x.value.number === +dayNumber);
    const dayHours = <FormArray>day.get('hours');
    dayHours.controls.forEach(x => {
      x.get('active').setValue(open);
      x.get('closed').setValue(open);
    });


    this.triggerChangeEvent();

  }

  public triggerChangeEvent() {
    const hoursList: Array<ListingTime> = [];
    
    this.weekDaysForm.controls.forEach(x => {
      const hours = <FormArray>x.get('hours');
      hours.controls.forEach(g => {
        hoursList.push(g.value);
      });
    });


    this.Validate(hoursList);


    this.change.emit(hoursList);
  }

  public Validate(hoursList: Array<ListingTime>): boolean{

    this.isValid = true;

    for (let index = 0; index < hoursList.length; index++) {

      if(hoursList[index].closed) {
        hoursList[index].start = null;
        hoursList[index].end = null;
      }

      if( !(hoursList[index].start && hoursList[index].end) && !hoursList[index].closed){
        this.isValid = false;
      }
    }


    return this.isValid;
  }


  public toggleExtraHour(dayNumber: number, added: boolean): void {
    const hours = <FormArray>this.weekDaysForm.at(dayNumber).get('hours');
    const hour = hours.at(1);
    if (hour) {
      if (hour.get('isNew')) {
        if (added) {
          hours.push(this.pushHour(null, dayNumber, false));
        } else {
          hours.removeAt(1);
        }
      } else {
        //console.log(added)
        hour.get('active').setValue(added);
      }
    } else {
      if (added) {
        hours.push(this.pushHour(null, dayNumber, false));
      } else {
        hours.removeAt(1);
      }
    }

    this.triggerChangeEvent();
  }

  public toggleHours(dayNumber: number, closed: boolean): void {

    const hours = <FormArray>this.weekDaysForm.at(dayNumber).get('hours');
    hours.controls.forEach(x => x.get('closed').setValue(closed));
    //// hours.controls.forEach(x => x.get('active').setValue(closed));

    // console.log(this.hoursForm.controls.weekdays)
    this.triggerChangeEvent();
  }

  private initForm(): void {
    // Create the 7 days always
    this.hoursForm = this._fb.group({
      weekdays: this._fb.array([
        this._fb.group({
          day: 'Sunday',
          number: 0,
          usingSecond: false,
          opened: false,
          closed: false,
          hours: this._fb.array(this.generateDaysGroups(0))
        }),
        this._fb.group({
          day: 'Monday',
          number: 1,
          usingSecond: false,
          opened: false,
          closed: false,
          hours: this._fb.array(this.generateDaysGroups(1))
        }),
        this._fb.group({
          day: 'Tuesday',
          number: 2,
          opened: false,
          closed: false,
          usingSecond: false,
          hours: this._fb.array(this.generateDaysGroups(2))
        }),
        this._fb.group({
          day: 'Wednesday',
          number: 3,
          opened: false,
          closed: false,
          usingSecond: false,
          hours: this._fb.array(this.generateDaysGroups(3))
        }),
        this._fb.group({
          day: 'Thursday',
          number: 4,
          opened: false,
          closed: false,
          usingSecond: false,
          hours: this._fb.array(this.generateDaysGroups(4))
        }),
        this._fb.group({
          day: 'Friday',
          number: 5,
          opened: false,
          closed: false,
          usingSecond: false,
          hours: this._fb.array(this.generateDaysGroups(5))
        }),
        this._fb.group({
          day: 'Saturday',
          number: 6,
          opened: false,
          closed: false,
          usingSecond: false,
          hours: this._fb.array(this.generateDaysGroups(6))
        })
      ])
    });
    this.checkUsingSecond();
  }

  private checkUsingSecond(): void {
    this.weekDaysForm.controls.forEach(x => {
      const hours = <FormArray>x.get('hours');
      const opened = hours.controls.filter(f => !f.value.isNew && f.value.active);
      const openDays = hours.controls.filter(f => !f.value.isNew && !f.value.closed);
      if (opened.length > 1) {
        x.get('usingSecond').setValue(true);
      }
      if (opened.length > 0) {
        x.get('opened').setValue(true);
        x.get('closed').setValue(false);

      } else {
        x.get('opened').setValue(false);
        x.get('closed').setValue(true);
      }
      if (openDays.length === 0) {
        x.get('opened').setValue(false);
        x.get('closed').setValue(true);
      }
    });
  }

  private generateDaysGroups(dayNumber: number): FormGroup[] {
    const hoursGroup: FormGroup[] = [];
    const hours = this.hours.filter(x => +x.dayName === +dayNumber);
    if (hours.length > 0) {
      hours.forEach(hour => {
        hoursGroup.push(this.pushHour(hour));
      });
    } else {
      // The minimum is one
      hoursGroup.push(this.pushHour(null, dayNumber));
    }
    return hoursGroup;
  }

  private pushHour(hour: ListingTime, dayNumber: number = -1, closed = true): FormGroup {
    if (dayNumber === -1) {
      return this._fb.group(<IFListingTime>{
        active: hour.active,
        addedDate: hour.addedDate,
        closed: hour.closed,
        dayName: hour.dayName,
        end: hour.end,
        id: hour.id,
        isSpecialHour: hour.isSpecialHour,
        listingId: hour.listingId,
        specialDate: hour.specialDate,
        specialDateName: hour.specialDateName,
        start: hour.start
      });
    } else {
      return this._fb.group(<IFListingTime>{
        active: true,
        addedDate: null,
        closed: closed,
        dayName: dayNumber,
        end: null,
        id: 0,
        isSpecialHour: false,
        listingId: this.listingId,
        specialDate: null,
        specialDateName: null,
        start: null,
        isNew: true // UI Property,
      });
    }

  }

  private chooseMode(): void {
    // For now is always open until we improve the hours component.
    // TODO: Better logic to choose a mode.
    this.hoursMode = HoursMode.AlwaysOpen;
  }

}
