import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IQuickSearch } from '../../../../shared/viewmodels';
import { MapwordsStore } from '../../../../shared/store';

@Component({
  selector: 'mw-quick-search',
  templateUrl: './mw-quick-search.component.html',
  styleUrls: ['./mw-quick-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwQuickSearchComponent implements OnInit, OnChanges {

  @Input()
  // public terms: IQuickSearch[] = [];
  public terms: IQuickSearch[] = [];

  @Input()
  public precategory: IQuickSearch = null;

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public childbackshowed = new EventEmitter<IQuickSearch>();

  @Output()
  public back = new EventEmitter<IQuickSearch>();

  public othersOpen = false;

  public indx1;

  public indx2;

  public currentTerms;

  public cat;

  constructor(public store: MapwordsStore) { }

  ngOnInit() {
    this.indx1 = -1;
    this.indx2 = 0;
    this.currentTerms = this.terms;
    this.cat = null;
    // Call quick search service and populate terms
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    this.othersOpen = false;
  }

  public goBack(category: IQuickSearch): void {
    category.isOpen = false;
    category.isBackClosed = true;
    this.othersOpen = false;
    if (!category.parentCat) {
      this.currentTerms = this.terms;
      this.cat = null;
    } else {
      this.currentTerms = category.parentCat.subCategories; 
      this.cat = category.parentCat;
    }
    // if (this.precategory !== null) {
    //   this.precategory.isBackClosed = false;
    // } else {
    //   this.store.searchListing.BottomMenu = true;
    //   this.store.searchListing.searchBody = 'search-body no-pagination';
    // }
  }

  public hideBack(category: IQuickSearch): void {
    category.isBackClosed = true;
  }

  public makeSearch(event: string): void {
    this.search.emit(event);
  }

  public openAction(category: IQuickSearch): void {
    if (category.subCategories) {
      for (let i = 0; i < category.subCategories.length; i++) {
        let cat = category.subCategories[i];
        cat.parentCat = category;
        this.cat = category;
      }
    }


    // console.log(this.store.searchListing.BottomMenu);
    this.store.searchListing.BottomMenu = false;
    this.store.searchListing.searchBody = 'search-body no-pagination';
    // console.log(this.store.searchListing.BottomMenu);
    const precategoryExists = category.parentCat !== null;
    if (category.subCategories) {
      category.isOpen = true;
      category.isBackClosed = false;
      this.othersOpen = true;
      this.currentTerms = category.subCategories;
      if (category.subCategories) {
        this.childbackshowed.emit(category.parentCat);
      }
    } else {
      if (!category.subCategories) {
        if (this.cat.isSearchArgument) {    
          this.search.emit(category.parentCat.name + ' ' + category.name);
        } else {
          this.search.emit(category.name);
        }
      } else {
        this.search.emit(category.name);
      }
    }
  }
}
