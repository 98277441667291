import { Injectable } from '@angular/core';
import { action, observable, computed, reaction } from 'mobx';
import { Listing, GoogleReview, ReviewReply, ListingReview, BusinessAddress, ListingContact, Client, ListingPhoto, S3SignedUrl } from '../models';
import { ListingsService, GoogleReviewService, ReviewsService } from '../services/api';
import { AlertTypes, ActionTypes } from '../viewmodels';
import { cloneDeep, orderBy } from 'lodash';
import { AlertsStore } from './alerts.store';
import { AuthStore } from './auth.store';
import { SearchStore } from './search.store';
import { environment } from '../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { S3Store } from './s3.store';
import { CookieService } from '../../../node_modules/ngx-cookie-service';

@Injectable()
export class SearchListingStore {

  @observable
  public listing: Listing = new Listing({
    reviews: [],
    businessAddress: new BusinessAddress({}),
    categories: [],
    masterCategories: [],
    listingHours: [],
    services: [],
    photos: [],
    menus: [],
    contact: new ListingContact({}),
  });

  @observable
  public openClaimRequested = false;

  @observable
  public claimIsOpen = false;

  @observable
  public photoIsOpen = false;
  
  @observable
  public photoGaleryIndexUrl = '';

  @observable
  public d360IsOpen = false;
  @observable
  public menuIsOpen = false;

  @observable
  public detailIsOpen = false;

  @observable
  public D360 = false;

  @observable
  public verifyIsOpen = false;

  @observable
  public verifyPhone = '';

  @observable
  public BottomMenu = true;

  @observable
  public searchBody = 'search-body no-pagination';

  public imageOrigin = environment.imagesOrigin;
  
  constructor(
    private _listingsService: ListingsService,
    private _googleReviewService: GoogleReviewService,
    private _reviewsService: ReviewsService,
    private _alerts: AlertsStore,
    private _auth: AuthStore,
    private s3store: S3Store,
    private cookieService: CookieService 
  ) {
    reaction(
      r => this.listing.reviews ? this.listing.reviews.length : 0,
      r => this.orderReviews(),
      { name: '[REACTION] ORDER BY', fireImmediately: true }
    );
  }

  /**
   * Use this property to clone the listing without the reference.
   *
   * @readonly
   * @type {Listing}
   * @memberOf SearchListingStore
   */
  @computed
  public get clonedListing(): Listing {
    return cloneDeep(this.listing);
  }

  @computed
  public get isAReviewAdded(): boolean {

    const review = this.listing.reviews
      .find(x => x.clientId === this._auth.user.id);
    if (review !== undefined) {
      return true;
    }
    return false;
  }

  @action('[SEARCHLISTING][CLAIM:MODAL] Open')
  public openClaim(): void {
    this.claimIsOpen = true;
  }

  @action('[SEARCHLISTING][CLAIM:MODAL] Open')
  public closeClaim(): void {
    this.claimIsOpen = false;
  }

  @action('SELECT LISTING')
  public selectListing(listing: Listing): void {
    // this._search.stopLoading();

   

      if(listing) {
        if(listing.avatar && !listing.signedAvatar) {
          listing.avatar.indexOf('http') > -1 ? listing.signedAvatar =  this.getAvatar(listing.avatar) : listing.signedAvatar = this.imageOrigin+ listing.avatar ;
        }
      }

      
      // console.log(listing.avatar);

      if(listing.reviews)
      {
        listing.reviews.forEach(review => {

          if(review.client) {
            if( review.client.avatar && !review.client.signedAvatar) {
              review.client.avatar.indexOf('http') > -1 ? review.client.signedAvatar =  this.getAvatar(review.client.avatar) : review.client.signedAvatar = this.imageOrigin+ review.client.avatar ;
            }
          }
        });

      }

      if(this.listing.reviews.length>0) {

        this.listing.reviews = this.listing.reviews.sort((a,b)=> {
        
          // Changed for Safari support
          var x = a.addedDate.toString().split(/[^0-9]/);
          var firstAddedDate: Date=new Date ( +x[0],+x[1]-1,+x[2],+x[3],+x[4],+x[5] );

          var y = b.addedDate.toString().split(/[^0-9]/);
          var secondAddedDate: Date=new Date ( +y[0],+y[1]-1,+y[2],+y[3],+y[4],+y[5] );
      
          return ( secondAddedDate.valueOf()- firstAddedDate.valueOf() )

        } ) ;

      }


    this.detailIsOpen = true;
    if (!listing.reviews) {
      listing.reviews = [];
    }
    this.listing = listing;
  }

  
  private getAvatar(avatar: string): string {
    var result = this.getSignedUrl(avatar);
    return result;
  }

  getSignedUrl(fileFullName:string): string {
    const bucket = new S3(environment.s3.BucketOptions);
    let fileName: string = '' ;

    // console.log('fileFullName');
    // console.log(fileFullName);

    if(fileFullName == undefined || fileFullName== null || fileFullName== ''){
      return fileFullName;
    }

    var startIndex = fileFullName.indexOf('amazonaws.com/');
    if(startIndex>-1) {
      fileName = fileFullName.substring(startIndex+14);

      if(fileName.indexOf( environment.s3.Bucket + '/' ) === 0){
        fileName = fileName.substring(environment.s3.Bucket.length + 1);
      }

    }
    else {
      return fileFullName;
    }


    var existsSign = this.s3store.signedUrlList.filter(x=> x.name === fileName && x.expires.getTime() > new Date().getTime() );
    
    // console.log('existsSign');
    // console.log(existsSign);    

    if(existsSign.length > 0) {
      
      // console.log('existsSign');
      // console.log(existsSign[0].value);    

      return existsSign[0].value;

    }
    else {

        const paramsSign = {
        Bucket: environment.s3.Bucket,
        Key: fileName,
        Expires: 60*environment.s3.SignedUrlExpires 
        };
    
        var signedUrl = bucket.getSignedUrl('getObject',paramsSign);
    
        // console.log('signedUrl');
        // console.log(signedUrl);    
    
        this.s3store.signedUrlList.push(new S3SignedUrl({name:fileName , value: signedUrl, expires: new Date( new Date().getTime() + (environment.s3.SignedUrlExpires*60*1000) ) })) ;

        // console.log('signedUrlList');
        // console.log(this.store.s3.signedUrlList);    

        return signedUrl;        
    }


  }


  @action('UNSELECT LISTING')
  public unSelectListing(): void {
    this.detailIsOpen = false;
    // this.listing = new Listing({
    //   reviews: [],
    //   businessAddress: new BusinessAddress({}),
    //   categories: [],
    //   masterCategories: [],
    //   listingHours: [],
    //   services: [],
    //   contact: new ListingContact({}),
    // });
  }

  // @action('[NEWCATEGORY][ADD] Try')
  // public AddNewCategory(newrequest): void {
  //   this._listingsService.PostAddCategory(newrequest);
  // }

  @action('[LISTING][CLAIMBYAGENT] Try')
  public claimByAgent(listing: Listing, afterSuccess): void {
    this._listingsService.PostByAgent(listing)
      .subscribe(
        x => this.claimSuccess(x.data, afterSuccess(x.data)),
        err => this.claimFailed()
      );
  }

  @action('[LISTING][CLAIM] Try')
  public claim(listing: Listing, afterSuccess): void {
    this._listingsService.Post(listing)
      .subscribe(
      x => this.claimSuccess(x.data, afterSuccess(x.data)),
      err => this.claimFailed()
      );
  }

  @action('[LISTING][CLAIM] Success')
  private claimSuccess(listing: Listing, afterSuccess): void {

    this.verifyPhone = listing.contact.phoneNumber;
    this.listing = listing;

    var user: Client;
    
    if(this.cookieService.get('user')) {
      user = JSON.parse(this.cookieService.get('user'));
    }
    
    // Emre added role
    if(user.role === 2)
    {
      this._alerts.add({
        title: 'Success',
        message: `Registration completed for ${listing.name}`,
        execute: afterSuccess
      });
    }
    else
    {
      this._alerts.add({
        title: 'Success',
        message: `Calling the business number on file. Please enter the pin code provided by the automated system to finish claiming ${listing.name}`,
        execute: afterSuccess
      });
    }

  }

  @action('[LISTING][CLAIM] Failed')
  private claimFailed(): void {

    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later.'
    });
  }

  @action('[LISTING][CLAIMBYAGENT][UPDATE] Try')
  public claimUpdateByAgent(listing: Listing, afterSuccess): void {
    this._listingsService.PutByAgent(listing)
      .subscribe(
        x => this.claimUpdateSuccess(x.data, afterSuccess),
        err => this.claimUpdateFailed(err)
      );
  }


  @action('[LISTING][CLAIM] Success')
  private claimUpdateSuccess(listing: Listing, afterSuccess): void {

   
      this._alerts.add({
        title: 'Success',
        message: `Business information updated.`,
        execute: afterSuccess
      });

  }

  @action('[LISTING][CLAIM] Failed')
  private claimUpdateFailed(err): void {

    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later. ' + err
    });
  }


  @action('[REVIEWS] ORDER BY')
  public orderReviews(): void {
    this.listing.reviews = orderBy(this.listing.reviews.slice(), ['id'], ['desc']);
  }

  @action('[REVIEW][Get] Review By Id')
  public getReviewById(id: number): ListingReview {
    return this.listing.reviews.find(x => x.id === id);
  }

  @action('[REVIEW][Add] Try')
  public addReview(review: ListingReview, placeId: string): void {
    if (this.listing.id != null) {
      review.listingId = this.listing.id;
    }
    //console.log(review.listingId );
    review.likes =  0;
    review.dislikes = 0;
    this._reviewsService.Post(review, placeId)
      .subscribe(
      x => this.addReviewSuccess(x.data),
      err => this.addReviewFail()
     );
  }

  @action('[REVIEW][Add] Success')
  private addReviewSuccess(x: ListingReview): void {
    x.replies = [];
    
    // console.log('added review');
    // console.log(x);

    this.listing.reviews.push(x);

    this.listing.reviews = this.listing.reviews.sort((a,b)=> {
                  
      var x = a.addedDate.toString().split(/[^0-9]/);
      var firstAddedDate: Date=new Date ( +x[0],+x[1]-1,+x[2],+x[3],+x[4],+x[5] );

      var y = b.addedDate.toString().split(/[^0-9]/);
      var secondAddedDate: Date=new Date ( +y[0],+y[1]-1,+y[2],+y[3],+y[4],+y[5] );
  
      return ( secondAddedDate.valueOf()- firstAddedDate.valueOf() )

    } ) ;



    if(x.imageUrls !== undefined) {
      x.imageUrls.forEach(element => {

        var photo = new ListingPhoto({ id:0, imageUrl: element , listingId: x.listingId, listReviewId:x.id});
        this.listing.photos.push(photo) ;
      });
    }

    this._alerts.add({
      title: 'Success',
      message: 'Your review has been submitted.'
    });
  }

  @action('[REVIEW][Add] Failed')
  private addReviewFail(): void {
    this._alerts.add({
      title: 'Error',
      type: AlertTypes.exeption,
      message: 'Unable to add your review, please try again.'
    });
  }

  @action('[REVIEW][Edit] Try')
  public editReview(review: ListingReview, placeId): void {
    this._reviewsService.Put(review, placeId)
      .subscribe(
      res => this.editReviewSuccess(res.data),
      err => this.editReviewFailed()
      );
  }

  @action('[REVIEW][Edit] Success')
  private editReviewSuccess(review: ListingReview): void {

    // const storeReview = this.listing.reviews.find(x => x.id === review.id);

    // Object.assign(storeReview, review);


    review.replies = this.listing.reviews.filter(x => x.id == review.id)[0].replies ;
    
    this.listing.reviews = this.listing.reviews.filter(x => x.id != review.id);
    this.listing.photos = this.listing.photos.filter(x => x.listReviewId != review.id );  

    
    this.listing.reviews.unshift(review);


    if(review.imageUrls !== undefined) {
      review.imageUrls.forEach(element => {

        let isImageExist=false;

        this.listing.photos.forEach(x=>{
          if(x.imageUrl == element){
            isImageExist = true;
          }
        })

        if(!isImageExist) {
          var photo = new ListingPhoto({ id:0, listReviewId:review.id, imageUrl: element});
          this.listing.photos.push(photo) ;
        }
      
      });
    }

    

    this._alerts.add({
      title: 'Success',
      message: 'You review has been edited successfully.',
    });
  }

  @action('[REVIEW][Edit] Failed')
  private editReviewFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to save your changes. Please try again later.',
    });
  }

  @action('[REVIEW][Delete] Try')
  public deleteReview(id: number, placeId: string): void {
    this._alerts.add({
      title: 'Confirm',
      message: 'This review will be deleted. Do you want to continue?',
      hasCancel: true,
      okLabel: 'Yes, Remove It',
      execute: (res: ActionTypes) => {
        // console.log(res)
        if (res === ActionTypes.ok) {
          this._reviewsService.Delete(id, placeId)
            .subscribe(
              data => this.deleteReviewSuccess(id),
              err => this.deleteReviewFailed()
            );

        }
      } 
    });
  }

  @action('[REVIEW][Delete] Success')
  private deleteReviewSuccess(reviewId ) {
   
    this._alerts.add({
      title: 'Success',
      message: 'Your review has been deleted.',
      type: AlertTypes.success
    });

    
    this.listing.reviews = this.listing.reviews.filter(x => x.id != reviewId);  
    this.listing.photos = this.listing.photos.filter(x =>  x.listReviewId != reviewId);  


  }

  @action('[REVIEW][Delete] Failed')
  private deleteReviewFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to delete your review. Please try again later.',
      type: AlertTypes.exeption
    });
  }

  @action('[REPLY][Add] Try')
  public addReply(reply: ReviewReply, after): void {
    if (!this._auth.isSignedIn) {
      console.error('The user must be signed in for this action.');
      return;
    }
    this._reviewsService.PostReviewReply(reply, this.listing.googlePlaceId)
      .subscribe(
      x => this.addReplySuccess(x.data, after),
      err => this.addReplyFailed()
      );
  }

  @action('[REPLY][Add] Success')
  private addReplySuccess(reply: ReviewReply, after): void {
    const review = this.listing.reviews.find(x => x.id === reply.listingReviewId);
    reply.client = this._auth.user;
    if(review.replies==null || review.replies==undefined) {
      review.replies = [] ;
    }
    review.replies.push(reply);
    after();
  }

  @action('[REPLY][Add] Failed')
  private addReplyFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later.'
    });
  }

  @action('[REPLY][Edit] Try')
  public editReply(reply: ReviewReply, after = () => { }): void {
    this._reviewsService.PutReviewReply(reply, this.listing.googlePlaceId)
      .subscribe(
      x => this.editReplySuccess(x.data, after),
      err => this.editReplyFailed()
      );
  }

  @action('[REPLY][Edit] Success')
  private editReplySuccess(reply: ReviewReply, after): void {
    const review = this.listing.reviews.find(x => x.id === reply.listingReviewId);
    let creply = review.replies.find(x => x.id === reply.id);
    creply = reply;
    after();
  }

  @action('[REPLY][Edit] Failed')
  private editReplyFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later.'
    });
  }

  @action('[REPLY][Remove] Try')
  public removeReply(replyId: number, reviewId: number): void {
    this._alerts.add({
      title: 'Confirmation',
      message: 'The selected reply will be deleted.',
      okLabel: 'Yes, delete it',
      hasCancel: true,
      execute: (res) => {
        if (res === ActionTypes.ok) {
          const isGoogleReply: boolean = this.listing.reviews
            .find(x => x.id === reviewId)
            .listingId ? false : true;
          this._reviewsService.DeleteReviewReply(replyId, isGoogleReply)
            .subscribe(
            x => this.removeReplySuccess(replyId, reviewId),
            err => this.removeReplyFailed()
            );
        }
      }
    });
  }

  @action('[REPLY][Remove] Success')
  private removeReplySuccess(replyId: number, reviewId: number): void {
    const review = this.listing.reviews.find(x => x.id === reviewId);
    review.replies = review.replies.filter(x => x.id !== replyId);
  }

  @action('[REPLY][Remove] Failed')
  private removeReplyFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later.'
    });
  }

}
