
import { Listing } from './Listing';


export interface IListingServices  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    service?: string;
    listing?: Listing;
    listingId?: number;
}
export class ListingServices  implements IListingServices {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public service: string;
    public listing: Listing;
    public listingId: number;
    constructor(obj?: IListingServices) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.service = obj.service !== undefined ? obj.service : null;
        this.listing = obj.listing !== undefined ? obj.listing : null;
        this.listingId = obj.listingId !== undefined ? obj.listingId : null;
    }
}
// For reactive forms intended
export interface IFListingServices  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    service?: string; 
    /**
    * Is Listing
    */
    listing?: any; 
    /**
    * Is number
    */
    listingId?: any; 
}
