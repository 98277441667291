import { DateTime } from "aws-sdk/clients/rekognition";



export interface IS3SignedUrl  {
    name?: string;
    value?: string;
    expires?: DateTime;
}
export class S3SignedUrl  implements IS3SignedUrl {
    name: string;
    value: string;
    expires?: DateTime;

    constructor(obj?: IS3SignedUrl) {
        
        this.name = obj.name !== undefined ? obj.name: null;
        this.value = obj.value !== undefined ? obj.value: null;
        this.expires = obj.expires !== undefined ? obj.expires: null;
    }
}

