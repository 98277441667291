import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Client } from '../../../../../shared/models/Client';
import { ClientsService } from '../../../../../shared/services/api';
import { bool } from '../../../../../../node_modules/aws-sdk/clients/signer';
import { MatSnackBar } from '../../../../../../node_modules/@angular/material/snack-bar';
import { MwSnackBarComponent } from '../../../../../shared/components/mw-alert/mw-snack-bar.component';
import { PageEvent } from '../../../../../../node_modules/@angular/material/paginator';
import { Title } from '../../../../../../node_modules/@angular/platform-browser';

@Component({
  selector: 'mw-admin-user',
  templateUrl: './mw-admin-user.component.html',
  styleUrls: ['./mw-admin-user.component.scss']
})


export class MwAdminUserComponent implements OnInit {
  
  searchResult: Client[] = [] ;
  loading = false;
  pagination: any = null;
  pageEvent: PageEvent;
  

  constructor(
      private _clientService: ClientsService,
      private snackBar: MatSnackBar,
      private _titleService: Title
    )
  { 

  }  

  ngOnInit() {
    this._titleService.setTitle('Admin - User Management');
    this.search('',false);
  } 


  search(term: string, fromPaging: boolean) {

      this.loading = true;

      let pageSize = 10 ; // this.pageEvent == undefined ? 10 : this.pageEvent.pageSize ;
      let pageIndex = this.pageEvent == undefined || !fromPaging ? 0 : this.pageEvent.pageIndex  ;

      this._clientService.GetBySearchTermForAdmin(term, pageSize , pageIndex).subscribe(x=> {
        this.searchResult = x.data;
        this.pagination = x.pagination;
        this.loading = false;
        
      });

  }


  saveUser(id: number, status: boolean, searchResultPanel: any) {

    this.loading = true;

    this._clientService.PostUpdateClientStatusForAdmin(id, status).subscribe(x=> {
      this.loading = false;
      this.showMessage('User updated');
      searchResultPanel.close();
    });

  }
  

  showMessage(message: string) {
    let snackBarRef = this.snackBar.openFromComponent(MwSnackBarComponent, {
      duration: 2000,
      data: {
        message: message,
      }
    });

  }

  changePage(event: any, term){

    this.pageEvent = event;
    this.search(term, true);
  }

  onTabClick(event) {
   
    
  }

  
}
