import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorLogService } from './ErrorLog.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private errorLogService: ErrorLogService) {
        super();
    }

    handleError(error) : void {
        this.errorLogService.logError(error);
    }
}
