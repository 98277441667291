import { FormControl, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export class ArrayValidator {

  /**
   * Validator that requires controls to have a value of a maximum length.
   */
  static maxLength(maxLength: number, active: boolean): ValidatorFn {
    return (control: FormArray): ValidationErrors | null => {

      const length: number = control.controls.filter(x => x.value.active === active).length;
      // console.log(length, control.value)
      return length > maxLength ?
        { 'maxlength': { 'requiredLength': maxLength, 'actualLength': length } } :
        null;
    };
  }


}

interface ValidationResult {
  [key: string]: boolean;
}
