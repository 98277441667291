import {
  Component,
  ViewChildren,
  QueryList,
  AfterViewInit,
  NgZone,
  ChangeDetectionStrategy
} from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MapwordsStore } from '../../../../shared/store';
import { IFUser } from '../../../../shared/models';
import { MwFormControlComponent } from '../../../../shared/components';
import { observable } from 'mobx-angular';
import { Client } from '../../../../shared/models/Client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CookieService } from '../../../../../node_modules/ngx-cookie-service';
// import { AuthService } from './auth-service';


@Component({
  templateUrl: './mw-signin.component.html',
  styleUrls: ['./mw-signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwSignInComponent implements AfterViewInit {
  public login: FormGroup;

  public redirect: string;
  public returnUrl: string;
  public isSubmited:boolean=false;

  @observable public user: Client = new Client({});

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;


  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 


  constructor(
    private router: Router,
    private _fb: FormBuilder,
    public store: MapwordsStore,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private ngZone: NgZone,
    private cookieService: CookieService 
  ) {

    this.cookieExpire.setTime(this.cookieTime);

    this._titleService.setTitle('Sign In - Mapwords');
    this.initForm();

    // this.returnUrl = this._route.snapshot.queryParams['returnUrl'];

    // Emre
     if (this._route.snapshot.queryParams['reason'] === 'sessionExpired') {
    //   this.store.alerts.add({
    //     title: 'Session Expired',
    //     message:
    //       'Please log in and you will be redirected to the previous page.'
    //   });
      this.store.auth.forceSignOut();
      this.router.navigateByUrl(this.redirect);
    }
  }

  ngOnInit() {
    this.redirect = this._route.snapshot.queryParams['redirect'];
    // console.log('this.redirect') ;
    // console.log(this.redirect) ;

    // console.log('localStorage');
    // console.log(this.cookieService.get('redirect'));

    if(this.cookieService.get('redirect') !== null && this.cookieService.get('redirect') !== undefined && this.cookieService.get('redirect') !== ''){
      this.redirect =this.cookieService.get('redirect');
      this.cookieService.delete('redirect','/') ;
    }

  }

  ngAfterViewInit() {
    this.formControls.forEach(x => (x.formDirty = false));
  }

  public submit(): void {
    this.isSubmited = true;
    if (this.login.valid) {
      this.store.auth.login(this.login.value, (ret) => {

        if (ret.title === 'Invalid') {

          this.login.setErrors({loginValidationError: ret.message});
          this.isSubmited = false;

        } else if (ret.title === 'Server Error') {

          this.login.setErrors({serverError:ret.message});

          this.isSubmited = false;

        } else if (this.store.auth.user.role === 2) {


          this.setLocalStorage() ;
          this.checkRedirect();
          
          setTimeout(() => {
            this.router.navigateByUrl('/cp/agent');
          }, 200);
        

          this.router.navigateByUrl('/');

          // Observable.interval(100).subscribe(i => {location.reload();})          

        } else if (this.store.auth.user.role === 3) {


          this.setLocalStorage() ;
          this.checkRedirect();
          

          this.router.navigateByUrl('/cp/admin/user');

        } else {

          this.setLocalStorage() ;

          if (this.redirect === null || this.redirect === undefined || this.redirect === '') {
              this.router.navigateByUrl('/');
          }          

          this.checkRedirect();



        }
      });
    } else {
      this.formControls.forEach(x => (x.formDirty = true));
      this.isSubmited = false;
    }

    
  }


  public setLocalStorage() {
          
          localStorage.setItem('counter', '1');
          sessionStorage.setItem('sessionCounted', new Date().getTime().toString());    

          let token = this.cookieService.get('authToken');

          
  }

   public checkRedirect() {

    if (this.redirect !== null && this.redirect !== undefined && this.redirect !=='' ) {

      localStorage.setItem('counter', '1');
      // window.location.href = environment.applicationUrl + '/#' + this.redirect ;
      location.href = environment.applicationUrl + this.redirect ;
      
      // console.log(environment.applicationUrl +  this.redirect) ;
      // this.router.navigateByUrl(this.redirect);


    }
    
    
  } 

  private initForm(): void {
    this.login = this._fb.group(<IFUser>{
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
