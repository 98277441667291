import { Menus_Items } from './Menus_Items';


export interface IMenus_Sections  {
    Id?: number;
    Active?: boolean;
    addedDate?: Date;
    MenuId?: number;
    section_Title?: string;
    section_OrderBy?: number;
    menuItems?: Menus_Items[];    
}
export class Menus_Sections implements IMenus_Sections {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public MenuId: number;
    public section_Title: string;
    public section_OrderBy: number;
    public menuItems: Menus_Items[];    
    constructor(obj?: IMenus_Sections) {        
        this.id = obj.Id !== undefined ? obj.Id : null;
        this.active = obj.Active !== undefined ? obj.Active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.MenuId = obj.MenuId !== undefined ? obj.MenuId : null;
        this.section_Title = obj.section_Title !== undefined ? obj.section_Title : null;
        this.section_OrderBy = obj.section_OrderBy !== undefined ? obj.section_OrderBy : null;
        this.menuItems = obj.menuItems !== undefined ? obj.menuItems : null;             
    }
}
// For reactive forms intended
export interface IFMenus_Sections  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is number
    */
    MenuId?: any; 
    /**
    * Is string
    */
    section_Title?: any;
    /**
    * Is int
    */
    section_OrderBy?: any;
    /**
    * Is Menu_Items
    */
    menuItems?: any; 
    
}
