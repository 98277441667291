import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { GetListingBySearchTermRouteModel } from '../../models/GetListingBySearchTermRouteModel';

import { Listing } from '../../models/Listing';
import { State } from '../../models/State';
import { BaseRestService } from './base.service';
import { S3 } from 'aws-sdk/clients/all';
import { environment } from '../../../environments/environment';
import { url } from 'inspector';
import { TokenModel } from '../../models/TokenModel';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class ListingsService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Gets all the listings
    *   @return Listings 
    */
    public Get(): Observable<RG<Listing[]>> {
       
        return this.get( `api/v1/alllistings`);
    }

    /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public GetById(id: number  = 0, place_id: string  = ""): Observable<RG<Listing>> {
        
        return this.get(`api/v1/listings?id=${id || 0}&place_id=${place_id || ""}`);
    }

        /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public CheckPano(lat: number  = 0,lng: number = 0): Observable<string> {
       
        return this.get(`api/v1/listings/pano?lat=${lat || 0}&lng=${lng || 0}`);
    }

    /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public GetByIdNoReviews(id: number  = 0): Observable<RG<Listing>> {
        
        return this.get(`api/v1/listingsnoreviews?id=${id || 0}`);
    }

    /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public GetByGID(gid: string  = ""): Observable<RG<Listing>> {
        
        return this.get(`api/v1/listingsbygid?gid=${gid || ""}`);
    }    

   /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public GetByUrl(url: string = ""): Observable<RG<Listing>> {
        
        return this.get(`api/v1/listingsbyURL?url=${url || ""}`);
    }    

   /** 
    *   Get a listing by ID or the Google Place_id. *Note: Setting ID to 0 will trigger a search of Google only.
    *   @return A Listing object. 
    *   @param id - The ID for the Listing (int)
    *   @param place_id - The Place_ID for the Listing (String)
    */
    public GetByDisplayName(name: string = ""): Observable<RG<Listing[]>> {
       
        return this.get(`api/v1/listings/displayname?name=${name || ""}`);
    }       

    /** 
    *   Get claimed listings by the ID of the owner.
    *   @return An array of Listing Objects 
    *   @param clientId - The Client ID of the Listing (INT)
    */
    public GetByClientId(clientId: number  = 0, page: number  = 1): Observable<RG<Listing[]>> {
       
        return this.get(`api/v1/listings/client?clientId=${clientId || 0}&page=${page || 1}`);
    }

    /** 
    *   Get all claimed listings by Category ID or Name
    *   @return An array of Listing Objects 
    *   @param categoryid - The Id of the Category(INT)
    *   @param categoryname - The Name of the Category (string)
    */
    public GetByCategoryId(categoryid: number  = 0, categoryname: string  = "", page: number  = 1): Observable<RG<Listing[]>> {
        
        return this.get(`api/v1/listings/category?categoryid=${categoryid || 0}&categoryname=${categoryname || ""}&page=${page || 1}`);
    }

    /** 
    *   Get all claimed listings by City and State
    *   @return An array of Listing Objects 
    *   @param city - City Name (string)
    *   @param state - State Name(string)
    */
    public GetByCityState(city: string , state: string , page: number  = 1): Observable<RG<Listing[]>> {
        
        return this.get(`api/v1/listings/citystate?city=${city}&state=${state}&page=${page || 1}`);
    }

    /** 
    *   Get all claimed listings by Zipcode
    *   @return An array of Listing Objects 
    *   @param zipcode - Zip Code (string)
    */
    public GetByZipCode(zipcode: string , page: number  = 1): Observable<RG<Listing[]>> {
        
        return this.get(`api/v1/listings/zipcode?zipcode=${zipcode}&page=${page || 1}`);
    }

    /** 
    *   Get all claimed listings within a certain distance
    *   @return An array of Listing Objects 
    *   @param lat - Latitude (double)
    *   @param lng - Longitude (double)
    *   @param distance - Distance (double)
    */
    public GetByDistance(lat: number , lng: number , distance: number ): Observable<RG<Listing[]>> {
       
        return this.get(`api/v1/listings/nearby?lat=${lat}&lng=${lng}&distance=${distance}`);
    }

    /** 
    *   Get all claimed listings and Google Listings based on filters
    *   @return An array of Listing Objects from Mapwords and Google 
    *   @param model - A SearchFilterViewModel object containing the filter parameters
    */
    public GetListingBySearchTerm(model: GetListingBySearchTermRouteModel , page: number  = 1): Observable<RG<Listing[]>> {
        
        let encodedSearch = encodeURIComponent(model.searchterm);

        return this.get(`api/v1/listings/search?page=${page || 1}&searchterm=${encodedSearch}&latitude=${model.latitude}&longitude=${model.longitude}&distance=${model.distance}&open=${model.open}&price=${model.price}&rating=${model.rating}&order=${model.order}`);
    }

    // Sean
    public AgentGetListingBySearchTerm(model: GetListingBySearchTermRouteModel , page: number  = 1): Observable<RG<Listing[]>> {
        
        return this.get(`api/v1/listings/agentsearch?page=${page || 1}&searchterm=${model.searchterm}&latitude=${model.latitude}&longitude=${model.longitude}&distance=${model.distance}&open=${model.open}&price=${model.price}&rating=${model.rating}&`);

    }

    public GetAllStates(): Observable<RG<State[]>> {
        
        return this.get( `api/v1/allstates`);
    }
    
    /** 
    *   Get all claimed listings and Google Listings based on filters
    *   @return An array of Listing Objects from Mapwords and Google 
    *   @param model - A SearchFilterViewModel object containing the filter parameters
    */
    public GetPin(model: Listing ): Observable<RG<Listing>> {
       
        return this.post(`api/v1/listings/phoneverify`, JSON.stringify(model) );
    }
    

    /** 
    *   Claim a listing.
    *   @return A Listing object. 
    *   @param model - A Listing object.
    */
    public Post(model: Listing ): Observable<RG<Listing>> {
        
        return this.post( `api/v1/listings`, JSON.stringify(model) );
    }

    // Sean
    public PostByAgent(model: Listing): Observable<RG<Listing>> {

        return this.post(`api/v1/listings/getbyagent`, JSON.stringify(model));
    }    

    /** 
    *   Verify Listing
    *   @return Bool 
    *   @param token - Token
    */
    public PutListingVerify(model: TokenModel ): Observable<RG<Listing>> {
        
        return this.put(`api/v1/listings/verify`, JSON.stringify(model) );
    }

    /** 
    *   Update a claimed listing
    *   @return A Listing object. 
    *   @param value - A Listing object.
    */
    public Put(model: Listing ): Observable<RG<Listing>> {
        return this.put(`api/v1/listings`, JSON.stringify(model) );
    }

    public PutByAgent(model: Listing ): Observable<RG<Listing>> {
        return this.put(`api/v1/listings/agent`, JSON.stringify(model) );
    }

    /** 
    *   Delete a claimed listing
    *   @return A empty listing. 
    *   @param id - The ID of the Listing to be deleted.
    */
    public Delete(id: number ): Observable<RG<Listing>> {
        
        return this.delete( `api/v1/listings?id=${id}`);
    }


    
  async uploadfile(file, fileName): Promise<string> {
    const bucket = new S3(environment.s3.BucketOptions);

    const params = {
      Bucket: environment.s3.Bucket,
      Key: fileName,
      Body: file
    };

    const options = {partSize: 1000 * 1024 * 1024, queueSize: 1};


    var result = await bucket.upload(params, options).promise();

    return result.Location.toString();

  }




}

