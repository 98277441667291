import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

/**
 * Toggle Inputs just for business flow
 */
@Component({
  selector: 'mw-toggle-inputs',
  templateUrl: './mw-toggle-inputs.component.html',
  styleUrls: ['./mw-toggle-inputs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MwToggleInputsComponent implements OnInit, OnChanges {

  @Input()
  public valid: boolean;

  @Input()
  public formNoPadding = false;

  @Output()
  public save = new EventEmitter();

  @Output()
  public open = new EventEmitter();

  @Input()
  public active: boolean = false;

  @Input()
  public hideActions = false;

  @Input()
  public showCancel = true;

  public ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges) {
    this.valid = changes['valid'].currentValue;
  }

  public openToggle() {
    this.active = true;
    //console.log(this);
    this.open.emit(this);
  }

  /**
	 * This close the control modal panel for the user
	 * @memberOf ToggleInputsComponent
	 */
  public closeToggle(): void {
    this.active = false;
  }

  public saveInformation() {
    if (this.valid) {
      this.closeToggle();
      this.save.emit(true);
    }
  }

  public onCancel() {
    this.closeToggle();
    this.save.emit(false);
  }

}
