import {
  Component, OnInit, Input, ViewContainerRef, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ListingReview, Listing, ReviewReply, ReplyTypes, Client } from '../../../../../shared/models';
import { ReviewsService } from '../../../../../shared/services/api';
import { environment } from '../../../../../environments/environment';
import { MapwordsStore } from '../../../../../shared/store';
import { ActionTypes } from '../../../../../shared/viewmodels';
import { S3filesService } from '../../../../../shared/services';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'mw-listing-review',
  templateUrl: './mw-listing-review.component.html',
  styleUrls: ['./mw-listing-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MwListingReviewComponent implements OnInit {

  @Input()
  public review: ListingReview;

  @Input()
  public location = '';

  @Output()
  public deletedReview = new EventEmitter<number>();

  @Output()
  public edit = new EventEmitter<number>();

  public replying = false;

  public preReplyMessage = '';

  public replyingMessage = '';

  public imageOrigin = environment.imagesOrigin;

  public reviewIsOpen = false;

  public isLoading = false;

  public get RepliesOrdered(): ReviewReply[] {
    // this.review.replies = _.orderBy(this.review.replies, ['addedDate'], ['desc']);
    return this.review.replies;
  }

  constructor(
    private _reviewsService: ReviewsService,
    public store: MapwordsStore,
    private _changeDetector: ChangeDetectorRef,
    private _router: Router,
    private s3filesService: S3filesService,
    private sanitizer:DomSanitizer
  ) { 


  }

  ngOnInit() {

  

  }




  public editReview(): void {
    this.reviewIsOpen = true;
  }

  public deleteReview(reviewId: number): void {
    this.store.searchListing.deleteReview(reviewId, this.store.searchListing.listing.googlePlaceId);
    // this.refreshPage() ;

  }

  

  public refreshPage() {

    let id = this.store.searchListing.listing.id ;
    let place_id = this.store.searchListing.listing.googlePlaceId;

    this._router.navigate(['/home', {
      outlets: {
        'search':
          [
            this.store.search.filter.searchterm,
            this.store.search.filter.latitude,
            this.store.search.filter.longitude,
            this.store.search.filter.distance,
            this.store.search.filter.price,
            this.store.search.filter.rating,
            this.store.search.filter.open,              
            this.store.search.page,
            this.store.search.filter.order
          ],
        'listing': null
      }
    }
    ]).then(() => {
      this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }]);
      
    });

  }

  public submitReply(): void {

    if(this.isLoading) {
      return ;
    }

    this.isLoading=true;


    if (!this.store.auth.isSignedIn  && this.store.auth.user !== null && this.store.auth.user !== undefined ) {
      this.store.alerts.add({
        title: 'Sign in required',
        message: 'Please sign in or create a new account.!!!',
        cancelLabel: 'Sign Up',
        okLabel: 'Sign In',
        hasCancel: true,
        execute: x => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-in?redirect=' + this._router.url + '&review=1');
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-up?redirect=' + this._router.url + '&review=1');
          }
        }
      });
      return;
    }

    if (this.replyingMessage.length !== 0) {
      this.store.searchListing.addReply(new ReviewReply({
        listingReviewId: this.review.id,
        message: this.replyingMessage,
        replyType: ReplyTypes.normal
      }), () => {
        this.isLoading=false;
        this.cancelReply();
      });
    }
    else
    {
      this.isLoading=false;
    }


  }

  public saveEditedReply(reply: ReviewReply): void {
    if (reply.message.length > 0) {
      reply.active = true;
      this.store.searchListing.editReply(reply);
    }
  }

  public removeReply(reply: ReviewReply): void {
    this.store.searchListing.removeReply(reply.id, reply.listingReviewId);
  }

  public cancelEditedReply(reply: ReviewReply): void {
    reply.active = true;
    reply.message = this.preReplyMessage;
  }

  public openReplyEditBox(reply: ReviewReply): void {
    reply.active = false;
    this.preReplyMessage = reply.message;
  }

  public openReplyBox(): void {
    this.replying = true;
  }

  public cancelReply(): void {
    this.replying = false;
    this.replyingMessage = '';
  }

  public getAvatar(avatar: string): string {
    
    return this.s3filesService.getSignedUrl(avatar);

  }
  
}
