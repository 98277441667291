import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumList'
})

export class EnumPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    const list = [];
    let i = 0;
    for (const key in value) {
      if (value.hasOwnProperty(key) && !/^\d+$/.test(key)) {
        list.push(
          {name: key.split('_').join(' '), value: i}
        );
        i++;
      }
    }
    return list;
  }
}
