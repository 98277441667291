import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Listing } from '../../../../shared/models';
import { ClipboardService } from '../../../../../node_modules/ngx-clipboard';

@Component({
  selector: 'mw-share-pop',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="bg-background-white"></div>
    <div class="mw-share-pop">
      <div class="sp-header">
        <h1><mat-icon>share</mat-icon> Share: {{listing.name}}</h1>
        <i class="ion-ios-close-empty" (click)="closeEvent()"></i>
      </div>
      <div class="sp-body">
        <div>
        <div class="form-control">
          <div class="url-description">{{listing.name}} link. </div>
          <div class="url-link" >
          <input class="url-text" maxlength="30" type="text" (keypress)="disableEvents($event)" #userinput [value]="currentUrl" disabled />
          </div>
        </div>

          <button mat-raised-button color="primary" (click)="copyToClipboard(currentUrl)" >COPY</button>

        </div>
      </div>
    </div>
  `,
  styleUrls: ['./mw-share-pop.component.scss']
})
export class MwSharePopComponent implements OnInit {

  @Input()
  public readonly listing: Listing;

  @Output()
  public close = new EventEmitter();

  public currentUrl: string;

  constructor(private _clipboardService: ClipboardService) {

  }

  ngOnInit() {
    this.currentUrl = location.href;
  }

  public closeEvent(): void {
    this.close.emit();
  }

  public disableEvents(event: KeyboardEvent): boolean {
    return false;
  }

  public copy(): void {

    // var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    // if(iOS) {
    //   this.iosCopyToClipboard(location.href);
    // }
    // else {
    //   this.copyTextToClipboard(location.href);
    // }
    
  
  
  }

  public copyTextToClipboard(text) {
    const textArea = <any>document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      // console.log('Copying text command was ' + msg);
    } catch (err) {
      // console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  copyInputMessage(inputElement){
    inputElement.disabled = false;
    
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 99999);

    inputElement.disabled = true;
  }

  copyToClipboard(text) {
    this._clipboardService.copyFromContent(text);
  }

  // public iosCopyToClipboard(val: string){
  //   let selBox = document.createElement('textarea');
  //   selBox.style.position = 'fixed';
  //   selBox.style.left = '0';
  //   selBox.style.top = '0';
  //   selBox.style.opacity = '0';
  //   selBox.value = val;
  //   document.body.appendChild(selBox);
  //   selBox.focus();
  //   selBox.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(selBox);
  // }

  

}
