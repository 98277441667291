import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { MapwordsStore } from '../store';
import { Listing, RG } from '../models';
import { ListingsService } from './api';
import { ErrorResolver } from '../../app/common/app-error-resolver';

@Injectable()
export class URLListingResolver implements Resolve<RG<Listing>> {

  constructor(
    private _listingsService: ListingsService,
    private _router: Router,
    public store: MapwordsStore) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = route.params['url'];
    const listing$ = this._listingsService.GetByUrl(url);
    return listing$.catch(e => {
      // this.store.alerts.add({
      //   title: 'Ooops!',
      //   message: 'Unable to complete the operation at this time, please try again later.',
      //   execute: () => {
      //     location.reload();
      //   }
      // });
      // return Observable.empty();
      return new ErrorResolver().resolve(e);
    });
  }

}
