/// <reference types="@types/googlemaps" />
import {
  Component, OnInit, ViewChild, NgZone, ChangeDetectionStrategy, 
  AfterViewInit, OnDestroy
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { GetListingBySearchTermRouteModel as Filter, Listing, Client, Address } from '../../../shared/models';
import { MwMapComponent } from '../../../shared/components';
// import { quickSearchTerm, IQuickSearch } from 'viewmodels';
import { MapwordsStore } from '../../../shared/store';
import { reaction, when, toJS } from 'mobx';

import { IQuickSearchObject } from '../../../shared/models/QuickSearchObject';
import { CategoryTreeService, ClientsService, LocationService } from '../../../shared/services/api';
import { DeviceDetectorService } from '../../../../node_modules/ngx-device-detector';
import { environment } from '../../../environments/environment';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';
//import { google } from '@agm/core/services/google-maps-types';

declare var $: any;
//declare var addToHomescreen: any;
//declare const google: any;
@Component({
  selector: 'mw-home',
  templateUrl: './mw-home.component.html',
  styleUrls: ['./mw-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwHomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MwMapComponent)
  public map: MwMapComponent;

  public quickSearchDisplay = true;

  public ShowLeftNav = true;

  public searchTerms:IQuickSearchObject[] =[] ; // cloneDeep(quickSearchTerm);

  public searchTermsAll:IQuickSearchObject[] =[] ;

  public position: { lat, lng };  

  public showPosition = false;

  public showFilterPosition = true;

  public isHome = true;

  public lastResultsPlaceId: string;

  public showmap = true;

  public collaps = false;

  public showSearch = true;

  public lat: number;

  public lng: number;

  public client: Client;

  public showCompleteProfile: boolean=false;

  public clientLoaded: boolean=false;

  public initPos: any;

  constructor(
    public store: MapwordsStore,
    private _zone: NgZone,
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleService: Title,
    private _categoryTreeService: CategoryTreeService,
    private _clientsService: ClientsService,
    private _locationService: LocationService,
    private _deviceService: DeviceDetectorService,
    private cookieService: CookieService 
  ) { }

  ngOnInit() {

    console.log(this._router.url);

    if(this._router.url.startsWith('/home#/vici')) {
      window.location.href = environment.applicationUrl + this._router.url.substring(6);
    }

    // Emre
    // For categorytree
    if(this.searchTerms.length === 0) {
        this._categoryTreeService.GetCategoryTree().subscribe((x) =>{
          this.searchTermsAll = x.data;
          this.searchTermsReset();
      });
    }    

    let isFilterSetted = this.setFilters();

    if(! isFilterSetted) {
      this.setCurrentPosition();
    }

    this.setMap();
    
    // console.log("Init", this._route.firstChild);

    this._router.events.forEach(e => {

      this.searchTermsReset();

      if (e instanceof NavigationStart) {
        this.store.search.startLoading();
      }
      if (e instanceof NavigationEnd) {
        this.store.search.stopLoading();
        
        // this.searchTerms = cloneDeep(quickSearchTerm);
        // this.searchTerms = this._categoryTreeStore.categoryTree;   
      }
    });


    if(this.checkFirstNameLastName() ){

      this._clientsService.GetProfileById(this.store.auth.user.id)
        .subscribe((x) => {
          this.client = x.data;
          this.clientLoaded = true;
        });

    }



  }

  
  ngAfterViewInit() {
    // console.log("After Init", this.store.search.filter.latitude);

    this.setReactions();
    this.map.api.getNativeMap().then(map => {

      google.maps.event.addDomListenerOnce(map, 'idle', () => {
   
        google.maps.event.trigger(map, 'resize');
      });
      if (window.innerWidth < 490) {
        console.log('Initial Center Mobile');
        map.panBy(0, -50);
      } else if (window.innerWidth < 740 && window.innerWidth > 500) {
        map.panBy(-200, 0);
        //  console.log('Initial Center Tablet');
      } else {
        map.setZoom(13);
        //this.centerWithMarkers();
        //  console.log('Initial Center Desktop');
      }
    });

    if (window.innerWidth < 1400) {
      this.ShowLeftNav = false;
    }

    // var addtohome = addToHomescreen({
    //   debug: false,				// override browser checks
    //   displayPace: 1440,			// minutes before the message is shown again (0: display every time, default 24 hours)
    //   autostart: true,			// show the message automatically
    //   message: '<p> Click on the <i class="ath-icon ath-action-icon"></i> below to add MapWords to your home screen </p>'
    // });

    this.searchTermsReset();
    this.showCompleteProfile = this.checkFirstNameLastName();


  };

  public getInitPosition(e) {
    //console.log(e);
    this.position.lat = e.lat;
    this.position.lng = e.lng;

    //console.log(this.position);
    // const filter = this.store.search.cloneFilter;
    //         filter.latitude = this.position.lat;
    //         filter.longitude = this.position.lng;           
    //         this.store.search.updateFilter(filter);

  }

  public showMap(): void {
    this.showmap = !this.showmap;
  }

  public checkFirstNameLastName(): boolean{

    let user: any;

    if(this.cookieService.get('user')) {
      user = JSON.parse(this.cookieService.get('user'));
    }


    if (this.store.auth.isSignedIn && user && this.store.auth.isClient) {
      if (user.firstName === undefined && user.lastName === undefined) {
        
        return true;
      }
    }

    return false;
  }

  public collapsSearch(): void {
    this.collaps = !this.collaps;
    this.showSearch = !this.showSearch;
  }

  public search(): void {

    if (this.store.search.filter.latitude === undefined && this.store.search.filter.longitude === undefined){
      this.store.search.filter.latitude = this.position.lat;
      this.store.search.filter.longitude = this.position.lng;
    }

    if (this.store.search.filter.searchterm === '' || this.store.search.filter.searchterm === null) {
      //this.centerWithMarkers();
      // this.store.search.stopLoading();
    } else { 
      this.store.search.filter.searchterm = this.store.search.filter.searchterm.trim();
      this.store.search.startLoading();
      // this.store.search.updatePage(1); // //////////////
      this._router.navigate(['/home', {
        outlets: {
          'search':
            [
              this.store.search.filter.searchterm,
              this.store.search.filter.latitude,
              this.store.search.filter.longitude,
              this.store.search.filter.distance,
              this.store.search.filter.price,
              this.store.search.filter.rating,
              this.store.search.filter.open,              
              this.store.search.page,
              this.store.search.filter.order
            ],
          'listing': null
        }
      }
      ]).then(() => {
        // console.log("1");
        this.centerWithMarkers();
        // this.store.search.stopLoading();
      });
    }
  }

  public quickSearch(searchTerm: string): void {
    this.searchTermsReset();
    const filter = this.store.search.cloneFilter;
    filter.searchterm = searchTerm;
    this.store.search.updateFilter(filter);
    this.search();
  }

  public setFilters(): boolean {

    let isFiltersSetted = false;

    if (this._route.children[0] !== undefined) {
      let params = this._route.children[0].params['value'];
      if (params.searchterm === undefined && this._route.children[1] !== undefined) {
        params = this._route.children[1].params['value'];
      }

      const filter = new Filter({
        latitude: +params.latitude,
        longitude: +params.longitude,
        searchterm: params.searchterm,
        open: params.open,
        price: params.price,
        rating: params.rating,
        distance: params.distance,
        order: params.order
      });
      this.store.search.updateFilter(filter);
      this.store.search.updatePage(+params.page);

      this.position = {
        lat: 0,
        lng: 0
      };
      
      this.position.lat = filter.latitude;
      this.position.lng = filter.longitude;

      isFiltersSetted = true;
    }

    return isFiltersSetted;
  }

  ngOnDestroy() {
  }

  public setMap(): void {
   

    this._router.events.subscribe((x: NavigationEnd) => {
      if (x.url === '/home' || x.url === '/') {
        this._titleService.setTitle('Mapwords');
        this.quickSearchDisplay = true;
        this.store.search.cleanListingsResults();
        this.store.searchListing.unSelectListing();
        this.isHome = true;
      } else {
        this.quickSearchDisplay = false;
        this.isHome = false;
      }

    });
  
  }

  public setReactions(): void {
    reaction(
      () => this.store.search.listingResults.map(x => x.active),
      x => {
        this._zone.runOutsideAngular(() => {
          this.map.api.getNativeMap().then(map => {
            if (this.store.search.hoveredListingBasic) {
              // map.setCenter({
              //   lat: this.store.search.hoveredListingBasic.businessAddress.latitude,
              //   lng: this.store.search.hoveredListingBasic.businessAddress.longitude
              // });
              if (window.innerWidth < 490) {
                // console.log('Hovered Mobile');
                map.setCenter({
                  lat: this.store.search.hoveredListingBasic.businessAddress.latitude,
                  lng: this.store.search.hoveredListingBasic.businessAddress.longitude
                });
                map.setZoom(13);
                map.panBy(0, -60);
                setTimeout(() => {
                  this.showmap = true;
                }, 250);
              } else if (window.innerWidth < 740 && window.innerWidth > 500) {
                map.setCenter({
                  lat: this.store.search.hoveredListingBasic.businessAddress.latitude,
                  lng: this.store.search.hoveredListingBasic.businessAddress.longitude
                });
                map.setZoom(13);
                map.panBy(-200, 0);
              } else if (window.innerWidth > 740) {
                //  map.fitBounds(<any>new google.maps.LatLngBounds());
                map.setZoom(13);
                map.panBy(
                  0, 0
                );
                // console.log(
                //   this.store.search.hoveredListingBasic.businessAddress.latitude,
                //   this.store.search.hoveredListingBasic.businessAddress.longitude);
              }
            }
          });
        });

      },
      { name: '[UI][MAP] Hover preview' }
    );

    // It dispose itself so is not calling again
    when(
      () => this.store.search.listingsResultsNumber > 0,
      () => {
        this.centerWithMarkers();
      }
    );

    reaction(
      () => toJS(this.store.search.filter),
      x => {
        // if (this.store.search.filter.searchterm === '') {
        //   return;
        // }
        console.log('reaction filter');
        console.log(this.store.search.filter);
        this.store.search.resetPagination();
        this.search();
      }, { name: '[UI][FILTER] Changed' }
    );

    reaction(
      () => this.store.searchListing.listing,
      x => {
        if (window.innerWidth > 800) {
          this._zone.runOutsideAngular(() => {
            this.map.api.getNativeMap().then(map => {
              // console.log('Active Listing Preview')
              map.setCenter({
                lat: this.store.searchListing.listing.businessAddress.latitude,
                lng: this.store.searchListing.listing.businessAddress.longitude
              });
              map.setZoom(13);
              if (window.innerWidth > 1000) {
                map.panBy(-400, 0);
              }
            });
          });
        }
      }, { name: '[UI][MAP][SearchListingStore] Selected' }
    );

    reaction(() => this.store.search.page, page => {
      this.search();
    }, { name: '[UI][PAGINATION] Changed' });
  }

  public setCurrentPosition(): void {

    this.position = {
      lat: 0,
      lng: 0
    };

    if(this._deviceService.isDesktop() ) {
      this.setLocationFromIp();
    }
    else {

          if (navigator.geolocation) {
            // console.log('GET LOCATION Home COMPONENT');

            navigator.geolocation.getCurrentPosition(x => {

              this._locationService.GetCurrentAddress(x.coords.latitude, x.coords.longitude)
                .subscribe(c => {

                  this.position.lat = x.coords.latitude;
                  this.position.lng = x.coords.longitude;

                  const filter = this.store.search.cloneFilter;
                  filter.latitude = this.position.lat;
                  filter.longitude = this.position.lng;
                  this.store.search.updateFilter(filter);
                });        
            },
            errorGeo => {
              // console.log('errorGeo');
              // console.log(errorGeo);

              this.setLocationFromIp();
              
            });
          }
     }
  }


  

  public setLocationFromIp () {

      // this._locationService.GetIp().subscribe (ipData => {

      //       console.log('external ip');
      //       console.log(ipData.ip);
      // });
        
      this._locationService.GetLocationFromIp()
        .subscribe(c => {

          this.position.lat = c.data.latitude;
          this.position.lng = c.data.longitude;

          const filter = this.store.search.cloneFilter;
          filter.latitude = c.data.latitude;
          filter.longitude = c.data.longitude;

          this.store.search.updateFilter(filter);


        });

  }




  public centerWithMarkers(): void {

    this.map.api.getNativeMap().then(map => {
      const bounds = new google.maps.LatLngBounds();

      if (this.isHome) {
        bounds.extend(new google.maps.LatLng(this.store.search.filter.latitude, this.store.search.filter.longitude));
        map.fitBounds(<any>bounds);
      }

      if (this.store.search.listingsResultsNumber > 0) {
        this.quickSearchDisplay = false;
        bounds.extend(new google.maps.LatLng(this.store.search.filter.latitude, this.store.search.filter.longitude));
        // if (this.lastResultsPlaceId !== this.store.search.listingResults[0].googlePlaceId) {
        this.store.search.listingResults.forEach(x => {
          bounds.extend(new google.maps.LatLng(x.businessAddress.latitude, x.businessAddress.longitude));
        });
        map.fitBounds(<any>bounds);
        // }
      }
      this._zone.runOutsideAngular(() => {
        google.maps.event.addDomListenerOnce(map, 'bounds_changed', () => {
          this._zone.run(() => {
            if (this.isHome) {
              map.setCenter({ lat: this.store.search.filter.latitude, lng: this.store.search.filter.longitude });
              map.setZoom(13);
              if (window.innerWidth < 490) {
                console.log('Run Outside Angular Mobile');
                map.panBy(0, 10);
              } else if (window.innerWidth < 740 && window.innerWidth > 500) {
                map.panBy(-200, 0);
              } else {
                map.setZoom(13);
                // console.log('Recenter Initialization');
                // console.log(
                //   this.store.search.filter.latitude,
                //   this.store.search.filter.longitude
                // );
              }
            }
            if (this.store.search.listingResults.length > 0) {
              // console.log(map.getZoom());
              // if (map.getZoom() > 12) {
              //   map.setZoom(11);
              // }
              if (this.lastResultsPlaceId !== this.store.search.listingResults[0].googlePlaceId) {
                if (window.innerWidth < 490) {
                  console.log('Search Lisiting Results Mobile');
                  map.panBy(0, 0);
                } else if (window.innerWidth < 740 && window.innerWidth > 500) {
                  map.panBy(-200, 0);
                } else {
                  map.panBy(-300, 0);
                }
                if (this.store.search.listingsResultsNumber === 1) {
                  map.setCenter({
                    lat: this.store.search.listingResults[0].businessAddress.latitude,
                    lng: this.store.search.listingResults[0].businessAddress.longitude
                  })
                  map.setZoom(13);
                  map.panBy(-300, 0);
                  //  console.log('Center Location Store')
                }
                this.lastResultsPlaceId = this.store.search.listingResults[0].googlePlaceId;
                this.store.searchListing.unSelectListing();
              }
            }
          });
        });
        google.maps.event.addDomListener(window, 'resize', () => {
          google.maps.event.trigger(map, 'resize');
          this._zone.run(() => {
            if (this.store.search.listingsResultsNumber > 0) {
              map.setZoom(10);
              map.setCenter(<any>bounds.getCenter());
              map.fitBounds(<any>bounds);
            } else {
              map.setCenter({ lat: this.store.search.filter.latitude, lng: this.store.search.filter.longitude });
            } if (window.innerWidth < 490) {
              map.panBy(0, 190);
            } else if (window.innerWidth < 740 && window.innerWidth > 500) {
              map.panBy(-200, 0);
            } else {
              map.panBy(-300, 0);
            }
          });
        });

      });


    });
  }

  public searchTermsReset(): void {

    this._zone.run(() => {
      this.searchTerms = this.searchTermsAll;    
    });

    // this.searchTerms = cloneDeep(quickSearchTerm);




    // console.log('Reseting');
    // items.forEach(x => {
    //   x.isOpen = false;
    //   x.isBackClosed = true;
    //   if (x.subCategories) {
    //     x.subCategories = this.searchTermsReset(x.subCategories);
    //   }
    // });
    // return items;
  }

}
