import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './not-found.component';

import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import { MwAdsComponent } from './pages/ads.component';
import { MwContactUsComponent } from './pages/contact-us.component';
import { MwPartnersComponent } from './pages/partners.component';
import { MwPrivacyComponent } from './pages/privacy.component';
import { MwTermsComponent } from './pages/terms.component';
import { MwTestVideoComponent } from './pages/testVideo.component';
import { MwViciComponent } from './pages/vici.component';
import { MwHomeComponent } from './feature/mw-home/mw-home.component';
import { AuthGuard } from './feature/mw-auth/auth-guard.service';
import { MwLocalSnapsComponent } from './pages/localsnaps.component';
import { MwSignInComponent } from './feature/mw-auth/mw-signin/mw-signin.component';

const appRoutes: Routes = [ 
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'advertising', component: MwAdsComponent },
  { path: 'contact-us', component: MwContactUsComponent },
  { path: 'localsnaps', component: MwLocalSnapsComponent },
  { path: 'partners', component: MwPartnersComponent },
  { path: 'privacy', component: MwPrivacyComponent },
  { path: 'terms-of-use', component: MwTermsComponent },
  { path: 'terms', component: MwTermsComponent },
  { path: 'testVideo', component: MwTestVideoComponent },
  { path: 'vici', component: MwViciComponent },  
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { preloadingStrategy: SelectivePreloadingStrategy }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }

export const globalRoutedComponents = [
  MwAdsComponent,
  MwContactUsComponent,
  MwPartnersComponent,
  MwPrivacyComponent,
  MwTermsComponent,
  MwTestVideoComponent,
  MwViciComponent,
  MwLocalSnapsComponent,
];
