/// <reference types="@types/googlemaps" />
import {
  Component, Input, OnInit, ViewChild, NgZone, ChangeDetectionStrategy, ChangeDetectorRef,
  AfterViewInit, OnDestroy, HostListener, ViewEncapsulation
} from '@angular/core';
import { MwMapComponent } from '../../../shared/components';
import { ListingsService } from '../../../shared/services/api';
import { MapwordsStore } from '../../../shared/store';
import { Listing, ListingReview, ListingTime, ListingPhoto, Category, ListingServices } from '../../../shared/models';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../../../environments/environment';
import { MatAutocomplete } from '@angular/material';

@Component({
  selector: 'mw-claimed-profile',
  templateUrl: './mw-claimed-profile.component.html',
  styleUrls: ['./mw-claimed-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class MwClaimedProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription;

  @Input()
  public listing: Listing;

  @ViewChild(MwMapComponent)
  public map: MwMapComponent;

  public url: SafeUrl;

  public noHours = false;

  public today: ListingTime = new ListingTime({});

  public todayNumber;

  public lat;

  public lng;

  public amex;

  public cash;

  public check;

  public discover;

  public masterCard;

  public visa;

  public MasterCategories;

  public Categories;

  public services;

  public dayhold;

  public avatar;


  constructor(
    private _TitleService: Title,
    private _sanitizer: DomSanitizer,
    public _route: ActivatedRoute,
    private _listingsService: ListingsService,
    public store: MapwordsStore,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.lat = 33.664535;
    this.lng = -117.752741;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.subscription = this._listingsService.GetByDisplayName(params['displayname']).subscribe(
        data => {
        this.listing = data.data[0];
          this.lat = this.listing.businessAddress.latitude;
          this.lng = this.listing.businessAddress.longitude;
          this.amex = this.listing.payments.amex;
          this.cash = this.listing.payments.cash;
          this.check = this.listing.payments.check;
          this.discover = this.listing.payments.discover;
          this.masterCard = this.listing.payments.masterCard;
          this.visa = this.listing.payments.visa;
          this.MasterCategories = this.listing.masterCategories;
          this.Categories = this.listing.categories;
          this.services = this.listing.services;
          this._TitleService.setTitle(`${this.listing.name} - Mapwords`);

          this.listing.listingHours.forEach(x => {
            if (this.dayhold !== x.dayName) {
              this.dayhold = x.dayName;
            } else {
              x.dayName = 7;
            }
          });

          if (this.listing.avatar.indexOf('http') === 0 || this.listing.avatar.indexOf('https') === 0 ) {
            this.avatar = this.listing.avatar;
          } else {
            this.avatar = environment.imagesOrigin + this.listing.avatar
          }

          console.log(this.listing);
          
        }
      );
    });
    // this.storeChanged();
    
  }

  ngAfterViewInit() {

    if (this.map) {
      this.map.api.getNativeMap().then(map => {
        map.addListener('idle', () => {
          google.maps.event.trigger(map, 'resize');
          map.setCenter({ lat: this.listing.businessAddress.latitude, lng: this.listing.businessAddress.longitude });
        });
      });
    }
  }

  ngOnDestroy() {
  }

  public storeChanged() {
    console.log('Store has changed');
    if (this.listing) {
      this.listing = this.store.searchListing.listing;


      this._TitleService.setTitle(`${this.listing.name} - Mapwords`);
      if (this.listing.contact.website) {
        this.url = this._sanitizer.bypassSecurityTrustUrl(this.listing.contact.website);
      } else {
        this.url = null;
      }
      if (this.listing.listingHours) {
        this.noHours = false;
        const storeToday = this.listing.listingHours.find(x => x.dayName === this.todayNumber);
        if (storeToday) {
          this.today = storeToday;
        } else {
          this.today = new ListingTime({
            dayName: this.todayNumber,
            active: false
          });
        }
      } else {
        this.noHours = true;
      }
    }
    return this.listing;
  }


  

}


