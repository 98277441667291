import { Component, OnInit, Input } from '@angular/core';
import { IMwAlertViewModel } from './mw-alert.viewmodel';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'mw-alert',
  templateUrl: './mw-alert.component.html',
  styleUrls:  ['./mw-alert.component.scss']
})
export class MwAlertComponent implements OnInit {

  @Input()
  public alert: IMwAlertViewModel;

  constructor(public dialogRef: MatDialog) { }

  ngOnInit() { 

  }

}
