import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../../shared.module';
import { MwAuthRoutingModule, routedComponents } from './mw-auth.routing';

@NgModule({
  imports: [
    SharedModule,
    MwAuthRoutingModule,
    FlexLayoutModule
  ],
  declarations: [
    routedComponents
  ],
  providers: [],
})
export class MwAuthModule { }
