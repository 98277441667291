
import { Listing } from './Listing';
import { Category } from './Category';


export interface ICategoryListing  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    listing?: Listing;
    listingId?: number;
    category?: Category;
    categoryId?: number;
}
export class CategoryListing  implements ICategoryListing {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public listing: Listing;
    public listingId: number;
    public category: Category;
    public categoryId: number;
    constructor(obj?: ICategoryListing) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.listing = obj.listing !== undefined ? obj.listing : null;
        this.listingId = obj.listingId !== undefined ? obj.listingId : null;
        this.category = obj.category !== undefined ? obj.category : null;
        this.categoryId = obj.categoryId !== undefined ? obj.categoryId : null;
    }
}
// For reactive forms intended
export interface IFCategoryListing  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is Listing
    */
    listing?: any; 
    /**
    * Is number
    */
    listingId?: any; 
    /**
    * Is Category
    */
    category?: any; 
    /**
    * Is number
    */
    categoryId?: any; 
}
