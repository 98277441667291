import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad, ActivatedRoute, Route
} from '@angular/router';
import { MapwordsStore } from '../../../shared/store';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: MapwordsStore, private router: Router) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    
    
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.store.auth.isSignedIn == true) { return true; }
    // Navigate to the login page with extras
    this.router.navigate([`/auth/sign-in`]);
    return false;
  }
}

@Injectable()
export class AgentAuthGuard implements CanActivate {

  constructor(private store: MapwordsStore, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.store.auth.isAgent == true) return true;
    else {
      this.router.navigate(['**']);
      return false;
    }
  }
}

@Injectable()
export class ClientAuthGuard implements CanActivate {
  
  constructor(private store: MapwordsStore, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.store.auth.isClient == true) return true;
    else {
      this.router.navigate(['**']);
      return false;
    }
  }
}