import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { MapwordsStore } from '../../../../shared/store';

@Component({
  selector: 'mw-verify',
  templateUrl: './mw-verify.component.html',
  styleUrls: ['./mw-verify.component.scss']
})
export class MwVerifyComponent implements OnInit {

  constructor(
    public store: MapwordsStore,
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleService: Title
  ) { }

  ngOnInit() {
    this._titleService.setTitle('Verify Account - Mapwords');
    const token = this._route.snapshot.params['token'];
    const redirect = this._route.snapshot.params['redirect'];
    setTimeout(() => {
      this.store.auth.verify(token, () => {
        //if (isRegisteredByAgent) {
          //this._router.navigateByUrl('/auth/recover/' + token);
        //}
        //this._router.navigateByUrl('/auth/sign-in');

        if(redirect === '1') {
          this._router.navigateByUrl('/auth/recover/' + token);
        }


      }, () => {
        this._router.navigateByUrl('/auth/sign-up');
      });
    }, 2000);
  }

}
