import { Component, OnInit, OnDestroy} from '@angular/core';
import { Location } from '@angular/common';
//import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
//import {ModalGalleryModule} from 'angular-modal-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { Listing, ListingTime, Menus } from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
//import {Angular2ImageGalleryModule} from "./../../../angular2-image-gallery";

@Component({
  selector: 'mw-menu-pop',
  templateUrl: './mw-menu-pop.component.html',
  styleUrls: ['./mw-menu-pop.component.scss']
})
export class MwMenuPopComponent implements OnInit, OnDestroy {

  public listing: Listing;

  public url: SafeUrl;

  public todayNumber;
  public showModal: boolean = false;
  public menutitles: string;
  public today: ListingTime = new ListingTime({});
  public menu: Menus[] = [];
  public routerSubs: Subscription;

  constructor(
    public store: MapwordsStore,
    private _route: ActivatedRoute,
    private _router: Router,
    private _TitleService: Title,
    private _sanitizer: DomSanitizer) {

}

  ngOnInit() {
    this.storeChanged();
    this.routerSubs = this._route.data.subscribe(x => {
      this.store.searchListing.selectListing(x['listing']['data']);
      this.showModal = true;
      this.store.search.hoverListing(x['listing']['data']['googlePlaceId']);
    });
    this.menutitles = "";
    this.menu = [];
    for (let thismenu of this.store.searchListing.listing.menus) {
        this.menutitles += thismenu.menu_Title;
        this.menu.push(thismenu);
    }
    const d = new Date();
    this.todayNumber = d.getDay();
  }

  ngOnDestroy() {
    this.routerSubs.unsubscribe();
  }

  public storeChanged() {
    // console.log('Store has changed');
    // console.log(this.listing);
    if (this.listing) {
        this.listing = this.store.searchListing.listing;
    }
    return this.listing = this.store.searchListing.listing;
  }

  public goBack(): void {
    this.store.searchListing.menuIsOpen = false;
  }

}
