import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IListing, Listing, GetListingBySearchTermRouteModel as Filter, RG } from '../models';
import { ListingsService } from './api';
import { MapwordsStore } from '../store';
import { ErrorResolver } from '../../app/common/app-error-resolver';

@Injectable()
export class SearchResolver implements Resolve<RG<IListing[]>> {

  constructor(
    private _listingsService: ListingsService,
    private _router: Router,
    public store: MapwordsStore) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const filter = new Filter({
      searchterm: route.params['searchterm'],
      distance: +route.params['distance'],
      latitude: +route.params['latitude'],
      longitude: +route.params['longitude'],
      open: route.params['open'],
      price: route.params['price'],
      rating: route.params['rating'],
      order: route.params['order']
    });

    // console.log('SearchResolver') ;
    // console.log(route.params['page']) ;

    const page = +route.params['page'];
    const results$ = this._listingsService.GetListingBySearchTerm(filter, page);
    // results$.subscribe(x => {
    //   x.data;
    // });
    
    return results$.catch(e => {
      //console.log(e.statusText);
        // this.store.alerts.add({
        //   // title: 'Error',
        //   // message: 'We are working on this, please try again later.',
        //   title: 'No results found',
        //   message: 'Please try a different search',
        //   execute: () => {
        //     location.reload();
        //   }
        // });
      // return Observable.empty();
      
      this._router.navigateByUrl('/');

      return new ErrorResolver().resolve(e) ;
    });
  }

}
