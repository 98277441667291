import {Component, EventEmitter, Input, Output} from '@angular/core';
import { IQuickSearchObject } from '../../models/QuickSearchObject';
import { FormArray } from '@angular/forms';

@Component({
    selector: 'mw-tree-view',
    styleUrls: ['./mw-tree-view.component.scss'],
    templateUrl: './mw-tree-view.component.html'
})

export class MwTreeViewComponent {
    @Input() categories: Array<IQuickSearchObject>;

    @Output() categoryChanged = new EventEmitter<categoryChangeModel>();

    categoryChange(id: number, checkStatus: boolean, name: string ) {

        let result = new categoryChangeModel();
        result.categoryId = id;
        result.checkStatus = checkStatus;
        result.categoryName = name;

        this.categoryChanged.emit(result);

      }

    public onCategoryChanged(result: categoryChangeModel) {
        this.categoryChange(result.categoryId, result.checkStatus, result.categoryName );
    }

    public currentLevel;
    public selectedItem;

    public activeCategory(event: any, item: any) {
        this.selectedItem = item.Name;
    }

    public activeClass = []

}

export class categoryChangeModel {
    public categoryId: number;
    public categoryName: string;
    public checkStatus: boolean;
}