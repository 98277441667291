import 'hammerjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ChangeDetectorRef, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MobxAngularModule } from 'mobx-angular';
// import { Hammer } from 'hammerjs';
// import { MouseTrap } from 'mousetrap';
import { AuthGuard } from './feature/mw-auth/auth-guard.service';
import { CanDeactivateGuard } from './feature/mw-auth/can-deactivate-guard.service';
import { AuthHttpService } from '../shared/services';
import { MwHomeComponent } from './feature/mw-home/mw-home.component';
import { MwClaimedProfileComponent } from './feature/mw-claimed-profile/mw-claimed-profile.component';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';
import { MwAuthModule } from './feature/mw-auth/mw-auth.module';
// import {Angular2ImageGalleryModule} from "./angular2-image-gallery";
import { AppRoutingModule, globalRoutedComponents } from './app-routing.module';
import { PageNotFoundComponent } from './not-found.component';
import { MwHomeModule } from './feature/mw-home/mw-home.module';
import { MwControlPanelModule } from './feature/mw-control-panel/mw-control-panel.module';
import { MwClaimedProfileModule } from './feature/mw-claimed-profile/mw-claimed-profile.module';
//import { MwAgentModule } from './feature/mw-agent/mw-agent.module';
import { ClickoutsideDirective } from '../shared/directives/clickoutside.directive';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from '../shared/utils';
import { HttpClientModule } from '@angular/common/http';
import { AppErrorHandler } from './common/app-error-handler';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, globalRoutedComponents],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MobxAngularModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
      libraries: ['places']
    }),
    SharedModule,
    MwHomeModule,
    MwControlPanelModule,
    MwClaimedProfileModule,
    MwAuthModule,
    AppRoutingModule,
    ClipboardModule,
    DeviceDetectorModule.forRoot()
    //MwAgentModule
  ],
  providers: [
    CookieService,
    { provide: Http, useClass: AuthHttpService },
    AuthGuard,
    CanDeactivateGuard,
    MwHomeComponent,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {provide:ErrorHandler , useClass: AppErrorHandler},
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
