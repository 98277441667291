// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// npm run  build --env=prod
// 2023

/*
export const environment = {
  production: false,

    //  Local
      imagesOrigin: 'http://localhost:53903/',
      apiUrl: 'http://localhost:53903/',
      applicationUrl: 'http://localhost:4200',


  // claim business phone name defautl setting
  phoneNumberDefault: false,
  // Email re-direction for Admins
  adminEmail: 'mapword.junk@egumball.com',

  disableGloballErrorHandler: false,

  s3: {
    Bucket: 'qa-mapwords' ,

    BucketOptions : 
      {
        accessKeyId: 'AKIAISBQUCBY5NU4AIKA',
        secretAccessKey: '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',
        region: 'us-west-1'
      } ,
    Url: 'https://s3-us-west-1.amazonaws.com/' ,
    SignedUrlExpires: 20 //minutes
  },

  consoleOutputs: {
    showAPIRequest: true,
    showAPIReponse: true,    
    showErrors: true
  },

  externalLocationLink : "https://www.googleapis.com/geolocation/v1/geolocate?key=" ,

  googleKey: "AIzaSyBwKu2ZLFepCgrPGOGhxZm_99uynWky07c" ,
  
  cookieExpireDuration : 300 , // minutes


  version :{
    checkVersion : true,
    previousVersion : "2.0.4",
    currentVersion : "2.0.5",
  },




 };


*/
export const environment = {
  production: true,
  // Live
    imagesOrigin: 'https://api.mapwords.com/',
    apiUrl: 'https://api.mapwords.com/',
    applicationUrl: 'https://mapwords.com',

  // claim business phone name defautl setting
  phoneNumberDefault: true,
  // Email re-direction for Admins
  adminEmail: 'mapwordsadmin@egumball.com',

  disableGloballErrorHandler: false,

  s3: {
    Bucket: 'prod-mapwords' ,

    BucketOptions : 
      {
        accessKeyId: 'AKIAISBQUCBY5NU4AIKA',
        secretAccessKey: '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',
        region: 'us-west-1'
      } ,
    Url: 'https://s3-us-west-1.amazonaws.com/' ,
    SignedUrlExpires: 20 //minutes
  },

  consoleOutputs: {
    showAPIRequest: false,
    showAPIReponse: false,    
    showErrors: false
  },

  externalLocationLink : "https://www.googleapis.com/geolocation/v1/geolocate?key=" ,
  
  googleKey: "AIzaSyBaFrD_0HJiYr2R-V4vZKNcgw3UwfEOA0g" ,

  cookieExpireDuration : 300 , // minutes

  version :{
    checkVersion : true,
    previousVersion : "2.0.4",
    currentVersion : "2.0.5",
  },
  
};
