import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MwControlPanelComponent } from './mw-control-panel.component';
import { MwUserComponent } from './mw-user';
import { MwListingComponent, MwListingVerificationComponent } from './mw-listing';
import { CpListingResolver, CpUserResolver, CpResolver, SearchResolver, ListingResolver} from '../../../shared/services';
import { AuthGuard, AgentAuthGuard, ClientAuthGuard } from '../mw-auth/auth-guard.service';
import { MwAgentComponent } from './mw-agent/mw-agent.component';
import { MwAgentSearchListComponent } from './mw-agent/mw-agent-search-list/mw-agent-search-list.component';
import { MwAgentSearchFormComponent } from './mw-agent/mw-agent-search-form/mw-agent-search-form.component';
import { MwAdminVideoComponent } from './mw-admin/mw-admin-video/mw-admin-video.component';
import { AgentSearchResolver } from '../../../shared/services/agentsearch.resolver';
import { MwAdminUserComponent } from './mw-admin/mw-admin-user/mw-admin-user.component';

const routes: Routes = [
  {
    path: 'cp', component: MwControlPanelComponent, canActivate: [AuthGuard], resolve: {cp: CpResolver}, children: [
      {
        path: '', redirectTo: '/cp/user', canActivate: [ClientAuthGuard], pathMatch: 'full'
      },
      {
        path: 'user', component: MwUserComponent, canActivate: [ClientAuthGuard], resolve: {cpUser: CpUserResolver}
      },
      {
        path: 'user/listing/:id', component: MwListingComponent, canActivate: [ClientAuthGuard], resolve: {cpListing: CpListingResolver}
      },
      {
        path: 'user/listing/verify/:token', component: MwListingVerificationComponent, canActivate: [ClientAuthGuard],
      },
      { path: 'agent', component: MwAgentComponent, canActivate: [AgentAuthGuard], children: [
          {
            path: ':searchterm/:latitude/:longitude/:distance/:price/:rating/:open/:page',
            component: MwAgentSearchListComponent, outlet: 'agentSearch',
            resolve: { listing: AgentSearchResolver }
          },
          {
            path: ':id/:placeid', component: MwAgentSearchFormComponent, outlet: 'agentListing',
            resolve: { listing: ListingResolver }
          }
        ]
      },
      {
        path: 'admin/video', component: MwAdminVideoComponent
      },
      {
        path: 'admin/user', component: MwAdminUserComponent
      },      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MwControlPanelRoutingModule { }

export const routedComponents = [
  MwControlPanelComponent
];
