import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { UploadFiles } from '../models/UploadFiles';
import { environment } from '../../environments/environment';
import { S3SignedUrl } from '../models';
import { S3Store, MapwordsStore } from '../store';

@Injectable()
export class S3filesService {
  
    constructor( public store: MapwordsStore ) { }

    private getS3Bucket(): any {
        const bucket = new S3(environment.s3.BucketOptions);
        return bucket;
      }
    
      selectedFiles: FileList;
      public BUCKET = environment.s3.Bucket.toString();
      public FOLDER: 'images';
      public fileName:string;
      public imageUrl:string;
      // upload() {
      //   const file = this.selectedFiles.item(0);
      //   this.uploadfile(file);
      // }
    
      // selectFile(event) {
      //   this.selectedFiles = event.target.files;
      // }
      public resultMessage: string;
    
      uploadfile(file, fileName) {
        const bucket = new S3(environment.s3.BucketOptions);
    
        //this.fileName = this.FOLDER + '/' + userId + '/' + cdatetime + '_' + file.name;
        const params = {
          Bucket: environment.s3.Bucket.toString(),
          Key: fileName,
          Body: file
        };
    
        bucket.upload(params, function (err, data) {
          if (err) {
            //console.log('There was an error uploading your file: ', err);
            this.resultMessage = 'There was an error uploading your file';
            return false;
          }
          //console.log('Successfully uploaded file.', data);
          this.resultMessage = 'Successfully uploaded file';
          return true;
        });
      }
      
      getFiles():Observable<Array<UploadFiles>> {
        const uploadFiles = new Array<UploadFiles>();
        const params = {
          Bucket: this.BUCKET,
          Prefix: this.FOLDER
        };
        this.getS3Bucket().listObjects(params, (err, data) => {
          if (err) {
            console.log('There was an error getting your files: ' + err);
            return;
          }
    
          const fileData = data.Contents;
          // console.log(fileDatas[2]);
          // uploadFiles.push(new UploadFiles(fileDatas[2].Key, 'https://s3-us-west-1.amazonaws.com/' + params.Bucket + '/' + fileDatas[2].Key));
          //console.log(uploadFiles);
          fileData.forEach(function (file) {
            //console.log(file);
            //console.log(uploadFiles);
            uploadFiles.push(new UploadFiles(file.Key, environment.s3.Url + params.Bucket + '/' + file.Key));
          });
        });
        return Observable.of(uploadFiles);
      }
    
    

    
    getSignedUrl(fileFullName:string): string {
      const bucket = new S3(environment.s3.BucketOptions);
      let fileName: string = '' ;

      // console.log('fileFullName');
      // console.log(fileFullName);

      if(fileFullName == undefined || fileFullName== null || fileFullName== ''){
        return fileFullName;
      }

      var startIndex = fileFullName.indexOf('amazonaws.com/');
      if(startIndex>-1) {
        fileName = fileFullName.substring(startIndex+14);

        if(fileName.indexOf( environment.s3.Bucket + '/' ) === 0){
          fileName = fileName.substring(environment.s3.Bucket.length + 1);
        }

      }
      else {
        return fileFullName;
      }


      var existsSign = this.store.s3.signedUrlList.filter(x=> x.name === fileName && x.expires.getTime() > new Date().getTime() );
      
      // console.log('existsSign');
      // console.log(existsSign);    

      if(existsSign.length > 0) {
        
        // console.log('existsSign');
        // console.log(existsSign[0].value);    

        return existsSign[0].value;

      }
      else {

          const paramsSign = {
          Bucket: environment.s3.Bucket,
          Key: fileName,
          Expires: 60*environment.s3.SignedUrlExpires 
          };
      
          var signedUrl = bucket.getSignedUrl('getObject',paramsSign);
      
          // console.log('signedUrl');
          // console.log(signedUrl);    
      
          this.store.s3.signedUrlList.push(new S3SignedUrl({name:fileName , value: signedUrl, expires: new Date( new Date().getTime() + (environment.s3.SignedUrlExpires*60*1000) ) })) ;

          // console.log('signedUrlList');
          // console.log(this.store.s3.signedUrlList);    

          return signedUrl;        
      }


    }
       
  }
  