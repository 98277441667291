export interface IAlertViewModel {
  image?: string;
  title: string;
  message: string;
  type?: AlertTypes;
  okLabel?: string;
  cancelLabel?: string;
  hasCancel?: boolean;
  active?: boolean;
  id?: number;
  execute?(action: ActionTypes): void;
}

export enum AlertTypes {
  warning,
  exeption,
  success
}

export enum ActionTypes {
  close,
  cancel,
  ok
}
