


export interface IListingConfigs  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    showAddress?: boolean;
    homeBasedBusiness?: boolean;
    businessClosed?: boolean;
}
export class ListingConfigs  implements IListingConfigs {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public showAddress: boolean;
    public homeBasedBusiness: boolean;
    public businessClosed: boolean;
    constructor(obj?: IListingConfigs) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.showAddress = obj.showAddress !== undefined ? obj.showAddress : null;
        this.homeBasedBusiness = obj.homeBasedBusiness !== undefined ? obj.homeBasedBusiness : null;
        this.businessClosed = obj.businessClosed !== undefined ? obj.businessClosed : null;
    }
}
// For reactive forms intended
export interface IFListingConfigs  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is boolean
    */
    showAddress?: any; 
    /**
    * Is boolean
    */
    homeBasedBusiness?: any; 
    /**
    * Is boolean
    */
    businessClosed?: any; 
}
