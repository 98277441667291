import { Injectable } from '@angular/core';
import { action, observable, computed } from 'mobx';
import { IAlertViewModel, ActionTypes, AlertTypes } from '../viewmodels';
import * as _ from 'lodash';

/*

this.store.alert.add({
  title: '',
  message: '',
  response: (action) => {
    if (ok) {
      this.store.review.add();
    } else {
      this.store.review.cancel();
    }
  }
})

*/

@Injectable()
export class AlertsStore {

  @observable
  public alerts: IAlertViewModel[] = [];

  @computed
  public get lastAlert(): IAlertViewModel {
    return _.last(this.alerts);
  }

  @computed
  public get activedAlerts(): IAlertViewModel[] {
    return this.alerts.filter(x => x.active === true);
  }

  @computed
  public get showAlert(): boolean {
    const count = this.alerts.filter(x => x.active === true).length;
    if (count > 0) {
      return true;
    }
    return  false;
  }

  @action('[ALERT] Add Alert')
  public add(alert: IAlertViewModel): void {
    if (this.alerts.find(x => x.id === alert.id && x.active && x.title === alert.title)) {
      return;
    }
    alert.active = true;
    alert.hasCancel = alert.hasCancel || false;
    alert.type = AlertTypes.success;
    if (alert.execute === undefined) {
      alert.execute = (action: ActionTypes) => {};
    }
    this.alerts.push(alert);
  }

  @action('[ALERT] Execute Alert Action')
  public execute(alert: IAlertViewModel, action: ActionTypes): void {
    alert.execute(action);
    this.remove(alert);
  }

  @action('[ALERT] Remove Alert')
  private remove(alert: IAlertViewModel): void {
    alert.active = false;
  }

}
