/// <reference types="@types/googlemaps" />
import {
  Component, OnInit, ViewEncapsulation, ViewChild, Input, Output, EventEmitter,
  OnDestroy, ElementRef, NgZone, ChangeDetectionStrategy
} from '@angular/core';
import { IMwSquareSelector } from '../../../../shared/components';
import { mdistance, mprices, mhours, mratings, morder } from '../../../../shared/viewmodels';
import { ActivatedRoute, Router, NavigationStart, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GetListingBySearchTermRouteModel as Filter } from '../../../../shared/models';
import { ListingResolver } from '../../../../shared/services';
import { LocationService } from '../../../../shared/services/api';
import { MapwordsStore } from '../../../../shared/store';
import { reaction, toJS } from 'mobx';
import $ from "jquery";
import { MapsAPILoader } from '@agm/core';
import { DeviceDetectorService } from '../../../../../node_modules/ngx-device-detector';
import { environment } from '../../../../environments/environment';
//import { google } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'mw-search',
  templateUrl: './mw-search.component.html',
  styleUrls: ['./mw-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwSearchComponent implements OnInit, OnDestroy {

  @Input()
  showSearch = false;  

  @Output()
  public search = new EventEmitter<any>();

  @Output()
  public initPos = new EventEmitter<any>();

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('searchTermInput')
  public searchTermElementRef: ElementRef;

  @ViewChild('bodySearch')
  public bodySearchElementRef: ElementRef;

  @ViewChild('tabGroup')
  public tabGroupElementRef : any;

  public filter: Filter = new Filter({});

  public menuBottom = true;

  public locationString = '';

  public showLoading = false;

  public distance: IMwSquareSelector[] = mdistance;

  public prices: IMwSquareSelector[] = mprices;

  public ratings: IMwSquareSelector[] = mratings;

  public hours: IMwSquareSelector[] = mhours;

  public order: IMwSquareSelector[] = morder;

  public filtersOpen = false;

  public showFilter = false;

  public locationOpen = false;

  public numberOfListings: number;

  public autocomplete: any;

  public filster$Subs: Subscription;

  public router$Subs: Subscription;

  public locationOpenTimeout: any;

  public showPagination = false;

  public lat: number;

  public lng: number;

  public url: string;

  public orderByString: string;

  public searchTermPattern = '^[a-z0-9_-]{2,20}$';

  //public listing$Subs: Subscription;
  public resultsCount: number;
  public result$Count: Subscription;

  public initPosition: any;
  
  constructor(
    public store: MapwordsStore,
    private _listingResolver: ListingResolver,
    private _router: Router,
    private _mapsApiLoader: MapsAPILoader,
    private _locationService: LocationService,
    private _ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private _deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {


    // if (this.store.search.filter.latitude == null && this.store.search.filter.longitude == null) {
    //   this.setCurrentPosition();    
    // }

    this.store.searchListing.BottomMenu = false;
    this.storeChanges()
    this.setOrderbyString();

    this.filster$Subs = this._listingResolver.filter$.subscribe(x => {
      if (this.filter.latitude !== x.latitude && this.filter.longitude !== x.longitude) {
        if (isNaN(x.latitude) || isNaN(x.longitude)) {
          this.activatedRoute.params.subscribe((params: Params) => {
            this.lat = +params['latitude'];
            this.lng = +params['longitude'];
            this.store.search.filter.latitude = this.lat;
            this.store.search.filter.longitude = this.lng;
            this.filter.searchterm = params['searchterm'];
          });
        } else {
          this.lat = x.latitude;
          this.lng = x.longitude;
          this.showFilter = true;
          this.showPagination = true;
          this.store.searchListing.BottomMenu = true;
          this.store.searchListing.searchBody = 'search-body';
          this.filter.searchterm = x.searchterm;
        }
        this._locationService.GetCurrentAddress(this.lat, this.lng).subscribe(c => {
          this.locationString = `${c.data.city}, ${c.data.state}`;
        });
      }

              // Emre
              this.resultsCount = this.store.search.pageResultsCount ;



    });

    if (this.locationString === '') {
  
      if (isNaN(this.store.search.filter.latitude) || isNaN(this.store.search.filter.longitude)) {
        this.activatedRoute.params.subscribe((params: Params) => {
          this.lat = +params['latitude'];
          this.lng = +params['longitude'];
          this.store.search.filter.latitude = this.lat;
          this.store.search.filter.longitude = this.lng;
          this.filter.latitude = this.lat;
          this.filter.longitude = this.lng;
          this.store.searchListing.BottomMenu = false;
        });
      }

      // console.log('Init')
      // tslint:disable-next-line:max-line-length
      if (this.filter.latitude !== this.store.search.filter.latitude && !isNaN(this.filter.latitude) && this.filter.longitude !== this.store.search.filter.longitude && !isNaN(this.filter.longitude)) {
        this._locationService.GetCurrentAddress(this.store.search.filter.latitude, this.store.search.filter.longitude).subscribe(c => {
          this.locationString = `${c.data.city}, ${c.data.state}`;
        });
      }

              // Emre
              this.resultsCount = this.store.search.pageResultsCount ;

    }
    // console.log(this.store.searchListing.BottomMenu);

    this.router$Subs = this._router.events.subscribe((x: NavigationStart) => {
      // console.log('x = ' + x.url);
      this.setLoadVariables(x.url);
    });     

      this.url = this._router.url;

      this.setLoadVariables(this.url);

    
      // Emre
      this.resultsCount = this.store.search.pageResultsCount ;





    // this.resultsCount = this._mwSearchService.getData();
    // console.log(this.resultsCount);
    // this._mwSearchService.resultCount$.subscribe((data) => {
    //   this.resultsCount = data;
      
    // });


  }
  
  
  private setLoadVariables(url: string) {

    if(!url) {
      return;
    }

    if (url === '/home') {
      this.showFilter = false;
      this.showPagination = false;
      this.menuBottom = false;

      this.filter.searchterm = '';

      this.store.searchListing.BottomMenu = false;
      this.store.searchListing.searchBody = 'search-body no-pagination';

      //console.log('showPagination');
      //console.log(this.showPagination);


    } else if (url === '/' || url === '/cp') {
      this.showFilter = false;
      this.showPagination = false;
      this.menuBottom = true;

      this.filter.searchterm = '';
      this.filtersOpen = false;

      this.store.searchListing.BottomMenu = false;
      this.store.searchListing.searchBody = 'search-body no-pagination';
    } else {
      this.showFilter = true;
      this.showPagination = true;
      this.menuBottom = false;

      this.store.searchListing.BottomMenu = false;
      this.store.searchListing.searchBody = 'search-body pagination';
      this.showSearch = true;
    }

  }


  ngAfterViewInit(){
    //this.elementRef.nativeElement.querySelector('')
    this.initGooglePlaces();


  }

  ngOnDestroy() {
    this.filster$Subs.unsubscribe();
    // this.router$Subs.unsubscribe();
    // console.log(this.autocomplete);
    //this.listing$Subs.unsubscribe();
    //this.result$Count.unsubscribe();
  }

  public storeChanges() {
 
    if (this.filter.open != null) {
      reaction(() => toJS(this.store.search.filter), x => {
        // console.log(x)
        this.filter = x;
        // console.log(this.filter);
        if (isNaN(x.latitude) || isNaN(x.longitude)) {
          this.activatedRoute.params.subscribe((params: Params) => {
            this.lat = +params['latitude'];
            this.lng = +params['latitude'];
            this.store.search.filter.latitude = this.lat;
            this.store.search.filter.longitude = this.lng;
          });
        } else {
          this.lat = x.latitude;
          this.lng = x.longitude;
        }
        this._locationService.GetCurrentAddress(this.lat, this.lng)
          // tslint:disable-next-line:no-shadowed-variable
          .subscribe((x) => {
            this.locationString = `${x.data.city}, ${x.data.state}`;
            // console.log('Change Location Name');
          });
      });
      reaction(() => this.store.search.page, page => {
        this.bodySearchElementRef.nativeElement.scrollTop = 0;

      });
      this.filter = this.store.search.cloneFilter;
      this.distance.find(x => x.value === +this.filter.distance).active = true;
      this.prices.find(x => x.value === +this.filter.price).active = true;
      this.ratings.find(x => x.value === +this.filter.rating).active = true;
      this.hours.find(x => x.value === (this.filter.open.toString() === 'true')).active = true;
      
      if(this.order.find(x => x.value === +this.filter.order) != undefined) {
        this.order.find(x => x.value === +this.filter.order).active = true;
      }
      
    }
  }

  public initGooglePlaces(): void {
    //console.log(this.autocomplete)
    //console.log(this.searchElementRef.nativeElement);
    //console.log(this.searchElementRef.nativeElement.value);
    this._mapsApiLoader.load().then(() => {
      if (!this.autocomplete) {

        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ['(cities)']
        });
        
        this._ngZone.run(() => {
          this.autocomplete.addListener('place_changed', () => {
            this._router.navigate(['/home', { outlets: { listing: null } }]);
            const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
            //console.log(place);
            
            let firstResult = $(".pac-container .pac-item:first").text();
            //console.log(firstResult);

            let modlocString = firstResult.replace
              ( /(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g
              , function(match, first){
                  if (first) match = match[0].toUpperCase() + match.substr(1);
                  return match + ' ';
                  }
              ); 
            //console.log(modlocString);
            if (modlocString) {
              let geocoder = new google.maps.Geocoder();
              geocoder.geocode({"address":modlocString}, (results, status) => {
                let lat = results[0].geometry.location.lat(),
                    lng = results[0].geometry.location.lng()
                // console.log(lat + " : " + lng);   
                const filter = this.store.search.cloneFilter;
                filter.latitude = lat;
                filter.longitude = lng;  
                
                // console.log(filter);
              
                this.store.search.updateFilter(filter);
                this.locationOpen = false;
                this.filtersOpen = false;
              }); 
            }            

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // console.log(place.formatted_address);
            this.locationString = place.address_components[0].long_name + ', ' + place['address_components'][2]['short_name'];
            if (modlocString) {
              this.locationString = modlocString;
            } else {
              this.locationString = place.formatted_address;
            }
            
            // console.log(this.locationString);
            const filter = this.store.search.cloneFilter;
            filter.latitude = place.geometry.location.lat();
            filter.longitude = place.geometry.location.lng();
            // console.log('Init-Google');
            // console.log(filter);
            
            this.store.search.updateFilter(filter);
            this.locationOpen = false;
            this.filtersOpen = false;
          });
        });
      }
    });
  }

  public cleanLocation(): void {
    this.locationString = '';
  }

  public searchAction(reset: boolean, loadPage: number): void {

    if (this.filter.searchterm !== '' && reset === true) {
      this.filter.distance = 5;
      this.filter.price = 0;
      this.filter.rating = 0;
      this.filter.order = 0;
      this.filter.open = false;
      this.distance.forEach(x => x.active = false);
      this.prices.forEach(x => x.active = false);
      this.ratings.forEach(x => x.active = false);
      this.hours.forEach(x => x.active = false);
      this.order.forEach(x => x.active = false);
      this.distance.find(x => x.value === +this.filter.distance).active = true;
      this.prices.find(x => x.value === +this.filter.price).active = true;
      this.ratings.find(x => x.value === +this.filter.rating).active = true;
      this.hours.find(x => x.value === (this.filter.open.toString() === 'true')).active = true;

      if(this.order.find(x => x.value === +this.filter.order) != undefined) {
        this.order.find(x => x.value === +this.filter.order).active = true;
      }
    }

    if(this.filter.searchterm !== '') {
      this.filter.searchterm = this.filter.searchterm.trim();
    }
    else {
      return;
    }

    this.store.search.page = loadPage ;
    this.store.search.updateFilter(this.filter);
    //console.log(this.filter);
    //this.filter.open = false;
    // this.locationOpen = false;

    this._router.navigate(['/home', {
      outlets: {
        'search':
          [
            this.store.search.filter.searchterm,
            this.store.search.filter.latitude,
            this.store.search.filter.longitude,
            this.store.search.filter.distance,
            this.store.search.filter.price,
            this.store.search.filter.rating,
            this.store.search.filter.open,              
            loadPage, //this.store.search.page,
            this.store.search.filter.order
          ],
        'listing': null
      }
    }
    ]);
  }

  public closeFilter() {
    this.filtersOpen = false;
    this.store.searchListing.searchBody = !this.filtersOpen ?
    'search-body pagination filter-close' : 'search-body pagination filter-close' ;

    if(this.store.search.pageResultsCount == 0) {
      this.store.searchListing.searchBody = 'search-body no-pagination filter-close';
    }
    else {
      this.store.searchListing.searchBody = 'search-body pagination filter-close';
    }
  }

  public useCurrentLocation(): void {
    // console.log('GET LOCATION SEARCH COMPONENT');
    // navigator.geolocation.getCurrentPosition((position) => {
    //   console.log(position, position.coords.latitude, position.coords.longitude);

    //   this._locationService.GetCurrentAddress(position.coords.latitude, position.coords.longitude)
    //     .subscribe(c => {
    //       const filter = this.store.search.cloneFilter;
    //       filter.latitude = position.coords.latitude;
    //       filter.longitude = position.coords.longitude;
    //       this.store.search.updateFilter(filter);
    //       this.locationString = `${c.data.city}, ${c.data.state}`;
    //       this.locationOpen = false;
    //     });
    //  });
    var options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 50000
    };        

    if(this._deviceService.isDesktop() ) {
      this.setLocationFromIp();
    }
    else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            console.log(pos);
            var crd = pos.coords;
          
            // console.log('Your current position is:');
            // console.log(`Latitude : ${crd.latitude}`);
            // console.log(`Longitude: ${crd.longitude}`);
            // console.log(`More or less ${crd.accuracy} meters.`);
            this._locationService.GetCurrentAddress(pos.coords.latitude, pos.coords.longitude)
              .subscribe(c => {
                const filter = this.store.search.cloneFilter;
                filter.latitude = pos.coords.latitude;
                filter.longitude = pos.coords.longitude;
                this.store.search.updateFilter(filter);
                this.locationString = `${c.data.city}, ${c.data.state}`;
                this.locationOpen = false;
              });
          }, 
          (error) => {
            // console.log('Geolocation service: ' + error.message);

            this.setLocationFromIp();

          }, options); 
      }
    }
  } 


  public setLocationFromIp () {

    // this._locationService.GetIp().subscribe (ipData => {

    //   console.log('external ip');
    //   console.log(ipData.ip);
    // });

    this._locationService.GetLocationFromIp()
      .subscribe(c => {

          const filter = this.store.search.cloneFilter;
          filter.latitude = c.data.latitude;
          filter.longitude = c.data.longitude;

          this.store.search.updateFilter(filter);
          this.locationString = `${c.data.city}, ${c.data.state}`;
          this.locationOpen = false;
      });

  }

  public showPosition(position:any) {

    let lat:number = position.coords.latitude;
    let lng:number = position.coords.longitude;
    console.log(lat + " : " + lng);
    //const filter = this.store.search.cloneFilter;  
  }

  public toggleLocationFilter(action: boolean): void {
    if (!action) {
      this.locationOpenTimeout = setTimeout(() => {
        // console.log('closing')
        this.locationOpen = false;
      }, 500);
    } else {
      clearTimeout(this.locationOpenTimeout);
      this.locationOpen = true;
      this.bodySearchElementRef.nativeElement.scrollTop = 0;
    }
  }

  public heardEnter(e): void {
    
    if (e.keyCode === 13) {

      if(!this.filter.searchterm) {
        return;
      }
      //console.log("heardEnter")
      // console.log(this.store)
      const sterm = this.filter.searchterm.trim();
      this.filter = this.store.search.cloneFilter;
      //console.log(this.filter);
      this.filter.searchterm = sterm;
      this.searchTermElementRef.nativeElement.blur();
      this.store.search.updateFilter(this.filter);
      this.redirectToSearchPage();
    }
  }
  
  public redirectToSearchPage(): void{
    this._router.navigate(['/home', {
      outlets: {
        'search':
          [
            this.store.search.filter.searchterm,
            this.store.search.filter.latitude,
            this.store.search.filter.longitude,
            this.store.search.filter.distance,
            this.store.search.filter.price,
            this.store.search.filter.rating,
            this.store.search.filter.open,              
            this.store.search.page,
            this.store.search.filter.order
          ],
        'listing': null
      }
    }
    ]);
  };

  public changeOrderBy(prop: string, order: string): void {
    this.store.search.orderListingResults(prop, order);
    this.setOrderbyString();
  }

  public setOrderbyString(): void {
    this.orderByString = 'Select Order';
    if (this.store.search.orderResultsBy.order === 'asc') {
      this.orderByString = 'A-Z';
    } else {
      if (this.store.search.orderResultsBy.prop === 'Distance') {
        this.orderByString = 'Distance';
      } else if (this.store.search.orderResultsBy.prop === 'overallStars') {
        this.orderByString = 'Ratings';
      } else if (this.store.search.orderResultsBy.prop === 'price') {
        this.orderByString = 'Price';
      } else if (this.store.search.orderResultsBy.prop === 'Order') {
        this.orderByString = 'Order';
      }
    }
  }

  public toggleFilter(): void {
    if (this.locationOpen) {
      this.filtersOpen = true;
      this.locationOpen = false;
    } else if (!this.filtersOpen) {
      this.filtersOpen = true;
    } else if (this.filtersOpen) {
      this.filtersOpen = false;
    }
    this.bodySearchElementRef.nativeElement.scrollTop = 0;
    if(this.store.search.pageResultsCount == 0) {
      this.store.searchListing.searchBody = 'search-body no-pagination';
    }
    else {
      this.store.searchListing.searchBody = 'search-body pagination';
    }    
    this.store.searchListing.searchBody = this.filtersOpen
    ? this.store.searchListing.searchBody + ' filter-open' : this.store.searchListing.searchBody + ' filter-closed' ;     
  }


  onSelectChange(event) {
    // this works for only IE - Edge known bug
    if(event.index != 0 && this.isIE){
      this.tabGroupElementRef.selectedIndex = event.index -1  ;
      this.sleep(500);
      this.tabGroupElementRef.selectedIndex =  event.index ;
    }
  }

  public sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }

  private isIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    let isIE = false;
    if (match !== -1) {
        isIE = true;
    }
    return isIE;
}

  public toggleLocation(): void {
    if (this.filtersOpen) {
      this.locationOpen = true;
      this.filtersOpen = false;
    } else if (!this.locationOpen) {
      this.locationOpen = true;
    } else if (this.locationOpen) {
      this.locationOpen = false;
    }
    this.bodySearchElementRef.nativeElement.scrollTop = 0;
    // this.store.searchListing.searchBody = this.locationOpen
    // ? 'search-body pagination location-open' : 'search-body pagination location-closed';
    if(this.store.search.pageResultsCount == 0) {
      this.store.searchListing.searchBody = 'search-body no-pagination';
    }
    else {
      this.store.searchListing.searchBody = 'search-body pagination';
    }
    this.store.searchListing.searchBody = this.locationOpen
    ? this.store.searchListing.searchBody + ' location-open' : this.store.searchListing.searchBody + ' location-closed' ;
  }

  public menuBottomContainer(): void {
    if (this.store.search.listingsResultsNumber > 0) {
      this.menuBottom = false;
    }
  }

  changeDistance(item) {
    //console.log(this.filter);
    this.filter = this.store.search.cloneFilter;
    //console.log(this.filter);
    this.filter.distance = item;
    this.store.search.updateFilter(this.filter);
    //console.log(ev);
  }

  changeRating(item) {
    this.filter = this.store.search.cloneFilter;
    this.filter.rating = item;
    this.store.search.updateFilter(this.filter);
  }

  changeHours (item) {
    this.filter = this.store.search.cloneFilter;
    this.filter.open = item;
    this.store.search.updateFilter(this.filter);
  }

  changeOrder(item) {
    this.filter = this.store.search.cloneFilter;
    this.filter.order = item;
    this.store.search.updateFilter(this.filter);
  }
}
