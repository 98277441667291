import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, Observer, Subscriber } from 'rxjs';
import { ListingReview, Listing } from '../../../../shared/models';
import { UploadFiles } from '../../../../shared/models/UploadFiles';
import * as S3 from 'aws-sdk/clients/s3';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
// import { MwWriteReviewComponent } from './mw-write-review.component';

@Injectable()
export class MwWriteReviewService {

  public review$: Observable<ListingReview>;
  private _review$Observer: Observer<ListingReview>;
  public loading$: Observable<boolean>;

  constructor(private _dialog: MatDialog) {

    this.review$ = new Observable<ListingReview>(observer => this._review$Observer = observer).share();

  }

  // public openReviewWritter(viewContainerRef: ViewContainerRef, reviewId?: number): Observable<ListingReview> {
  //   // console.log('here')
  //   let dialogRef: MatDialogRef<MwWriteReviewComponent>;
  //   const config = new MatDialogConfig();
  //   config.viewContainerRef = viewContainerRef;
  //   config.width = '550px';
  //   config.height = '550px';
  //   config.role = 'dialog';
  //   dialogRef = this._dialog.open(MwWriteReviewComponent, config);
  //   if (reviewId > 0) {
  //     dialogRef.componentInstance.reviewId = reviewId;
  //   }
  //   return dialogRef.afterClosed();
  // }

  public pushReview(review: ListingReview) {
    if (this._review$Observer) {
      this._review$Observer.next(review);
    }
  }

  private getS3Bucket(): any {
    const bucket = new S3(environment.s3.BucketOptions
    );
    return bucket;
  }

  selectedFiles: FileList;
  public BUCKET = environment.s3.Bucket;
  public FOLDER: 'images';
  public fileName:string;
  public imageUrl:string;
  // upload() {
  //   const file = this.selectedFiles.item(0);
  //   this.uploadfile(file);
  // }

  // selectFile(event) {
  //   this.selectedFiles = event.target.files;
  // }
  public resultMessage: string;

  async uploadfile(file, fileName): Promise<string> {
    const bucket = new S3(environment.s3.BucketOptions);

    //this.fileName = this.FOLDER + '/' + userId + '/' + cdatetime + '_' + file.name;


    const params = {
      Bucket: environment.s3.Bucket,
      Key: fileName,
      Body: file
    };

    const options = {partSize: 1000 * 1024 * 1024, queueSize: 1};

    var result = await bucket.upload(params, options).promise();

    return result.Location.toString();
  }

  uploadImageFile(file, fileName) {
    const bucket = new S3(environment.s3.BucketOptions);


    const params = {
      Bucket: environment.s3.Bucket,
      Key: fileName,
      ContentType: 'image/png', 
      ContentEncoding: 'base64',
      Body: file
    };

    const options = {partSize: 1000 * 1024 * 1024, queueSize: 1};

    bucket.upload(params, options, function (err, data) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        this.resultMessage = 'There was an error uploading your file';
        return false;
      }
      console.log('Successfully uploaded file.', data);
      this.resultMessage = 'Successfully uploaded file';
      return true;
    });

    
  }



  getObjectData(fileName,callbackfunc) :any {
    const bucket = new S3(environment.s3.BucketOptions);

    var objectData: any;
    //this.fileName = this.FOLDER + '/' + userId + '/' + cdatetime + '_' + file.name;
    const params = {
      Bucket: environment.s3.Bucket,
      Key: fileName,
    };

    const options = { partSize: 1000 * 1024 * 1024, queueSize: 1 };

    return bucket.getObject(params, function (err, data) {
      if (err) {
        //console.log('There was an error uploading your file: ', err);
        throw 'Video Thumbnail Not Found';
      }
      //console.log('Successfully uploaded file.', data);
      callbackfunc(data.Body.toString());
    });
  }



  getFiles():Observable<Array<UploadFiles>> {
    const uploadFiles = new Array<UploadFiles>();
    const params = {
      Bucket: this.BUCKET,
      Prefix: this.FOLDER
    };
    this.getS3Bucket().listObjects(params, (err, data) => {
      if (err) {
        console.log('There was an error getting your files: ' + err);
        return;
      }

      const fileDatas = data.Contents;
      // console.log(fileDatas[2]);
      // uploadFiles.push(new UploadFiles(fileDatas[2].Key, 'https://s3-us-west-1.amazonaws.com/' + params.Bucket + '/' + fileDatas[2].Key));
      //console.log(uploadFiles);
    })
    return Observable.of(uploadFiles);
  }
}
