import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'mw-privacy',
    styleUrls: ['./mw-static-pages.scss'],
    template: `
    <style>
        .header {
            top: 0;
        }
        .static-page-container {
            color: #000000;
        }
    </style>
    <h1 class="header">
        Privacy Statement
    </h1>
    <div fxFlexOffset.xs="0" class="static-page-container privacy-statement">
        <p>
            At Mapwords.com we make the power of local search work for you! With product offerings starting as low as $99.00, Mapwords
            will help your business be part of the millions of local searches performed each day. For more information on how
            Mapwords.com can help you and your business grow, email us at <a href="mailto:sales@mapwords.com"> sales@mapwords.com</a>. We look forward to helping you!
        </p>
        <h2>Personal information collection</h2>
        <p style="font-weight: bold">
            Mapwords may collect and use the following kinds of personal information:
        </p>
        <ul>
            <li>Information related to your patronage of businesses noted in your comments or by others.</li>
            <li>Information you provide to substantiate a review, including receipts and other supporting documentation.</li>
            <li>Information related to where you buy goods and services as noted in your comments.</li>
            <li>Information related to the type of businesses you operate or conduct commerce.</li>
            <li>Any other information that you send to Mapwords.com.</li>
        </ul>
        <p style="font-weight: bold">
            Mapwords may use your personal information to:
        </p>
        <ul>
            <li>Administer this website.</li>
            <li>Personalize the website for you.</li>
            <li>Enable your access to and use of the website services.</li>
            <li>Publish information about you on the website.</li>
            <li>Send to you products that you purchase.</li>
            <li>Supply to you services that you purchase.</li>
            <li>Send to you statements and invoices.</li>
            <li>Collect payments from you.</li>
            <li>Send you marketing communications.</li>
        </ul>
        <p>
            Where Mapwords discloses your personal information to its agents or sub-contractors for these purposes, the agent or sub-contractor
            in question will be obligated to use that personal information in accordance with the terms of this privacy statement.
        </p>
        <p>
            In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, Mapwords may disclose your
            personal information to the extent that it is required to do so by law, in connection with any legal proceedings
            or prospective legal proceedings, and in order to establish, exercise or defend its legal rights.
        </p>

        <h2>Securing your data</h2>
        <p>
            Mapwords will take reasonable technical and organizational precautions to prevent the loss, misuse or alteration of your
            personal information.</p>
        <p>
            Mapwords will store all the personal information you provide on its secure servers.
        </p>
        <p>
            Information relating to electronic transactions entered into via this website will be protected by encryption technology.
        </p>

        <h2>Cross-border data transfers</h2>
        <p>
            Information that Mapwords collects may be stored and processed in and transferred between any of the countries in which Mapwords.com
            operates to enable the use of the information in accordance with this privacy policy.
        </p>
        <p>
            In addition, personal information that you submit for publication on the website will be published on the internet and may
            be available around the world.
        </p>
        <p>
            You agree to such cross-border transfers of personal information.
        </p>

        <h2>Updating this statement</h2>
        <p>
            Mapwords may update this privacy policy by posting a new version on this website.
        </p>
        <p>
            You should check this page occasionally to ensure you are familiar with any changes.
        </p>

        <h2>Other websites</h2>
        <p>
            This website contains links to other websites.
        </p>
        <p>
            Mapwords.com is not responsible for the privacy policies or practices of any third party.
        </p>

        <h2>Contact Mapwords</h2>
        <p>
            If you have any questions about this privacy policy or Mapwords' treatment of your personal information, please write.
        </p>
        <p>
            <b>Mapwords, Inc.</b>
            <br>
            7545 Irvine Center Drive, Suite 200
            <br> Irvine, CA 92618
        </p>
        <p>
            Email:
            <a href="mailto:inquiries@mapwords.com">inquiries@mapwords.com</a>
        </p>
    </div>
    <div class="back-to-top">
        BACK TO TOP
        <i class="material-icons">arrow_upward</i>
    </div>
    <div class="footer">
        <div class="content">
            <!--
                <div class="social" fxLayout="row" fxLayoutAlign="start center">
                    <p>FOLLOW US ON:</p>
                    <div class="social-links">
                        <a href="">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="">
                            <i class="fab fa-facebook"></i>
                        </a>
                    </div>
                </div>
            -->
            <hr>
            <div class="bottom-footer" fxLayout="row" fxLayout.xs="column">
                <div class="static-pages" fxLayout="row wrap" fxFlex="auto" fxLayoutAlign.xs="space-evenly center">
                    <!--
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords About Us" [routerLink]="['/about_us']">
                            <span>About Us</span>
                        </a>
                    -->
                    <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Advertising" [routerLink]="['/advertising']">
                        <span>Advertising</span>
                    </a>
                    <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="LocalSnaps" [routerLink]="['/localsnaps']">
                        <span>LocalSnaps &trade;</span>
                    </a>                        
                    <!--
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Contact" [routerLink]="['/contact']">
                            <span>Contact Us</span>
                        </a>
                    -->
                    <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Privacy" [routerLink]="['/privacy']">
                        <span>Privacy</span>
                    </a>
                    <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Terms" [routerLink]="['/terms-of-use']">
                        <span>Terms</span>
                    </a>
                </div>
                <div class="copyright-desktop" fxHide.xs="true" fxFlex="35%" fxFlex.xs="100%">
                    Copyright &copy; 2019 Mapwords. All Rights Reserved
                </div>
                <div class="copyright-mobile" fxHide fxShow.xs="true">
                    &copy; 2019 Mapwords
                </div> 
            </div>
        </div>
    </div>
`
})
export class MwPrivacyComponent implements OnInit {

    constructor() { }

    ngOnInit() {}
}
