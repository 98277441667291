import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mw-auth',
  template: `
    <router-outlet></router-outlet>
  `
})
export class MwAuthComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() { }

}
