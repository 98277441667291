export const hoursdata = ['2017-01-23 00:00:00', '2017-01-23 00:30:00', '2017-01-23 01:00:00', '2017-01-23 01:30:00', '2017-01-23 02:00:00', '2017-01-23 02:30:00', '2017-01-23 03:00:00', '2017-01-23 03:30:00', '2017-01-23 04:00:00', '2017-01-23 04:30:00', '2017-01-23 05:00:00', '2017-01-23 05:30:00', '2017-01-23 06:00:00', '2017-01-23 06:30:00', '2017-01-23 07:00:00', '2017-01-23 07:30:00', '2017-01-23 08:00:00', '2017-01-23 08:30:00', '2017-01-23 09:00:00', '2017-01-23 09:30:00', '2017-01-23 10:00:00', '2017-01-23 10:30:00', '2017-01-23 11:00:00', '2017-01-23 11:30:00', '2017-01-23 12:00:00', '2017-01-23 12:30:00', '2017-01-23 13:00:00', '2017-01-23 13:30:00', '2017-01-23 14:00:00', '2017-01-23 14:30:00', '2017-01-23 15:00:00', '2017-01-23 15:30:00', '2017-01-23 16:00:00', '2017-01-23 16:30:00', '2017-01-23 17:00:00', '2017-01-23 17:30:00', '2017-01-23 18:00:00', '2017-01-23 18:30:00', '2017-01-23 19:00:00', '2017-01-23 19:30:00', '2017-01-23 20:00:00', '2017-01-23 20:30:00', '2017-01-23 21:00:00', '2017-01-23 21:30:00', '2017-01-23 22:00:00', '2017-01-23 22:30:00', '2017-01-23 23:00:00', '2017-01-23 23:30:00'];

export function getDayName(dayNumber: number) {
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[dayNumber];
}

export const listDays = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 }
];
