import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'mw-contact-us',
  styleUrls: ['./mw-static-pages.scss'],
  template: `
        <style>
            .header {
                top: 0;
            }
            .sub-header {
                font-size: 14px;
            }
        </style>
        <h1 class="header">
            Contact Us
            <p class="sub-header">
                Thank you for using mapwords. To request support, please complete the form below.
            </p>
        </h1>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" class="static-page-container contact-us">
            <div fxFlex="70%" class="form">
                <form>
                    <div class="" fxLayout="column" fxLayoutGap="40px">
                        <div fxLayout="column" fxLayoutGap="15px">
                            <div class="" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                                <div class="" fxFlex="auto" fxLayout="column">
                                    <label> First Name </label>
                                    <input label="First Name">
                                </div>
                                <div class="" fxFlex="auto" fxLayout="column">
                                    <label> Last Name </label>
                                    <input label="Last Name">
                                </div>
                            </div>
                            <div class="" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                                <div class="" fxFlex="auto" fxLayout="column">
                                    <label> Email </label>
                                    <input label="Email">
                                </div>
                                <div class="" fxFlex="auto" fxLayout="column">
                                    <label> Phone Number </label>
                                    <input label="Phone Number">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="" fxLayout="column" fxLayoutGap="15px">
                                <div class="" fxLayout="column">
                                    <label> Subject </label>
                                    <input>
                                </div>
                                <div class="" fxLayout="column">
                                    <label> Message </label>
                                    <textarea></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="contact-information" fxFlex="30%">
                <div class="" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
                    <div class="" fxLayout="column" fxLayoutAlign="center center">
                        <div class="title">Customer Service</div>
                        <a href="tel:1-800-123-4567"> 1 800 123 4567</a>
                        <a href="mailto:info@mapwords.com">info@mapwords.com</a>
                    </div>
                    <div class="" fxLayout="column" fxLayoutAlign="center center">
                        <div class="title">Sales & Marketing</div>
                        <a href="tel:1-800-123-4567"> 1 800 123 4567</a>
                        <a href="mailto:info@mapwords.com">info@mapwords.com</a>
                    </div>
                    <div class="" fxLayout="column" fxLayoutAlign="center center">
                        <div class="title">Accounting & Billing</div>
                        <a href="tel:1-800-123-4567"> 1 800 123 4567</a>
                        <a href="mailto:info@mapwords.com">info@mapwords.com</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top">
            BACK TO TOP
            <i class="material-icons">arrow_upward</i>
        </div>
        <div class="footer">
            <div class="content">
                <!--
                    <div class="social" fxLayout="row" fxLayoutAlign="start center">
                        <p>FOLLOW US ON:</p>
                        <div class="social-links">
                            <a href="">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                -->
                <hr>
                <div class="bottom-footer" fxLayout="row" fxLayout.xs="column">
                    <div class="static-pages" fxLayout="row wrap" fxFlex="auto" fxLayoutAlign.xs="space-evenly center">
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords About Us" [routerLink]="['/about_us']">
                                <span>About Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Advertising" [routerLink]="['/advertising']">
                            <span>Advertising</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="LocalSnaps" [routerLink]="['/localsnaps']">
                            <span>LocalSnaps &trade;</span>
                        </a>                        
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Contact" [routerLink]="['/contact']">
                                <span>Contact Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Privacy" [routerLink]="['/privacy']">
                            <span>Privacy</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Terms" [routerLink]="['/terms-of-use']">
                            <span>Terms</span>
                        </a>
                    </div>
                    <div class="copyright-desktop" fxHide.xs="true" fxFlex="35%" fxFlex.xs="100%">
                        Copyright &copy; 2019 Mapwords. All Rights Reserved
                    </div>
                    <div class="copyright-mobile" fxHide fxShow.xs="true">
                        &copy; 2019 Mapwords
                    </div> 
                </div>
            </div>
        </div>
    `
})
export class MwContactUsComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
