import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';

import { Listing, RG, GetListingBySearchTermRouteModel as Filter } from '../../../../shared/models';
import { ActionTypes } from '../../../../shared/viewmodels';
import { ListingResolver, S3filesService } from '../../../../shared/services';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';

@Component({
  selector: 'mw-ads-listing',
  templateUrl: './mw-ads-listing.component.html',
  styleUrls: ['./mw-ads-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MwAdsListingComponent implements OnInit, OnDestroy {
  public TitleLength: string;

  openOrClose: string;

  @Input()
  public pageposition = 1;

  @Input()
  public pagelimit = 5;

  public listings: Listing[] = [];

  public imageOrigin = environment.imagesOrigin;

  public listing$Subs: Subscription;
  public params$Subs: Subscription;

  open() {
    if (this.openOrClose === 'inactive') {
      this.openOrClose = 'active'
    } else if (this.openOrClose === 'active') {
        this.openOrClose = 'inactive'
    } else {
        this.openOrClose = 'inactive'
    }
  }

  constructor(
    public store: MapwordsStore,
    private _listingResolver: ListingResolver,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private s3filesService: S3filesService
  ) { }

  ngOnInit() {
    this.listing$Subs = this._route.data.subscribe(x => {
      if (window.innerHeight < 800) {
        this.TitleLength = '30';
      } else {
        this.TitleLength = '55';
      }
      const pagination: any = x['listings']['pagination'];
      this.store.search.updatePageLimit(+pagination.pages);
      this.store.search.updateResultsCount(+pagination.queryCount);
      const results: Listing[] = x['listings']['data'];
      if (results.length === 0) {
        console.log('No results add alert');
        this.store.alerts.add({
          id: 1,
          title: 'No results',
          // tslint:disable-next-line:max-line-length
          message: 'No Results Found',
          okLabel: 'Greater Distance',
          cancelLabel: 'Cancel',
          hasCancel: true,
          execute: (action: ActionTypes) => {
            if (action === ActionTypes.ok) {
              const filter = this.store.search.cloneFilter;
              filter.distance = +filter.distance + 10;
              this.store.search.updateFilter(filter);
            }
          }
        });
        return;
      }
      this.store.search.searchListings(results);
      this.listings = this.store.search.listingResults;
      this._titleService.setTitle(`Searching ${this.store.search.filter.searchterm} - Mapwords`);
    });

    this.params$Subs = this._route.params.subscribe((x: Filter) => {
      if (x.searchterm === null) { return; }
      this._listingResolver.filterUrlChange(x);
    });

  }

  ngOnDestroy() {
    this.listing$Subs.unsubscribe();
    this.params$Subs.unsubscribe();
  }

  public loadListing(id: number, place_id: string): void {
    // console.log('Load Listing');
    this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }]);
  }

  public hoverListing(listing: Listing, active: boolean): void {
    if (listing.active && !this.store.searchListing.detailIsOpen) {
      this.store.search.hoverListing(listing.googlePlaceId);
    }
  }

  public getAvatar(avatar: string): string {

    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }
  
}
