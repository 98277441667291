


export interface IPayments  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    amex?: boolean;
    visa?: boolean;
    masterCard?: boolean;
    discover?: boolean;
    check?: boolean;
    cash?: boolean;
}
export class Payments  implements IPayments {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public amex: boolean;
    public visa: boolean;
    public masterCard: boolean;
    public discover: boolean;
    public check: boolean;
    public cash: boolean;
    constructor(obj?: IPayments) {

        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.amex = obj.amex !== undefined ? obj.amex : null;
        this.visa = obj.visa !== undefined ? obj.visa : null;
        this.masterCard = obj.masterCard !== undefined ? obj.masterCard : null;
        this.discover = obj.discover !== undefined ? obj.discover : null;
        this.check = obj.check !== undefined ? obj.check : null;
        this.cash = obj.cash !== undefined ? obj.cash : null;
    }
}
// For reactive forms intended
export interface IFPayments  {
    /**
    * Is number
    */
    id?: any;
    /**
    * Is boolean
    */
    active?: any;
    /**
    * Is Date
    */
    addedDate?: any;
    /**
    * Is boolean
    */
    amex?: any;
    /**
    * Is boolean
    */
    visa?: any;
    /**
    * Is boolean
    */
    masterCard?: any;
    /**
    * Is boolean
    */
    discover?: any;
    /**
    * Is boolean
    */
    check?: any;
    /**
    * Is boolean
    */
    cash?: any;
}
