

export interface IReviewFile  {
    fileName?: string;
    fileType?: reviewFileType;
    file?: any;
    alreadyUploaded?: boolean;
    fileUrl?: string;

}


export class ReviewFile implements IReviewFile {
    public fileName: string;    
    public fileType: reviewFileType;
    public file: any;
    public alreadyUploaded: boolean;
    public fileUrl: string; 

    constructor() {
        
        this.fileName = null;
        this.fileType = null;
        this.file =  null;
        this.alreadyUploaded = false;
        this.fileUrl = null;
    }
}


export enum reviewFileType {
    image,
    video
}
