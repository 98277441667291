


export interface IMenus_Items  {
    Id?: number;
    Active?: boolean;
    addedDate?: Date;
    SectionId?: number;
    menuItem_Title?: string;
    menuItem_Description?: string;
    menuItem_Price?: number;
    menuItem_Special_Price?: number;
    IsFree?: boolean;
    menuItem_OrderBy?: number;      
}
export class Menus_Items implements IMenus_Items {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public SectionId: number;
    public menuItem_Title: string;
    public menuItem_Description: string;
    public menuItem_Price: number;
    public menuItem_Special_Price: number;
    public IsFree: boolean;
    public menuItem_OrderBy: number;       
    constructor(obj?: IMenus_Items) {        
        this.id = obj.Id !== undefined ? obj.Id : null;
        this.active = obj.Active !== undefined ? obj.Active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.SectionId = obj.SectionId !== undefined ? obj.SectionId : null;
        this.menuItem_Title = obj.menuItem_Title !== undefined ? obj.menuItem_Title : null;
        this.menuItem_Description = obj.menuItem_Description !== undefined ? obj.menuItem_Description : null;
        this.menuItem_Price = obj.menuItem_Price !== undefined ? obj.menuItem_Price : null;
        this.menuItem_Special_Price = obj.menuItem_Special_Price !== undefined ? obj.menuItem_Special_Price : null;
        this.IsFree = obj.IsFree !== undefined ? obj.IsFree : null;
        this.menuItem_OrderBy = obj.menuItem_OrderBy !== undefined ? obj.menuItem_OrderBy : null;        
    }
}
// For reactive forms intended
export interface IFQuickSearchObject  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is number
    */
    SectionId?: any; 
    /**
    * Is string
    */
    menuItem_Title?: any; 
    /**
    * Is string
    */
    menuItem_Description?: any; 
    /**
    * Is number
    */
    menuItem_Price?: any; 
    /**
    * Is number
    */
    menuItem_Special_Price?: any; 
    /**
    * Is bool
    */
    IsFree?: any; 
    /**
    * Is number
    */
    menuItem_OrderBy?: any; 
    
}
