import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Client, Address, Listing } from '../../../shared/models';
import { environment } from '../../../environments/environment';
import { MapwordsStore } from '../../../shared/store';
import { reaction, autorun } from 'mobx';
import { Location } from '@angular/common';
import { observable } from 'mobx-angular';
import { isNullOrUndefined } from 'util';
import { S3filesService } from '../../../shared/services';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Component({
  selector: 'mw-control-panel',
  templateUrl: './mw-control-panel.component.html',
  styleUrls: ['./mw-control-panel.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class MwControlPanelComponent implements OnInit {
  public showControlPanel = true;

  @Input() PassedAccount;

  public isActiveMenu = false;
  public account: Client = new Client({ listings: [] });
  public selectedAccount: any;
  public imageOrigin = environment.imagesOrigin;

  public myURL;

  @observable public user: Client = new Client({});
  public showProfile = true;

  public signedAvatar: string = '';

  constructor(
    public store: MapwordsStore,
    public _route: ActivatedRoute,
    public _router: Router,
    private _titleService: Title,
    private s3filesService: S3filesService,
    private cookieService: CookieService 
  ) {}

  public ngOnInit() {
    // let role = +this.routeParam.params['id']; // Sean
    // const role: string = this._route.snapshot.params.id;
    // console.log(role);
    // }

    if(this.cookieService.get('user')) {
      this.user = JSON.parse(this.cookieService.get('user'));
    }

    
    

    if(this.user.role !== null || this.user.role !== undefined) {
      if (this.user.role === 2 || this.user.role === 3) {
        this.showProfile = false;
      }
    }

    // sean

    this._titleService.setTitle('Control Panel - Mapwords');
    this._route.data.subscribe(x => {
      const client = <Client>x['cp']['data'];
      const account = this.store.auth.user;

      client.listings.forEach(listing => {
        
        if(listing) {
          if(listing.avatar) {
            listing.avatar.indexOf('http') > -1 ? listing.signedAvatar =  this.getAvatar(listing.avatar) : listing.signedAvatar = this.imageOrigin+ listing.avatar ;
          }
        }
  

      });


      account.listings = client.listings;
      this.account = account;

      if (isNullOrUndefined(this.account.firstName)) {
        this.selectedAccount = {
          avatar: this.account.avatar,
          displayName: this.user.email,
          isUser: true
        };
      }
      else {
        this.selectedAccount = {
          avatar: this.account.avatar,
          displayName: `${this.account.firstName} ${this.account.lastName}`,
          isUser: true
        };
      }      

      this.myURL = window.location.href;


      if (this.myURL.indexOf('listing') > -1) {
        const strs = this.myURL.split('/');
        const lid = strs[strs.length - 1];

        account.listings.forEach(item => {
          if (item.id == lid) {
            this.selectedAccount = {
              avatar: item.avatar,
              displayName: item.name,
              isUser: false
            };
           
          }
        });
      }
    });




    reaction(
      () => this.store.auth.user,
      x => {

        // console.log('reaction this.store.auth.user') ;
        // console.log(this.store.auth.user) ;
        // console.log(x) ;
        

        if(x === null || x === undefined) {
          return;
        }

        if (x.avatar != null) {
          this.account.avatar = x.avatar;
        }
        
        
        this.account.firstName = x.firstName;
        this.account.lastName = x.lastName;
        this.account.middleName = x.middleName;
        this.account.listings = x.listings;

        if (this.selectedAccount.isUser) {
          this.selectedAccount = {
            avatar: x.avatar,
            displayName: `${this.store.auth.user.firstName} ${
              this.store.auth.user.lastName
            }`,
            isUser: true
          };
        }
        else {


          this.myURL = window.location.href;

          // console.log(this.myURL);

          if (this.myURL.indexOf('listing') > -1) {
            const strs = this.myURL.split('/');
            const lid = strs[strs.length - 1];
    
            // console.log(lid);
            // console.log(this.account.listings);

             this.account.listings.forEach(item => {
              if (item.id == lid) {
                this.selectedAccount = {
                  avatar: item.avatar,
                  displayName: item.name,
                  isUser: false
                };
               
              }
            });
          }

        }
      }
    );




  }



  

  
  private getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }

  /**
   * changeProfile
   */
  public changeProfile(account: any): void {
    // console.log(this.selectedAccount);
    this.selectedAccount = {
      avatar: account.avatar,
      displayName: account.name,
      id: account.id
    };
    this.closeMenuRouter();
    this._router.navigate(['cp/user/listing', account.id]);
  }

  public setProfile(passedAccount): void {
    this.selectedAccount = passedAccount;
  }

  public goToProfile() {
    this.selectedAccount = {
      avatar: this.account.avatar,
      displayName: `${this.account.firstName} ${this.account.lastName}`,
      isUser: true
    };
    this.closeMenuRouter();
    this._router.navigate(['cp/user']);
  }

  public toggleMenuRouter(): void {
    if (this.isActiveMenu == true) {
      this.isActiveMenu = false;
    } else {
      this.isActiveMenu = true;
    }
  }

  public openMenuRouter(): void {
    this.isActiveMenu = true;
  }

  public closeMenuRouter() {
    this.isActiveMenu = false;
  }

  public getAvatarPath(imgOrigin: string , avatar: any) : string {
    if(avatar !== null && avatar !== undefined ) {
      if(avatar.indexOf('googleusercontent') > 0  || avatar.indexOf('https') === 0) {
        
        var result = this.s3filesService.getSignedUrl(avatar);
        return result;

        // return avatar;
      }
      
    }

    return imgOrigin + avatar;
  }
  

  public getAccountAvatarPath(id: number) : string {

    let avatar='' ;

    if(this.store.auth.user.listings!== undefined){
      if(this.store.auth.user.listings.filter(x=> x.id== id).length == 1) {
        avatar = this.store.auth.user.listings.filter(x=> x.id== id)[0].avatar ;
      }     
      else
      {
        avatar = this.selectedAccount.avatar ;
      } 
    }
    else
    {
      avatar = this.selectedAccount.avatar ;
    }

    if(avatar !== null && avatar !== undefined ) {
      if(avatar.indexOf('googleusercontent') > 0  || avatar.indexOf('https') === 0) {
        
        var result = this.s3filesService.getSignedUrl(avatar);
        return result;

        // return avatar;
      }
      
    }

    return this.imageOrigin + avatar;
  }


  public getCurrentListing(listings: Listing[], id: number): Listing[] {
      return listings.filter(x=>x.id == id);
  }

  
}
