import { Component, OnChanges, SimpleChange,  OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mw-square-selector',
  template: `
    <div fxLayout="row">
      <div *ngFor="let item of names" fxFlex
        (click)="selectItem(item)"
        [class.last]="isItLast(item)"
        [class.active]="item.active"
        [style.width]="100/count+ '%'"
        class="item">
          {{item.name}}
      </div>
    </div>
  `,
  styleUrls: ['./mw-square-select.component.scss']
})
export class MwSquareSelectorComponent implements OnInit {

  @Input()
  public names: IMwSquareSelector[];
  @Output()
  public select: EventEmitter<any> = new EventEmitter();

  public count: number;

  constructor() { }

  ngOnInit() {
    // this.names.forEach(x => x.active = false);
    // this.names[0].active = true;
    this.count = this.names.length;
  }

  public selectItem(item: IMwSquareSelector): void {
   
    this.names.forEach(x => x.active = false);
    this.names.find(x => x === item).active = true;
    console.info(this.names);
    let i = this.names.find(x => x === item);

    this.select.emit(i.value);
  }

  public isItLast(item: IMwSquareSelector): boolean {
    let index: number;
    index = this.names.findIndex(x => x === item);
    if (this.count === index + 1) {
      return true;
    } else {
      return false;
    }
  }

}

export interface IMwSquareSelector {
  name: string;
  value: any;
  active?: boolean;
}
