import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { ClientsService } from "../../../../shared/services/api";
import { Client, Address } from "../../../../shared/models";
import { isNullOrUndefined } from "util";

@Component({
  selector: "mw-user",
  templateUrl: "./mw-user.component.html",
  styleUrls: ["./mw-user.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MwUserComponent implements OnInit {
  public client: Client = new Client({
    address: new Address({}),
    listings: [],
    places: [],
    professionalSkills: []
  });

  constructor(
    private _clientService: ClientsService,
    private _route: ActivatedRoute,
    private _titleService: Title
  ) {}

  public ngOnInit() {
    this._route.data.subscribe(x => {
      this.client = x["cpUser"]["data"];

      if (isNullOrUndefined(this.client.firstName)) {
        this._titleService.setTitle(
          `${this.client.email} - Control Panel - Mapwords`
        );
      } else {
        this._titleService.setTitle(
          `${this.client.firstName} - Control Panel - Mapwords`
        );
      }
    });
  }

  /**
   * Runs when detects that was a tab change
   * @memberOf BusinessComponent
   */
  public tabChange(): void {
    // console.log(this.userProfile.toggles);
    // this.userProfile.toggles.forEach(t => t.active = false);
  }
}
