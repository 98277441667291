import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorConstants } from '../models/ErrorConstants';

@Injectable()
export class ErrorLogService {

    constructor() { }

    logError(error: any) {
        const date = new Date().toUTCString();

        if (error instanceof HttpErrorResponse) {
            console.error(date, ErrorConstants.httpError, error.message, 'Status code:', (<HttpErrorResponse>error).status);
        }
        else if (error instanceof TypeError) {
            console.error(date, ErrorConstants.typeError, error.message, error.stack);
        }
        else if (error instanceof Error) {
            console.error(date, ErrorConstants.generalError, error.message, error.stack);
        }
        else if (error instanceof ErrorEvent) {
            console.error(date, ErrorConstants.generalError, error.message);
        }
        else {
            console.error(date, ErrorConstants.unexpectedHappened, error.message, error.stack);
        }
    }
}
