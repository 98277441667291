export interface IGetListingBySearchTermRouteModel {
    searchterm?: string;
    latitude?: number;
    longitude?: number;
    distance?: number;
    open?: boolean;
    price?: number;
    rating?: number;
    order?: number;
}
export class GetListingBySearchTermRouteModel implements IGetListingBySearchTermRouteModel {
    public searchterm: string = '';
    public latitude: number;
    public longitude: number;
    public distance: number;
    public open: boolean;
    public price: number;
    public rating: number;
    public order: number;
    constructor(obj?: IGetListingBySearchTermRouteModel) {
        this.searchterm = obj.searchterm !== undefined ? obj.searchterm : null;
        this.latitude = obj.latitude !== undefined ? obj.latitude : null;
        this.longitude = obj.longitude !== undefined ? obj.longitude : null;
        this.distance = obj.distance !== undefined ? obj.distance : null;
        this.open = obj.open !== undefined ? obj.open : null;
        this.price = obj.price !== undefined ? obj.price : null;
        this.rating = obj.rating !== undefined ? obj.rating : null;
        this.order = obj.order !== undefined ? obj.order : null;
    }
}
