import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'mw-image-editor',
  templateUrl: './mw-image-editor.component.html',
  styleUrls: ['./mw-image-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MwImageEditorComponent implements OnInit {

  @ViewChild('imageFile')
  public imageInput: ElementRef;

  public imageUrl: string;

  public openedCropper = false;
  public fileExtension;
  public isFileAllowed: boolean = true;
  public fileErrorMessage: string;

  //  public dialogRef: MdDialogRef<MwImageEditorComponent>
  constructor(public dialogRef: MatDialogRef<MwImageEditorComponent>) {
  }

  ngOnInit() { }

  public openEditor(event): void {

    if(event.target.files.length === 0) {
      return;
    }

      let file = event.target.files[0];
      const reader: FileReader = new FileReader();

      let fileName = file.name;
      this.fileExtension = fileName.split('.').pop().toLowerCase();
      //console.log(this.fileExtension);
      if(this.fileExtension === 'jpg' || this.fileExtension === 'png' || this.fileExtension === 'jpeg' || this.fileExtension === 'ico'){
        reader.addEventListener('load', () => {
          this.imageUrl = reader.result;
          this.openedCropper = true;
        }, false);
        if (this.imageInput) {
          reader.readAsDataURL(this.imageInput.nativeElement.files[0]);
        }
      }   
      else {
        this.isFileAllowed = false;
        this.fileErrorMessage = 'File format is not allowed';
      }   

  }

  public cancelEditor(): void {
    this.fileErrorMessage = '';
    this.openedCropper = false;
  }

  public save(url: string): void {
    this.resizeBase64Img(url, 300, 300);
  }

  public resizeBase64Img(base64: string, width: number, height: number): void {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    const image = new Image();
    image.src = base64;

    image.onload = (e) => {
      context.scale(width / image.width, height / image.height);
      context.drawImage(image, 0, 0);
      this.saveFinal(canvas.toDataURL());
    };
  }

  public saveFinal(url: string): void {

    this.imageUrl = url;
    this.openedCropper = false;
    this.dialogRef.close(this.imageUrl);
  }
}
