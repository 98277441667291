import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MwAuthComponent } from './mw-auth.component';
import { MwSignInComponent } from './mw-signin/mw-signin.component';
import { MwSignUpComponent } from './mw-signup/mw-signup.component';
import { MwVerifyComponent } from './mw-verify/mw-verify.component';
import { MwAuthRecoverComponent } from './mw-recover/mw-recover.component';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { AuthGuard } from './auth-guard.service';

const routes: Routes = [
  {
    path: 'auth', component: MwAuthComponent, children: [
      { path: 'sign-in', component: MwSignInComponent, },
      { path: 'sign-up', component: MwSignUpComponent },
      { path: 'verify/:token/:redirect', component: MwVerifyComponent },
      { path: 'verify/:token', component: MwVerifyComponent },
      { path: 'recover', component: MwAuthRecoverComponent },
      { path: 'recover/:token', component: MwAuthRecoverComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MwAuthRoutingModule { }

export const routedComponents = [
  MwAuthComponent,
  MwSignInComponent,
  MwVerifyComponent,
  MwAuthRecoverComponent
];
