


export interface IActivityLog  {
    id?: number;
    addedDate?: Date;
    active?: boolean;
    table?: string;
}
export class ActivityLog  implements IActivityLog {
    public id: number;
    public addedDate: Date;
    public active: boolean;
    public table: string;
    constructor(obj?: IActivityLog) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.table = obj.table !== undefined ? obj.table : null;
    }
}
// For reactive forms intended
export interface IFActivityLog  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is string
    */
    table?: any; 
}
