import { Directive, ElementRef, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';

@Directive({
  selector: '[clickOutsideCP]'
})
export class ClickoutsideCPDirective {

  @Output()
  public clickOutsideCP = new EventEmitter();

  constructor(private _elementRef: ElementRef) { }
 
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    //const clickedInside = this.clickTarget.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutsideCP.emit(null);
    }
  }
}
