import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Client, IFClient } from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
import { MwFormControlComponent } from '../../../../shared/components';
import { ClientsService } from '../../../../shared/services/api';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '../../../../../node_modules/@angular/material/snack-bar';
import { MwSnackBarComponent } from '../../../../shared/components/mw-alert/mw-snack-bar.component';
import { CookieService } from '../../../../../node_modules/ngx-cookie-service';

@Component({
  selector: 'mw-signup',
  templateUrl: './mw-signup.component.html',
  styleUrls: ['./mw-signup.component.scss']
})
export class MwSignUpComponent implements OnInit, AfterViewInit {
  public client: Client;
  public fClient: FormGroup;

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;

  @ViewChild('username') username: ElementRef;

  @Input() adminCreate: boolean = false;

  // public userNameAvailable = true;
  // public emailAvailable = false;

  public isUsernameAvailable = true;
  public isEmailAvailable = true;

  public genderOptions = [
    { name: 'Male', value: 0 },
    { name: 'Female', value: 1 },
    { name: 'Other', value: 2 }
  ];
  public defaultOption = null;
  public g: FormGroup;
  public submitted = false;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,4}$';
  public unamePattern = '^[a-zA-Z0-9_-]{3,20}$';
  public fnamePattern = '^[a-zA-Z]{0,35}$';
  public lnamePattern = '^[a-zA-Z]{0,35}$';
  public bdatePattern = '^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$';

  public redirect:string = '';

  constructor(
    private _fb: FormBuilder,
    public store: MapwordsStore,
    private _router: Router,
    private _clientsService: ClientsService,
    private _titleService: Title,
    public snackBar: MatSnackBar,
    private cookieService: CookieService 
  ) {

  }

  ngOnInit() {

    if(! this.adminCreate) {
      this._titleService.setTitle('Sign Up - Mapwords');
    }

    this.initForm();

    if(this.cookieService.get('redirect') !== null && this.cookieService.get('redirect') !== undefined && this.cookieService.get('redirect') !== ''){
      this.redirect = this.cookieService.get('redirect');
      this.cookieService.delete('redirect','/') ;
    }

  }

  ngAfterViewInit() {
    this.formControls.forEach(x => (x.formDirty = false));
  }

  public dateOk: boolean = true;
  public dateChecker() {
    let bdate = this.fClient.get('birthDate').value;
    if (bdate !== "" && bdate !== undefined) {
      if (Date.parse(bdate) < Date.parse("01, 01, 1918") || Date.parse(bdate) > Date.now() || isNaN(Date.parse(bdate))) {
        //console.log(bdate)
        //console.log("Invalid input");
        this.dateOk = false;
        // return {'invaliddate': true};
      }
      else {
        this.dateOk = true;
      }
    }
    else {
      this.dateOk = true;
    }    
  }

  public submit(): void {

    if(this.adminCreate) {
      let newPassword = this.randomPassword(6) ;

      this.fClient.patchValue({
        password: newPassword,
        confirmPassword : newPassword
       });
    }

    if (this.fClient.get('firstName').valid && this.fClient.get('lastName').valid && this.fClient.get('email').valid 
        && this.fClient.get('password').valid && this.fClient.get('confirmPassword').valid && (  this.adminCreate ? true : this.fClient.get('acceptTerms').valid)
        && (  this.adminCreate ? parseInt( this.fClient.get('role').value ) > -1 : true)
      )  {
      this.store.client.signUp(this.fClient.value, () => {
      this.checkRedirect();

      // if (this.redirect === null || this.redirect === undefined || this.redirect === '') {
      //   this._router.navigateByUrl('/');
      // }    

        // location.href = '/';
      });
    } else {
      // this.formControls.forEach(x => x.formDirty = true);
      this.validateAllFormFields(this.fClient);
    }
  }


  showMessage(message: string) {
    let snackBarRef = this.snackBar.openFromComponent(MwSnackBarComponent, {
      duration: 2000,
      data: {
        message: message,
      }
    });
  }

  public randomPassword(length) {
    const chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890';
    let pass = '';
    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  public checkRedirect() {
    if(this.adminCreate) {
      this.showMessage('User created');
      setTimeout(function () {
        //window.location.href = environment.applicationUrl + '/#/cp/admin/user' ;
        window.location.href = environment.applicationUrl + '/cp/admin/user' ;
      }, 2000);
    }
    else if (this.redirect !== null && this.redirect !== undefined) {
      // window.location.href = environment.applicationUrl + '/#' + this.redirect ;
      window.location.href = environment.applicationUrl + this.redirect ;
      // this.router.navigateByUrl(this.redirect);
    }
  }
  
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private initForm(): void {
    this.fClient = this._fb.group(
      <IFClient>{
        username: [
          '',
          [
            // Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            this.validateUsername.bind(this)
          ]
          //this.validateUsername.bind(this)
        ],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        gender: ['',],
        role: ['',],
        password: ['', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(16)
          ]
        ],
        confirmPassword: ['', Validators.required],
        birthDate: [ '', ],
        email: [ '', [
            Validators.required,
            Validators.pattern(this.emailPattern),
            Validators.maxLength(250),
            this.validateEmail.bind(this)
          ]
        ],
        mobilePhone: [''],
        acceptTerms: [false, this.adminCreate ? null : Validators.requiredTrue]
      },
      {
        validator: Validators.compose([
          this.passwordMatchValidator,
          this.adminCreate ? null : this.acceptTermChecked,
        ])
      }
    );
  }

  passwordMatchValidator(g: FormGroup) {
    if (g.get('confirmPassword').value.length > 0) {
      return g.get('password').value === g.get('confirmPassword').value
        ? null
        : { mismatch: true };
    }
  }

  public validateUsername(e) {
    const username = e.value;
    this._clientsService
      .GetUniqueUsername(username)
      .subscribe(x => {
        //if (username === this.store.auth.user.username) {
        if (x.data.answer) {
          this.isUsernameAvailable = true;
          return;
        }
        this.isUsernameAvailable = x.data.answer;
      });

    //return this.isUsernameAvailable ? null : {usernameTaken: true};
  }

  public isEmail: {[key: string]: boolean} | null;

  public validateEmail(control: FormControl) {
    const email = control.value;
    this._clientsService
      .GetUniqueEmail(email)
      .subscribe(x => {        
        //this.isEmailAvailable = x.data.answer;
        
        if (x.data.answer) {
          this.isEmailAvailable = true;
          this.isEmail = null;
          return;
        }
        this.isEmailAvailable = x.data.answer;
        this.isEmail = {emailTaken: true};
        //return this.isEmailAvailable ? null : {emailTaken: true};
      });    
  }

  public acceptTermChecked(
    control: AbstractControl
  ): { [key: string]: boolean } {
    const acceptTerms = control.get('acceptTerms');
    return !acceptTerms.dirty
      ? null
      : acceptTerms.value === true
        ? null
        : { noaccept: true };
  }

  public usernameAvailable(g): void {
    this._clientsService
      .GetUniqueUsername(g.value)
      .subscribe(x => {
        if (g.value === this.store.auth.user.username) {
          this.isUsernameAvailable = true;
          return;
        }
        this.isUsernameAvailable = x.data.answer;
      });
  }

  public emailAvailable(): void {
    this._clientsService
      .GetUniqueEmail(this.fClient.get('email').value)
      .subscribe(x => {
        if (this.fClient.get('email').value === this.store.auth.user.email) {
          this.isEmailAvailable = true;
          return;
        }
        this.isEmailAvailable = x.data.answer;
      });
  }

  public min = new Date(1930, 1, 1);

  public max = new Date(2018, 1, 1);
  
}
