import { Injectable, Inject } from '@angular/core';
import { observable } from 'mobx';
import { S3Store } from './s3.store';
import { ListingStore } from './listing.store';
import { AlertsStore } from './alerts.store';
import { ClientStore } from './client.store';
import { SearchStore } from './search.store';
import { SearchListingStore } from './search-listing.store';
import { AuthStore } from './auth.store';

// useStrict(true);

@Injectable()
export class MapwordsStore {

  @observable
  public id: number = 1;

  constructor(
    public listing: ListingStore,
    public alerts: AlertsStore,
    public client: ClientStore,
    public search: SearchStore,
    public searchListing: SearchListingStore,
    public myClaimListing: SearchListingStore ,
    public auth: AuthStore,
    public s3: S3Store
  ) {
    // remotedev(this, {name: 'MAPWORDS STORE', global: true, onlyActions: true});
    // remotedev(this.search, {name: 'SEARCH STORE', onlyActions: true});
    // remotedev(this.auth, {name: 'AUTH STORE', onlyActions: true});
  }

}
