import { Listing } from "./Listing";
import { Client } from "models";

export interface IGetListingForExistingClientModel {
    listings: Listing[];
    client: Client;
}
export class GetListingForExistingClientModel implements IGetListingForExistingClientModel {
    public listings: Listing[];
    public client: Client;
    constructor(obj?: IGetListingForExistingClientModel) {
        this.listings = obj.listings !== undefined ? obj.listings : null;
        this.client = obj.client !== undefined ? obj.client : null;
    }
}
