import { GoogleReview } from "./GoogleReview";



export class GoogleReviewDTO extends GoogleReview {
        public clientEmail:string;
        public businessName: string;
        public reviewLink: string;
        public videoName: string;        
}

