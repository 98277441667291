


export interface IAddress  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    longitude?: number;
    latitude?: number;
    street?: string;
    suiteNumber?: string;
    city?: string;
    zipCode?: string;
    state?: string;
    country?: string;
}
export class Address  implements IAddress {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public longitude: number;
    public latitude: number;
    public street: string;
    public suiteNumber: string;
    public city: string;
    public zipCode: string;
    public state: string;
    public country: string;
    constructor(obj?: IAddress) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.longitude = obj.longitude !== undefined ? obj.longitude : null;
        this.latitude = obj.latitude !== undefined ? obj.latitude : null;
        this.street = obj.street !== undefined ? obj.street : null;
        this.suiteNumber = obj.suiteNumber !== undefined ? obj.suiteNumber : null;
        this.city = obj.city !== undefined ? obj.city : null;
        this.zipCode = obj.zipCode !== undefined ? obj.zipCode : null;
        this.state = obj.state !== undefined ? obj.state : null;
        this.country = obj.country !== undefined ? obj.country : null;
    }
}
// For reactive forms intended
export interface IFAddress  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is number
    */
    longitude?: any; 
    /**
    * Is number
    */
    latitude?: any; 
    /**
    * Is string
    */
    street?: any; 
    /**
    * Is string
    */
    suiteNumber?: any; 
    /**
    * Is string
    */
    city?: any; 
    /**
    * Is string
    */
    zipCode?: any; 
    /**
    * Is string
    */
    state?: any; 
    /**
    * Is string
    */
    country?: any; 
}
