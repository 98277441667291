import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from '../../../node_modules/ngx-cookie-service';
import { environment } from '../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'mw-vici',
  template: ``
})
export class MwViciComponent implements OnInit {

  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 


  constructor( private router: Router, private route: ActivatedRoute, private cookieService: CookieService ) {

    

    this.router.events.subscribe((res) => { 

        this.cookieExpire.setTime(this.cookieTime);
        // var text = this.route.snapshot.queryParams['text'] ;
        // var latitude = this.route.snapshot.queryParams['latitude'] ;
        // var longitude = this.route.snapshot.queryParams['longitude'] ;
        // var radius = this.route.snapshot.queryParams['radius'] ;

        var state = this.route.snapshot.queryParams['state'] ;
        var city = this.route.snapshot.queryParams['city'] ;
        var phonenumber = this.route.snapshot.queryParams['phone'] ;
        

        this.cookieService.set('viciState', state, this.cookieExpire,'/' );
        this.cookieService.set('viciCity', city, this.cookieExpire,'/' );
        this.cookieService.set('viciPhone', phonenumber, this.cookieExpire,'/' );




      });




   }

  ngOnInit() {

    setTimeout(() => {
      this.router.navigateByUrl('/cp/agent');
    }, 200);
  

    this.router.navigateByUrl('/');


   }
}
