import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, EventEmitter, Output} from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Title, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';

import { Listing, RG, GetListingBySearchTermRouteModel as Filter } from '../../../../shared/models';
import { ListingResolver, S3filesService } from '../../../../shared/services';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';

@Component({
  selector: 'mw-detail-preview',
  templateUrl: './mw-detail-preview.component.html',
  styleUrls: ['./mw-detail-preview.component.scss'],
  // Refreshment problem with onPush originally, Emre changed to Default to fix it
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwDetailPreviewComponent implements OnInit, OnDestroy {
  public TitleLength: string;

  openOrClose: string;

  @Input()
  public pageposition = 1;

  @Input()
  public pagelimit = 10;

  public listings: Listing[] = [];

  public imageOrigin = environment.imagesOrigin;

  public listing$Subs: Subscription;
  public params$Subs: Subscription;

  public resultCount: number;

  public showNoResultFound = false ;

  public url: SafeUrl;

  open() {
    if (this.openOrClose === 'inactive') {
      this.openOrClose = 'active'
    } else if (this.openOrClose === 'active') {
        this.openOrClose = 'inactive'
    } else {
        this.openOrClose = 'inactive'
    }
  }

  constructor(
    public store: MapwordsStore,
    private _listingResolver: ListingResolver,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private s3filesService: S3filesService
  ) { }

    // public listing: Listing;
  ngOnInit() {
    this._route.data.subscribe(x => {

      // this.listing.listingHours = x['listing']['data']['listingHours'];
      // console.log(this.listing.listingHours);
    })
    this.listing$Subs = this._route.data.subscribe(x => {
      if (window.innerHeight < 800) {
        this.TitleLength = '30';
      } else {
        this.TitleLength = '55';
      }

      this.showNoResultFound = false;
  
      const pagination: any = x['listings']['pagination'];
      
      // this._mwSearchService.setData(pagination.queryCount);

      // this.store.search.updatePageLimit(+pagination.pages);
      // this.store.search.updateResultsCount(+pagination.queryCount);
      const results: Listing[] = x['listings']['data'];
      if (results.length === 0) {
        // console.log('No results add alert');
        this.showNoResultFound = true;

        // this.store.alerts.add({
        //   id: 1,
        //   title: 'No results',
        //   // tslint:disable-next-line:max-line-length
        //   message: 'No Results Found',
        //   okLabel: 'Greater Distance',
        //   cancelLabel: 'Cancel',
        //   hasCancel: true,
        //   execute: (action: ActionTypes) => {
        //     if (action === ActionTypes.ok) {
        //       const filter = this.store.search.cloneFilter;
        //       filter.distance = +filter.distance + 10;
        //       this.store.search.updateFilter(filter);
        //     }
        //   }
        // });

        return;
      }


      // console.log('results x') ;
      // console.log(x['listings']['pagination']) ;



      

      // this.store.search.page = x['listings']['pagination'].page ;
      // this.store.search.pageFromCount= x['listings']['pagination'].page ;
      
      this.store.search.pageLimit= x['listings']['pagination'].pages ;
      this.store.search.pageResultsCount = x['listings']['pagination'].queryCount ;  
        
           

      this.store.search.updatePaginationArrows();

      this.store.search.searchListings(results);

      this.store.search.pageToCountComputed=  this.store.search.pageToCount  ;    
     
      this.listings = this.store.search.listingResults;
      //console.log(this.listings);
      this._titleService.setTitle(`Searching ${this.store.search.filter.searchterm} - Mapwords`);
    });

    this.params$Subs = this._route.params.subscribe((x: Filter) => {
      if (x.searchterm === null) { return; }
      this._listingResolver.filterUrlChange(x);
    });

  }

  ngOnDestroy() {
    this.listing$Subs.unsubscribe();
    this.params$Subs.unsubscribe();
  }

  public loadListing(id: number, place_id: string): void {
    // console.log('Load Listing');
    this.showNoResultFound = false;
    this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }]);
  }

  public hoverListing(listing: Listing, active: boolean): void {
    if (listing.active && !this.store.searchListing.detailIsOpen) {
      this.store.search.hoverListing(listing.googlePlaceId);
    }
  }


  public backToCategories() {
    this._router.navigate(['/home']);
  }

  public getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }
  
}
