import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})

export class EllipsisPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (args[0] === -1) {
      return value;
    }
    if (value === null){
      return value;
    }
    if (value.length > args[0]) {
      return value.substring(0, args[0] - 3) + '...';
    } else {
      return value;
    }
  }
}
