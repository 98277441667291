import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: string, arg: string): string {
    if (arg === 'fromNow') {
      return moment(value).fromNow();
    } else {
      return moment(value).format(arg);
    }
  }
}
