
import { User } from './User';


export interface IUserLogIn  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    user?: User;
    userId?: number;
}
export class UserLogIn  implements IUserLogIn {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public user: User;
    public userId: number;
    constructor(obj?: IUserLogIn) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.user = obj.user !== undefined ? obj.user : null;
        this.userId = obj.userId !== undefined ? obj.userId : null;
    }
}
// For reactive forms intended
export interface IFUserLogIn  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is User
    */
    user?: any; 
    /**
    * Is number
    */
    userId?: any; 
}
