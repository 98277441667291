/// <reference types="@types/googlemaps" />
import { Component, OnInit, QueryList, ViewContainerRef, ViewChildren, ViewChild, Input, AfterViewInit,
  ViewEncapsulation, OnChanges, ElementRef, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Listing, IFListing, IFListingTime, IFBusinessAddress, IFListingContact, IFListingConfigs,
  IFPayments, IFListingServices, IFCategoryListing, IFMasterCategoryListing, MasterCategory,
  Category, ListingTime, CategoryListing, ListingServices } from "../../../../../shared/models";
import { ListingsService, CategoriesService, CategoryTreeService } from "../../../../../shared/services/api";
import { environment } from "../../../../../environments/environment";
import * as _ from "lodash";
import { listDays } from "../../../../../shared/viewmodels";
import { MwImageEditorService, MwMapComponent, MwToggleInputsComponent } from "../../../../../shared/components";
import { ArrayValidator } from "../../../../../shared/forms/validators";
import { MapwordsStore } from "../../../../../shared/store";
import { hoursdata } from "../../../../../shared/viewmodels/hours.viewdata";
import * as moment from "moment";
import { S3filesService } from "../../../../../shared/services";
import { windowWhen } from "rxjs/operator/windowWhen";
import { IQuickSearchObject } from "../../../../../shared/models/QuickSearchObject";
import { Observable } from "rxjs";
import { MatAutocomplete } from "@angular/material";
import { isObject } from "util";
import { MAT_SELECT_SCROLL_STRATEGY } from "@angular/material";
import { Overlay, BlockScrollStrategy } from "@angular/cdk/overlay";
import { Service } from "../../../../../shared/models/Service";
import { SpecialHour, IFSpecialHour } from "../../../../../shared/models/SpecialHour";

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: "mw-listing-details",
  templateUrl: "./mw-listing-details.component.html",
  styleUrls: ["./mw-listing-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }
  ]
})
export class MwListingDetailsComponent implements OnInit, AfterViewInit {
  public imageOrigin = environment.imagesOrigin;

  @Input()
  public listing: Listing;

  @ViewChild(MwToggleInputsComponent)
  public toggles: QueryList<MwToggleInputsComponent>;

  @ViewChild(MwMapComponent)
  public map: MwMapComponent;

  @ViewChild("start")
  public startToggle: ElementRef;

  @ViewChild("end")
  public endToggle: ElementRef;

  @ViewChild('serviceInputText')
  public serviceInputText: ElementRef;

  @ViewChild("closed")
  public closedToggle: ElementRef;

  @ViewChild("day")
  public dayElement: ElementRef;
  public fListing: FormGroup;
  public hoursData: string[] = hoursdata;

  public dateIsValid = true;

  public get services(): FormArray {
    return <FormArray>this.fListing.get("services");
  }

  public get hours(): any[] {
    const list = _.orderBy(
      <Array<any>>this.fListing.get("listingHours").value,
      ["dayName"]
    );
    return list;
  }

  public get listingHoursForm(): FormArray {
    return <FormArray>this.fListing.get("listingHours");
  }

  public get specialHoursForm(): FormArray {
    return <FormArray>this.fListing.get("specialHours");
  }

  // public get specialHoursList(): any[] {
  //   const list = _.orderBy(<Array<any>>this.fListing.get("specailHours").value, ["dayName"]);
  //   return list;
  // }

  public get categories(): FormArray {
    return <FormArray>this.fListing.get("categories");
  }

  public get masterCategories(): FormArray {
    return <FormArray>this.fListing.get("masterCategories");
  }

  public searchRegularCategories = (term: string) => {
    return this._categoriesService.GetbyCategoryName(term).map(x => x.data);
  };

  public listDays: { name; value }[] = listDays;

  public dayhold;

  public indx1;

  public indx2;

  public newTimes: ListingTime[][];

  public masterCategoriesCatalog: { name; value }[] = [];

  public masterCategoryCatalog: MasterCategory[];

  public states: any[] = [];

  public selectedAvatar: any = null;

  public noscroll = false;
  public wasInside = false;

  public get listingHours(): ListingTime[] {
    // const list = _.orderBy(this.listing.listingHours, ['dayName']);
    const list = this.listing.listingHours;

    list.forEach(x => {
      // console.log(x);
      if (this.dayhold !== x.dayName) {
        this.dayhold = x.dayName;
      } else {
        x.isSameDayName = true;
      }
    });
    return list;
  }

  public initialListingHours: ListingTime[] = [];

  public get initSpecialHours(): SpecialHour[] {
    const list = this.listing.specialHours;
    return list;
  }

  public addedSpecialHours: SpecialHour[] = [];

  public get toggleListingHours(): ListingTime[] {
    const list = _.orderBy(this.listing.listingHours, ["dayName"]);
    return list;
  }

  public serviceList: Observable<Service[]> ;
  public servicesControl = new FormControl();
  public selectedServices: ListingServices[] = [];
  public selectedServicesInitial: ListingServices[] = [];

  public hitEnterFlag = false;

  public showServiceErrorMessage=false;

  public listcnt: number;
  public dnameduplicate = false;

  constructor(
    private _fb: FormBuilder,
    private _listingsService: ListingsService,
    private _categoriesService: CategoriesService,
    private _editor: MwImageEditorService,
    private _viewRef: ViewContainerRef,
    public store: MapwordsStore,
    private _router: Router,
    private _categoryTreeService: CategoryTreeService,
    private s3filesService: S3filesService
  ) {
    
  }

  public ngOnInit() {
    if (this.listing.ownerId !== this.store.auth.user.id) {
      this._router.navigateByUrl("/cp/user");
    }

    this.selectedServicesInitial = Object.assign([], this.listing.services); 
    
    this.indx1 = -1;
    this.indx2 = 0;
    // this.listing.listingHours.forEach(x => {
    //   if (this.dayhold !== x.dayName) {
    //     this.dayhold = x.dayName;
    //   } else {
    //     x.dayName = 7;
    //   }
    // });
    //console.log(this.listing.payments);
    this.getAllStates();
    //this.initForm();
    this.getMasterCategoriesCatalog();
    //console.log(<FormArray>this.fListing.get('services'));

    this.servicesControl.valueChanges.subscribe(x => {
      if (this.validateService(x)) {
      
        this.noscroll = true;
        this._categoriesService.GetbyServiceName(x).subscribe( (x) => {

          this.serviceList = Observable.of( x.data.filter(q=> this.selectedServices.findIndex( s=> s.service.toLowerCase() === q.name.toLowerCase() ) == -1 ) );
        });
      }
    });
  }

  public validateService(serviceName: string): boolean {

    let response = true;

    if(serviceName == undefined || serviceName == null || serviceName == "" || isObject(serviceName)) {
      response = false;  
    }
    else if(serviceName.length<2) {
      response = false;  
    }

   
    return response;
  }


  @HostListener("document:click")
  clickout() {
    //console.log("document:click");
    if (!this.wasInside) {
      this.noscroll = false;
    } else {
      this.noscroll = true;
    }

    this.wasInside = false;
  }

  public setScroll() {
    //console.log("setScroll");
    this.wasInside = true;
    this.noscroll = false;
  }

  ngAfterViewInit() {
    if (this.map) {
      this.map.api.getNativeMap().then(map => {
        map.addListener("idle", () => {
          google.maps.event.trigger(map, "resize");
          map.setCenter({
            lat: this.listing.businessAddress.latitude,
            lng: this.listing.businessAddress.longitude
          });
        });
      });
    }
    this._categoryTreeService.GetCategoryTree().subscribe(x => {
      this.categoryTree1 = x.data;
      for (let i = 0; i < this.categoryTree1.length; i++)
        this.setCategoryTreeParents(this.categoryTree1[i], null);
      // console.log(this.categoryTree1);
    });
  }

  private setCategoryTreeParents(tree: any, parent: any) {
    if (tree != null) {
      {
        tree.highlited = false;
        if (parent != null) tree.parent = parent;
        if (tree.subCategories != null) {
          for (let i = 0; i < tree.subCategories.length; i++) {
            this.setCategoryTreeParents(tree.subCategories[i], tree);
          }
        }
      }
    }
  }

  public validateKey(event: KeyboardEvent, type: string): boolean {
    switch(type) {
      case 'pageLink':
        if (String.fromCharCode(event.keyCode).match(/[a-z0-9_-]/g) == null) {
          return false;
        }
        break;
      case null: 
        if (String.fromCharCode(event.keyCode).match(/[A-Za-z0-9_ ]/g) == null) {
          return false;
        }
        break;
    }    
  }

  public ngOnChanges() {
    this.initForm();
  }

  public checkCategory(): boolean {
    if (
      this.SelectedCategoryTreeItems.length < 1 ||
      this.SelectedCategoryTreeItems.length > 3
    ) {
      return false;
    }
    return true;
  }

  public checkService(): boolean {
    if (this.selectedServices.length < 1 || this.selectedServices.length > 10) {
      return false;
    }

    return true;
  }

  public async submitForm(): Promise<void> {
    // if (this.fListing.valid) {
    // console.log(this.fListing.value);
    if ( (
      this.SelectedCategoryTreeItems.length < 1 ||
      this.SelectedCategoryTreeItems.length > 3 ||
      this.selectedServices.length < 1 ||
      this.selectedServices.length > 10 )
      && this.selectedAvatar == null
    ) {
      return;
    }
    this.fListing.value.masterCategories = this.fListing.value.masterCategories.filter(
      x => x.removeFinal !== true
    );
    // this.fListing.value.categories = this.fListing.value.categories.filter(x => x.removeFinal !== true);
    // this.fListing.value.services = this.fListing.value.services.filter(x => x.removeFinal !== true);
    this.fListing.value.categories = this.SelectedCategoryTreeItems;
    this.fListing.value.services = this.selectedServices;

    this.fListing.value.specialHours = this.addedSpecialHours;

    var BUCKET = environment.s3.Bucket.toString();
    var IMAGEFOLDER = "Avatar";

    var dateTime = new Date();
    let cdateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss.SSS");

    // Emre for S3 upload
    
    if (this.selectedAvatar !== null) {
      var imageExtension = this.selectedAvatar.split(";")[0].split("/");
      imageExtension = imageExtension[imageExtension.length - 1];

      var imageName = cdateTime.toString();
      imageName = imageName.replace(/[^A-Z0-9]/gi, "") + "." + imageExtension;

      var fileName =
        IMAGEFOLDER + "/" + this.listing.id.toString() + "/" + imageName;
      var buf = new Buffer(
        this.selectedAvatar.replace(/^data:image\/\w+;base64,/, ""),
        "base64"
      );

      await this._listingsService
        .uploadfile(buf, fileName)
        .then(uploadedImageUrl => {
          this.fListing.value.avatar = uploadedImageUrl.toString();
          this.listing.avatar = uploadedImageUrl.toString();

          this._listingsService.Put(this.fListing.value).subscribe(x => {
            // this.listing = x.data;
            // this.initForm();
            // this.imageOrigin = environment.imagesOrigin;

            // console.log(this.listing.introduction);
            // console.log(x.data.introduction);

            this.listing.contact = x.data.contact;
            this.listing.payments = x.data.payments;
            this.listing.introduction = x.data.introduction;
            this.listing.businessAddress = x.data.businessAddress;
            this.listing.name = x.data.name;
            this.listing.displayName = x.data.displayName;
            
            this.store.auth.user.listings.filter(
              x => x.id == this.listing.id
            )[0].avatar = uploadedImageUrl.toString();

            this.store.auth.user.listings.filter(
              x => x.id == this.listing.id
            )[0].signedAvatar = uploadedImageUrl.toString();

            this.store.auth.updateUserListing(x.data,  uploadedImageUrl.toString());

          });
        });
      } else {
 
        this._listingsService.Put(this.fListing.value)
          .subscribe(x => {
            // this.listing = x.data;
            // this.initForm();
            // this.imageOrigin = environment.imagesOrigin;

            if(x.data.contact) this.listing.contact = x.data.contact;
            if(x.data.payments) this.listing.payments = x.data.payments;
            this.listing.introduction = x.data.introduction;
            this.listing.businessAddress = x.data.businessAddress;
            if(x.data.specialHours) this.listing.specialHours = x.data.specialHours;
            if(x.data.specialHours) this.initialSpecialHours = x.data.specialHours;
            this.listing.name = x.data.name;
            this.listing.displayName = x.data.displayName;
          });        
      }
    // }
  }

  public toggleInput(isSaved: boolean) {

    if (isSaved) {
      this.submitForm();
      //location.reload();
      //this.initForm();
    } else {
      this.listing.listingHours = [];
      this.initialListingHours.forEach(x => {
        const y = Object.assign({}, x);
        this.listing.listingHours.push(y);
      });

      this.imageOrigin = environment.imagesOrigin;
      
      this.selectedServices = [];

      if ( this.selectedServicesInitial !== undefined && this.selectedServicesInitial !== null) {
        this.selectedServices = this.selectedServicesInitial;
        this.listing.services = this.selectedServicesInitial;
      }

      this.addedSpecialHours = this.initialSpecialHours.filter(x=>x.id !=0);
      this.listing.specialHours = this.initialSpecialHours.filter(x=>x.id !=0);

      // console.log('specialHours');
      // console.log(this.addedSpecialHours) ; 
      // console.log(this.listing.specialHours) ; 

      this.initForm();
      // location.reload();
    }
  }

  public saveHours(hours: ListingTime[]): void {
    const formHours = <FormArray>this.fListing.get("listingHours");
    // tslint:disable-next-line:no-shadowed-variable
    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }

    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

      // if( !(hours[index].start && hours[index].end) && !hours[index].closed){
      //   hours.splice(index,1);
      // }
    }

    this.listing.listingHours = hours;
    let index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });

    this.submitForm();

    // tslint:disable-next-line:no-shadowed-variable
    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }
    
    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

      // if( !(hours[index].start && hours[index].end) && !hours[index].closed){
      //   hours.splice(index,1);
      // }
    }

    this.listing.listingHours = hours;
    index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });
  }

  public getAllStates() {
    this._listingsService.GetAllStates().subscribe(res => {
      let statelist: any[] = [];
      statelist = res.data;
      const data = { name: "", value: "" };
      statelist.forEach(x => {
        data.name = x.name;
        data.value = x.code;
        this.states.push({ name: x.name, value: x.code });
      });
    });
  }

  public editPhoto(): void {
    this._editor
      .open(this.imageOrigin + this.listing.avatar, this._viewRef)
      .subscribe(x => {
        if (x === "" || x === undefined || x === null) {
          return;
        }
        // this.imageOrigin = '';

        this.fListing.value.avatar = x;
        this.fListing.get("avatar").setValue(x);
        // this.fListing.get("signedAvatar").setValue(x);        
        this.selectedAvatar = x; // .substring( x.indexOf(',') + 1 );
      });
  }

  public getAvatarPath(avatar: any): string {
    if (avatar !== null && avatar !== undefined) {
      if (
        avatar.indexOf("googleusercontent") > 0 ||
        avatar.indexOf("https") === 0 ||
        avatar.indexOf("data:image") === 0
      ) {
        var result = this.s3filesService.getSignedUrl(avatar);
        return result;

        // return avatar;
      }
    }
    return this.imageOrigin + avatar;
  }

  private initSelects(): void {
    this._categoriesService.GetMaster().subscribe(
      x => (this.masterCategoryCatalog = x.data),
      err => {
        this.store.searchListing.closeClaim();
      }
    );
  }

  public addToList(value: any, listName: string): void {
    if (value === undefined) {
      //console.log('value: bailing')
      return;
    }
    if (value.id === undefined || value.id === "") {
      return;
    }
    const list = <FormArray>this.fListing.get(listName);
    //console.log(list);
    let object: AbstractControl;
    switch (listName) {
      case "services":
        object = list.controls.find(x => x.value.name === value.id);
        break;
      case "categories":
        object = list.controls.find(x => x.value.name === value.id);
        break;
      case "listingHours":
        const hour = <ListingTime>value;
        object = list.controls.find(
          (x: { value: IFListingTime }) =>
            x.value.start === hour.start &&
            x.value.end === hour.end &&
            x.value.closed === hour.closed &&
            x.value.dayName === +hour.id
        );
        if (hour.closed) {
          list.controls.forEach(x => {
            if (x.value.dayName === +hour.id) {
              x.value.active = false;
            }
          });
        }
        break;
      case "masterCategories":
        object = list.controls.find(
          x => x.value.masterCategoryId === +value.id
        );
        break;
    }
    if (object) {
      object.value.active = true;
    } else {
      this.pushToList(value, listName, true);
    }
  }

  public removeFromList(index: any, listName: string, mod = "") {
    const list = <FormArray>this.fListing.get(listName);
    // Listing Hours
    if (listName === "listingHours") {
      let item;
      if (mod === "special") {
        item = list.controls.find(
          x =>
            x.value.specialDateName === index.specialDateName &&
            x.value.specialDate === index.specialDate
        );
      } else {
        item = list.controls.find(
          x =>
            x.value.end === index.end &&
            x.value.start === index.start &&
            x.value.dayName === index.dayName
        );
      }

      if (item.value.id) {
        item.value.active = false;
      } else {
        index = list.controls.findIndex(
          x =>
            x.value.end === index.end &&
            x.value.start === index.start &&
            x.value.dayName === index.dayName
        );
        list.removeAt(index);
      }
      return;
    }
    const object = list.at(index);
    if (object.value.id) {
      object.value.active = false;
      list.removeAt(index);
      // list.push(this._fb.control({ active: false, removeFinal: true }));
    } else {
      list.removeAt(index);
    }
  }

  public removeFromCategoryList(categoryId: number): void {
    // const list: FormArray = <FormArray>this.fListing.get(listName);
    // list.removeAt(index);

    var x = this.findByCategoryId(this.categoryTree1, categoryId);

    this.selectService(x);
    this.clickedCategory(x);
  }

  public findByCategoryId(list: any[], id: number): any {
    let result: any;
    list.forEach(x => {
      if (x.id == id) {
        result = x;
        return result;
      } else if (x.subCategories !== undefined) {
        let tmp = this.findByCategoryId(x.subCategories, id);
        if (tmp !== undefined) {
          result = tmp;
          return result;
        }
      }
    });
    return result;
  }

  public myListFormatter(data: Category): string {
    const html = `<span>${data.description}</span>`;
    return html;
  }

  private initForm(): void {
    // console.clear();
    // console.log(this.listing);

    this.fListing = this._fb.group(<IFListing>{
      // signedAvatar: this.listing.signedAvatar,
      active: this.listing.active,
      businessAddressId: this.listing.businessAddressId,
      contactId: this.listing.contactId,
      addedDate: this.listing.addedDate,
      googlePlaceId: this.listing.googlePlaceId,
      icon: this.listing.icon,
      id: this.listing.id,
      listingConfigsId: this.listing.listingConfigsId,
      overallStars: this.listing.overallStars,
      price: this.listing.price,
      monthlyPrice: this.listing.monthlyPrice,
      rankingOrder: this.listing.rankingOrder,
      openNow: this.listing.openNow,
      paymentsId: this.listing.paymentsId,
      ownerId: this.listing.ownerId,
      name: [this.listing.name, [Validators.required]],
      displayName: [this.listing.displayName, [Validators.minLength(6), Validators.maxLength(50)]],
      avatar: this.listing.avatar,
      businessAddress: this._fb.group(<IFBusinessAddress>{
        active: this.listing.active,
        addedDate: this.listing.businessAddress.addedDate,
        id: this.listing.businessAddress.id,
        city: [this.listing.businessAddress.city, [Validators.required]],
        country: this.listing.businessAddress.country,
        latitude: this.listing.businessAddress.latitude,
        longitude: this.listing.businessAddress.longitude,
        state: [this.listing.businessAddress.state, [Validators.required]],
        street: [this.listing.businessAddress.street, [Validators.required]],
        suiteNumber: this.listing.businessAddress.suiteNumber,
        zipCode: [this.listing.businessAddress.zipCode, [Validators.required]]
      }),
      payments: this._fb.group(<IFPayments>{
        active: this.listing.payments.active,
        addedDate: this.listing.addedDate,
        id: this.listing.payments.id,
        amex: this.listing.payments.amex,
        cash: this.listing.payments.cash,
        check: this.listing.payments.check,
        discover: this.listing.payments.discover,
        masterCard: this.listing.payments.masterCard,
        visa: this.listing.payments.visa
      }, {validator: this.requiredCheck}),
      listingConfigs: this._fb.group(<IFListingConfigs>{
        active: this.listing.listingConfigs.active,
        addedDate: this.listing.listingConfigs.addedDate,
        businessClosed: this.listing.listingConfigs.businessClosed,
        id: this.listing.listingConfigs.id,
        homeBasedBusiness: this.listing.listingConfigs.homeBasedBusiness,
        showAddress: this.listing.listingConfigs.showAddress
      }),
      contact: this._fb.group(<IFListingContact>{
        id: this.listing.contact.id,
        active: true,
        addedDate: this.listing.contact.addedDate,
        phoneNumber: [this.listing.contact.phoneNumber,  [Validators.required]],
        mobileNumber: this.listing.contact.mobileNumber,
        website: this.listing.contact.website,
        tollFreeNumber: this.listing.contact.tollFreeNumber,
        faxNumber: this.listing.contact.faxNumber,
        publicEmail: this.listing.contact.publicEmail,
        contactName: this.listing.contact.contactName
      }),
      services: this._fb.array([], ArrayValidator.maxLength(10, true)),
      //services: this._fb.array([], Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)])),
      listingHours: this._fb.array(this.setFormHours()),
      //categories: this._fb.array(this.setFormCategories(), ArrayValidator.maxLength(3, true)),
      categories: this._fb.array(
        this.setFormCategories(),
        ArrayValidator.maxLength(3, true)
        ),
        masterCategories: this._fb.array(
          this.setFormMasterCategories(),
          ArrayValidator.maxLength(1, true)
          ),
          specialHours: this._fb.array([]),
          //categories: this._fb.array([], Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(3)])),
          //masterCategories: this._fb.array(this.setMasterCategories(), Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(1)]))
          introduction: this.listing.introduction,
        });
        
    this.SelectedCategoryTreeItems = [];

    if (
      this.listing.categories !== undefined &&
      this.listing.categories !== null
    ) {
      this.SelectedCategoryTreeItems = this.listing.categories;
    }

    this.selectedServices = [];

    if (this.listing.services !== undefined && this.listing.services !== null) {
      this.selectedServices = this.listing.services;
    }

    this.addedSpecialHours = [];
    this.initialSpecialHours = [];

    if (this.listing.specialHours !== undefined && this.listing.specialHours !== null){
      this.addedSpecialHours = this.listing.specialHours;
      this.initialSpecialHours = this.listing.specialHours;
    }
  }

  public requiredCheck(g: FormGroup) {
    let amex = g.get('amex').value,
        visa = g.get('visa').value,
        master = g.get('masterCard').value,
        discover = g.get('discover').value,
        check = g.get('check').value,
        cash = g.get('cash').value

    if (amex == true || visa == true || master == true || discover == true || check == true || cash == true) {
      return null;
    } else {
      return {nocheck: true};
    }
  }

  public paymentCheckStatus() {
    let pay = this.fListing.controls.payments;

    let amex = pay.get('amex').value,
        visa = pay.get('visa').value,
        master = pay.get('masterCard').value,
        discover = pay.get('discover').value,
        check = pay.get('check').value,
        cash = pay.get('cash').value
    
    if (amex == true || visa == true || master == true || discover == true || check == true || cash == true) {
      return true;
    } else {
      return false;
    }
  }

  private setMasterCategories(): FormGroup[] {
    const cats = [];
    // if (this.listing.masterCategories) {
    //   this.listing.masterCategories.forEach(mCat => {
    //     if (mCat.masterCategory.description !== 'Uncategorized') {
    //       console.log(mCat.masterCategory);
    //       cats.push(this._fb.group({
    //         MasterCategoryId: [mCat.masterCategory.id],
    //         viewName: [mCat.masterCategory.description]
    //       }));
    //     }
    //   });
    // }
    this.listing.masterCategories.forEach(x => {
      cats.push(this.pushMasterCategories(x));
    });
    return cats;
  }

  private setFormServices(): FormGroup[] {
    const list = [];
    //console.log(this.listing.services);
    this.listing.services.forEach(x => {
      list.push(this.pushServices(x));
    });
    return list;
  }

  private setFormHours(): FormGroup[] {
    const list = [];
    this.initialListingHours = [];
    //console.log(this.listingHours);
    this.listing.listingHours.forEach(x => {
      let y = Object.assign({}, x);
      this.initialListingHours.push(y);
      list.push(this.pushHours(x));
    });
    return list;
  }

  private setFormCategories(): FormGroup[] {
    // console.log(this.listing.categories)
    const list = [];
    this.listing.categories.forEach(x => {
      list.push(this.pushCategories(x));
    });
    return list;
  }

  private setFormMasterCategories(): FormGroup[] {
    const list = [];
    this.listing.masterCategories.forEach(x => {
      list.push(this.pushMasterCategories(x));
    });
    return list;
  }

  private pushToList(x: any, listName: string, isNew = false): void {
    // Everything here is new
    switch (listName) {
      case "services":
        //this.services.push(this.pushServices(x));
        this._categoriesService.GetbyCategoryName(x.id).subscribe(c => {
          this.services.push(this.pushServices(c.data[0], true));
        });
        break;
      case "categories":
        this._categoriesService.GetbyCategoryName(x.id).subscribe(c => {
          this.categories.push(this.pushCategories(c.data[0], true));
        });
        break;
      case "listingHours":
        this.listingHoursForm.push(this.pushHours(x, true));
        break;
      case "masterCategories":
        this.masterCategories.push(this.pushMasterCategories(x, true));
        break;
    }
  }

  // private pushServices(x: any): FormGroup {
  //   if (x.name) {
  //     x.service = x.name;
  //   }
  //   return this._fb.group(<IFListingServices>{
  //     active: true,
  //     addedDate: x.addedDate,
  //     id: x.id,
  //     listingId: this.listing.id,
  //     service: x.service,
  //     name: x.name
  //   });
  // }

  private pushServices(x: any, isNew = false): FormGroup {
    // Needs this logic cause is a many to many relationship object
    //console.log(x);
    //console.log(isNew);
    if (!isNew) {
      return this._fb.group(<IFListingServices>{
        active: true,
        addedDate: x.addedDate,
        id: x.id,
        service: x.service,
        listingId: x.listingId,
        name: x.service
      });
    } else {
      // Means that I need to create a new one
      return this._fb.group(<IFListingServices>{
        active: true,
        id: x.id,
        service: x.name,
        listingId: x.listingId,
        name: x.description
      });
    }
  }

  private pushHours(x: any, isNew = false): FormGroup {
    // return this._fb.group(<IFListingTime>x);
    return this._fb.group(<IFListingTime>{
      active: x.active,
      addedDate: x.addedDate,
      closed: x.closed,
      dayName: isNew ? +x.id : +x.dayName,
      end: x.end,
      id: isNew ? 0 : +x.id,
      isSpecialHour: x.isSpecialHour,
      listingId: this.listing.id,
      specialDate: x.specialDate,
      specialDateName: x.specialDateName,
      start: x.start
    });
  }

  private pushCategories(x: any, isNew = false): FormGroup {
    // Needs this logic cause is a many to many relationship object
    if (!isNew) {
      return this._fb.group(<IFCategoryListing>{
        active: true,
        addedDate: x.addedDate,
        id: x.id,
        categoryId: x.categoryId,
        listingId: x.listingId,
        name: x.category.description
      });
    } else {
      // Means that I need to create a new one
      return this._fb.group(<IFCategoryListing>{
        active: true,
        categoryId: x.id,
        listingId: this.listing.id,
        name: x.description
      });
    }
  }

  private pushMasterCategories(x: any, isNew = false): FormGroup {
    // Needs this logic cause is a many to many relationship object
    if (!isNew) {
      return this._fb.group(<IFMasterCategoryListing>{
        active: x.active,
        addedDate: x.addedDate,
        id: x.id,
        listingId: x.listingId,
        masterCategoryId: x.masterCategoryId,
        name: x.masterCategory.description
      });
    } else {
      // Means that I need to create a new one
      x = this.masterCategoriesCatalog.find(d => d.value === +x.id);
      return this._fb.group(<IFMasterCategoryListing>{
        active: true,
        listingId: this.listing.id,
        masterCategoryId: x.value,
        name: x.name
      });
    }
  }

  private getMasterCategoriesCatalog(): void {
    this._categoriesService.GetMaster().subscribe(x => {
      x.data.forEach(c => {
        this.masterCategoriesCatalog.push({
          name: c.description,
          value: c.id
        });
      });
    });
  }

  private setDates(): FormGroup[] {
    const days: FormGroup[] = [];
    //console.log(this.listing.listingHours);
    if (this.listing.listingHours) {
      let cnt = -1;
      this.listing.listingHours.forEach(hour => {
        cnt = cnt + 1;
        if (cnt < hour.dayName) {
          for (; cnt < hour.dayName; cnt++) {
            days.push(
              this._fb.group(<IFListingTime>{
                active: true,
                addedDate: null,
                closed: true,
                dayName: cnt,
                end: null,
                start: null,
                id: 0,
                isSpecialHour: false
              })
            );
          }
        } else {
          cnt = hour.dayName;
        }
        days.push(
          this._fb.group(<IFListingTime>{
            active: true,
            addedDate: null,
            closed: hour.closed,
            dayName: hour.dayName,
            end: hour.end,
            start: hour.start,
            id: 0,
            isSpecialHour: false
          })
        );
      });
      cnt = cnt + 1;
      if (cnt < 7) {
        for (; cnt < 7; cnt++) {
          days.push(
            this._fb.group(<IFListingTime>{
              active: true,
              addedDate: null,
              closed: true,
              dayName: cnt,
              end: null,
              start: null,
              id: 0,
              isSpecialHour: false
            })
          );
        }
      }
    } else {
      let cnt = 0;
      for (; cnt < 7; cnt++) {
        days.push(
          this._fb.group(<IFListingTime>{
            active: true,
            addedDate: null,
            closed: true,
            dayName: cnt,
            end: null,
            start: null,
            id: 0,
            isSpecialHour: false
          })
        );
      }
    }
    return days;
  }

  public onTimeChange(e) {
    if (e) {
      const list = this.listing.listingHours;
      if (e.id) {
        const hourList = list.filter(x => x.id == e.id);
        if (e.name == "start") {
          hourList[0].start = this.combineDateAndTime(
            hourList[0].start,
            e.value
          );
        } else {
          // hourList[0].end = (Date.parse(hourList[0].end.toString()) + e.value).toDate();
          hourList[0].end = this.combineDateAndTime(hourList[0].end, e.value);
        }
        this.fListing.value.listingHours = list;
      } else {
        const hourList = list.filter(x => x.id == null && x.dayName == e.title);
        if (e.name === "start") {
          hourList[0].start = this.combineDateAndTime(
            this.newHour.start,
            e.value
          );
        } else {
          // hourList[0].end = (Date.parse(hourList[0].end.toString()) + e.value).toDate();
          hourList[0].end = this.combineDateAndTime(this.newHour.end, e.value);
        }
        this.fListing.value.listingHours = list;
      }
      //console.log(this.fListing.value.listingHours);
    }
    //console.log(this.listing.listingHours);
  }

  public combineDateAndTime(date, time): string {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; // Jan is 0, dec is 11
    const day = new Date().getDate();
    const dateString = "" + year + "-" + month + "-" + day;
    const combinedString = dateString + " " + time;
    // console.log(combinedString);
    // console.log(moment(combinedString, 'YYYY-MM-DD h:m:s A').format('YYYY-MM-DD HH:mm:ss'));
    const combined = moment(combinedString, "YYYY-MM-DD h:m:s A").format(
      "YYYY-MM-DD HH:mm:ss"
    );

    return combined;
  }

  public toggleHours(dayname, closedchecked) {
    this.listing.listingHours.forEach(function(x) {
      if (x.dayName === dayname) {
        x.closed = !x.closed;
      }
      // x.closed = (x.dayName == dayname) ?   closedchecked  : x.closed
    });
    this.fListing.value.listingHours = this.listing.listingHours;
  }

  public newHour: any;

  public toggleExtraHour(dayname, closedchecked, idx) {
    const i = dayname;
    const list = this.listing.listingHours.filter(x => x.dayName === dayname);
    if (closedchecked) {
      this.newHour = {
        // id: null,
        active: true,
        addedDate: this.combineDateAndTime(new Date(), "12:00 AM"),
        dayName: dayname,
        // public start: Date;
        // public end: Date;
        start: this.combineDateAndTime(new Date(), "12:00 AM"),
        end: this.combineDateAndTime(new Date(), "12:00 AM"),
        isSpecialHour: false,
        closed: false,
        specialDate: this.combineDateAndTime(new Date(), "12:00 AM"),
        specialDateName: "",
        // listing: this.listing,
        listingId: this.listing.id,
        isSameDayName: true
      };
      // closedchecked = false;
      const y = Object.assign({}, this.newHour);
      if (
        this.listing.listingHours.filter(x => x.dayName === dayname).length > 1
      )
        this.listing.listingHours[idx + 1].active = true;
      else {
        this.listing.listingHours.splice(idx + 1, 0, y);
      }
    } else {
      // closedchecked = false;
      this.listing.listingHours[idx + 1].active = false;
      // this.listing.listingHours.splice(idx+1, 1)
    }
    this.fListing.value.listingHours = this.listing.listingHours;
  }

  public hasSeveralHours(time): boolean {
    const b = this.listing.listingHours.filter(h => h.dayName === time.dayName);
    return (
      this.listing.listingHours.filter(
        h => h.dayName === time.dayName && h.active
      ).length > 1
    );
  }

  public checkHours(hour,time): boolean {

    //let timeText =  moment(time, 'hh:mm a').format("hh:mm a"); ;

    // console.log(hour) ;
    // console.log(time) ;
    // console.log(timeText) ;

    return (hour.substring(11) == time.substring(11) ) ;

  }

  // Category Tree

  public categoryTree1: IQuickSearchObject[];
  public categoryTree2: IQuickSearchObject[];
  public categoryTree3: IQuickSearchObject[];
  public categoryTree4: IQuickSearchObject[];

  // public selectedCategories: any[] = [];

  public SelectedCategoryTreeItems: CategoryListing[] = [];

  checked(item) {
    item.checked = item.active ? false : true;
  }

  public selectService(item: any) {
    // console.log('item.id: ' + item.id);
    // console.log(item.name);
    let found = false;
    let cat = new CategoryListing({
      category: new Category({ name: item.name }),
      listingId: this.listing.id,
      categoryId: item.id
    });
    for (let i = 0; i < this.SelectedCategoryTreeItems.length; i++) {
      if (this.SelectedCategoryTreeItems[i].categoryId == item.id) {
        this.SelectedCategoryTreeItems.splice(i, 1);
        found = true;
      }
    }
    if (!found) {
      item.highlited = true;
      this.SetSelectedTreeUp(item);
      this.SelectedCategoryTreeItems.push(cat);
    } else if (found === true) {
      item.highlited = false;
      this.deSelectService(item);
    }
  }

  public SetSelectedTreeUp(item) {
    item.highlited = true;
    if (item.parent != null) {
      this.SetSelectedTreeUp(item.parent);
    }
  }

  public deSelectService(item) {
    item.highlited = false;
    if (item.parent != null) {
      this.deSelectService(item.parent);
    }
  }

  public selectedItem;

  public clickedCategory(item: any) {
    if (!item.clicked) {
      this.SetClick(item);
      item.clicked = true;
    } else if (item.clicked === true) {
      this.deClick(item);
      item.clicked = false;
    }
    if (!item.parent) {
      for (let i = 0; i < this.categoryTree1.length; i++) {
        if (item != this.categoryTree1[i]) {
          this.deClick(this.categoryTree1[i]);
        }
      }
    } else {
      for (let i = 0; i < item.parent.subCategories.length; i++) {
        if (item != item.parent.subCategories[i]) {
          item.parent.subCategories[i].clicked = false;
        }
      }
    }
  }

  public SetClick(item) {
    item.clicked = true;
    if (item.parent != null) {
      this.SetClick(item.parent);
    }
  }

  public deClick(item) {
    item.clicked = false;
    if (item.parent != null) {
      this.deClick(item.parent);
    }
  }

  public clearCategory(i) {
    switch (i) {
      case 1:
        if (this.categoryTree2 != []) {
          this.categoryTree2 = [];
          this.categoryTree3 = [];
          this.categoryTree4 = [];
        }
      case 2:
        if (this.categoryTree3 != []) {
          this.categoryTree3 = [];
          this.categoryTree4 = [];
        }
      case 3:
        if (this.categoryTree4 != []) {
          this.categoryTree4 = [];
        }
    }
  }

  public openSubCat(i, item) {
    this.clearCategory(i);
    switch (i) {
      case 1: {
        this.categoryTree2 = item.subCategories;
        break;
      }
      case 2: {
        this.categoryTree3 = item.subCategories;
        break;
      }
      case 3: {
        this.categoryTree4 = item.subCategories;
        break;
      }
    }
    for (let j = 0; j < item.subCategories.length; j++) {
      this.deClick(item.subCategories[j]);
    }
    this.clickedCategory(item);
  }

  public setChecked(item) {
    return (
      item.highlited ||
      this.SelectedCategoryTreeItems.findIndex(x => x.categoryId == item.id) >
        -1
    );
  }

  public displayFn(category?: Category): string | undefined {
    if (category) {
      return category.description;
    }

    return undefined;
  }

  public addService(obj: MatAutocomplete, senderInput: boolean){
    
    this.showServiceErrorMessage = false;

    if(this.hitEnterFlag && senderInput) {
      this.hitEnterFlag = false;

      if(this.selectedServices.length>0) {
        this.selectedServices.splice(this.selectedServices.length-1 ,1);
      }
    }

    this.noscroll = false;

    var selectedItem= obj.options.filter(x=>x.selected==true);
    let inputLength:number = +this.serviceInputText.nativeElement.value.length;

    if (inputLength == 0) {
      return;
    } 

    if (selectedItem.length == 0 && inputLength > 4) {

      let inputBoxText = this.serviceInputText.nativeElement.value;

      if ( this.selectedServices.filter(x => x.service == inputBoxText).length == 0 ) {
        let item = new ListingServices({id:0, active:true, service:inputBoxText, listingId: 0});
    
        if(!this.checkServiceIsExists(inputBoxText)) {
          this.selectedServices.push(item);
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]) ;
      }
    }

    if(selectedItem !== null && selectedItem.length != 0) {
      let description = selectedItem[0].value.description;

      if ( this.selectedServices.filter(x=>x.service==description).length == 0 ) {
        let item = new ListingServices({id:0 ,active:true, service:description,listingId: 0 });
    
        if(!this.checkServiceIsExists(description)) {
          this.selectedServices.push(item);
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]) ;
      }
    }
    this.servicesControl.setValue("");
    this.serviceList = Observable.of([]) ;

    this.enableScroll();
  }


  public checkServiceIsExists(serviceName: string): boolean {

    let result = false;

    if(this.selectedServices.length>0) {
      this.selectedServices.forEach(element => {
        if(element.service.toLowerCase().trim() == serviceName.toLowerCase().trim()) {
          result = true;
        }
      });
    }

    return result;
  }


  public enableScroll() {
    this.noscroll=false;
    this.wasInside=false;
  }
  
  public removeService(index:number) {
      this.selectedServices.splice(index,1);
  }

  public hitEnter(e) {

    this.showServiceErrorMessage = false;
    
    e.preventDefault();

    this.hitEnterFlag = false;

    if (this.serviceInputText.nativeElement.value.length > 4) {
     
      let inputBoxText = this.serviceInputText.nativeElement.value
      if ( this.selectedServices.filter(x => x.service == inputBoxText).length == 0 ) {
        let item = new ListingServices({id:0, active:true, service:inputBoxText, listingId: 0});
  
        if(!this.checkServiceIsExists(inputBoxText)) {
          this.selectedServices.push(item);
          this.hitEnterFlag = true;
          this.enableScroll();
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]) ;
      }
    }
  }

  public specialHours: any[] = [];
  public initialSpecialHours: SpecialHour[] = [];
  public exist: any[] = [];

  public addSpecialHours(value: any, listName: string) {

    if(!this.checkDate(value)) {
      return;
    }


    let item: SpecialHour = {
      id: value.id,
      active: value.active,
      addedDate: value.addedDate,
      dayName: value.dayName,
      start: value.start,
      end: value.end,
      closed: value.closed,
      isSpecialHour: value.isSpecialHour,
      specialDate: value.specialDate,
      specialDateName: value.specialDateName,
      listing: null,
      listingId: this.listing.id,
      isSameDayName: value.isSameDayName
    }

    this.addedSpecialHours.push(item);
    
  }




  public checkDate(value: any): boolean{

    try {
        this.dateIsValid = true;

        let timeFormatted: any = moment(value.specialDate, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm:ss');
        let dbValues = this.listing.specialHours.filter(x => x.specialDate == timeFormatted || x.specialDateName == value.specialDateName);

        this.exist = this.fListing.get("specialHours").value.filter(x => x.specialDate == value.specialDate || x.specialDateName == value.specialDateName);  

        let timeValidate = moment(value.specialDate, 'dddd, MMMM Do YYYY').isValid();
        
        let newDate = new Date(value.specialDate);


        if (this.exist.length > 0 || dbValues.length > 0 || value.specialDate === undefined || value.specialDateName === undefined || !timeValidate || timeFormatted == 'Invalid date' || newDate.toString() == 'Invalid Date' || value.specialDate.length < 8 || value.specialDate.length > 10) { 
          this.dateIsValid = false;

        }
        else {
          this.dateIsValid = true;
        }

        return this.dateIsValid;
    }
    catch {
      return false;
    }


  }



  public removeSpecialHour(index: number) { 
    this.addedSpecialHours.splice(index, 1);
  }

  private pushSpecialHours(x: any, isNew = false): FormGroup {
    // return this._fb.group(<IFListingTime>x);
    return this._fb.group(<IFSpecialHour>{
      active: x.active,
      addedDate: x.addedDate,
      closed: x.closed,
      dayName: isNew ? +x.id : +x.dayName,
      end: x.end,
      id: isNew ? 0 : +x.id,
      isSpecialHour: x.isSpecialHour,
      listingId: this.listing.id,
      specialDate: x.specialDate,
      specialDateName: x.specialDateName,
      start: x.start
    });
  }

  private setSpecialHours(): FormGroup[] {   
    const list = [];
    this.initialSpecialHours = [];
    //console.log(this.listingHours);
    this.listing.specialHours.forEach(x => {
      let y = Object.assign({}, x);
      this.initialSpecialHours.push(y);
      list.push(this.pushSpecialHours(x));
    });
    return list;
  }
  
  public validateDisplayname(dName: AbstractControl): boolean {
    this.listcnt = 0;
    this.dnameduplicate = false;
    this._listingsService.GetByDisplayName(dName.value)
      .subscribe(x => {
        // console.log(this.listcnt);
        // console.log(x.data);
        x.data.forEach(c => {
          // console.log(c);
          // console.log(this.listing);
          if(c.id && c.googlePlaceId === this.listing.googlePlaceId) {
            this.dnameduplicate = false;
          }
          else {
            this.dnameduplicate = true;
            this.listcnt = this.listcnt + 1;
          }          
        });
      });
    // console.log(this.dnameduplicate);
    return this.dnameduplicate;
  }
  
  public validateKeyDisplayName(event: KeyboardEvent): boolean {

    // console.log(event.charCode) ;

    // if (String.fromCharCode(event.charCode).match(/[a-z0-9]/g) == null && event.charCode!= 0) {
    if (String.fromCharCode(event.charCode).match(/[a-zA-Z0-9]/g) == null && event.charCode!= 0 ) {
      return false;
    } else {
      return true;
    }
  }

}
