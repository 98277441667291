import { Component, ViewContainerRef, OnInit, HostBinding, ChangeDetectionStrategy,
  ViewChild, ElementRef, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
// import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
// import {ModalGalleryModule} from 'angular-modal-gallery';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title} from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { Listing, ListingReview, ListingTime, ListingPhoto } from '../../../../shared/models';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';
// import {Angular2ImageGalleryModule} from "./../../../angular2-image-gallery";


@Component({
  selector: 'mw-360-pop',
  templateUrl: './mw-360-pop.component.html',
  styleUrls: ['./mw-360-pop.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class Mw360PopComponent implements OnInit, OnDestroy, AfterViewInit {

  public listing: Listing;

  public url360: SafeResourceUrl;

  public todayNumber;
  public showModal = false;
  public showSlide = true;
  public today: ListingTime = new ListingTime({});

  public d360image = false;
  public menuimage = false;
  public photoId = 0;
  public currentId = 0;
  public photoCount = 0;

  public photoURL: string[] = [];
  public photoGallery = '';
  public image = '';

  public routerSubs: Subscription;

  constructor(
    public store: MapwordsStore,
    private _route: ActivatedRoute,
    private _router: Router,
    private _TitleService: Title,
    private _sanitizer: DomSanitizer) {

}

  ngOnInit() {
    // console.log('Hi');

    this.showModal = true;

    this.storeChanged();

    // this.routerSubs = this._route.data.subscribe(x => {
    //   this.store.searchListing.selectListing(x['listing']['data']);
    //   this.showModal = true;
    //   this.store.search.hoverListing(x['listing']['data']['googlePlaceId']);
    // });



    const d = new Date();
    this.todayNumber = d.getDay();
  }

  ngOnDestroy() {
    // this.routerSubs.unsubscribe();
  }

  public storeChanged() {
    // console.log('Store has changed');
    // console.log(this.listing);
    if (this.listing) {
      this.listing = this.store.searchListing.listing;
      const re = /assets/gi;
      this.photoId = 0;
      this.photoCount = 0;
      this.currentId = 1;
      this.photoURL = [];
      this.url360 = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/streetview?location='+this.listing.businessAddress.latitude + ',' + this.listing.businessAddress.longitude + '&fov=90&heading=90&pitch=10&key=AIzaSyAZ9bTkEsQZKsok1HjNlTxjVl_vK0Ifp7c');
      for (const picture of this.listing.photos) {
          if (picture.imageUrl.search(re) === -1) {
             if (this.store.searchListing.D360) {
               this.photoCount++;
               this.photoGallery = '360 Degree View';
               this.photoURL.push(picture.imageUrl);
               if (this.image === '') {this.image = picture.imageUrl; }
             }
          } else {
             if (!this.store.searchListing.D360) {
               this.photoCount++;
               this.photoGallery = 'Services';
               this.photoURL.push(environment.imagesOrigin + picture.imageUrl);
               if (this.image === '') {this.image = environment.imagesOrigin + picture.imageUrl; }
             }
          }
      }
      this.image = this.photoURL[0];
    }
    return this.listing = this.store.searchListing.listing;
  }

  public goBack(): void {
    this.store.searchListing.d360IsOpen = false;
  }

  public BrowseAll(): void {
    this.showSlide = false;
  }
  public ShowSlide(): void {
    this.showSlide = true;
  }

  public goPrevious(): void {
    if (this.photoId > 0) {
      this.photoId--;
      this.currentId--;
      this.image = this.photoURL[this.photoId];
    }
  }
  public goNext(): void {
    if (this.photoId < this.photoURL.length - 1) {
      this.photoId++;
      this.currentId++;
      this.image = this.photoURL[this.photoId];
    }
  }
  ngAfterViewInit() {}
}
