import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: `
    <div class="notFound">
      <img src="../assets/images/mapwords-logo-blue.svg" />
      <h2>Page not found</h2>
      <p class="page-not-found">The page you requested could not be found.</p>
      <p class="redirecting">You will be redirected to the home page...</p>
    </div>
  `,
  styles: [`
    .notFound {
      display: flex;
      align-items: center;
      width: 70vw;
      height: 35vh;
      flex-direction: column;
      box-shadow: 0px 3px 6px #00000029;
      margin: auto;
      margin-top: 25vh;
    }
    img {
      width: 150px;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
    p.page-not-found {
      font-size: 14px;
      font-weight: 500;
      margin-top: 1vh;
    }
    p.redirecting {
      font-size: 13px;
      font-weight: 400;
      color: #6E90F4;
      margin-top: 5vh;
    }
    @media (max-width:375px) {
      .notFound {
        justify-content: center;
        width: 100vw;
        height: 100vh;
        margin-top: 0;
      }
    }
  `]
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private _router: Router
  ) {}

  ngOnInit() {

        setTimeout(() => {

          this._router.navigateByUrl('/home');

        }, 5000);        

  }


}
