import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, Params} from '@angular/router';
import { action, observable, computed, reaction, extendObservable, toJS } from 'mobx';
import {
  Listing,
  GetListingBySearchTermRouteModel as Filter,
  S3SignedUrl
} from '../models';
import { ListingsService, LocationService } from '../services/api';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { S3Store } from './s3.store';

@Injectable()
export class SearchStore {

  @observable
  public listingResults: Listing[] = [];

  @observable
  public filter: Filter = {
    searchterm: '',
    distance: 5,
    price: 0,
    open: false,
    order: 0,
    rating: 0,
    // latitude: 33.6839,
    // longitude: -117.7947
    latitude: null,
    longitude: null
  };

  // public filter = extendObservable(this, {
  //   searchterm: '',
  //   distance: 20,
  //   price: 0,
  //   open: false,
  //   rating: 0,
  //   latitude: 33.6839,
  //   longitude: -117.7947
  // });

  @observable
  public orderResultsBy: { prop, order } = {
    prop: 'rankingOrder, monthlyPrice',
    order: 'desc, desc'
  };

  @observable
  public page = 1;

  @observable
  public pageLimit = 1;

  @observable
  public pageResultsCount = 0;

 @observable
  public pageToCountComputed = 0;
  
  @observable
  public isPagePrevious = false;

  @observable
  public isPageForward = false;

  // Is using the current location?
  @observable
  public usingLocation = false;

  @observable
  public locationName = '';

  @observable
  public isLoading = false;



  public lat;
  public lng;

  public imageOrigin = environment.imagesOrigin;
  
  constructor(
    private _listingsService: ListingsService,
    private _locationService: LocationService,
    private activatedRoute: ActivatedRoute,
    private s3store: S3Store
  ) { }


  @computed
  public get listingsResultsNumber(): number {
    return this.listingResults.length;
  }

  @computed
  public get hoveredListingBasic(): Listing {
    return this.listingResults.find(x => x.active === false);
  }

  @computed
  public get cloneFilter(): Filter {
    return toJS(this.filter);
  }

  @computed
  public get pageShowingResultsCount(): number {
    const limit = 10;
    const pageFixed = this.page - 1;
    const numberOfPrevious = limit * pageFixed; // 10 * 0 = 0 || 10 * 1 = 10 || 10 * 2 = 20
    const showingNumber = this.listingsResultsNumber + numberOfPrevious;
    return showingNumber;
  }

  @computed
  public get pageFromCount(): number {
      const limit = 10;
      const pageFixed = this.page - 1;
      const pageFrom = pageFixed * limit + 1;
      return pageFrom;
  }

  @computed
  public get pageToCount(): number {
      const limit = 10;
      const pageTo = this.page * limit + 1;
      return pageTo > this.pageShowingResultsCount ? this.pageShowingResultsCount : pageTo;
  }

  @action('UPDATE LOCATION NAME')
  public updateLocationName(): void {
    if (isNaN(this.filter.latitude)  || isNaN(this.filter.latitude)) {
      this.activatedRoute.params.subscribe((params: Params) => {
        this.lat = +params['latitude'];
        this.lng = +params['latitude'];
      });
    } else {
      this.lat = this.filter.latitude;
      this.lng = this.filter.latitude;
    }

    this._locationService.GetCurrentAddress(this.lat, this.lng)
      .subscribe(x => {
        this.locationName = `${x.data.city}, ${x.data.state}`;
      });
  }

  @action('UPDATE SEARCH LISTINGS RESULT')
  public searchListings(listings: Listing[]): void {
    this.listingResults = _.orderBy(listings.slice(), [this.orderResultsBy.prop, 'rankingOrder, monthlyPrice'], [this.orderResultsBy.order, 'desc, desc']);


    this.listingResults.forEach(listing => {

      if(listing) {
        if(listing.avatar) {
          listing.avatar.indexOf('http') > -1 ? listing.signedAvatar =  this.getAvatar(listing.avatar) : listing.signedAvatar = this.imageOrigin+ listing.avatar ;
        }
      }

      

      // console.log(listing.avatar);

      if(listing.reviews)
      {
        listing.reviews.forEach(review => {
          if(review.client) {
            if(review.client.avatar) {
              review.client.avatar.indexOf('http') > -1 ? review.client.signedAvatar =  this.getAvatar(review.client.avatar) : review.client.signedAvatar = this.imageOrigin+ review.client.avatar ;
            }
          }
        });
      }


    });


    this.isLoading = false;
  }

  private getAvatar(avatar: string): string {
    var result = this.getSignedUrl(avatar);
    return result;
  }

  getSignedUrl(fileFullName:string): string {
    const bucket = new S3(environment.s3.BucketOptions);
    let fileName: string = '' ;

    // console.log('fileFullName');
    // console.log(fileFullName);

    if(fileFullName == undefined || fileFullName== null || fileFullName== ''){
      return fileFullName;
    }

    var startIndex = fileFullName.indexOf('amazonaws.com/');
    if(startIndex>-1) {
      fileName = fileFullName.substring(startIndex+14);

      if(fileName.indexOf( environment.s3.Bucket + '/' ) === 0){
        fileName = fileName.substring(environment.s3.Bucket.length + 1);
      }

    }
    else {
      return fileFullName;
    }


    var existsSign = this.s3store.signedUrlList.filter(x=> x.name === fileName && x.expires.getTime() > new Date().getTime() );
    
    // console.log('existsSign');
    // console.log(existsSign);    

    if(existsSign.length > 0) {
      
      // console.log('existsSign');
      // console.log(existsSign[0].value);    

      return existsSign[0].value;

    }
    else {

        const paramsSign = {
        Bucket: environment.s3.Bucket,
        Key: fileName,
        Expires: 60*environment.s3.SignedUrlExpires 
        };
    
        var signedUrl = bucket.getSignedUrl('getObject',paramsSign);
    
        // console.log('signedUrl');
        // console.log(signedUrl);    
    
        this.s3store.signedUrlList.push(new S3SignedUrl({name:fileName , value: signedUrl, expires: new Date( new Date().getTime() + (environment.s3.SignedUrlExpires*60*1000) ) })) ;

        // console.log('signedUrlList');
        // console.log(this.store.s3.signedUrlList);    

        return signedUrl;        
    }


  }

  
  @action('CLEAN SEARCH LISTINGS RESULT')
  public cleanListingsResults(): void {
    this.listingResults = [];
    this.filter.searchterm = '';
  }

  @action('HOVER LISTING')
  public hoverListing(placeId: string): void {
    // console.log('HOVER LISTING');
    this.listingResults.forEach(x => {
      if (placeId === x.googlePlaceId) {
        x.active = false;
      } else {
        x.active = true;
      }
    });

    // this.unHoverListings();
    // this.listingResults
    //   .find(x => x.googlePlaceId === placeId)
    //   .active = false;
    // for (let index = 0; index < this.listingsResultsNumber; index++) {
    //   const element = this.listingResults[index];
    //   if (element.googlePlaceId !== placeId) {
    //     element.active = true;
    //   } else {
    //     element.active = false;
    //   }
    // }
  }

  @action('UNHOVER LISTINGS')
  public unHoverListings(): void {

  }

  @action('UPDATE FILTER')
  public updateFilter(filter: Filter): void {

    if (filter.distance === null) {
      filter.distance = this.filter.distance
    }

    if (filter.latitude === null) {
      filter.latitude = this.filter.latitude
    }

    if (filter.longitude === null) {
      filter.longitude = this.filter.longitude
    }

    if (filter.open === null) {
      filter.open = this.filter.open
    }


    if (filter.price === null) {
      filter.price= this.filter.price
    }


    if (filter.rating === null) {
      filter.rating = this.filter.rating
    }


    if (filter.searchterm === null) {
      filter.searchterm = this.filter.searchterm
    }

    if (filter.order === null) {
      filter.order = this.filter.order
    }

    if (
      this.filter.searchterm === filter.searchterm &&
      this.filter.distance === filter.distance &&
      this.filter.latitude === filter.latitude &&
      this.filter.longitude === filter.longitude &&
      this.filter.open === filter.open &&
      this.filter.price === filter.price &&
      this.filter.rating === filter.rating &&
      this.filter.order === filter.order
    ) { return; }
    // this.startLoading();
    

    this.filter = Object.assign(this.filter, filter);
     // console.log(this.isLoading, 'loading...')
  }

  @action('ORDER SEARCH RESULT')
  public orderListingResults(prop: string, order: string): void {
    this.orderResultsBy = { prop, order };

    this.searchListings(this.listingResults);
  }

  @action('[SEARCH][LOADING] START')
  public startLoading(): void {
    this.isLoading = true;
    // console.log('loading...', this.isLoading);
  }

  @action('[SEARCH][LOADING] STOP')
  public stopLoading(): void {
    this.isLoading = false;
    // console.log('stop loading', this.isLoading)
  }

  @action('[SEARH][PAGE:LIMIT] Update')
  public updatePageLimit(pageLimit: number): void {
    this.pageLimit = pageLimit;
    this.updatePaginationArrows();
  }

  @action('[SEARH][PAGE] Update')
  public updatePage(page: number): void {
    this.page = page;
    this.updatePaginationArrows();
  }

  @action('[SEARCH][PAGINATION] Update Arrows')
  public updatePaginationArrows(): void {



    if (this.page === this.pageLimit || this.pageResultsCount < this.page * 10) {
      this.isPageForward = false;
    }
    if ((this.page - 1) === 0 || (this.page - 1) < 0) {
      this.isPagePrevious = false;
    }

    if (this.page < this.pageLimit  && this.page > 1) {
      this.isPagePrevious = true;
    }

    if (this.page < this.pageLimit ) {
      this.isPageForward = true;
    }

    if (this.pageLimit > 1 && (this.page - 1) !== 0) {
      this.isPagePrevious = true;
    }


  }

  @action('[SEARCH][PAGE:COUNT] Update Results Count')
  public updateResultsCount(count: number): void {
    this.pageResultsCount = count;
  }

  @action('[SEARCH][PAGE] Back')
  public previousPage(): void {
    if (this.isPagePrevious) {
      this.page = this.page - 1;
    }
    this.isPagePrevious = false;
  }

  @action('[SEARCH][PAGE] Forward')
  public forwardPage(): void {
    if (this.isPageForward) {
      this.page = this.page + 1;
    }
    this.isPageForward = false;
  }

  @action('[SEARCH][PAGE] Reset Pagination')
  public resetPagination(): void {
    this.page = 1;
    this.updatePaginationArrows();
  }

}
