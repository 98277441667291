import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { GoogleReview } from '../../models/GoogleReview';
import { BaseRestService } from './base.service';
import { GoogleReviewDTO } from '../../models/GoogleReviewDTO';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class GoogleReviewService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get all Reviews
    *   @return An array of Review objects 
    */
    public Get(): Observable<RG<GoogleReviewDTO[]>> {
        return this.get(`api/v1/allgooglereviews`);
    }


    /** 
    *   Get Reviews by paging
    *   @return An array of Review objects 
    */
   public GetByPage(videoReviewStatus: number,pageSize: number,pageNumber: number): Observable<RG<GoogleReviewDTO[]>> {
    return this.get(`api/v1/allgooglereviews/${videoReviewStatus}/${pageSize}/${pageNumber}`);
}




    // /** 
    // *   Get review by ID
    // *   @return An array of Review objects 
    // *   @param id - The ID of the Review  (int)
    // */
    public GetById(id: number): Observable<RG<GoogleReview>> {
        return this.get(`api/v1/googlereviews?id=${id}`);
    }




    // /** 
    // *   Get all reviews for a listing.
    // *   @return An array of Review objects 
    // *   @param listingid - The Listing ID of the Review (int)
    // */
    public GetByPlaceId(place_id: string , page: number  = 0): Observable<RG<GoogleReview[]>> {
        return this.get(`api/v1/googlereviews/place?place_id=${place_id}&page=${page || 0}`);
    }




    
    // /** 
    // *   Get all reviews a client posted
    // *   @return An array of Review objects 
    // *   @param clientid - The Client ID of the Review (int)
    // */
    public GetByClientId(clientid: number  = 0, page: number  = 0): Observable<RG<GoogleReview[]>> {
        return this.get(`api/v1/googlereviews/client?clientid=${clientid || 0}&page=${page || 0}`);
    }





    /** 
    *   Create a review
    *   @return A Review object 
    *   @param model - A Review object
    */
    public Post(model: GoogleReview ): Observable<RG<GoogleReview>> {
        
        return this.post( `api/v1/googlereviews`, JSON.stringify(model) );
    }

    /** 
    *   Update a review
    *   @return A Review object 
    *   @param model - A Review object
    */
    public Put(model: GoogleReview ): Observable<RG<GoogleReview>> {
        
        return this.put(`api/v1/googlereviews`, JSON.stringify(model) );
    }

    /** 
    *   Update a review for VideoReviewStatus
    *   @return A Review object 
    *   @param model - A Review object
    */
   public UpdateVideoReviewStatus(model: GoogleReview ): Observable<RG<GoogleReview>> {
    
    return this.put(`api/v1/googlereviews/videoreview`, JSON.stringify(model) );
}



    /** 
    *   Delete a review
    *   @return A empty review. 
    *   @param id - The ID of the review. (int)
    */
    public Delete(id: number ): Observable<RG<GoogleReview>> {
        
        return this.delete( `api/v1/googlereviews?id=${id}`);
    }
}

