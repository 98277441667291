import { Component, forwardRef, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NG_VALIDATORS } from '@angular/forms';
import { hoursdata } from '../../viewmodels';
@Component({
  selector: 'mw-formcontrol',
  templateUrl: './mw-formcontrol.component.html',
  styleUrls: ['./mw-formcontrol.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MwFormControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MwFormControlComponent),
      multi: true
    }
  ]
})
export class MwFormControlComponent implements ControlValueAccessor, OnChanges, OnInit {

  @Input()
  public type = 'text';

  @Input()
  public list: Array<{ value, name }>;

  @Input()
  public label: string;

  @Input()
  public description: string;

  @Input()
  public placeholder: string;

  @Input()
  public value: any;

  @Input()
  public disabled = false;

  public validation: any;

  @Input()
  public formDirty = false;

  @Output()
  public changes = new EventEmitter();

  @Output()
  public keypress = new EventEmitter<KeyboardEvent>();

  public hourlist = hoursdata;

  public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,4}$';

  public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public zipmask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];

  public datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  public expirydatemask = [/\d/, /\d/, '/', /\d/, /\d/];

  public americaExpress = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/,
    '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];

  public discover = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/,
    '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];

  public masterCard = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/,
    '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];

  public visa = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/,
    '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, '-',  /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];

  public cvv = [ /[1-9]/, /[1-9]/, /[1-9]/];

  public ngOnInit(): void {
    this.placeholder = this.placeholder || this.label || '';
  }

  public ngOnChanges(): void {
    this.propagateChange(this.value);
  }

  public change(value: any): void {
    this.value = value;
    this.formDirty = true;
    this.propagateChange(value);
    this.changes.emit(value);
  }

  public writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  public validate(c: FormControl) {
    this.validation = c.errors;
    setTimeout(() => this.validation = c.errors, 100);
    return this.validateFn(c);
  }

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
  }

  public propagateChange = (_: any) => { }

  public validateFn: any = () => { };

}
