
import { Gender } from './Gender';
import { ProfessionalSkill } from './ProfessionalSkill';
import { Address } from './Address';
import { ClientPlace } from './ClientPlace';
import { RelationshipStatus } from './RelationshipStatus';
import { ClientRelationshipClient } from './ClientRelationshipClient';
import { Listing } from './Listing';
import { ListingReview } from './ListingReview';
import { GoogleReview } from './GoogleReview';
import { ReviewReply } from './ReviewReply';
import { User, IUser, IFUser } from './User';

export interface IClient extends IUser {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    birthDate?: Date;
    gender?: Gender;
    phone?: string;
    mobilePhone?: string;
    webSite?: string;
    aboutMe?: string;
    workTitle?: string;
    workName?: string;
    professionalSkills?: ProfessionalSkill[];
    homeTown?: string;
    currentCity?: string;
    address?: Address;
    addressId?: number;
    places?: ClientPlace[];
    relationshipStatus?: RelationshipStatus;
    relationships?: ClientRelationshipClient[];
    relationshipsSecond?: ClientRelationshipClient[];
    listings?: Listing[];
    listingReviews?: ListingReview[];
    googleReviews?: GoogleReview[];
    reviewReplies?: ReviewReply[];
    needChangePwd?: boolean;
    fullName?: string;
    signedAvatar?: string;
}
export class Client extends User implements IClient {
    public avatar: string;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public birthDate: Date;
    public gender: Gender;
    public phone: string;
    public mobilePhone: string;
    public webSite: string;
    public aboutMe: string;
    public workTitle: string;
    public workName: string;
    public professionalSkills: ProfessionalSkill[];
    public homeTown: string;
    public currentCity: string;
    public address: Address;
    public addressId: number;
    public places: ClientPlace[];
    public relationshipStatus: RelationshipStatus;
    public relationships: ClientRelationshipClient[];
    public relationshipsSecond: ClientRelationshipClient[];
    public listings: Listing[];
    public listingReviews: ListingReview[];
    public googleReviews: GoogleReview[];
    public reviewReplies: ReviewReply[];
    public needChangePwd: boolean;
    public fullName?: string;
    public signedAvatar?: string;

    constructor(obj?: IClient) {
        super(obj);
        this.avatar = obj.avatar !== undefined ? obj.avatar : null;
        this.firstName = obj.firstName !== undefined ? obj.firstName : null;
        this.lastName = obj.lastName !== undefined ? obj.lastName : null;
        this.middleName = obj.middleName !== undefined ? obj.middleName : null;
        this.birthDate = obj.birthDate !== undefined ? obj.birthDate : null;
        this.gender = obj.gender !== undefined ? obj.gender : null;
        this.phone = obj.phone !== undefined ? obj.phone : null;
        this.mobilePhone = obj.mobilePhone !== undefined ? obj.mobilePhone : null;
        this.webSite = obj.webSite !== undefined ? obj.webSite : null;
        this.aboutMe = obj.aboutMe !== undefined ? obj.aboutMe : null;
        this.workTitle = obj.workTitle !== undefined ? obj.workTitle : null;
        this.workName = obj.workName !== undefined ? obj.workName : null;
        this.professionalSkills = obj.professionalSkills !== undefined ? obj.professionalSkills : null;
        this.homeTown = obj.homeTown !== undefined ? obj.homeTown : null;
        this.currentCity = obj.currentCity !== undefined ? obj.currentCity : null;
        this.address = obj.address !== undefined ? obj.address : null;
        this.addressId = obj.addressId !== undefined ? obj.addressId : null;
        this.places = obj.places !== undefined ? obj.places : null;
        this.relationshipStatus = obj.relationshipStatus !== undefined ? obj.relationshipStatus : null;
        this.relationships = obj.relationships !== undefined ? obj.relationships : null;
        this.relationshipsSecond = obj.relationshipsSecond !== undefined ? obj.relationshipsSecond : null;
        this.listings = obj.listings !== undefined ? obj.listings : null;
        this.listingReviews = obj.listingReviews !== undefined ? obj.listingReviews : null;
        this.googleReviews = obj.googleReviews !== undefined ? obj.googleReviews : null;
        this.reviewReplies = obj.reviewReplies !== undefined ? obj.reviewReplies : null;
        this.needChangePwd = obj.needChangePwd != undefined ? obj.needChangePwd : null;
        this.fullName = obj.fullName !== undefined ? obj.fullName : null;
        this.signedAvatar = obj.signedAvatar !== undefined ? obj.signedAvatar : null;
    }
}
// For reactive forms intended
export interface IFClient extends IFUser {
    /**
    * Is string
    */
    avatar?: any;
    /**
    * Is string
    */
    firstName?: any;
    /**
    * Is string
    */
    lastName?: any;
    /**
    * Is string
    */
    middleName?: any;
    /**
    * Is Date
    */
    birthDate?: any;
    /**
    * Is Gender
    */
    gender?: any;
    /**
    * Is string
    */
    phone?: any;
    /**
    * Is string
    */
    mobilePhone?: any;
    /**
    * Is string
    */
    webSite?: any;
    /**
    * Is string
    */
    aboutMe?: any;
    /**
    * Is string
    */
    workTitle?: any;
    /**
    * Is string
    */
    workName?: any;
    /**
    * Is ProfessionalSkill[]
    */
    professionalSkills?: any;
    /**
    * Is string
    */
    homeTown?: any;
    /**
    * Is string
    */
    currentCity?: any;
    /**
    * Is Address
    */
    address?: any;
    /**
    * Is number
    */
    addressId?: any;
    /**
    * Is ClientPlace[]
    */
    places?: any;
    /**
    * Is RelationshipStatus
    */
    relationshipStatus?: any;
    /**
    * Is ClientRelationshipClient[]
    */
    relationships?: any;
    /**
    * Is ClientRelationshipClient[]
    */
    relationshipsSecond?: any;
    /**
    * Is Listing[]
    */
    listings?: any;
    /**
    * Is ListingReview[]
    */
    listingReviews?: any;
    /**
    * Is GoogleReview[]
    */
    googleReviews?: any;
    /**
    * Is ReviewReply[]
    */
    reviewReplies?: any;
    needChangePwd?: any;
    fullName?: any;
    signedAvatar?: any;
}
