
import { UserLogIn } from './UserLogIn';


export interface IUser  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    username?: string;
    email?: string;
    isEmailVerified?: boolean;
    password?: string;
    isTemporalPassword?: boolean;
    logIns?: UserLogIn[];
    role?: number;
}
export class User  implements IUser {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public username: string;
    public email: string;
    public isEmailVerified: boolean;
    public password: string;
    public isTemporalPassword: boolean;
    public logIns: UserLogIn[];
    public role: number;
    constructor(obj?: IUser) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.username = obj.username !== undefined ? obj.username : null;
        this.email = obj.email !== undefined ? obj.email : null;
        this.isEmailVerified = obj.isEmailVerified !== undefined ? obj.isEmailVerified : null;
        this.password = obj.password !== undefined ? obj.password : null;
        this.isTemporalPassword = obj.isTemporalPassword !== undefined ? obj.isTemporalPassword : null;
        this.logIns = obj.logIns !== undefined ? obj.logIns : null;
        this.role = obj.role !== undefined ? obj.role : null;
    }
}
// For reactive forms intended
export interface IFUser  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    username?: any; 
    /**
    * Is string
    */
    email?: any; 
    /**
    * Is boolean
    */
    isEmailVerified?: any; 
    /**
    * Is string
    */
    password?: any; 
    /**
    * Is boolean
    */
    isTemporalPassword?: any; 
    /**
    * Is UserLogIn[]
    */
    logIns?: any; 
    role? : any;
}
