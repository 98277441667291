
import { BusinessAddress } from './BusinessAddress';
import { ListingContact } from './ListingContact';
import { Payments } from './Payments';
import { ListingConfigs } from './ListingConfigs';
import { CategoryListing } from './CategoryListing';
import { MasterCategoryListing } from './MasterCategoryListing';
import { ListingTime } from './ListingTime';
import { ListingReview } from './ListingReview';
import { ListingServices } from './ListingServices';
import { ListingPhoto } from './ListingPhoto';
import { Client } from './Client';
import { Menus } from './Menus';
import { MasterCategoryRequest } from './MasterCategoryRequest';
import { SpecialHour } from './SpecialHour';

export interface IListing  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    googlePlaceId?: string;
    name?: string;
    displayName?: string;
    avatar?: string;
    icon?: string;
    businessAddress?: BusinessAddress;
    businessAddressId?: number;
    introduction?: string;
    contact?: ListingContact;
    contactId?: number;
    payments?: Payments;
    paymentsId?: number;
    listingConfigs?: ListingConfigs;
    listingConfigsId?: number;
    categories?: CategoryListing[];
    masterCategories?: MasterCategoryListing[];
    masterCategoryRequest?: MasterCategoryRequest;
    // masterCategoryRequest?: MasterCategoryListing[];
    listingHours?: ListingTime[];
    reviews?: ListingReview[];
    services?: ListingServices[];
    photos?: ListingPhoto[];
    menus?: Menus[];
    overallStars?: number;
    price?: number;
    monthlyPrice?: number;
    rankingOrder?: number;
    openNow?: boolean;
    isVerified?: boolean;
    owner?: Client;
    ownerId?: number;
    useremailByAgent?: string;
    isEGumballCustomer?: boolean;
    agentId?: number;
    specialHours?: SpecialHour[];
    signedAvatar?: string;
    permanentlyClosed?: boolean;
}
export class Listing  implements IListing {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public googlePlaceId: string;
    public name: string;
    public displayName: string;
    public avatar: string;
    public icon: string;
    public businessAddress: BusinessAddress;
    public businessAddressId: number;
    public introduction: string;
    public contact: ListingContact;
    public contactId: number;
    public payments: Payments;
    public paymentsId: number;
    public listingConfigs: ListingConfigs;
    public listingConfigsId: number;
    public categories: CategoryListing[];
    public masterCategories: MasterCategoryListing[];
    public masterCategoryRequest: MasterCategoryRequest;
    // public masterCategoryRequest: MasterCategoryListing[];
    public listingHours: ListingTime[];
    public reviews: ListingReview[];
    public services: ListingServices[];
    public photos: ListingPhoto[];
    public menus: Menus[];
    public overallStars: number;
    public price: number;
    public monthlyPrice: number;
    public rankingOrder: number;
    public openNow: boolean;
    public isVerified: boolean;
    public owner: Client;
    public ownerId: number;

    // sean
    public useremailByAgent: string;
    public specialHours: SpecialHour[];
    public permanentlyClosed: boolean;

    public isEGumballCustomer: boolean;

    public agentId?: number;
    public signedAvatar: string;

    constructor(obj?: IListing) {

        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.googlePlaceId = obj.googlePlaceId !== undefined ? obj.googlePlaceId : null;
        this.name = obj.name !== undefined ? obj.name : null;
        this.displayName = obj.displayName !== undefined ? obj.displayName : null;
        this.avatar = obj.avatar !== undefined ? obj.avatar : null;
        this.icon = obj.icon !== undefined ? obj.icon : null;
        this.businessAddress = obj.businessAddress !== undefined ? obj.businessAddress : null;
        this.businessAddressId = obj.businessAddressId !== undefined ? obj.businessAddressId : null;
        this.introduction = obj.introduction !== undefined ? obj.introduction : null;
        this.contact = obj.contact !== undefined ? obj.contact : null;
        this.contactId = obj.contactId !== undefined ? obj.contactId : null;
        this.payments = obj.payments !== undefined ? obj.payments : null;
        this.paymentsId = obj.paymentsId !== undefined ? obj.paymentsId : null;
        this.listingConfigs = obj.listingConfigs !== undefined ? obj.listingConfigs : null;
        this.listingConfigsId = obj.listingConfigsId !== undefined ? obj.listingConfigsId : null;
        this.categories = obj.categories !== undefined ? obj.categories : null;
        this.masterCategories = obj.masterCategories !== undefined ? obj.masterCategories : null;
        this.masterCategoryRequest = obj.masterCategoryRequest !== undefined ? obj.masterCategoryRequest : null;
        this.listingHours = obj.listingHours !== undefined ? obj.listingHours : null;
        this.reviews = obj.reviews !== undefined ? obj.reviews : null;
        this.services = obj.services !== undefined ? obj.services : null;
        this.photos = obj.photos !== undefined ? obj.photos : null;
        this.menus = obj.menus !== undefined ? obj.menus : null;
        this.overallStars = obj.overallStars !== undefined ? obj.overallStars : null;
        this.price = obj.price !== undefined ? obj.price : null;
        this.monthlyPrice = obj.monthlyPrice !== undefined ? obj.monthlyPrice : null;
        this.rankingOrder = obj.rankingOrder !== undefined ? obj.rankingOrder : null;
        this.openNow = obj.openNow !== undefined ? obj.openNow : null;
        this.isVerified = obj.isVerified !== undefined ? obj.isVerified : null;
        this.owner = obj.owner !== undefined ? obj.owner : null;
        this.ownerId = obj.ownerId !== undefined ? obj.ownerId : null;
        this.useremailByAgent = obj.useremailByAgent !== undefined ? obj.useremailByAgent : null;
        this.isEGumballCustomer = obj.isEGumballCustomer !== undefined ? obj.isEGumballCustomer : null;
        this.agentId = obj.agentId !== undefined ? obj.agentId : null;
        this.specialHours = obj.specialHours !== undefined ? obj.specialHours : null;
        this.signedAvatar = obj.signedAvatar !== undefined ? obj.signedAvatar : null;
        this.permanentlyClosed = obj.permanentlyClosed !== undefined ? obj.permanentlyClosed : null;
    }
}
// For reactive forms intended
export interface IFListing  {
    /**
    * Is number
    */
    id?: any;
    /**
    * Is boolean
    */
    active?: any;
    /**
    * Is Date
    */
    addedDate?: any;
    /**
    * Is string
    */
    googlePlaceId?: any;
    /**
    * Is string
    */
    name?: any;
    /**
    * Is string
    */
    displayName?: any;
    /**
    * Is string
    */
    avatar?: any;
    /**
    * Is string
    */
    icon?: any;
    /**
    * Is BusinessAddress
    */
    businessAddress?: any;
    /**
    * Is number
    */
    businessAddressId?: any;
    /**
    * Is string
    */
    introduction?: any;
    /**
    * Is ListingContact
    */
    contact?: any;
    /**
    * Is number
    */
    contactId?: any;
    /**
    * Is Payments
    */
    payments?: any;
    /**
    * Is number
    */
    paymentsId?: any;
    /**
    * Is ListingConfigs
    */
    listingConfigs?: any;
    /**
    * Is number
    */
    listingConfigsId?: any;
    /**
    * Is CategoryListing[]
    */
    categories?: any;
    /**
    * Is MasterCategoryListing[]
    */
    masterCategories?: any;

    masterCategoryRequest?: any;
    /**
    * Is ListingTime[]
    */
    listingHours?: any;
    /**
    * Is ListingReview[]
    */
    reviews?: any;
    /**
    * Is ListingServices[]
    */
    services?: any;
    /**
    * Is ListingPhoto[]
    */
    photos?: any;
    /**
    * Is MenusObject[]
    */
    menus?: any;
    /**
    * Is number
    */
    overallStars?: any;
    /**
    * Is number
    */
    price?: any;
    /**
    * Is boolean
    */
    monthlyPrice?: any;
    /**
    * Is number
    */
    rankingOrder?: any;
    /**
    * Is number
    */
    openNow?: any;
    /**
    * Is boolean
    */
    isVerified?: any;
    /**
    * Is Client
    */
    owner?: any;
    /**
    * Is number
    */
    ownerId?: any;

    useremailByAgent?: any;

    isEGumballCustomer?: boolean;
    agentId?: number;
    specialHours?: any;
    signedAvatar?: string;
    permanentlyClosed?: any;
}
