import { Component, OnInit, Input, Inject } from '@angular/core';
import { IMwAlertViewModel } from './mw-alert.viewmodel';
import { MatDialog, MAT_SNACK_BAR_DATA, MatDialogRef, MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'mw-snack-bar',
  templateUrl: './mw-snack-bar.component.html',
  styleUrls:  ['./mw-snack-bar.component.scss']
})
export class MwSnackBarComponent implements OnInit {


  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IMwAlertViewModel) { 
  }

  ngOnInit() { }


}
