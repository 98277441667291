import { Injectable } from '@angular/core';
import { Observer, Observable } from 'rxjs';
import { Listing } from '../../../../shared/models';

@Injectable()
export class MwListingMapPreviewService {
  public listing$: Observable<any>;
  private _listing$Observer: Observer<Listing>;

  constructor() {
    this.listing$ = new Observable(observer => this._listing$Observer = observer).share();
  }

  public hoveredListing(listing: Listing) {
    if (this._listing$Observer) {
      this._listing$Observer.next(listing);
    }
  }

}
