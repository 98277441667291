import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MwClaimedProfileComponent } from './mw-claimed-profile.component';

const routes: Routes = [
    {
      path: 'listing/:displayname', component: MwClaimedProfileComponent
    }
  ];

  @NgModule({
    imports: [
        RouterModule.forRoot(routes)
      ],
    exports: [RouterModule],
  })

  export class MwClaimedProfileRoutingModule { }