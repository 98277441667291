import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { ListingReview } from '../../models/ListingReview';

import { ReviewReply } from '../../models/ReviewReply';
import { BaseRestService } from './base.service';
import { ThumbsReview } from '../../models/ThumbsReview';
import { ThumbsModel } from '../../models/ThumbsModel';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';



@Injectable()
export class ReviewsService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Get all Reviews
    *   @return An array of Review objects 
    */

    public Get(): Observable<RG<ListingReview[]>> {
        return this.get(`api/v1/allreviews`);
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/allreviews`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });
    }







    /** 
    *   Get review by ID
    *   @return An array of Review objects 
    *   @param id - The ID of the Review  (int)
    */
    public GetById(id: number ): Observable<RG<ListingReview>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/reviews?id=${id}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });
        return this.get(`api/v1/reviews?id=${id}`);
    }

    /** 
    *   Get all reviews for a listing.
    *   @return An array of Review objects 
    *   @param listingid - The Listing ID of the Review (int)
    */
    public GetByListingId(listingid: number , page: number  = 0): Observable<RG<ListingReview[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/reviews/listing?listingid=${listingid}&page=${page || 0}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/reviews/listing?listingid=${listingid}&page=${page || 0}`);
    }

    /** 
    *   Get all reviews a client posted
    *   @return An array of Review objects 
    *   @param clientid - The Client ID of the Review (int)
    */
    public GetByClientId(clientid: number  = 1, page: number  = 0): Observable<RG<ListingReview[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/reviews/client?clientid=${clientid || 1}&page=${page || 0}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/reviews/client?clientid=${clientid || 1}&page=${page || 0}`);
    }

    /** 
    *   Get all reviews a client posted to a listing
    *   @return An array of Review objects 
    *   @param clientid - The Client ID of the Review (int)
    *   @param listingid - The Listing ID of the Review (int)
    */
    public GetByClientIdnListingId(listingid: number , clientid: number  = 1, page: number  = 1): Observable<RG<ListingReview[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/reviews/listing/clientlist?listingid=${listingid}&clientid=${clientid || 1}&page=${page || 1}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/reviews/listing/clientlist?listingid=${listingid}&clientid=${clientid || 1}&page=${page || 1}`);
    }

    /** 
    *   Create a review
    *   @return A Review object 
    *   @param model - A Review object
    */
    public Post(model: ListingReview , placeId: string ): Observable<RG<ListingReview>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }

        // return this._http.post(environment.apiUrl + `api/v1/reviews?placeId=${placeId}`, JSON.stringify(model) , { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });
        
        return this.post(`api/v1/reviews?placeId=${placeId}`, JSON.stringify(model));
    }

    /** 
    *   Update a review
    *   @return A Review object 
    *   @param model - A Review object
    */
    public Put(model: ListingReview , placeId: string ): Observable<RG<ListingReview>> {
       
        return this.put(`api/v1/reviews?placeId=${placeId}`, JSON.stringify(model) );
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // debugger;
        // return this._http.put(environment.apiUrl + `api/v1/reviews?placeId=${placeId}`, JSON.stringify(model) , { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });
    }

    // public PutThumb(model: ListingReview , placeId: string ): Observable<RG<ListingReview>> {
    //     // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
    //     //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
    //     // }
    //     // debugger;
    //     // return this._http.put(environment.apiUrl + `api/v1/thumbreviews?placeId=${placeId}`, JSON.stringify(model) , { headers: this.headers })
    //     //     .map((resp: Response) => resp.json())
    //     //     .catch((error: any) => {
    //     //         return Observable.throw(error);
    //     //     });
    
    //     return this.put(`api/v1/thumbreviews?placeId=${placeId}`, JSON.stringify(model));
    // }


    public PostThumb(model: ThumbsReview ): Observable<RG<ThumbsModel>> {
        
        return this.post(`api/v1/thumbs`, JSON.stringify(model));
    }
    
    /** 
    *   Delete a review
    *   @return A empty review. 
    *   @param id - The ID of the review. (int)
    */
    public Delete(id: number , placeId: string ): Observable<RG<ListingReview>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.delete(environment.apiUrl + `api/v1/reviews?id=${id}&placeId=${placeId}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });
        return this.delete(`api/v1/reviews?id=${id}&placeId=${placeId}`);
    }

    /** 
    *   Get all replies to a review
    *   @return An array of review replies. 
    *   @param reviewid - The ID of the Review(-int)
    */
    public GetRepliesByListingReviewId(reviewid: number , page: number  = 1): Observable<RG<ReviewReply[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/replies/review?reviewid=${reviewid}&page=${page || 1}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/replies/review?reviewid=${reviewid}&page=${page || 1}`);
    }

    /** 
    *   Get all replies to a review by a specific client
    *   @return An array of review replies. 
    *   @param clientid - The ID of the Client
    */
    public GetReviewRepliesByClientId(clientid: number  = 0, page: number  = 1): Observable<RG<ReviewReply[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/replies?clientid=${clientid || 0}&page=${page || 1}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/replies?clientid=${clientid || 0}&page=${page || 1}`);
    }

    /** 
    *   Get all replies to a review by a specific client and Listing
    *   @return An array of review replies. 
    *   @param clientid - The ID of the Client
    *   @param listingid - The ID of the Listing
    */
    public GetReviewRepliesByListingIdnClientId(listingid: number , clientid: number  = 0, page: number  = 1): Observable<RG<ReviewReply[]>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.get(environment.apiUrl + `api/v1/replies/listing?listingid=${listingid}&clientid=${clientid || 0}&page=${page || 1}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.get(`api/v1/replies/listing?listingid=${listingid}&clientid=${clientid || 0}&page=${page || 1}`);
    }

    /** 
    *   Add a reply to a review
    *   @return A Reviewreply object 
    *   @param model - A Reviewreply object
    */
    public PostReviewReply(model: ReviewReply , placeId: string ): Observable<RG<ReviewReply>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.post(environment.apiUrl + `api/v1/reply?placeId=${placeId}`, JSON.stringify(model) , { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.post(`api/v1/reply?placeId=${placeId}`, JSON.stringify(model));
    }

    /** 
    *   Update a reply to a review
    *   @return A Reviewreply object 
    *   @param model - A Reviewreply object
    */
    public PutReviewReply(model: ReviewReply , placeId: string ): Observable<RG<ReviewReply>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.put(environment.apiUrl + `api/v1/reply?placeId=${placeId}`, JSON.stringify(model) , { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.put(`api/v1/reply?placeId=${placeId}`, JSON.stringify(model));
    }

    /** 
    *   Delete a reply to a review
    *   @return A empty Reviewreply object 
    *   @param id - The ID of the reply (int)
    */
    public DeleteReviewReply(id: number , isGoogleReply: boolean ): Observable<RG<ReviewReply>> {
        // if (JSON.parse(localStorage.getItem('authToken')) !== null) {
        //     this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('authToken')).access_token });    
        // }
        // return this._http.delete(environment.apiUrl + `api/v1/reply?id=${id}&isGoogleReply=${isGoogleReply}`, { headers: this.headers })
        //     .map((resp: Response) => resp.json())
        //     .catch((error: any) => {
        //         return Observable.throw(error);
        //     });

        return this.delete(`api/v1/reply?id=${id}&isGoogleReply=${isGoogleReply}`);
    }
}

