
import { ReplyTypes } from './ReplyTYpes';
import { Client } from './Client';
import { ListingReview } from './ListingReview';

export interface IReviewReply  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    message?: string;
    replyType?: ReplyTypes;
    client?: Client;
    clientId?: number;
    listingReview?: ListingReview;
    listingReviewId?: number;
    thumbStatus?: boolean;
    addedDateText?: string;
}
export class ReviewReply  implements IReviewReply {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public message: string;
    public replyType: ReplyTypes;
    public client: Client;
    public clientId: number;
    public listingReview: ListingReview;
    public listingReviewId: number;
    public thumbStatus: boolean;
    public addedDateText?: string;
    
    constructor(obj?: IReviewReply) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.message = obj.message !== undefined ? obj.message : null;
        this.replyType = obj.replyType !== undefined ? obj.replyType : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
        this.listingReview = obj.listingReview !== undefined ? obj.listingReview : null;
        this.listingReviewId = obj.listingReviewId !== undefined ? obj.listingReviewId : null;
        this.thumbStatus = obj.thumbStatus !== undefined ? obj.thumbStatus : null;
        this.addedDateText = obj.addedDateText !== undefined ? obj.addedDateText : null;        
    }
}
// For reactive forms intended
export interface IFReviewReply  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    message?: any; 
    /**
    * Is ReplyTypes
    */
    replyType?: any; 
    /**
    * Is Client
    */
    client?: any; 
    /**
    * Is number
    */
    clientId?: any; 
    /**
    * Is ListingReview
    */
    listingReview?: any; 
    /**
    * Is number
    */
    listingReviewId?: any; 
    thumbStatus?: any;
    addedDateText?:any;
}
