


export interface IListingContact  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    mobileNumber?: string;
    phoneNumber?: string;
    website?: string;
    tollFreeNumber?: string;
    faxNumber?: string;
    publicEmail?: string;
    contactName?: string;
}
export class ListingContact  implements IListingContact {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public mobileNumber: string;
    public phoneNumber: string;
    public website: string;
    public tollFreeNumber: string;
    public faxNumber: string;
    public publicEmail: string;
    public contactName: string;
    constructor(obj?: IListingContact) {

        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.mobileNumber = obj.mobileNumber !== undefined ? obj.mobileNumber : null;
        this.phoneNumber = obj.phoneNumber !== undefined ? obj.phoneNumber : null;
        this.website = obj.website !== undefined ? obj.website : null;
        this.tollFreeNumber = obj.tollFreeNumber !== undefined ? obj.tollFreeNumber : null;
        this.faxNumber = obj.faxNumber !== undefined ? obj.faxNumber : null;
        this.publicEmail = obj.publicEmail !== undefined ? obj.publicEmail : null;
        this.contactName = obj.contactName !== undefined ? obj.contactName : null;
    }
}
// For reactive forms intended
export interface IFListingContact  {
    /**
    * Is number
    */
    id?: any;
    /**
    * Is boolean
    */
    active?: any;
    /**
    * Is Date
    */
    addedDate?: any;
    /**
    * Is string
    */
    mobileNumber?: any;
    /**
    * Is string
    */
    phoneNumber?: any;
    /**
    * Is string
    */
    website?: any;
    /**
    * Is string
    */
    tollFreeNumber?: any;
    /**
    * Is string
    */
    faxNumber?: any;
    /**
    * Is string
    */
    publicEmail?: any;
    /**
    * Is string
    */
    contactName?: any;
}
