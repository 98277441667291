import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mw-fcontrol',
  templateUrl: './mw-fcontrol.component.html',
  styleUrls: ['./mw-fcontrol.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MwFControlComponent implements OnInit, OnChanges {

  @Input()
  public name: string;

  @Input()
  public legend: string;

  @Input()
  public select: boolean;

  @Input()
  public errors: { errors: { required, minlength }, dirty } = { dirty: false, errors: { required: null, minlength: null } };
  public maxLength: { errors: { required, maxlength }, dirty } = { dirty: false, errors: { required: null, maxlength: null } };

  constructor() { }
  // Text
  ngOnInit() {

  }

  ngOnChanges(change: SimpleChanges) {
    try {
      // console.log(change['errors'].currentValue);
      this.errors.dirty = change['errors'].currentValue.dirty;
      this.errors.errors.required = change['errors'].currentValue.errors.required || false;
      this.errors.errors.minlength = change['errors'].currentValue.errors.minlength || null;
      this.maxLength.errors.maxlength = change['errors'].currentValue.errors.maxlength || null;
    } catch (error) {
      this.errors.dirty = false;
      this.errors.errors = { required: null, minlength: null};
      this.maxLength.errors = { required: null, maxlength: null};
    }
  }

}
