import { Listing } from './Listing';
import { MasterCategory } from './MasterCategory';
import { MasterCategoryRequest } from './MasterCategoryRequest';

export interface IMasterCategoryListing {
  id?: number;
  active?: boolean;
  addedDate?: Date;
  listing?: Listing;
  listingId?: number;
  masterCategory?: MasterCategory;
  masterCategoryId?: number;
  masterCategoryRequest?: MasterCategoryRequest;
  mcrId?: number;
}
export class MasterCategoryListing implements IMasterCategoryListing {
  public id: number;
  public active: boolean;
  public addedDate: Date;
  public listing: Listing;
  public listingId: number;
  public masterCategory: MasterCategory;
  public masterCategoryId: number;
  public masterCategoryRequest: MasterCategoryRequest;
  public mcrId: number;

  constructor(obj?: IMasterCategoryListing) {
    this.id = obj.id !== undefined ? obj.id : null;
    this.active = obj.active !== undefined ? obj.active : null;
    this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
    this.listing = obj.listing !== undefined ? obj.listing : null;
    this.listingId = obj.listingId !== undefined ? obj.listingId : null;
    this.masterCategory =
      obj.masterCategory !== undefined ? obj.masterCategory : null;
    this.masterCategoryId =
      obj.masterCategoryId !== undefined ? obj.masterCategoryId : null;
    this.masterCategoryRequest =
      obj.masterCategoryRequest !== undefined
        ? obj.masterCategoryRequest
        : null;
    this.mcrId = obj.mcrId !== undefined ? obj.mcrId : null;
  }
}
// For reactive forms intended
export interface IFMasterCategoryListing {
  /**
   * Is number
   */
  id?: any;
  /**
   * Is boolean
   */
  active?: any;
  /**
   * Is Date
   */
  addedDate?: any;
  /**
   * Is Listing
   */
  listing?: any;
  /**
   * Is number
   */
  listingId?: any;
  /**
   * Is MasterCategory
   */
  masterCategory?: any;
  /**
   * Is number
   */
  masterCategoryId?: any;

  masterCategoryRequest?: any;

  mcrId?: any;
}
