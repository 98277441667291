
import { DayNames } from './DayNames';
import { Listing } from './Listing';

export interface IListingTime {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    dayName?: DayNames;
    // start?: Date;
    // end?: Date;
    start?: string;
    end?: string;
    isSpecialHour?: boolean;
    closed?: boolean;
    specialDate?: Date;
    specialDateName?: string;
    listing?: Listing;
    listingId?: number;
    isSameDayName?: boolean;
}
export class ListingTime implements IListingTime {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public dayName: DayNames;
    // public start: Date;
    // public end: Date;
    public start: string;
    public end: string;
    public isSpecialHour: boolean;
    public closed: boolean;
    public specialDate: Date;
    public specialDateName: string;
    public listing: Listing;
    public listingId: number;
    public isSameDayName: boolean;

    constructor(obj?: IListingTime) {

        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.dayName = obj.dayName !== undefined ? obj.dayName : null;
        this.start = obj.start !== undefined ? obj.start : null;
        this.end = obj.end !== undefined ? obj.end : null;
        this.isSpecialHour = obj.isSpecialHour !== undefined ? obj.isSpecialHour : null;
        this.closed = obj.closed !== undefined ? obj.closed : null;
        this.specialDate = obj.specialDate !== undefined ? obj.specialDate : null;
        this.specialDateName = obj.specialDateName !== undefined ? obj.specialDateName : null;
        this.listing = obj.listing !== undefined ? obj.listing : null;
        this.listingId = obj.listingId !== undefined ? obj.listingId : null;
        this.isSameDayName = obj.isSameDayName !== undefined ? obj.isSameDayName : null;
    }
}
// For reactive forms intended
export interface IFListingTime {
    /**
    * Is number
    */
    id?: any;
    /**
    * Is boolean
    */
    active?: any;
    /**
    * Is Date
    */
    addedDate?: any;
    /**
    * Is DayNames
    */
    dayName?: any;
    /**
    * Is Date
    */
    start?: any;
    /**
    * Is Date
    */
    end?: any;
    /**
    * Is boolean
    */
    isSpecialHour?: any;
    /**
    * Is boolean
    */
    closed?: any;
    /**
    * Is Date
    */
    specialDate?: any;
    /**
    * Is string
    */
    specialDateName?: any;
    /**
    * Is Listing
    */
    listing?: any;
    /**
    * Is number
    */
    listingId?: any;

    isSameDayName?: any;
}
