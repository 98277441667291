export * from './mw-user-places/mw-user-places.component';
export * from './mw-user-profile/mw-user-profile.component';
export * from './mw-user-relationships/mw-user-relationships.component';
export * from './mw-user-work-education/mw-user-work-education.component';
export * from './mw-user.component';

import { MwUserPlacesComponent } from './mw-user-places/mw-user-places.component';
import { MwUserProfileComponent } from './mw-user-profile/mw-user-profile.component';
import { MwUserRelationshipsComponent } from './mw-user-relationships/mw-user-relationships.component';
import { UserWorkEducationComponent } from './mw-user-work-education/mw-user-work-education.component';
import { MwUserComponent } from './mw-user.component';
import { MwUserSecurityComponent } from './mw-user-security/mw-user-security.component';

export const userComponents = [
  MwUserSecurityComponent,
  MwUserPlacesComponent,
  // MwUserProfileComponent,
  MwUserRelationshipsComponent,
  UserWorkEducationComponent,
  MwUserComponent
];
