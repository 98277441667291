import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';
import { QuickSearchObject } from '../../models/QuickSearchObject';
import { BaseRestService } from './base.service';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class QuickSearchService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

    /** 
    *   Gets all the listings
    *   @return Listings 
    */
    public Get(): Observable<RG<QuickSearchObject[]>> {
        
        return this.get(`api/v1/quickSearch`);
    }    
}

