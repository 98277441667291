import { Listing } from "./Listing";
import { Client } from "./Client";

export interface IListingPhoto {
  id?: number;
  active?: boolean;
  addedDate?: Date;
  imageUrl?: string;
  description?: string;
  listing?: Listing;
  listingId?: number;
  client?: Client;
  clientId?: number;
  googleReviewId?: number;
  listReviewId?: number;  
}
export class ListingPhoto implements IListingPhoto {
  public id: number;
  public active: boolean;
  public addedDate: Date;
  public imageUrl: string;
  public description: string;
  public listing: Listing;
  public listingId: number;
  public client: Client;
  public clientId: number;
  public googleReviewId?: number;
  public listReviewId?: number;
  constructor(obj?: IListingPhoto) {
    this.id = obj.id !== undefined ? obj.id : null;
    this.active = obj.active !== undefined ? obj.active : null;
    this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
    this.imageUrl = obj.imageUrl !== undefined ? obj.imageUrl : null;
    this.description = obj.description !== undefined ? obj.description : null;
    this.listing = obj.listing !== undefined ? obj.listing : null;
    this.listingId = obj.listingId !== undefined ? obj.listingId : null;
    this.client = obj.client !== undefined ? obj.client : null;
    this.clientId = obj.clientId !== undefined ? obj.clientId : null;
    this.googleReviewId = obj.googleReviewId !== undefined ? obj.googleReviewId : null;    
    this.listReviewId = obj.listReviewId !== undefined ? obj.listReviewId : null;    
  }
}
// For reactive forms intended
export interface IFListingPhoto {
  /**
   * Is number
   */
  id?: any;
  /**
   * Is boolean
   */
  active?: any;
  /**
   * Is Date
   */
  addedDate?: any;
  /**
   * Is string
   */
  imageUrl?: any;
  /**
   * Is string
   */
  description?: any;
  /**
   * Is Listing
   */
  listing?: any;
  /**
   * Is number
   */
  listingId?: any;
  /**
   * Is Client
   */
  client?: any;
  /**
   * Is number
   */
  clientId?: any;
  /**
   * Is number
   */
  googleReviewId?: any;
  /**
   * Is number
   */
  listReviewId?: any;  
  
}
