import { Component, OnInit } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';

@Component({
  selector: 'mw-map',
  template: ''
})
export class MwMapComponent implements OnInit {

  constructor(public api: GoogleMapsAPIWrapper) { }

  ngOnInit() {}

}
