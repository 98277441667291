import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'mw-partners',
  styleUrls: ['./mw-static-pages.scss'],
  template: `
        <h1 class="header">
            Mapwords Partner Program
        </h1>
        <div class="static-page partners-page">
            <div class="static-page-container first" fxLayout="row" fxLayout.xs="column">
                <div class="intro" fxLayout="column" fxFlexOffset="20%" fxFlexOffset.md="0" fxFlexOffset.xs="0">
                    <h2>Become a Mapwords Partner and receive exclusive benefits and credibility.</h2>
                    <p>
                        Join the Mapwords Partner community, and your agency could qualify to add or edit business details through the Mapwords API. With the Mapword Partner badge, your agency will stand out from the rest by adding exclusivity and credibility as a Mapwords Partner.
                    </p>
                </div>
                <div fxShow="true" fxHide.xs="true" fxFlex="50%" fxFlex.xs="100%" class="image-container">
                    <img src="./assets/images/partners/Badge-Single.jpg"/>
                </div>
                <div fxShow.xs="true" fxHide.gt-xs="true" fxFlex.xs="100%" class="image-container" fxFlexOrder.xs="1">
                    <img src="./assets/images/partners/Badges-Mobile.jpg"/>
                </div>
            </div>
            <div class="grey-box second">
                <div class="static-page-container" fxLayout="row" fxLayout.xs="column">
                    <div class="left-margin" fxLayout="row" fxLayout.xs="column" fxFlex="100%" fxFlex.xs="100%" fxFlexOffset="20%" fxFlexOffset.md="0" fxFlexOffset.xs="0" fxLayoutAlign="start end">
                        <div fxLayout="column" fxFlexOffset.xs="0" fxFlexOrder.xs="2">
                            <h2>What does it take for an Agency to becomea Mapwords Partner?</h2>
                            <p>
                                To become a Mapwords Partner, an Agency must be able to demonstrate comprehension in the local ecosystem of online directory maintenance, illustrate servicing technology in managing your client’s business profile, and demonstrate internal compliance with the handling and communication of your agency services.
                            </p>
                        </div>
                        <div fxShow="true" fxHide.xs="true" fxFlex="50%" fxFlex.xs="100%" class="image-container" fxFlexOrder.xs="1">
                            <img src="./assets/images/partners/Badges.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="static-page-container third" fxLayout="row" fxLayout.xs="column">
                <div fxFlexOffset="20%" fxFlexOffset.md="0" fxFlexOffset.xs="0" fxFlex.xs="100%">
                    <h2>
                        Find a Mapwords Partner
                    </h2>
                    <p>
                        A Mapwords Partner will give your business the peace of mind knowing that you are dealing with a servicing agent who is knowledgable in the local search ecosystem.  A trusted Mapwords Partner has been interviewed and qualified to manage maps listings.
                    </p>
                </div>
                <div fxFlex="50%" fxFlex.xs="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutAlign="start center" fxLayoutAlign.xs="start">
                    <div class="search-container" fxLayout="row" fxLayoutGap="15px">
                        <div class="city" fxFlex="70%">
                            <div class="label"> City </div>
                            <input placeholder="City">
                        </div>
                        <div class="state" fxFlex="30%">
                            <div class="label"> State </div>
                            <input placeholder="State">
                        </div>
                    </div>
                    <div class="submit" fxFlex="auto" fxFlex.xs="100%">
                        <button>Search</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top">
            BACK TO TOP
            <i class="material-icons">arrow_upward</i>
        </div>
        <div class="footer">
            <div class="content">
                <!--
                    <div class="social" fxLayout="row" fxLayoutAlign="start center">
                        <p>FOLLOW US ON:</p>
                        <div class="social-links">
                            <a href="">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                -->
                <hr>
                <div class="bottom-footer" fxLayout="row" fxLayout.xs="column">
                    <div class="static-pages" fxLayout="row wrap" fxFlex="auto" fxLayoutAlign.xs="space-evenly center">
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords About Us" [routerLink]="['/about_us']">
                                <span>About Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Advertising" [routerLink]="['/advertising']">
                            <span>Advertising</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="LocalSnaps" [routerLink]="['/localsnaps']">
                            <span>LocalSnaps &trade;</span>
                        </a>                        
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Contact" [routerLink]="['/contact']">
                                <span>Contact Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Privacy" [routerLink]="['/privacy']">
                            <span>Privacy</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Terms" [routerLink]="['/terms-of-use']">
                            <span>Terms</span>
                        </a>
                    </div>
                    <div class="copyright-desktop" fxHide.xs="true" fxFlex="35%" fxFlex.xs="100%">
                        Copyright &copy; 2019 Mapwords. All Rights Reserved
                    </div>
                    <div class="copyright-mobile" fxHide fxShow.xs="true">
                        &copy; 2019 Mapwords
                    </div> 
                </div>
            </div>
        </div>
    `
})
export class MwPartnersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
