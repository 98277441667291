import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'mw-ads',
  styleUrls: ['./mw-static-pages.scss'],
  template: `        
        <h1 class="header">
            Advertise with Mapwords!
        </h1>
        <div class="ads-page">
            <div class="static-page-container first" fxLayout="row" fxLayout.xs="column">
                <div class="intro" fxLayout="column" fxFlexOffset="15%" fxFlexOffset.xs="0">
                    <h2>Why Advertise with Mapwords</h2>
                    <p>
                        At Mapwords.com we make the power of local search work for you! With product offerings starting as low as $99.00, Mapwords will help your business be part of the millions of local searches performed each day. For more information on how Mapwords.com can help you and your business grow, email us at <a href="mailto:sales@mapwords.com">sales@mapwords.com</a>. We look forward to helping you!
                    </p>
                </div>
                <div fxFlex="35%" fxFlex.xs="100%" class="image-container">
                    <img src="./assets/images/mapwords-ad-.png"/>
                </div>
            </div>
            <div class="grey-box second">
                <div class="static-page-container" fxLayout="row" fxLayout.xs="column">
                    <div fxLayout="row" fxLayout.xs="column" fxFlex="100%" fxFlex.xs="100%" fxFlexOffset="15%" fxFlexOffset.xs="0" fxLayoutAlign="start end">
                        <div fxLayout="column" class="columnsAds" fxFlex="56%" fxFlexOffset.xs="0">
                            <div fxFlex="50%">
                                <h2>Advertise with Mapwords and attract more customers</h2>
                            </div>
                            <div fxFlex="50%" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="" fxLayoutGap="50px" fxLayoutGap.xs="15px">
                                <div fxFlex="30%" fxFlex.xs="100%">
                                    <h3>Local Search Ads</h3>
                                    <p>
                                        With the millions of local searches performed each day on the internet, Mapwords puts your business listing at the top of all local area searches that are relevant to your business profile.
                                    </p>
                                </div>
                                <div fxFlex="29%" fxFlex.xs="100%">
                                    <h3>Mapwords Mobile Ads</h3>
                                    <p>
                                        When potential customers are busy and on the go, Mapwords advertising for mobile works by putting your company information above your competitors in your local area.
                                    </p>
                                </div>
                                <div fxFlex="31%" fxFlex.xs="100%">
                                    <h3>Competitor Listings Ads</h3>
                                    <p>
                                        When most consumers make a purchase decision within a week, it is an advantage to have your business branded on your competitor's business pages for when local customers are researching your services.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="25%" fxFlex.xs="100%" fxLayoutAlign="center end" id="mobile-ads" class="image-container">
                            <img src="./assets/images/mobile-ads.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="static-page-container third" fxLayout="row" fxLayout.xs="column">
                <div class="incorrect-information" fxFlexOffset="15%" fxFlex="25%" fxFlexOffset.xs="0" fxFlex.xs="100%">
                    <h2>
                        Is Incorrect Online Business Information Weighing Your Business Down?
                    </h2>
                    <p>
                        Let Mapwords updating and data monitoring service do the work for you. Mapwords enhances and updates business information such as name, address, phone number, hours, and key services across hundreds of local directories with ease. As a trusted data source, Mapwords has earned direct editing privileges for a number of local business directories found online making the updating process quick and affordable. Although, there are some local business directories that do not allow direct access to third parties, Mapwords team of citation managers will also manually update your business information for you.
                    </p>
                </div>
                <div class="image-container" fxFlex="25%" fxHide.xs>
                    <div class="new-section-img">
                        <img src="./assets/images/mw-ecosystem.jpg">
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top">
            BACK TO TOP
            <i class="material-icons">arrow_upward</i>
        </div>
        <div class="footer">
            <div class="content">
                <!--
                    <div class="social" fxLayout="row" fxLayoutAlign="start center">
                        <p>FOLLOW US ON:</p>
                        <div class="social-links">
                            <a href="">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                -->
                <hr>
                <div class="bottom-footer" fxLayout="row" fxLayout.xs="column">
                    <div class="static-pages" fxLayout="row wrap" fxLayoutWrap fxFlex="auto" fxLayoutAlign.xs="space-evenly center">
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords About Us" [routerLink]="['/about_us']">
                                <span>About Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Advertising" [routerLink]="['/advertising']">
                            <span>Advertising</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="LocalSnaps" [routerLink]="['/localsnaps']">
                            <span>LocalSnaps &trade;</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Partners" [routerLink]="['/partners']">
                            <span>Partners</span>
                        </a>
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Contact" [routerLink]="['/contact']">
                                <span>Contact Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Privacy" [routerLink]="['/privacy']">
                            <span>Privacy</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Terms" [routerLink]="['/terms-of-use']">
                            <span>Terms</span>
                        </a>
                    </div>
                    <div class="copyright-desktop" fxHide.xs="true" fxFlex="35%" fxFlex.xs="100%">
                        Copyright &copy; 2019 Mapwords. All Rights Reserved
                    </div>
                    <div class="copyright-mobile" fxHide fxShow.xs="true">
                        &copy; 2019 Mapwords
                    </div> 
                </div>
            </div>
        </div>
    `
})
export class MwAdsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
