import { IMwSquareSelector } from '../components';

export const mdistance: IMwSquareSelector[] = [
    {
        name: '5 Miles',
        value: 5
    },
    {
        name: '10 Miles',
        value: 10
    },
    {
        name: '25 Miles',
        value: 25
    },
    {
        name: '50 Miles',
        value: 50
    },
]

export const mprices: IMwSquareSelector[] = [
    {
        name: 'Any Price',
        value: 0
    },
    {
        name: '$$',
        value: 2
    },
    {
        name: '$$$',
        value: 3
    },
    {
        name: '$$$$',
        value: 4
    }
];

export const mratings: IMwSquareSelector[] = [
    {
        name: 'Any Rating',
        value: 0
    },
    {
        name: '2.0',
        value: 2
    },
    {
        name: '3.0',
        value: 3
    },
    {
        name: '4.0',
        value: 4
    }
];

export const mhours: IMwSquareSelector[] = [
    {
        name: 'Any Time',
        value: false
    },
    {
        name: 'Open Now',
        value: true
    }
];

export const morder: IMwSquareSelector[] = [
    {
        name: 'A-Z',
        value: 1
    },
    {
        name: 'Z-A',
        value: 2
    }
];
