import {
  Component, OnInit, Input, ViewContainerRef, Output, ViewEncapsulation,
  EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, AfterViewChecked
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingReview, Listing, ReviewReply, ReplyTypes, Client } from '../../../../shared/models';
import { S3filesService } from '../../../../shared/services';
import { ReviewsService, ClientsService, GoogleReviewService } from '../../../../shared/services/api';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';
import { ActionTypes } from '../../../../shared/viewmodels';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { Subscription, Observable } from 'rxjs';
import { Http } from '@angular/http';
import 'rxjs/add/operator/catch';
import { ThumbsReview } from '../../../../shared/models/ThumbsReview';


@Component({
  selector: 'mw-listing-review',
  templateUrl: './mw-listing-review.component.html',
  styleUrls: ['./mw-listing-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwListingReviewComponent implements OnInit, AfterViewChecked {

  @Input()
  public review: ListingReview;

  @Input()
  public location = '';

  @Output()
  public deletedReview = new EventEmitter<number>();

  @Output()
  public refreshReview = new EventEmitter();

  @Output()
  public edit = new EventEmitter<number>();

  
  @ViewChild('videoController') videoTag: ElementRef;
  
  public replying = false;

  public preReplyMessage = '';

  public replyingMessage = '';

  public imageOrigin = environment.imagesOrigin;

  public reviewIsOpen = false;

  public fullMessage= false;

  public canShowVideo = false;

  public videoThumbUrl = '' ;

  safeHtml: SafeHtml;
  public thumbsup: number = 0;
  public thumbsdown: number = 0;

  private subscription: Subscription;

  public thumbsLoading = false;
  public isLoading = false;

  public get RepliesOrdered(): ReviewReply[] {
    // this.review.replies = _.orderBy(this.review.replies, ['addedDate'], ['desc']);
    return this.review.replies;
  }

  constructor(
    private _reviewsService:  ReviewsService,
    public store: MapwordsStore,
    private _changeDetector: ChangeDetectorRef,
    private _router: Router,
    private _clientService: ClientsService,
    private _route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private _http: Http,
    private s3filesService: S3filesService
  ) { }

  ngAfterViewChecked(): void {}
  
  ngOnInit() {

    // console.log('this.review');  
    // console.log(this.review);
    // console.log(this.review.videoReviewStatus);
   
    if(this.store.auth.user !== null && this.review.client !== null && this.store.auth.user !== undefined && this.review.client !== undefined) {
      if(this.store.auth.user.id !== null && this.review.client.id !== null && this.store.auth.user.id !== undefined && this.review.client.id !== undefined ) {
        if (this.review.videoUrl != null && this.store.auth.user.id == this.review.client.id) {
          this.canShowVideo = true;
        }
      }
    }

    if (this.review.videoUrl != null && this.review.videoReviewStatus == 1) {
      this.canShowVideo = true;
    }

    if(this.review.videoUrl != null) {
        this.videoThumbUrl =  this.review.videoUrl.substring(0, this.review.videoUrl.length-4) + '.png'  ;
    }

    //console.log(this.review.dislikes + ' & ' + this.review.likes);
    this.thumbsup = this.review.likes;
    this.thumbsdown = this.review.dislikes;

    if(this.review.videoUrl !== undefined && this.review.videoUrl !== null && this.review.videoUrl!== '') {
           
      // let timer = TimerObservable.create(1000, 1000);
      // this.subscription = timer.subscribe(t => {
      //   this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
      //     '<video  width="100%" height="240" controls [poster]="review.videoReviewStatus === 1 ? videoThumbUrl : undefined" > <source src="'+ this.s3filesService.getSignedUrl( this.review.videoUrl )+'" type="video/mp4">  </video>'
      //   );      

      //   this.subscription.unsubscribe();
      // });

     
      this.subscription = Observable.timer(0, 1000).subscribe(t => {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
          '<video  width="100%" height="240" controls [poster]="review.videoReviewStatus === 1 ? videoThumbUrl : undefined" > <source src="'+ this.s3filesService.getSignedUrl( this.review.videoUrl )+'" type="video/mp4">  </video>'
        );      

        this.subscription.unsubscribe();
      });
    }

    if(this.review.imageUrls) {

      let signedUrls: string[] = [];
      this.review.imageUrls.forEach(url=>{
        signedUrls.push( this.getSignedUrl(url) ) ;
      });

      this.review.imageSignedUrls = signedUrls;
      

    }


    this.review.addedDateText = this.setAddedDateText(this.review.addedDate);

    if(this.review.replies) {
      this.review.replies.forEach(x=>
        x.addedDateText = this.setAddedDateText(x.addedDate)
      );
    }

  }





  public setAddedDateText(reviewDate: Date ): string {

    // console.log(reviewDate) ;
    // console.log(reviewDate.toString()) ;
    // console.log(Date.parse(reviewDate.toString())) ;

    // 2018-06-22 12:23:12
      

          
    //let diffInHours: number = (Date.now() - reviewDate.valueOf() ) / 1000 / 60 / 60;

    //let diffInHoursTemp: number = (Date.now() - newReviewDate.valueOf() ) / 1000 / 60 / 60;

    // console.log('reviewDate') ;
    // console.log(reviewDate) ;
    // console.log(diffInHours) ;
    // console.log(diffInHoursTemp) ;


    if(reviewDate) {
      
      var a = reviewDate.toString().split(/[^0-9]/);
      var newReviewDate: Date=new Date ( +a[0],+a[1]-1,+a[2],+a[3],+a[4],+a[5] );
      
      //let diffInHours: number = (Date.now() - (Date.parse(reviewDate.toString())) ) / 1000 / 60 / 60;
      let diffInMinutes: number = Math.round((Date.now() - newReviewDate.valueOf() ) / 1000 / 60) ;
      let diffInHours: number = (Date.now() - newReviewDate.valueOf() ) / 1000 / 60 / 60;

      // console.log('reviewDate') ;
      // console.log(reviewDate) ;
      // console.log(newReviewDate) ;
      // console.log(diffInHours) ;

      let durationTex='';

      if( diffInMinutes < 60) {
        durationTex = diffInMinutes.toString() + ' minutes ago' ;
      }
      else if( diffInHours < 2) {
        durationTex = 'an hour ago' ;
      }
      else if( diffInHours < 24) {
        durationTex = this.getDatePart(diffInHours , 1)  + ' hours ago' ;
      }
      else if( diffInHours < 24*1.5 ) {
        durationTex =  ' yesterday' ;
      }
      else if( diffInHours < 24*7 ) {
        durationTex = this.getDatePart(diffInHours , 24)  + ' days ago' ;
      }
      else if( diffInHours < 24*7*1.5 ) {
        durationTex =  ' last week' ;
      }      
      else if( diffInHours < 24*30 ) {
        durationTex = this.getDatePart(diffInHours , 24*7)  + ' weeks ago' ;
      } 
      else if( diffInHours < 24*30*1.5 ) {
        durationTex = ' last month' ;
      }
      else if( diffInHours < 24*7*52 ) {
        durationTex = this.getDatePart(diffInHours , 24*30)  + ' months ago' ;
      }   
      else if( diffInHours < 24*7*52*1.5 ) {
        durationTex = ' last year' ;
      }          
      else {
        durationTex = this.getDatePart(diffInHours , 24*7*52)  + ' years ago' ;
      }  



      return durationTex;
    }

    return '';

  }


  public getDatePart(hour: number, divider: number) : string {

    // console.log(hour);
    // console.log(divider);
    // console.log(Math.round(hour/divider).toString());

    return Math.round(hour/divider).toString() ;
  }
  


// public  getVideoStatus(path: string): Promise<boolean> {
//     return this._http.get(path)
//         .mapTo(true)
//         .catch((error) => Observable.of(false))
//         .toPromise();
// }


public ShowSlide(url: string): void {

  // console.log(index);
  this.store.searchListing.photoGaleryIndexUrl = url;
  this.store.searchListing.photoIsOpen = true;
  
  this.store.searchListing.d360IsOpen = false;
}

  public editReview(): void {
    this.reviewIsOpen = true;
  }

  public onWriteReviewClose(): void {
    this.reviewIsOpen=false;
    this.refreshReview.emit();
    this.ngOnInit();
  }

  public deleteReview(reviewId: number): void {
    this.store.searchListing.deleteReview(reviewId, this.store.searchListing.listing.googlePlaceId);
  }  

  public reply: ReviewReply;
  
  public submitReply(): void {

    if(this.isLoading) {
      return ;
    }

    this.isLoading=true;

    if (!this.store.auth.isSignedIn && this.store.auth.user !== null && this.store.auth.user !== undefined  ) {
      this.store.alerts.add({
        title: 'Sign into Mapwords',
        message: 'Please sign in or create a new account.',
        cancelLabel: 'Sign Up',
        okLabel: 'Sign In',
        hasCancel: true,
        execute: x => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-in?redirect=' + this._router.url );
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-up?redirect=' + this._router.url );
          }
        }
      });
      return;
    }

    if (this.replyingMessage.length !== 0) {
      this.store.searchListing.addReply(new ReviewReply({
        listingReviewId: this.review.id,
        message: this.replyingMessage,
        replyType: ReplyTypes.normal
      }), () => {
        this.isLoading=false;
        this.cancelReply();
      });
    }
    else
    {
      this.isLoading=false;
    }

  }

  public saveEditedReply(reply: ReviewReply): void {
    if (reply.message.length > 0) {
      reply.active = true;
      this.store.searchListing.editReply(reply);
    }
  }

  public removeReply(reply: ReviewReply): void {
    this.store.searchListing.removeReply(reply.id, reply.listingReviewId);
  }

  public cancelEditedReply(reply: ReviewReply): void {
    reply.active = true;
    reply.message = this.preReplyMessage;
  }

  public openReplyEditBox(reply: ReviewReply): void {
    reply.active = false;
    this.preReplyMessage = reply.message;
  }

  public openReplyBox(): void {
    this.replying = true;
  }

  public cancelReply(): void {
    this.replying = false;
    this.replyingMessage = '';
  }

  public readMoreMessage(): void {
    this.fullMessage = !this.fullMessage;
    this.fullMessage ? 'r-message full-message' : 'r-message'
  }

  public fullScreenVideo(): void {
    let elem = document.getElementById('video-review');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }

  

  // public thumbs: ThumbsGoogleReview = new ThumbsGoogleReview({
  //   id: 0,
  //   clientId: null,
  //   googleReviewId: null,
  //   status: false
  // })
  
  // public status: boolean =false;
  // public cId: number;
  // public googleReview: GoogleReview;
  // public listingReview: ListingReview;
  // public placeId = this.store.searchListing.listing.googlePlaceId

  // public listingThumbs: ThumbsListingReview = new ThumbsListingReview({
  //   id: 0,
  //   clientId: null,
  //   listingReviewId: null,
  //   status: false
  // })


  addThumbs(reviewId: number, listingId:number, status:boolean) {

    if (!this.store.auth.isSignedIn && this.store.auth.user !== null && this.store.auth.user !== undefined  ) {
      this.store.alerts.add({
        title: 'Sign into Mapwords',
        message: 'Please sign in or create a new account.',
        cancelLabel: 'Sign Up',
        okLabel: 'Sign In',
        hasCancel: true,
        execute: x => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-in?redirect=' + this._router.url );
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-up?redirect=' + this._router.url );
          }
        }
      });
      return;
    }


    let listingReviewId = 0;
    let googleReviewId = 0;
    let clientId = this.store.auth.user.id;

    if (listingId == 0) {
      googleReviewId = reviewId;
    }
    else
    {
      listingReviewId = reviewId;
    }


    let thumbsReview = new ThumbsReview();
    thumbsReview.clientId = clientId;
    thumbsReview.googleReviewId = googleReviewId;
    thumbsReview.listingReviewId = listingReviewId;
    thumbsReview.status = status;

    this.thumbsLoading = true;

    this._reviewsService.PostThumb(thumbsReview).subscribe(x => {

      this.review.likes = x.data.likes;
      this.review.dislikes = x.data.dislikes;
      this.review.selectedLikeType = x.data.selectedLikeType;

      console.log(x.data.selectedLikeType) ;

      this.thumbsLoading = false;
    });


  }


  // addThumbs(reviewId: number, listingId:number, thumb: string): void {
  //   if (!this.store.auth.isSignedIn && this.store.auth.user !== null && this.store.auth.user !== undefined  ) {
  //     console.log(this.store.auth.isSignedIn);
  //     console.log(this.store.auth.user);
  //     // debugger;
  //     this.store.alerts.add({
  //       title: 'Sign into Mapwords',
  //       message: 'Please sign in or create a new account.',
  //       cancelLabel: 'Sign Up',
  //       okLabel: 'Sign In',
  //       hasCancel: true,
  //       execute: x => {
  //         if (x === ActionTypes.ok) {
  //           this._router.navigateByUrl('/auth/sign-in?redirect=' + this._router.url );
  //         } else if (x === ActionTypes.cancel) {
  //           this._router.navigateByUrl('/auth/sign-up?redirect=' + this._router.url );
  //         }
  //       }
  //     });
  //     return;
  //   }
    
  //   console.log(this.store.auth.user.id);
  //   console.log(reviewId);
  //   console.log(listingId);
    
  //   console.log(thumb);
  //   let clientId = this.store.auth.user.id;
    
  //   this.thumbs.clientId = clientId,
  //   this.thumbs.googleReviewId = reviewId;
    
  //   this.thumbs.active = true;
    
  //   if (listingId === 0) {
  //     this._googleReviewService.GetById(reviewId).subscribe(x => 
  //       {
  //         x.data; 
  //         console.log(x.data);
           
  //         console.log(x.data.likes);
  //         console.log(x.data.dislikes);
  //         this.googleReview = x.data;
  //         console.log(this.googleReview);  
        
  //       if (this.googleReview.likes != 0 || this.googleReview.dislikes != 0) {
  //         // this._googleThumbsService.GetByClientId(clientId).subscribe(x => {
  //         //   console.log(x.data);
  //         // });
  //         //this._googleThumbsService.GetByReviewId(reviewId).subscribe(x => {

  //         //this._googleThumbsService.GetByClientId(clientId).subscribe(x => {
  //         this._googleThumbsService.GetByIds(reviewId, clientId).subscribe(x => {  
  //           console.log(x.data);
            
  //           if (x.data === undefined) {
              
  //             if (thumb == 'up') {
  //               this.thumbs.status = true;
  //               this.googleReview.likes++;
  //               this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
                
  //               this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data)
  //               });
                
  //             }
  //             else {
  //               this.thumbs.status = false;
  //               this.googleReview.dislikes++;
  //               this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
  //               this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data);
  //               });
               
  //             }
        
  //             return;
  //           }
  //           let id = x.data.id;        

  //           //let findReview = x.data.find(x => x.clientId === this.store.auth.user.id);
  //           let findReview = x.data;
            
  //           //let id = findReview.id;
  //           console.log(findReview);
  //           // debugger;
            
  //           //if (this.cId == this.store.auth.user.id) {
  //           if (findReview) {
  //             if (findReview.status == true) {
  //               if (thumb == 'up') {
  //                 console.log('You already liked');
  //               }
  //               else {
  //                 this.thumbs.status = false;
  //                 this._googleThumbsService.Put(id, this.thumbs).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                 });
                  
  //                 this.googleReview.likes--;
  //                 this.googleReview.dislikes++;
  //                 // debugger;
  //                 this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //                 });                
  //               }
  //             }
  //             if (findReview.status == false) {
  //               if (thumb == 'down') {
  //                 console.log('You already disliked');
  //               }
  //               else {
  //                 this.thumbs.status = true;
  //                 this._googleThumbsService.Put(id, this.thumbs).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                 });
  
  //                 this.googleReview.likes++;
  //                 this.googleReview.dislikes--;
  //                 // debugger;
  //                 this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //                 });                
  //               }
  //             }        
  //           }
  //           else {
  //             // debugger;
  //             if (thumb == 'up') {
  //               this.thumbs.status = true;
  //               this.googleReview.likes++;
  //               this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
               
  //               this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data)
  //               });
                
  //             }
  //             else {
  //               this.thumbs.status = false;
  //               this.googleReview.dislikes++;
  //               this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
  //               this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data);
  //               });
                
  //             }
  //           }
    
  //         });
  //       }
  //       else {
  //         if (thumb == 'up') {
  //           this.thumbs.status = true;
  //           this.googleReview.likes++;
  //           this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //               x.data;
  //               console.log(x.data);
  //               this.thumbsup = x.data.likes;
  //               this.thumbsdown = x.data.dislikes;
  //           });
  //           // debugger;
  //           this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //             x.data;
  //             console.log(x.data);
  //           });
  //         }
  //         else {
  //           this.thumbs.status = false;
  //           this.googleReview.dislikes++;
  //           this._googleReviewService.Put(this.googleReview).subscribe(x => {
  //               x.data;
  //               console.log(x.data);
  //               this.thumbsup = x.data.likes;
  //               this.thumbsdown = x.data.dislikes;
  //           });
  //           // debugger;
  //           this._googleThumbsService.Post(this.thumbs).subscribe(x => {
  //             x.data;
  //             console.log(x.data);
  //           });
            
  //         }        
  //       }
      
  //     });
  //   }
    
  //   else {
  //     console.log('this is listing review reply');
  //     // debugger;
      
  //     this.listingThumbs.clientId = clientId;
  //     this.listingThumbs.listingReviewId = reviewId;

  //     this._reviewsService.GetById(reviewId).subscribe(x => 
  //       {
  //         x.data; 
  //         console.log(x.data);
  //         //console.log(x.data.thumbs); 
  //         console.log(x.data.likes);
  //         console.log(x.data.dislikes);
  //         this.listingReview = x.data;
  //         console.log(this.listingReview);
  
   
  //       if (this.listingReview.likes != 0 || this.listingReview.dislikes !=0) {
  //         //this._listingThumbsService.GetByReviewId(reviewId).subscribe(x => {
  //         this._listingThumbsService.GetByIds(reviewId, clientId).subscribe(x => {
  //           console.log(x.data);

  //           if (x.data === undefined) {
          
  //             if (thumb == 'up') {
  //               this.thumbs.status = true;
  //               this.listingReview.likes++;
  //               this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
                
  //               this._listingThumbsService.Post(this.listingThumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data)
  //               });
                
  //             }
  //             else {
  //               this.thumbs.status = false;
  //               this.listingReview.dislikes++;
  //               this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //               });
  //               this._listingThumbsService.Post(this.listingThumbs).subscribe(x => {
  //                 x.data;
  //                 console.log(x.data);
  //               });
                
  //             }
        
  //             return;
  //           }

  //           let id = x.data.id;
  //           // let findReview = x.data.find(x => x.clientId === this.store.auth.user.id);
  //           let findReview = x.data;
  //           console.log(findReview);
    
  //           //if (this.cId == this.store.auth.user.id) {
  //           if (findReview) {
  //             if (findReview.status == true) {
  //               if (thumb == 'up') {
  //                 console.log('You already liked');
  //               }
  //               else {
  //                 this.listingThumbs.status = false;
  //                 this._listingThumbsService.Put(id, this.listingThumbs).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                 });
                  
  //                 this.listingReview.likes--;
  //                 this.listingReview.dislikes++;
  //                 // debugger;
  //                 this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //                 });                
  //               }
  //             }
  //             if (findReview.status == false) {
  //               if (thumb == 'down') {
  //                 console.log('You already disliked');
  //               }
  //               else {
  //                 this.listingThumbs.status = true;
  //                 this._listingThumbsService.Put(id, this.listingThumbs).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                 });
  
  //                 this.listingReview.likes++;
  //                 this.listingReview.dislikes--;
  //                 // debugger;
  //                 this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //                   x.data;
  //                   console.log(x.data);
  //                   this.thumbsup = x.data.likes;
  //                   this.thumbsdown = x.data.dislikes;
  //                 });                
  //               }
  //             }        
  //           }
  //         });
  //       }
  //       else {
   
  //         if (thumb == 'up') {
  //           this.listingThumbs.status = true;
  //           this.listingReview.likes++;
  //           this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //               x.data;
  //               console.log(x.data);
  //               this.thumbsup = x.data.likes;
  //               this.thumbsdown = x.data.dislikes;
  //           });
  //           // debugger;
  //           this._listingThumbsService.Post(this.listingThumbs).subscribe(x => {
  //             x.data;
  //             console.log(x.data);
  //           });
  //         }
  //         else {
  //           this.listingThumbs.status = false;
  //           this.listingReview.dislikes++;
  //           this._reviewsService.PutThumb(this.listingReview, this.placeId).subscribe(x => {
  //               x.data;
  //               console.log(x.data);
  //               this.thumbsup = x.data.likes;
  //               this.thumbsdown = x.data.dislikes;
  //           });
  //           // debugger;
  //           this._listingThumbsService.Post(this.listingThumbs).subscribe(x => {
  //             x.data;
  //             console.log(x.data);
  //           });            
  //         }        
  //       }
      
  //     });
  //   }    
  // }

  checkLogin() {
    if (this.store.auth.isSignedIn) {
      this.store.searchListing.openClaim();
    } else {
      this.store.myClaimListing=this.store.searchListing;
      this.store.myClaimListing.openClaimRequested=true;
      
      this.store.alerts.add({
        title: 'Sign into Mapwords',
        message: 'Please sign in or create a new account.',
        hasCancel: true,
        cancelLabel: 'Sign In',
        okLabel: 'Sign Up',
        execute: (x) => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-up?redirect=' + this._router.url);
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-in?redirect=' + this._router.url);
          }
        }
      });
    }
  }

  public getSignedUrl(url: string): string {
    if(url) {
      if(url.indexOf('Expires') > 0 ) {
        return url;
      }
    }
    return  this.s3filesService.getSignedUrl(url);
  }



}
