
import { CategoryListing } from './CategoryListing';


export interface ICategory  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    name?: string;
    description?: string;
    MenuGroup?: string;
    MasterCategoryId?: number
    categories?: CategoryListing[];
}
export class Category  implements ICategory {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public name: string;
    public description: string;
    public MenuGroup: string;
    public MasterCategoryId: number;
    public categories: CategoryListing[];
    constructor(obj?: ICategory) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.name = obj.name !== undefined ? obj.name : null;
        this.description = obj.description !== undefined ? obj.description : null;
        this.MenuGroup = obj.MenuGroup !== undefined ? obj.MenuGroup : null;
        this.MasterCategoryId = obj.MasterCategoryId !== undefined ? obj.MasterCategoryId : null;
        this.categories = obj.categories !== undefined ? obj.categories : null;
    }
}
// For reactive forms intended
export interface IFCategory  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    name?: any; 
    /**
    * Is string
    */
    description?: any;
    /**
    * Is string
    */
    MenuGroup?: any;
    /**
    * Is number
    */
    MasterCategoryId?: any;
    /**
    * Is CategoryListing[]
    */
    categories?: any; 
}
