import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobxAngularModule } from 'mobx-angular';

import { MomentPipe, EnumPipe, DayNamePipe, EllipsisPipe, phonePipe, SignedUrlPipe } from '../shared/pipes';

import { SearchStore, MapwordsStore, ClientStore, SearchListingStore, AlertsStore, AuthStore, ListingStore, CategoryTreeStore, S3Store } from '../shared/store';
import { ReviewsService, ClientsService, LocationService, GoogleReviewService, ListingsService,
  QuickSearchService, CategoriesService, DyanmicTagService, CategoryTreeService } from '../shared/services/api';
import { GlobalErrorHandler } from '../shared/services/GlobalErrorHandler';
import { ErrorLogService } from '../shared/services/ErrorLog.service';
import { S3filesService, PagerService } from '../shared/services';
import { ClickoutsideDirective, MwFControlDirective } from '../shared/directives';
import { CopypasteDirective } from '../shared/directives/copypaste.directive';

import { CdkTableModule } from '@angular/cdk/table';

import { MwAlertManagerComponent, MwAlertComponent, MwSquareSelectorComponent } from '../shared/components';
import { MwSnackBarComponent } from '../shared/components/mw-alert/mw-snack-bar.component';
import { MaterialModule } from './material';
import { MwCropperComponent } from '../shared/components/mw-cropper';
import { MwFControlComponent } from '../shared/components/mw-fcontrol/mw-fcontrol.component';
import { MwFormControlComponent } from '../shared/components/mw-formcontrol/mw-formcontrol.component';
import { MwHoursComponent } from '../shared/components/mw-hours/mw-hours.component';
import { MwHoursAgentComponent } from '../shared/components/mw-hours-agent/mw-hours-agent.component';
import { MwImageEditorService } from '../shared/components/mw-image-editor/mw-image-editor.service';
import { MwImageEditorComponent } from '../shared/components/mw-image-editor/mw-image-editor.component';
import { MwMapComponent } from '../shared/components/mw-map/mw-map.component';
import { MwRatingsComponent } from '../shared/components/mw-ratings/mw-ratings.component';
import { MwStarComponent, MwStarsRatingComponent } from '../shared/components/mw-ratings/mw-stars-rating.component';
import { MwTabGroupComponent, MwTabComponent } from '../shared/components/mw-tab-group';
import { MwToggleInputsComponent } from '../shared/components/mw-toggle-inputs/mw-toggle-inputs.component';
import { AuthGuard } from './feature/mw-auth/auth-guard.service';
import { MwTreeViewComponent } from '../shared/components/mw-tree-view/mw-tree-view.component';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MwUserProfileComponent } from './feature/mw-control-panel/mw-user';
import { MwCompleteProfilePopComponent, MwWriteReviewComponent } from './feature/mw-home';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MwSignUpComponent } from './feature/mw-auth/mw-signup/mw-signup.component';
import { PhoneMaskDirective } from '../shared/directives/phone-mask.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MobxAngularModule,

    MaterialModule,

    FlexLayoutModule,

    // Input Mask
    NgxMaskModule.forRoot(),

    // DateTimePicker
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    FlexLayoutModule,
    MomentPipe,
    EllipsisPipe,
    phonePipe,
    EnumPipe,
    DayNamePipe,
    SignedUrlPipe,
    ClickoutsideDirective,
    MwFControlDirective,
    CopypasteDirective,
    MwUserProfileComponent,
    MwToggleInputsComponent,
    MwWriteReviewComponent,

    NgxMaskModule,

    // DateTimePicker
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    
    MaterialModule,

    MwAlertManagerComponent,
    MwAlertComponent,
    MwSnackBarComponent,

    MwCropperComponent,
    MwFControlComponent,
    MwFormControlComponent,
    MwHoursComponent,
    MwHoursAgentComponent,
    MwImageEditorComponent,
    MwMapComponent,
    MwRatingsComponent,
    MwStarComponent,
    MwStarsRatingComponent,
    MwTabGroupComponent,
    MwTabComponent,
    MwToggleInputsComponent,
    MwSquareSelectorComponent, 
    MwTreeViewComponent,
    MwSignUpComponent,

    PhoneMaskDirective

  ],
  declarations: [
    EllipsisPipe,
    phonePipe,
    MomentPipe,
    DayNamePipe,
    SignedUrlPipe,
    EnumPipe,
    ClickoutsideDirective,
    MwFControlDirective,
    CopypasteDirective,
    MwUserProfileComponent,
    MwToggleInputsComponent,
    MwWriteReviewComponent,

    MwAlertManagerComponent,
    MwAlertComponent,
    MwSnackBarComponent,

    MwCropperComponent,
    MwFControlComponent,
    MwFormControlComponent,
    MwHoursComponent,
    MwHoursAgentComponent,
    MwImageEditorComponent,
    MwMapComponent,
    MwRatingsComponent,
    MwStarComponent,
    MwStarsRatingComponent,
    MwTabGroupComponent,
    MwTabComponent,
    MwToggleInputsComponent,
    MwSquareSelectorComponent,
    MwTreeViewComponent,
    MwSignUpComponent,

    PhoneMaskDirective
  ],
  entryComponents: [
    MwImageEditorComponent,
    MwSnackBarComponent
  ],
  providers: [
    ListingStore,
    AlertsStore,
    AuthStore,
    ClientStore,
    SearchStore,
    SearchListingStore,
    MapwordsStore,
    ReviewsService,
    ClientsService,
    LocationService,
    GoogleReviewService,
    ListingsService,
    QuickSearchService,
    CategoriesService,
    ErrorLogService,
    GlobalErrorHandler,
    S3filesService,
    PagerService,
    DyanmicTagService,
    CategoryTreeService,
    CategoryTreeStore,
    S3Store,

    MwImageEditorService,
    AuthGuard,
    GoogleMapsAPIWrapper
  ]
})
export class SharedModule { }

