import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { Listing } from '../../models/Listing';
import { User } from '../../models/User';
import { State } from '../../models/State';
import { DynamicTagModel } from '../../models/DynamicTagModel';
import { BaseRestService } from './base.service';
import { IQuickSearchObject } from '../../models/QuickSearchObject';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';


@Injectable()
export class CategoryTreeService extends BaseRestService<any> {

    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }

   
    /** 
    *   Get meta data 
    *   @return IQuickSearchObject
    */
   public GetCategoryTree(): Observable<RG<IQuickSearchObject[]>> {
    return this.get(`api/v1/categorytree`);
}


}