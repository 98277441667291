import { Injectable } from '@angular/core';
import {  S3SignedUrl } from '../models';


@Injectable()
export class S3Store {

  private _signedUrlList: S3SignedUrl[] = [];
  
  public get signedUrlList(): S3SignedUrl[] {
    return this._signedUrlList;
  }
  public set signedUrlList(value: S3SignedUrl[]) {
    this._signedUrlList = value;
  }


  constructor(
   
   ) {
     
  }


}
