
import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'mw-star',
  template: `
    <i (click)="handleRate($event)" *ngIf="active" class="star active ion-android-star"></i>
    <i (click)="handleRate($event)" *ngIf="!active" class="star ion-android-star-outline"></i>
  `,
  styles: [`
    .star {
      color: #6e90f4;
      cursor: pointer;
      font-size: 17px;
      transition: color .4s ease-in-out;
    }
    .star.active {
      color: #6e90f4;
    }
  `]
})
export class MwStarComponent {
  @Input() active: boolean;
  @Input() position: number;
  @Output() rate = new EventEmitter();

  handleRate() {
    this.rate.emit(this.position);
  }
}


@Component({
  selector: 'mw-stars-rating',
  template: `
    <div class="stars">
      <mw-star
        *ngFor="let star of stars"
        [active]="star <= rating"
        (rate)="onRate($event)"
        [position]="star">
      </mw-star>
    </div>
  `,
  styles: [`
    mw-star {
      display: block;
      max-width: 14px;
      float: left;
      margin-right: 1px;
    }
    .stars {
      margin-top: 4px;
    }
  `]
})
export class MwStarsRatingComponent {

  @Input()
  public starCount: number;

  @Input()
  public rating: number;

  @Output()
  public rate = new EventEmitter();

  public stars: number[] = [1, 2, 3, 4, 5];

  constructor() {
    const count = this.starCount < 0 ? 5 : this.starCount;
  }

  onRate(star) {
    this.rate.emit(star);
    this.rating = star;
  }
}
