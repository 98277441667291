import { Component, Input, Output, EventEmitter, OnInit, ViewContainerRef, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Listing, Client, ListingReview, IFListingReview } from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
import { environment } from '../../../../environments/environment';
import { MwFormControlComponent, MwImageEditorService } from '../../../../shared/components';
import { toJS } from 'mobx';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { UploadFiles } from '../../../../shared/models/UploadFiles';
import { ReviewFile, reviewFileType } from '../../../../shared/models/ReviewFile';
import { Router } from '@angular/router';
import { MwWriteReviewService } from './mw-write-review.service';
import { S3filesService } from '../../../../shared/services';
import { bool } from '../../../../../node_modules/aws-sdk/clients/signer';

@Component({
  selector: 'mw-write-review',
  templateUrl: './mw-write-review.component.html',
  styleUrls: ['./mw-write-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MwWriteReviewComponent implements OnInit {

  @Input()
  public reviewId: number;

  @Output()
  public close = new EventEmitter();  

  @ViewChild('videoInput') videoInputObj:ElementRef;

  @ViewChild('imageInput') imageInputObj:ElementRef;

  public listingReview: ListingReview;

  public listing: Listing;

  public formReview: FormGroup;

  public client: Client;

  public isEdit = true;

  public backup: ListingReview = new ListingReview({});

  public imageOrigin = environment.imagesOrigin;

  public selectedFiles: ReviewFile[]= [];

  public videoExists = false;

  @ViewChild(MwFormControlComponent)
  public MwFormControlComponent: MwFormControlComponent;

  constructor(
    public store: MapwordsStore,
    private _viewContainerRef: ViewContainerRef,
    private _mwWriteReviewService: MwWriteReviewService,
    private _fb: FormBuilder,
    private _photoEditor: MwImageEditorService,
    private _router: Router,
    private s3filesService: S3filesService
  ) { }

  ngOnInit() {
    this.initializeModels();
    this.initForm();
  }

  public saveReview() {

    // console.log('saveReview');
    // console.log(this.selectedFiles);

    if(!this.formReview.get('stars').value &&
      !this.formReview.get('message').value &&
      this.selectedFiles.length == 0 &&
      !this.isEdit
      ) {
        return;
      }



    this.store.search.isLoading = true;

     if (this.formReview.valid || this.formReview.get('stars').value) {

      
      var uploadcounter = 0;
      this.formReview.value.imageUrls = [] ;
      this.formReview.value.videoUrl = '' ;

      console.log(this.selectedFiles);

      if(this.selectedFiles.length > 0) {

                    this.selectedFiles.forEach(async item => {
                      
                      if(item.alreadyUploaded) {

                              if(item.fileType == reviewFileType.video) {
                                this.formReview.value.videoUrl = item.fileUrl ;
                              }
                              else if(item.fileType == reviewFileType.image) {
                                this.formReview.value.imageUrls.push(item.fileUrl) ;
                              }

                              uploadcounter += 1 ;

                              if(uploadcounter ===  this.selectedFiles.length ){
                                this.savePage();
                                this.store.search.isLoading = false;
                                return;
                            }
                      
                      }
                      else if(!item.alreadyUploaded) {

                              await this.upload(item).then((uploadedfileUrl) =>{

                              // console.log('uploadedfileUrl') ;
                              // console.log(uploadedfileUrl) ;

                              
                              uploadcounter += 1 ;

                              if(uploadcounter ===  this.selectedFiles.length ){
                                this.savePage();
                                this.store.search.isLoading = false;
                                return;
                              }
                              
                              });
                      }

                    });

        }
        else {
          this.formReview.value.imageUrls = [] ;
          this.formReview.value.videoUrl = '' ;
          this.savePage();
          this.store.search.isLoading = false;
        }

          
      } else {
        //this.MwFormControlComponent.formDirty = true;
        //this.formReview.dirty;
        this._viewContainerRef.element.nativeElement.scrollTop = 0;



      }
    
      this.store.search.isLoading = false;
    // console.log(this.file);
  }



  public savePage() {
    if (this.isEdit) {
      this.store.searchListing.editReview(this.formReview.value, this.store.searchListing.listing.googlePlaceId);

    } else {
      this.store.searchListing.addReview(this.formReview.value, this.store.searchListing.listing.googlePlaceId);
    }


    // window.setTimeout(this.refreshPage() , 3000) ;

  
    this.close.emit();
  }


  public refreshPage() {

    let id = this.store.searchListing.listing.id ;
    let place_id = this.store.searchListing.listing.googlePlaceId;

    this._router.navigate(['/home', {
      outlets: {
        'search':
          [
            this.store.search.filter.searchterm,
            this.store.search.filter.latitude,
            this.store.search.filter.longitude,
            this.store.search.filter.distance,
            this.store.search.filter.price,
            this.store.search.filter.rating,
            this.store.search.filter.open,              
            this.store.search.page,
            this.store.search.filter.order
          ],
        'listing': null
      }
    }
    ]).then(() => {
      
      this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }]);
      
    });

  }

  public uploadReceipt(): void {
    this._photoEditor.open(null, this._viewContainerRef).subscribe(imageUrl => {
      this.formReview.get('imageUrl').setValue(imageUrl);
    });
  }

  public cancel(): void {
    if (this.isEdit) {
      this.listingReview.message = this.backup.message;
      this.listingReview.stars = this.backup.stars;
    }
    this.close.emit();
  }

  public rate(rating: number): void {
    this.formReview.get('stars').setValue(rating);
  }

  private initializeModels(): void {
    this.listing = toJS(this.store.searchListing.listing);
    //console.log(this.listing);
    if (!this.reviewId) {
      this.isEdit = false;
      this.listingReview = new ListingReview({
        message: ''
      });
      this.client = toJS(this.store.auth.user);
      this.listingReview.client = this.client;
    } else {
      this.backup = toJS(this.store.searchListing.getReviewById(this.reviewId));
      this.listingReview = toJS(this.store.searchListing.getReviewById(this.reviewId));
      this.client = this.listingReview.client;
    }
  }

  private initForm(): void {
    //console.log(this.listingReview);
    this.formReview = this._fb.group(<IFListingReview>{
      active: [true],
      addedDate: [null],
      challengeStatus: [null],
      clientId: [this.store.auth.user.id],
      id: this.isEdit ? this.listingReview.id : 0,
      imageUrl: [this.isEdit ? this.listingReview.imageUrl : null],
      imageUrls: [this.isEdit ? this.listingReview.imageUrls : null],
      listingId: [this.listing.id],
      message: [this.isEdit ? this.listingReview.message : null, [Validators.maxLength(5000)]],
      qu1stvisit: [this.isEdit ? this.listingReview.qu1stvisit + '' : 'false'],
      qucontact: [this.isEdit ? this.listingReview.qucontact + '' : 'false'],
      qudiscuss: [this.isEdit ? this.listingReview.qudiscuss + '' : 'false'],
      quencouragereview: [this.isEdit ? this.listingReview.quencouragereview + '' : 'false'],
      qufriendemployee: [this.isEdit ? this.listingReview.qufriendemployee + '' : 'false'],
      qugiftofferreview: [this.isEdit ? this.listingReview.qugiftofferreview + '' : 'false'],
      quoffer: [this.isEdit ? this.listingReview.quoffer + '' : 'false'],
      qupay: [this.isEdit ? this.listingReview.qupay : null],
      qurefund: [this.isEdit ? this.listingReview.qurefund + '' : 'false'],
      quresolve: [this.isEdit ? this.listingReview.quresolve + '' : 'false'],
      stars: [this.isEdit ? this.listingReview.stars : 0],
      videoUrl: [this.isEdit ? this.listingReview.videoUrl : null],
      selectedLikeType: [this.isEdit ? this.listingReview.selectedLikeType : -1],
      businessName: [this.store.searchListing.listing.displayName],
    });


    // console.log('imageUrls');
    // console.log(this.listingReview.imageUrls);

    if(this.isEdit) {

      if(this.listingReview.videoUrl) {
        
        let fileName =  this.listingReview.videoUrl.split('/');

        let item = new ReviewFile();
        item.alreadyUploaded = true;
        item.fileName = fileName[fileName.length-1];
        item.fileType = reviewFileType.video;
        item.fileUrl = this.listingReview.videoUrl;

        this.selectedFiles.push(item);

        this.videoExists =true;
      }

      if(this.listingReview.imageUrls) {
        if(this.listingReview.imageUrls.length>0) {

          this.listingReview.imageUrls.forEach(element => {

            let fileName =  element.split('/');

            let item = new ReviewFile();
            item.alreadyUploaded = true;
            item.fileName = fileName[fileName.length-1];
            item.fileType = reviewFileType.image;
            item.fileUrl = element;

            this.selectedFiles.push(item);          

          });
        }

      }
    }

    // this.reader.onloadend = (event: any) => {
    //   //this.preview = event.target.result;
    //   this.preview = this.reader.result;
    //   console.log(this.preview);
    // } 
  }

  public BUCKET = environment.s3.Bucket.toString();
  public IMAGEFOLDER = 'Reviews/Images';
  public VIDEOFOLDER = 'Reviews/Videos';


  async upload(uploadFileItem: ReviewFile): Promise<string> {
    
    // console.log('uploadFileItem') ;
    // console.log(uploadFileItem.file) ;

    let imageUrl: string;
    let videoUrl: string;
    let fileName: string;   

    // this.file = this.selectedFiles[this.selectedFiles.length-1].item(0);
    var dateTime = new Date();
    let cdateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss.SSS");
    const userId = this.store.auth.user.id;
    //const placeId = this.listing.googlePlaceId;

    let extension: string = uploadFileItem.fileName.split('.').pop().toLowerCase();


    if (extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' ) {

      var imageName = cdateTime.toString() + uploadFileItem.fileName.substring(0, uploadFileItem.fileName.lastIndexOf('.'));
      imageName = imageName.replace(/[^A-Z0-9]/ig, "") + "." + uploadFileItem.fileName.split('.').pop();

      fileName = this.IMAGEFOLDER + '/' + userId + '/' + imageName;
      imageUrl = environment.s3.Url + this.BUCKET + '/' + fileName; 
      
     
      
      return await this._mwWriteReviewService.uploadfile(uploadFileItem.file, fileName).then( (uploadedImageUrl) => {

          // console.log('uploadedImageUrl') ;
          // console.log(uploadedImageUrl) ;
            
            if(this.formReview.value.imageUrls == null) {
              this.formReview.value.imageUrls = [] ;
            }
      
            this.formReview.value.imageUrls.push(imageUrl) ;

          return uploadedImageUrl;        
      }) ;
      



    }
    else {

      var videoName = cdateTime.toString() + uploadFileItem.fileName.substring(0, uploadFileItem.fileName.lastIndexOf('.'));  
      videoName = videoName.replace(/[^A-Z0-9]/ig, "") + ".mp4";
      fileName = this.VIDEOFOLDER + '/' + userId + '/' + videoName;
      videoUrl = environment.s3.Url + this.BUCKET + '/' + fileName;

      this.formReview.value.videoUrl = videoUrl;

      return await this._mwWriteReviewService.uploadfile(uploadFileItem.file, fileName).then( (uploadedVideoUrl) => {

      // console.log('uploadedVideoUrl') ;
      // console.log(uploadedVideoUrl) ;

      return videoUrl;
    }) ;

  }
  }

  public preview: any;
  public reader = new FileReader();  
  public file: any[];
  public videoUploaded = false;
  public imageUploaded = false;

  selectFile(event, sender: string) {
    // console.log(event.target.files)

    let fileExists=false;

    if(event.target.files.length === 0) {
      return;
    }

  
    var newItem = new ReviewFile();
    newItem.fileName = event.target.files[0].name;
    newItem.file = event.target.files[0];


    let extension = newItem.fileName.split('.').pop().toLowerCase();

    if( sender=='image' && ( extension == 'jpg' || extension == 'jpeg' || extension == 'png' || extension == 'gif' )) {
      newItem.fileType = reviewFileType.image;
    }  
    else if(sender=='video' && 
    (extension == 'mp4'|| extension == 'm4v' || extension == 'mov' || extension == '3gp' || extension == '3g2' || extension == 'wmv' 
    || extension == 'asf' || extension == 'avi' || extension == 'flv' || extension == 'mkv' || extension == 'webm' )){
      newItem.fileType = reviewFileType.video;
    }
    else {
      this.resetFileComponents();
      return ;
    }

   

    if(this.selectedFiles.length>0) {

      let videoExistsList = this.selectedFiles.filter(x=> x.fileType == reviewFileType.video).length > 0

      if(videoExistsList && newItem.fileType == reviewFileType.video) {
        this.resetFileComponents();
        return;
      }

      fileExists = this.selectedFiles.filter(x=> x.fileName == newItem.fileName).length > 0

      if(fileExists) {
        this.resetFileComponents();
        return;
      }
    
    }
    this.selectedFiles.push(newItem);
    if(newItem.fileType == reviewFileType.video) {
      this.videoExists =true;
    }

    this.resetFileComponents();
  }

  public resetFileComponents(): void {
    this.videoInputObj.nativeElement.value='' ;
    this.imageInputObj.nativeElement.value='' ;
  }

  public deleteFile(index: number): void{

    let item = this.selectedFiles.splice(index,1) ;
    
    if(item[0].fileType == reviewFileType.video) {
      this.videoExists =false;
    }

  }


  acceptedMimeTypes =[
    'image/gif',
    'image/jpeg',
    'image/png'
  ];

  @ViewChild('fileInput') fileInput: ElementRef;
  fileDataUri = '';
  errorMsg = '';

  previewFile() {
    const file = this.fileInput.nativeElement.files[0];

    if (file && this.validateFile(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
      reader.onload = () => {
        this.fileDataUri = reader.result;
      }
    } else {
      this.errorMsg = 'File must be jpg, png, or gif and cannot be exceed 500 kb in size'
    }
  }

  uploadFile(event: Event) {
    event.preventDefault();

    if (this.fileDataUri.length > 0) {
      const base64File = this.fileDataUri.split(',')[1];
      //console.log(base64File);
    }
  }

  validateFile(file) {
    //return this.acceptedMimeTypes.includes(file.type) && file.size < 500000;
  }

  public uploadedFiles: Observable<Array<UploadFiles>>;
  showFiles() {
    this.uploadedFiles = this._mwWriteReviewService.getFiles();
    //console.log(this.uploadedFiles);
  }

  public getAvatar(avatar: string): string {
    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }



}
