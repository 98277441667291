
import { Client } from './Client';
import { RelationshipType } from './RelationshipType';


export interface IClientRelationshipClient  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    clientId?: number;
    client?: Client;
    secondClientId?: number;
    secondClient?: Client;
    type?: RelationshipType;
    confirmed?: boolean;
}
export class ClientRelationshipClient  implements IClientRelationshipClient {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public clientId: number;
    public client: Client;
    public secondClientId: number;
    public secondClient: Client;
    public type: RelationshipType;
    public confirmed: boolean;
    constructor(obj?: IClientRelationshipClient) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.secondClientId = obj.secondClientId !== undefined ? obj.secondClientId : null;
        this.secondClient = obj.secondClient !== undefined ? obj.secondClient : null;
        this.type = obj.type !== undefined ? obj.type : null;
        this.confirmed = obj.confirmed !== undefined ? obj.confirmed : null;
    }
}
// For reactive forms intended
export interface IFClientRelationshipClient  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is number
    */
    clientId?: any; 
    /**
    * Is Client
    */
    client?: any; 
    /**
    * Is number
    */
    secondClientId?: any; 
    /**
    * Is Client
    */
    secondClient?: any; 
    /**
    * Is RelationshipType
    */
    type?: any; 
    /**
    * Is boolean
    */
    confirmed?: any; 
}
