import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MwHomeComponent } from './mw-home.component';
import { MwHomeRoutingModule, routedComponents } from './mw-home.routing';
import { SearchResolver, ListingResolver } from '../../../shared/services';
import { MwListingMapPreviewService } from '.';
import { MwAlertComponent} from '../../../shared/components';
import { HomeComponents, HomeServices, HomeEntryComponents } from '.';
import { NgxCarouselModule } from 'ngx-carousel';
import { AgmCoreModule } from '@agm/core';
import { MwAlertService } from '../../../shared/components/mw-alert/mw-alert.service';

@NgModule({
  imports: [
    SharedModule,
    FlexLayoutModule,
    MwHomeRoutingModule,
    AgmCoreModule,
    NgxCarouselModule,
  ],
  declarations: [
    MwHomeComponent,
    HomeComponents,
  ],
  providers: [
    SearchResolver,
    ListingResolver,
    MwListingMapPreviewService,
    HomeServices
  ],
  entryComponents: [
    HomeEntryComponents
  ]
})
export class MwHomeModule { }
