
export interface IThumbsReview  {
    googleReviewId?: number;
    listingReviewId?: number;
    clientId?: number;
    status?: boolean;
}
export class ThumbsReview  implements IThumbsReview {
    public googleReviewId: number;
    public listingReviewId: number;
    public clientId: number;
    public status: boolean;

    constructor() {
        this.googleReviewId =  null;
        this.listingReviewId = null;
        this.clientId =  null;
        this.status = null;
    }
}
