import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'mw-test-video',
  styleUrls: ['./mw-static-pages.scss'],
  template: `
    <video controls="" height="240" width="100%">
        <source src="https://s3-us-west-1.amazonaws.com/mapwordsprivate/testvideo.mp4" type="video/mp4" oncontextmenu="return false;"> 
    </video>
    `
})
export class MwTestVideoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
