


export interface ITokenModel  {
    listingId?: number;    
    pin?: string;
}
export class TokenModel  implements ITokenModel {
    listingId: number;
    pin: string;
    constructor() {
        this.listingId = null;
        this.pin =  null;
    }
}
