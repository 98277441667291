import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { MapwordsStore } from '../shared/store';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GetListingBySearchTermRouteModel as Filter, Client } from '../shared/models';
import {observable} from 'mobx-angular'
import { LocationService, ListingsService, DyanmicTagService } from '../shared/services/api';
import { MwAlertManagerComponent } from '../shared/components';
import { ActionTypes } from '../shared/viewmodels';
import { MatSnackBar } from '@angular/material';
import { MwSnackBarComponent } from '../shared/components/mw-alert/mw-snack-bar.component';
import { environment } from '../environments/environment';
import { DynamicTagModel } from '../shared/models/DynamicTagModel';
import { MwHomeComponent } from './feature/mw-home/mw-home.component';
import { Meta, Title } from '@angular/platform-browser';
import { S3filesService } from '../shared/services';
import { WindowRef } from '../../node_modules/@agm/core/utils/browser-globals';
import { CookieService } from '../../node_modules/ngx-cookie-service';
import { Observable } from '../../node_modules/rxjs';

@Component({
  selector: 'mw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent { 
  public imagesOrigin: string = environment.imagesOrigin;
  public applicationUrl: string = environment.applicationUrl;  

  @observable public user: Client = new Client({});
  public staticMenuBar: boolean = false;

  public getEmail: string;

  public lastLoadedDynamicTagPage = '' ;
  public removeTagList: DynamicTagModel[] = [] ;

  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 

  constructor(
    public store: MapwordsStore,
    private _router: Router,
    public MwHomeComponent: MwHomeComponent,
    private dynamicTagService: DyanmicTagService,
    private meta: Meta,
    private ngZone : NgZone ,
    private s3filesService: S3filesService,
    private titleService: Title,
    private winRef: WindowRef,
    private cookieService: CookieService 
  ) {
    this._router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit() {  

    this.cookieExpire.setTime(this.cookieTime);
  
    this.setDyanmicTags();

    // // Emre
    this.setStaticMenu();


    // console.log('counter');
    // console.log(this.cookieService.get('counter')) ;

    // console.log('sessionCounted initial');
    // console.log(sessionStorage.getItem('sessionCounted')) ;


    if (this.user) {

      // console.log('this.user');
      // console.log(this.user);

      this.store.auth.user.avatar = this.user.avatar;
      this.store.auth.user.signedAvatar = this.user.signedAvatar;


        if( Number.isNaN(+localStorage.getItem('counter')) || localStorage.getItem('counter') === null) {
          localStorage.setItem('counter', '0');
        }
        else if( sessionStorage.getItem('sessionCounted') == null) {

          var counter =  Number(localStorage.getItem('counter')) ;
          if(counter < 1){
         
            // drop existing session

            this.store.auth.forceSignOut();

            
            // localStorage.removeItem('user');
            // localStorage.removeItem('counter');
            // sessionStorage.removeItem('sessionCounted');

            // console.log('isSignedIn');
            // console.log(this.store.auth.isSignedIn);

            this.setStaticMenu();

            return;
          }
        }        

        if(sessionStorage.getItem('sessionCounted') == null) {

          sessionStorage.setItem('sessionCounted', new Date().getTime().toString());
        }

          
          if( Number.isNaN(+localStorage.getItem('counter')) || localStorage.getItem('counter') === null) {
            localStorage.setItem('counter', '0' );
          }
          
          var counter =  Number(localStorage.getItem('counter')) ;
          counter += 1 ;

          localStorage.setItem('counter', counter.toString() );



        // console.log('counter');
        // console.log(this.cookieService.get('counter')) ;

        // console.log('sessionCounted');
        // console.log(sessionStorage.getItem('sessionCounted')) ;

    }


    window.addEventListener("beforeunload", function (e) {


      if( ! Number.isNaN(+localStorage.getItem('counter')) &&  localStorage.getItem('counter') !== null) {

        var counter =  Number(localStorage.getItem('counter')) ;
        counter -= 1 ;
        localStorage.setItem('counter', counter.toString() );
        // sessionStorage.removeItem('sessionCounted');
      }
  
    });    
  }


  public setStaticMenu(): void {

    this.user =null;
    
    if(this.cookieService.get('user')) {
        this.user = JSON.parse(this.cookieService.get('user'));
    }

    // console.log('user');
    // console.log(this.user) ;

     if(this.user == null) {
      this.staticMenuBar = true;
    }
    else {
      if(this.user.role === 2) {
        this.staticMenuBar = true;
      }

      if(this.user.email) {
        this.getEmail = this.user.email;
      }

    }


  }


  public profile(): void {
    this._router.navigateByUrl('/cp/user');
  }

  public signOut(): void {
    this.store.auth.signOut(() => {
      //localStorage.removeItem('user');
      //localStorage.removeItem('counter');
      //sessionStorage.removeItem('sessionCounted');

      //console.log(localStorage);
      //console.log(this.store.listing);


      this._router.navigate(["/home"]);

      Observable.interval(200)
      .subscribe(i => { 
          location.reload();
      })

    });
  }

  public openPage(link: string): void {
    
    //this.winRef.getNativeWindow().location = '#/' + link ;  
    this.winRef.getNativeWindow().location = '/' + link ;  
    
  }

  public agentSignOut(): void {
    this.store.auth.signOut(() => {
      // localStorage.removeItem('user');
      // localStorage.removeItem('counter');
      // sessionStorage.removeItem('sessionCounted');


      // this.store.alerts.add({
      //   title: 'Agent',
      //   message: 'You are leaving the agent panel',
      //   execute: () => {
      //     // location.reload();
      //   }
      // });
      


      this._router.navigate(["/home"]);


      // this._router.navigateByUrl('/');
      // location.reload();

      Observable.interval(200)
      .subscribe(i => { 
          location.reload();
      })

    })
  }

  public goHome(): void {
    // location.href = '/';
    
    this._router.navigateByUrl('/');

    // Observable.interval(200)
    // .subscribe(i => { 
    //     location.reload();
    // })


    // console.log(this.MwHomeComponent.searchTerms)
    // this.MwHomeComponent.searchTermsReset(this.MwHomeComponent.searchTerms);
    // this.MwHomeComponent.searchTerms.forEach(x => {
    //   x.isBackClosed = false;
    // });
  }
  
  public goAgentsHomePage(): void {

    this.ngZone.run(() => this._router.navigate(['/cp/agent'])); 
    // Observable.interval(300)
    // .subscribe(i => { 
    //     location.reload();
    // })
 
  }


  public getDisplayName(): string {
    let userDisplayName = '';

    
    this.user =null;
    
    if(this.cookieService.get('user')) {
      this.user = JSON.parse(this.cookieService.get('user'));
    }

    if (this.user) {
      if (this.user.firstName !== undefined && this.user.lastName !== undefined) {
        userDisplayName = this.user.firstName + ' ' + this.user.lastName;
      }
      else {
        userDisplayName = this.user.email;
      }
    }
    
    return userDisplayName;
  }

  public getUserAvatar(): string {


    this.user =null;
    
    if(this.cookieService.get('user')) {
      this.user = JSON.parse(this.cookieService.get('user'));
    }

    var result = this.user.signedAvatar ;
    
    // console.log('getUserAvatar');
    // console.log(result);

    return result;
          
  }

  public getIsUserSignedIn(): boolean {
    // console.log('getIsUserSignedIn');
    // console.log(this.store.auth);    
    return this.store.auth.token !== null ;
  }

  public getIsAgentSignedIn(): boolean {
    // console.log('getIsUserSignedIn');
    // console.log(this.store.auth);    
    if( this.store.auth.token !== null ) {

      if(this.store.auth.isAgent) {
        return true;
      }
    }

    return false;
  }


  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }


  public setDyanmicTags(){

      this._router.events.subscribe((res) => { 

        if(this._router.url !== this.lastLoadedDynamicTagPage) {
          this.lastLoadedDynamicTagPage = this._router.url ;

          
          this.setMetaTag() ;
        }

      });



  }

  public setMetaTag(): void {
    
      // console.log(this._router.url + ' URL');
      this.dynamicTagService.GetDynamicTags(this._router.url,'meta').subscribe (x => {

        // console.log('x.data') ;
        // console.log(x) ;

        this.removeMetaTags(this.removeTagList, true);
        // Emre 
        // Its for removing existing tags
        this.removeMetaTags(x.data, false);

        // console.log('after removeMetaTags') ;

        // console.log('x.data') ;
        // console.log(x.data) ;

        x.data.forEach( item =>{

          if(item.attributeName1 === 'name' && item.attributeName2 === 'content') {
            this.meta.addTag({ name: item.attributeValue1, content: item.attributeValue2 });
          }
          else if(item.attributeName1 === 'property' && item.attributeName2 === 'content') {
            this.meta.addTag({ property: item.attributeValue1, content: item.attributeValue2});
          }  
          else if(item.attributeName1 === 'property' && ( item.attributeName2 === null || item.attributeName2 === undefined ) ) {
            this.meta.addTag({ property: item.attributeValue1});
          }
          else if(item.attributeName1 === 'title') {
            this.setTitle(item.attributeValue1);
          }

        });

        this.removeTagList = x.data ;
    });




  }


  public removeMetaTags(list: DynamicTagModel[], exactMatch: boolean): void {

    // console.log('this.removeTagList');
    // console.log(this.removeTagList);

      list.forEach( item =>{
    
        var allTags = this.meta.getTags( item.attributeName1 + '="' + item.attributeValue1 + '"') ;

        // console.log('allTags');
        // console.log(allTags);

        if(allTags.length === 0 || allTags === null) {
          return
        }

        allTags.forEach( ( currentTag: HTMLMetaElement ) =>{

          if(currentTag.content === item.attributeValue2 || item.attributeValue2 === null || item.attributeName2 === undefined || !exactMatch  ) {

            // console.log('currentTag');
            // console.log(currentTag);

            this.meta.removeTagElement(currentTag) ;
          }
        });
   
      });
  }


}
