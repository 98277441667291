import { Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';

import { Client, User, IFUser } from '../../../../../shared/models';
import { MapwordsStore } from '../../../../../shared/store';
import { MwFormControlComponent } from '../../../../../shared/components';
import { EmailValidator } from '../../../../../shared/forms/validators';
import { ClientsService } from '../../../../../shared/services/api';

@Component({
  selector: 'mw-user-security',
  templateUrl: './mw-user-security.component.html',
  styleUrls: ['./mw-user-security.component.scss']
})

export class MwUserSecurityComponent implements OnInit {

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;


  public userForm: FormGroup;

  public emailAvailble = true;

  public isUsernameAvailable = true;

  public isEmailAvailable = true;

  public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,4}$';

  @Input()
  public client: Client;
  public user: User;

  constructor(
    public store: MapwordsStore,
    private _fb: FormBuilder,
    private _clientsService: ClientsService,
    private _clientService: ClientsService
  ) { }

  ngOnInit() {
    
    this.initForm();

    //console.log(this.store.auth.user.username);
    //console.log(this.store.auth.user.email);
  }

  public get isFormValid(): boolean {
    if (this.userForm.valid && this.isUsernameAvailable && this.isEmailAvailable && ( this.userForm.value.password != '' || this.userForm.value.email != ''  )) {
      return true;
    }
    return false;
  }

  public userSubmit(isSaved: boolean): void {
    let user: User = this.userForm.value;
    

    if (isSaved && this.isFormValid) {
      this.store.auth.updateSecurityUser(this.userForm.value);
      this.userForm.setValue({
        password: '',
        confirmPassword: '',
        email: '',
        confirmEmail: ''
      });
    } else {
      this.initForm();
    }
  }


  public usernameAvailable(): void {
    this._clientService
      .GetUniqueUsername(this.userForm.value.username)
      .subscribe(x => {
        if (this.userForm.value.username === this.store.auth.user.username) {
          this.isUsernameAvailable = true;
          return;
        }
        this.isUsernameAvailable = x.data.answer;
      });
  }
  
  public emailAvailable(): void {
    this._clientService
      .GetUniqueEmail(this.userForm.value.email)
      .subscribe(x => {
        if (this.userForm.value.email === this.store.auth.user.email) {
          this.isEmailAvailable = true;
          return;
        }
        this.isEmailAvailable = x.data.answer;
      });
  }

  public isEmail: {[key: string]: boolean} | null;

  public validateEmail(control: FormControl) {
    const email = control.value;
    this._clientsService
      .GetUniqueEmail(email)
      .subscribe(x => {        
        //this.isEmailAvailable = x.data.answer;
        
        if (x.data.answer) {
          this.isEmailAvailable = true;
          this.isEmail = null;
          return;
        }
        this.isEmailAvailable = x.data.answer;
        this.isEmail = {emailTaken: true};
        //return this.isEmailAvailable ? null : {emailTaken: true};
      });    
  }

  private initForm(): void {
    this.userForm = this._fb.group(<IFUser>{
     // username: [this.store.auth.user.username, [Validators.required, Validators.minLength(2)]],
      //email: [this.store.auth.user.email, [Validators.required, EmailValidator]],
      email: ['', [
        EmailValidator,
        Validators.pattern(this.emailPattern),
        this.validateEmail.bind(this)
      ]],
      confirmEmail: [''],
      password: ['', [Validators.minLength(6)]],
      confirmPassword: ['']
    }, {
        validator: Validators.compose([
          this.passwordMatcher,
          this.emailMatcher,
        ])
      });
  }

  public validateKey(event: KeyboardEvent): boolean {
    if (String.fromCharCode(event.keyCode).match(/[a-z0-9]/g) == null) {
      return false;
    }
  }

  private passwordMatcher(control: AbstractControl): { [key: string]: boolean } {
    const password = control.get('password');
    const confirm = control.get('confirmPassword');
    // tslint:disable-next-line:curly
    if (!password || !confirm) return null;
    return password.value === confirm.value ? null : { nomatchPass: true };
  }

  private emailMatcher(control: AbstractControl): { [key: string]: boolean } {
    const newEmail = control.get('email');
    const confirmEmail = control.get('confirmEmail');
    // tslint:disable-next-line:curly
    if (!newEmail || !confirmEmail) return null;
    return newEmail.value === confirmEmail.value ? null : { nomatchEmail: true };
  }
}

  