import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RG } from '../../models/response';

import { Client } from '../../models/Client';
import { User } from '../../models/User';
import { Address } from '../../models/Address';
import { BoolResponseApiModel } from '../../models/BoolResponseApiModel';
import { VerifyLoginModel } from '../../models/VerifyLoginModel';
import { GetListingForExistingClientModel } from '../../models/GetListingForExistingClientModel';
import { BaseRestService } from './base.service';
import { bool } from '../../../../node_modules/aws-sdk/clients/signer';
import { CookieService } from '../../../../node_modules/ngx-cookie-service';

@Injectable()
export class ClientsService extends BaseRestService<any> {
    
    constructor( _http: Http, _cookieService: CookieService) {
        super(_http, _cookieService);
    }
    /**
    *   Get all Clients
    *   @return An array of Clients
    */
    public Get(): Observable<RG<Client[]>> {
       
        return this.get(`api/v1/client`);
    }

    /**
    *   Get Client by ID
    *   @return A client object
    *   @param id - The ID of the Client
    */
    public GetById(id: number ): Observable<RG<Client>> {
        
        return this.get(`api/v1/client/${id}`);
    }


    /**
    *   Get Client by ID
    *   @return A client object
    *   @param id - The ID of the Client
    */
    public GetUniqueEmail(email: string ): Observable<RG<BoolResponseApiModel>> {
       
        return this.get(`api/v1/client/uniqueemail?email=${email}`);
    }

    /**
    *   Get Client by ID
    *   @return A client object
    *   @param id - The ID of the Client
    */
    public GetUniqueUsername(username: string ): Observable<RG<BoolResponseApiModel>> {
       
        return this.get( `api/v1/client/uniqueusername?username=${username}`);
    }

    /**
    *   Search a client by email
    *   @return List Array
    *   @param email -
    */
    public GetListingForExistingClient(email: string ): Observable<RG<GetListingForExistingClientModel>> {

        return this.get(`api/v1/client/clientemail?email=${email}`);
    }


    
    /**
    *   Search a client by email, firstName, lastName, middleName and username
    *   @return
    *   @param general -
    */
    public GetBySearchTerm(general: string ): Observable<RG<Client[]>> {
       
        return this.get( `api/v1/client/search?general=${general}`);
    }


        
    /**
    *   Search a client by email, firstName, lastName, middleName and username
    *   @return
    *   @param searchterm -
    */
   public GetBySearchTermForAdmin(searchterm: string, pageSize: number, page: number ): Observable<RG<Client[]>> {
       
    return this.get( `api/v1/client/adminsearch?searchterm=${searchterm}&pageSize=${pageSize}&page=${page}`);
}


    /**
    *   Search a client by email, firstName, lastName, middleName and username
    *   @return
    *   @param searchterm -
    */
   public PostUpdateClientStatusForAdmin(id:number , status:bool ): Observable<RG<Client>> {
       
    return this.post( `api/v1/client/admin/status?id=${id}&status=${status}`, null);
}

    /**
    *   Get the complete profile of a Client
    *   @return
    *   @param id -
    */
    public GetProfileById(id: number ): Observable<RG<Client>> {
       
        return this.get(`api/v1/client/profile?id=${id}`);
    }

    /**
    *   Get basic information of the client listings
    *   @return
    *   @param id -
    */
    public GetClientListings(id: number ): Observable<RG<Client>> {
        
        return this.get( `api/v1/clients/listings?id=${id}`);
    }

    /**
    *   Login
    *   @return A Client object
    *   @param username - Username
    *   @param password - Password(ClearText)
    */
    public GetLogin(username: string , password: string ): Observable<RG<Client>> {
       
        return this.get(`api/v1/client/login?username=${username}&password=${password}`);
    }

    /**
    *   Login
    *   @return A Client object
    *   @param username - Username
    *   @param password - Password(ClearText)
    */
    public GetBasicProfile(): Observable<RG<Client>> {
       
        return this.get( `api/v1/client/profile/basic`);
    }

    /**
    *   Add a Client
    *   @return A Client object.
    *   @param value - A Client object
    */
    public Post(value: Client ): Observable<RG<Client>> {
        
        return this.post( `api/v1/client`, JSON.stringify(value) );
    }

    /**
    *   Update a Client object.
    *   @return A Client object
    *   @param value - A Client object.
    */
    public Put(value: Client ): Observable<RG<Client>> {
       
        return this.put( `api/v1/client`, JSON.stringify(value) );
    }

    /**
    *   Verify Client
    *   @return Bool
    *   @param token - Token
    */
    //public PutVerifyClient(token: string ): Observable<RG<BoolResponseApiModel>> {
    public PutVerifyClient(token: string): Observable<RG<VerifyLoginModel>> {
        
        return this.put( `api/v1/client/verify?token=${token}`,null);
    }

    /**
    *   Verify Account
    *   @return Bool
    *   @param token - Token
    */
    public PutRecoveryVerify(token: string , password: string ): Observable<RG<BoolResponseApiModel>> {
       
        return this.put( `api/v1/client/recovery/verify?token=${token}`, JSON.stringify(password) );
    }

    /**
    *   Update the Client Security Information
    *   @return bool
    *   @param username - New Username
    *   @param email - New Email
    *   @param password - New Password
    */
    public PutClientSecurity(model: User ): Observable<RG<BoolResponseApiModel>> {
        
        return this.put( `api/v1/client/security`, JSON.stringify(model) );
    }

    /**
    *   Verify Account
    *   @return Bool
    *   @param token - Token
    */
    public PutRecovery(email: string ): Observable<RG<BoolResponseApiModel>> {
       
        return this.put( `api/v1/client/recovery`, JSON.stringify(email) );
    }

    /**
    *   Update the general info of a Client object.
    *   @return A Client object
    *   @param value - A Client object.
    */
    public PutProfile(value: Client ): Observable<RG<Client>> {
        return this.put( `api/v1/client/profile`, JSON.stringify(value) );
    }

    /**
    *   Update the work info of a Client object.
    *   @return A Client object
    *   @param value - A Client object.
    */
    public PutWork(value: Client ): Observable<RG<Client>> {
       
        return this.put(`api/v1/client/work`, JSON.stringify(value) );
    }

    /**
    *   Update the places info of a Client object.
    *   @return A Client object
    *   @param value - A Client object.
    */
    public PutPlaces(value: Client ): Observable<RG<Client>> {
       
        return this.put(`api/v1/client/places`, JSON.stringify(value) );
    }

    /**
    *   Update the relationships of a Client object.
    *   @return A Client object
    *   @param value - A Client object.
    */
    public PutRelationShip(value: Client ): Observable<RG<Client>> {
       
        return this.put(`api/v1/client/relationships`, JSON.stringify(value) );
    }

    /**
    *   Delete a Client object
    *   @return A empty client.
    *   @param id - The ID of the Client object.
    */
    public Delete(id: number ): Observable<RG<Client>> {
       
        return this.delete(`api/v1/client?id=${id}`);
    }

    /**
    *   Get Client by address
    *   @return A Client Object.
    *   @param id - The ID of the Address object.
    */
    public GetAddress(id: number ): Observable<RG<Address>> {
      
        return this.get(`api/v1/address?id=${id}`);
    }

    /**
    *   Helping typescript
    *   @return
    *   @param model -
    */
    public SetProperties(model: Address , model1: BoolResponseApiModel ): Observable<RG<string>> {
        
        return this.post(`api/clients/`, JSON.stringify({ model: model, model1: model1 }) );
    }
}

