import { NgModule } from '@angular/core';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MwControlPanelRoutingModule, routedComponents } from './mw-control-panel.routing';
import { SharedModule } from '../../shared.module';
import { MwControlPanelComponent } from './mw-control-panel.component';
import { userComponents } from './mw-user';
import { MwToggleInputsComponent } from '../../../shared/components';
import { CpListingResolver, CpUserResolver, CpResolver } from '../../../shared/services';
import { ClickoutsideCPDirective } from './clickoutsideCP.directive';
import { MwAgentComponent } from './mw-agent/mw-agent.component';
import { MwAgentSearchComponent } from './mw-agent/mw-agent-search/mw-agent-search.component';
import { MwAgentSearchListComponent } from './mw-agent/mw-agent-search-list/mw-agent-search-list.component';
import { MwAgentSearchFormComponent } from './mw-agent/mw-agent-search-form/mw-agent-search-form.component';
import { ClientAuthGuard, AgentAuthGuard } from '../mw-auth/auth-guard.service';
import { MwAgentGenerateFormComponent } from './mw-agent/mw-agent-generate-form/mw-agent-generate-form.component';
import { AgentSearchResolver } from '../../../shared/services/agentsearch.resolver';
import { MwAdminVideoComponent } from './mw-admin/mw-admin-video/mw-admin-video.component';
import { listingComponents } from './mw-listing';
import { MwListingMapPreviewService } from '../mw-home';
import { MwAdminUserComponent } from './mw-admin/mw-admin-user/mw-admin-user.component';

@NgModule({
  imports: [
    SharedModule,
    FlexLayoutModule,
    AgmCoreModule,
    MwControlPanelRoutingModule
  ],
  declarations: [
    routedComponents,
    userComponents,
    listingComponents,
    ClickoutsideCPDirective,
    MwAgentComponent,
    MwAgentSearchComponent,
    MwAgentSearchListComponent,
    MwAgentSearchFormComponent,
    MwAgentGenerateFormComponent,
    MwAdminVideoComponent,
    MwAdminUserComponent
],
  providers: [
    CpListingResolver,
    CpUserResolver,
    CpResolver,
    ClientAuthGuard,
    AgentAuthGuard,
    AgentSearchResolver,
    MwListingMapPreviewService,
    GoogleMapsAPIWrapper
  ],
})
export class MwControlPanelModule { }
