import { Injectable } from '@angular/core';
import { action, observable, computed } from 'mobx';
import { Client } from '../models';
import { ClientsService } from '../services/api';
import { AlertsStore } from './alerts.store';

@Injectable()
export class ClientStore {

  @observable
  public client: Client = new Client({ id: 3 });

  constructor(
    private _clientsService: ClientsService,
    private _alerts: AlertsStore
  ) { }


  @action('UPDATE CLIENT PROFILE')
  public updateProfile(client: Client): void {
    // this._clientsService.PutProfile(client).subscribe(x => {
    //   this.client = x.data;
    // });
  }

  @action('[CLIENT][SIGN UP] Try')
  public signUp(model: Client, after): void {
    this._clientsService.Post(model)
      .subscribe(
        x => 
        {
          //console.log(x.data);
          this.signUpSuccess(x.data, after)
        },
        err => this.signUpFailed()
      );
  }

  @action('[CLIENT][SIGN UP] Success')
  private signUpSuccess(client: Client, after): void {
    this._alerts.add({
      title: 'Success',
      message: 'Please check your email and confirm your account.',
      execute: after
    });
  }

  @action('[CLIENT][SIGN UP] Failed')
  private signUpFailed(): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to complete the operation at this time, please try again later.',
    });
  }

}
