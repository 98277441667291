/// <reference types="@types/googlemaps" />
import { Component, OnInit, Input, ViewEncapsulation, ViewContainerRef, ViewChild, ViewChildren, ContentChild,
 QueryList, ElementRef, AfterViewInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, NgZone} from '@angular/core';
import {  FormBuilder,  FormArray,  FormGroup,  Validators,  AbstractControl,  FormControl } from '@angular/forms';

import { MwFormControlComponent, MwImageEditorService, MwMapComponent } from '../../../../shared/components';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';
import { toJS, reaction, when } from 'mobx';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { quickSearchTerm } from '../../../../shared/viewmodels/IQuickSearch';
import { cloneDeep } from 'lodash';
import { GetListingBySearchTermRouteModel as Filter } from '../../../../shared/models';
import { Title } from '@angular/platform-browser';
//import { google } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'mw-agent',
  templateUrl: './mw-agent.component.html',
  styleUrls: ['./mw-agent.component.scss']
})
export class MwAgentComponent implements OnInit, AfterViewInit {

  @ViewChild(MwMapComponent)
  public map: MwMapComponent;

  public quickSearchDisplay = true;

  public ShowLeftNav = true;

  public searchTerms = cloneDeep(quickSearchTerm);

  public position: { lat, lng };

  public showPosition = false;

  public showFilterPosition = true;

  public isHome = true;

  public lastResultsPlaceId: string;

  public showmap = true;

  public collaps = false;

  public showSearch = true;

  public lat: number;

  public lng: number;

  constructor(
    public store: MapwordsStore,
    private _zone: NgZone,
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleService: Title
  ) { }

  ngOnInit() {
    // this.setFilters();
    // this.setMap();
    // console.log("Init", this._route.firstChild);

    this._router.events.forEach(e => {
      if (e instanceof NavigationStart) {
        this.store.search.startLoading();
      }
      if (e instanceof NavigationEnd) {
        this.store.search.stopLoading();
        this.searchTerms = cloneDeep(quickSearchTerm);
      }
    });

  }

  ngAfterViewInit() {
    // console.log("After Init", this.store.search.filter.latitude);
    
    this.setReactions();    
  }

  public showMap(): void {
    this.showmap = !this.showmap;
  }

  public collapsSearch(): void {
    this.collaps = !this.collaps;
    this.showSearch = !this.showSearch;
  }

  public search(): void {
    console.log('search');
  

    if (this.store.search.filter.searchterm === '' || this.store.search.filter.searchterm === null) {
      //this.centerWithMarkers();
      // this.store.search.stopLoading();
    } else {      
      //this.store.search.startLoading();
      // this.store.search.updatePage(1);



      this._router.navigate(['/cp/agent', {
        outlets: {
          'agentSearch':
            [
              this.store.search.filter.searchterm,
              this.store.search.filter.latitude,
              this.store.search.filter.longitude,
              this.store.search.filter.distance,
              this.store.search.filter.price,
              this.store.search.filter.rating,
              this.store.search.filter.open,
              this.store.search.page
            ],
          'agentListing': null
        }
      }
      ]);
      // .then(() => { 
      //    console.log("1");
      //    //this.centerWithMarkers();
      //    //this.store.search.stopLoading();
      // });
    }
  }

  public quickSearch(searchTerm: string): void {
    console.log('quickSearch');

    this.searchTermsReset();
    const filter = this.store.search.cloneFilter;
    filter.searchterm = searchTerm;
    this.store.search.updateFilter(filter);
    this.search();
  }

  public setFilters(): void {
    if (this._route.children[0] !== undefined) {
      let params = this._route.children[0].params['value'];
      if (params.searchterm === undefined && this._route.children[1] !== undefined) {
        params = this._route.children[1].params['value'];
      }
      const filter = new Filter({
        latitude: +params.latitude,
        longitude: +params.longitude,
        searchterm: params.searchterm,
        open: params.open,
        price: params.price,
        rating: params.rating,
        distance: params.distance,
      });
      this.store.search.updateFilter(filter);
      this.store.search.updatePage(+params.page);
    }
  }

  ngOnDestroy() {
  }

  public setReactions(): void {
    reaction(
      () => this.store.search.listingResults.map(x => x.active),
      x => {
        
      },
      { name: '[UI][MAP] Hover preview' }
    );

    // It dispose itself so is not calling again
    when(
      () => this.store.search.listingsResultsNumber > 0,
      () => {
        //this.centerWithMarkers();
      }
    );

    reaction(
      () => toJS(this.store.search.filter),
      x => {
        // if (this.store.search.filter.searchterm === '') {
        //   return;
        // }
        this.store.search.resetPagination();
        this.search();
      }, { name: '[UI][FILTER] Changed' }
    );

    reaction(
      () => this.store.searchListing.listing,
      x => {
        
      }, { name: '[UI][MAP][SearchListingStore] Selected' }
    );

    reaction(() => this.store.search.page, page => {
      this.search();
    }, { name: '[UI][PAGINATION] Changed' });
  }

  public setCurrentPosition(): void {
    // console.log('Set current position');
    this.position = {
      lat: 33.6839,
      lng: -117.7947
    };
    if (navigator.geolocation) {
      // console.log('GET LOCATION Home COMPONENT');
      navigator.geolocation.getCurrentPosition(x => {
        this.position = {
          lat: x.coords.latitude,
          lng: x.coords.longitude
        };
        if (this.store.search.filter.searchterm === '') {
          const filter = this.store.search.cloneFilter;
          filter.latitude = this.position.lat;
          filter.longitude = this.position.lng;
          this.store.search.updateFilter(filter);
        }
      });
    }
  }

  public centerWithMarkers(): void {
   
    this.map.api.getNativeMap().then(map => {
      const bounds = new google.maps.LatLngBounds();

      if (this.isHome) {
        bounds.extend(new google.maps.LatLng(this.store.search.filter.latitude, this.store.search.filter.longitude));
        map.fitBounds(<any>bounds);
      }

      if (this.store.search.listingsResultsNumber > 0) {
        this.quickSearchDisplay = false;
        bounds.extend(new google.maps.LatLng(this.store.search.filter.latitude, this.store.search.filter.longitude));
        // if (this.lastResultsPlaceId !== this.store.search.listingResults[0].googlePlaceId) {
        this.store.search.listingResults.forEach(x => {
          bounds.extend(new google.maps.LatLng(x.businessAddress.latitude, x.businessAddress.longitude));
        });
        map.fitBounds(<any>bounds);
        // }
      }
      this._zone.runOutsideAngular(() => {
        google.maps.event.addDomListenerOnce(map, 'bounds_changed', () => {
          this._zone.run(() => {
            if (this.isHome) {
              map.setCenter({ lat: this.store.search.filter.latitude, lng: this.store.search.filter.longitude });
              map.setZoom(13);
              if (window.innerWidth < 490) {
                //  console.log('Run Outside Angular Mobile');
                map.panBy(0, 10);
              } else if (window.innerWidth < 740 && window.innerWidth > 500) {
                map.panBy(-200, 0);
              } else {
                // console.log('Recenter Initialization');
                // console.log(
                //   this.store.search.filter.latitude,
                //   this.store.search.filter.longitude
                // );
              }
            }
            if (this.store.search.listingResults.length > 0) {
              // console.log(map.getZoom());
              // if (map.getZoom() > 12) {
              //   map.setZoom(11);
              // }
              if (this.lastResultsPlaceId !== this.store.search.listingResults[0].googlePlaceId) {
                if (window.innerWidth < 490) {
                  //  console.log('Search Lisiting Results Mobile')
                  map.panBy(0, 150);
                } else if (window.innerWidth < 740 && window.innerWidth > 500) {
                  map.panBy(-200, 0);
                } else {
                  map.panBy(-300, 0);
                }
                if (this.store.search.listingsResultsNumber === 1) {
                  map.setCenter({
                    lat: this.store.search.listingResults[0].businessAddress.latitude,
                    lng: this.store.search.listingResults[0].businessAddress.longitude
                  })
                  map.setZoom(13);
                  map.panBy(-300, 0);
                  //  console.log('Center Location Store')
                }
                this.lastResultsPlaceId = this.store.search.listingResults[0].googlePlaceId;
                this.store.searchListing.unSelectListing();
              }
            }
          });
        });
        google.maps.event.addDomListener(window, 'resize', () => {
          google.maps.event.trigger(map, 'resize');
          this._zone.run(() => {
            if (this.store.search.listingsResultsNumber > 0) {
              map.setZoom(10);
              map.setCenter(<any>bounds.getCenter());
              map.fitBounds(<any>bounds);
            } else {
              map.setCenter({ lat: this.store.search.filter.latitude, lng: this.store.search.filter.longitude });
            } if (window.innerWidth < 490) {
              map.panBy(0, 190);
            } else if (window.innerWidth < 740 && window.innerWidth > 500) {
              map.panBy(-200, 0);
            } else {
              map.panBy(-300, 0);
            }
          });
        });

      });

    });
  }

  public searchTermsReset(): void {
    this.searchTerms = cloneDeep(quickSearchTerm);
    // console.log('Reseting');
    // items.forEach(x => {
    //   x.isOpen = false;
    //   x.isBackClosed = true;
    //   if (x.subCategories) {
    //     x.subCategories = this.searchTermsReset(x.subCategories);
    //   }
    // });
    // return items;
  }

}
