
import { Client } from './Client';


export interface IClientPlace  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    name?: string;
    latitude?: number;
    longitude?: number;
    client?: Client;
    clientId?: number;
}
export class ClientPlace  implements IClientPlace {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public name: string;
    public latitude: number;
    public longitude: number;
    public client: Client;
    public clientId: number;
    constructor(obj?: IClientPlace) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.name = obj.name !== undefined ? obj.name : null;
        this.latitude = obj.latitude !== undefined ? obj.latitude : null;
        this.longitude = obj.longitude !== undefined ? obj.longitude : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
    }
}
// For reactive forms intended
export interface IFClientPlace  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    name?: any; 
    /**
    * Is number
    */
    latitude?: any; 
    /**
    * Is number
    */
    longitude?: any; 
    /**
    * Is Client
    */
    client?: any; 
    /**
    * Is number
    */
    clientId?: any; 
}
