import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Renderer } from '@angular/core';

import Cropper from 'cropperjs';

@Component({
  moduleId: module.id,
  selector: 'mw-cropper',
  templateUrl: './mw-cropper.component.html',
  styleUrls: ['./mw-cropper.component.scss']
})
export class MwCropperComponent implements OnInit, AfterViewInit {

  @Input()
  public imageURL;

  @Input()
  public editor = false;

  @ViewChild('imageContainer')
  public image: ElementRef;

  @Output()
  public cancel = new EventEmitter<any>();

  @Output()
  public save = new EventEmitter<string>();

  @Output()
  public blob = new EventEmitter<any>();

  public cropper: Cropper;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer) { }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    const image = this._elementRef.nativeElement.querySelector('#image-container');
    this.cropper = new Cropper(image, {
      viewMode: 1,
      aspectRatio: 1 / 1,
      guides: false,
      background: false,
      cropBoxMovable: true,
      cropBoxResizable: false,
      minCropBoxWidth: 200,
      minCropBoxHeight: 200
    });
  }

  public saveImage(): void {
    const imgUrl = this.cropper.getCroppedCanvas().toDataURL();
    this.save.emit(imgUrl);
  }

  public cancelEditor(): void {
    this.cancel.emit();
  }

}
