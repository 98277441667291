import { Component,ViewEncapsulation, ViewContainerRef, OnInit, HostBinding, ChangeDetectionStrategy, ViewChild,
  ElementRef, OnDestroy, Input, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
// import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
// import {ModalGalleryModule} from 'angular-modal-gallery';
import { Listing, ListingReview, ListingTime, ListingPhoto, Client, Address } from '../../../../shared/models';
import { MapwordsStore } from '../../../../shared/store';
// import {Angular2ImageGalleryModule} from "./../../../angular2-image-gallery";

@Component({
  selector: 'mw-complete-profile-pop',
  templateUrl: './mw-complete-profile-pop.component.html',
  styleUrls: ['./mw-complete-profile-pop.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class MwCompleteProfilePopComponent implements OnInit {
 
  @Input()
  public client: Client ;

  public isClose: boolean = false;

  constructor(
    public store: MapwordsStore,
    ) {

  }

  ngOnInit() {

    // this.client.firstName = this.store.auth.user.firstName; 
    // this.client.lastName = this.store.auth.user.lastName;
    
  }

  onFormSaved(arg: boolean) {
    if(arg) {
      this.isClose = true;
    }
  }
  
  
}
