import { Component, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'mw-localsnaps',
  styleUrls: ['./mw-static-pages.scss'],
  template: `
        <h1 class="localSnaps-header header">
            LocalSnaps <span class="trade-mark">&trade;</span>
        </h1>
        <div class="localSnaps-page" fxLayout="column">
            <div class="static-page-container first" fxLayout="column">
                <div class="intro" fxLayout="column" fxLayoutAlign="center">
                    <h2>Contribute</h2>
                </div>
                <div fxFlex="35%" fxFlex.xs="100%" class="image-container">
                    <img fxShow="true" fxHide.xs="true" src="./assets/images/localSnaps/contribute-image.jpg"/>
                    <img fxShow.xs="true" fxHide.gt-xs="true" src="./assets/images/localSnaps/Conterbute-image-mobile.jpg">
                </div>
            </div>
            <div class="static-page-container white-p2">
                <div fxLayout="column" class="columnsAds" fxFlex="100%" fxFlexOrder.xs="0" fxFlexOffset.xs="0">
                    <h2>Inform</h2>
                </div>
            </div>
            <div class="grey-box second">
                <div class="static-page-container" fxLayout="column">
                    <div fxLayout="column" fxFlex="100%" fxLayoutAlign="space-evenly center">
                        <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap.xs="15px">
                            <div class="text" fxFlex="455px" fxFlex.xs="100%" fxLayout="column" fxFlexOrder="1" fxFlexOrder.xs="2">
                                <h2 class="subtitle">LocalSnaps should include:</h2>
                                <ul class="">
                                    <li>Location where service was rendered</li>
                                    <li>Product or service received</li>
                                    <li>Compliment or description of issue</li>
                                </ul>                                    
                                <h2 class="subtitle p2">Approval process:</h2>
                                <ul class="">
                                    <li>Videos are moderated by Mapwords staff</li>
                                    <li>You will receive notification once approved</li>
                                    <li>No inapproriate content allowed</li>
                                </ul>
                            </div>
                            <div class="image-container" fxFlex.xs="100%" fxLayoutAlign="center end"fxFlexOrder="2" fxFlexOrder.xs="1">
                                <img src="./assets/images/localSnaps/Inform-Image.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="static-page-container third" fxFlex="100%" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="space-evenly center">
                <div class="decide-container" fxFlexOffset.xs="0" fxFlex.xs="100%">
                    <h2 class="decide">
                        Decide
                    </h2>
                    <div class="text">
                        A LocalSnap Video <br> 
                        Is Worth A Thousand Reviews.
                    </div>
                </div>
                <div class="image-container">
                    <div class="new-section-img">
                        <img fxShow="true" fxHide.xs="true" src="./assets/images/localSnaps/Decide-Image.jpg">
                        <img fxShow.xs="true" fxHide.gt-xs="true" src="./assets/images/localSnaps/Decide-image-mobile.jpg">
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top">
            BACK TO TOP
            <i class="material-icons">arrow_upward</i>
        </div>
        <div class="footer">
            <div class="content">
                <!--
                    <div class="social" fxLayout="row" fxLayoutAlign="start center">
                        <p>FOLLOW US ON:</p>
                        <div class="social-links">
                            <a href="">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                -->
                <hr>
                <div class="bottom-footer" fxLayout="row" fxLayout.xs="column">
                    <div class="static-pages" fxLayout="row wrap" fxFlex="auto" fxLayoutAlign.xs="space-evenly center">
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords About Us" [routerLink]="['/about_us']">
                                <span>About Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Advertising" [routerLink]="['/advertising']">
                            <span>Advertising</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="LocalSnaps" [routerLink]="['/localsnaps']">
                            <span>LocalSnaps &trade;</span>
                        </a>                        
                        <!--
                            <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Contact" [routerLink]="['/contact']">
                                <span>Contact Us</span>
                            </a>
                        -->
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Privacy" [routerLink]="['/privacy']">
                            <span>Privacy</span>
                        </a>
                        <a fxLayout="row" fxLayoutAlign="space-evenly start" mat-menu-item title="Mapwords Terms" [routerLink]="['/terms-of-use']">
                            <span>Terms</span>
                        </a>
                    </div>
                    <div class="copyright-desktop" fxHide.xs="true" fxFlex="35%" fxFlex.xs="100%">
                        Copyright &copy; 2019 Mapwords. All Rights Reserved
                    </div>
                    <div class="copyright-mobile" fxHide fxShow.xs="true">
                        &copy; 2019 Mapwords
                    </div> 
                </div>
            </div>
        </div>
    `
})
export class MwLocalSnapsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
