import { Injectable } from '@angular/core';
import { action, observable, computed } from 'mobx';
import { ListingsService } from '../services/api';
import { AlertsStore } from './alerts.store';
import { TokenModel } from '../models/TokenModel';

@Injectable()
export class ListingStore {

  constructor(
    private _alerts: AlertsStore,
    private _listingsService: ListingsService
  ) {}

  @action('[LISTING][VERIFY] Try')
  public verifyListing(model: TokenModel, success: (listingId: number) => void, failed?: () => void): void {
    this._listingsService.PutListingVerify(model)
      .subscribe(
        x => this.verifyListingSuccess(x.data.id, success),
        err => this.verifyListingFailed()
      );
  }

  @action('[LISTING][VERIFY] Success')
  private verifyListingSuccess(listingId: number, success: (listingId: number) => void): void {
    this._alerts.add({
      title: 'Success',
      message: 'Review or add information about your business.',
      execute: () => {
        success(listingId);
      }
    });
  }

  @action('[LISTING][VERIFY] Failed')
  private verifyListingFailed(failed?: () => void): void {
    this._alerts.add({
      title: 'Error',
      message: 'PinCode not found.',
      execute: () => {
        if (failed !== undefined) {
          failed();
          return;
        }
      }
    });
  }

}
