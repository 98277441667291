import { Http, Headers, RequestOptions, Response  } from "@angular/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ErrorResolver } from "../../../app/common/app-error-resolver";
import 'rxjs/add/operator/map';
import { PARAMETERS } from "../../../../node_modules/@angular/core/src/util/decorators";
import { CookieService } from "../../../../node_modules/ngx-cookie-service";
import { RG } from "../../models/response";


export abstract class BaseRestService<T> {

    headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(protected _http: Http, private cookieService: CookieService ){
    }
  
    get(actionUrl:string):Observable<T> {

        if(environment.consoleOutputs.showAPIRequest) {
            console.log('Get request => ' + actionUrl);
            console.log('Previous Version :' + environment.version.previousVersion);
            console.log('Current Version :' + environment.version.currentVersion);
        }
        
        if(this.cookieService.get('authToken')) {
            if (JSON.parse(this.cookieService.get('authToken')) !== null) {
                this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(this.cookieService.get('authToken')).access_token });
            }
        }


        var result = this._http.get(environment.apiUrl + actionUrl , { headers: this.headers })
        .map(resp=>resp.json() as T)
        .catch((response: Response) => {
            return new ErrorResolver().resolve(response);
        });


        if(environment.version.checkVersion) {
            result.subscribe( x=> {
           
                if(x.version && x.data) {
                    // console.log(x.version);
                    // console.log(environment.version.currentVersion);
                    console.log('Server Version :' + x.version);

                    if(x.version != environment.version.previousVersion && x.version != environment.version.currentVersion) {
                        // console.log('Different version');
                        window.location.reload();
                    }
                }
               

            });
        }


        // if(environment.consoleOutputs.showAPIReponse) {
        //     result.subscribe((x)=> {
        //         console.log('Get response => ' + actionUrl);
        //         console.log(x) ;
        //     });
        // }

        return result;
    }

  
    getExternal(actionUrl:string):Observable<T> {

        if(environment.consoleOutputs.showAPIRequest) {
            console.log('Get request => ' + actionUrl);
        }

        var result = this._http.get( actionUrl)
        .map(resp=>resp.json() as T)
        .catch((response: Response) => {
            return new ErrorResolver().resolve(response);
        });

        return result;
    }

    post(actionUrl:string, model: any):Observable<T> {


        if(environment.consoleOutputs.showAPIRequest) {
            console.log('Post request => ' + actionUrl);
            console.log(model);
        }
        

        if(this.cookieService.get('authToken')) {
            if (JSON.parse(this.cookieService.get('authToken')) !== null) {
                this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(this.cookieService.get('authToken')).access_token });
            }
        }

      var result = this._http.post(environment.apiUrl + actionUrl , model , { headers: this.headers })
        .map(resp=>resp.json() as T)
        .catch((response: Response) => {
            return new ErrorResolver().resolve(response);
        });


        // if(environment.consoleOutputs.showAPIReponse) {
        //     result.subscribe((x)=> {
        //         console.log('Post response => ' + actionUrl);
        //         console.log(x) ;
        //     });
        // }

        return result;
    }


    put(actionUrl:string, model: any):Observable<T> {
        
        if(environment.consoleOutputs.showAPIRequest) {
            console.log('Put request => ' + actionUrl);
            console.log(model);
        }
        

        if(this.cookieService.get('authToken')) {
            if (JSON.parse(this.cookieService.get('authToken')) !== null) {
                this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(this.cookieService.get('authToken')).access_token });
            }
        }

      var result = this._http.put(environment.apiUrl + actionUrl , model , { headers: this.headers })
        .map(resp=>resp.json() as T)
        .catch((response: Response) => {
            return new ErrorResolver().resolve(response);
        });


        // if(environment.consoleOutputs.showAPIReponse) {
        //     result.subscribe((x)=> {
        //         console.log('Put response => ' + actionUrl);
        //         console.log(x) ;
        //     });
        // }

        return result;
    }    


    delete(actionUrl:string):Observable<T> {

        if(environment.consoleOutputs.showAPIRequest) {
            console.log('Delete request => ' + actionUrl);
        }
        

        if(this.cookieService.get('authToken')) {
            if (JSON.parse(this.cookieService.get('authToken')) !== null) {
                this.headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer '+ JSON.parse(this.cookieService.get('authToken')).access_token });
            }
        }

      var result = this._http.delete(environment.apiUrl + actionUrl , { headers: this.headers })
        .map(resp=>resp.json() as T)
        .catch((response: Response) => {
            return new ErrorResolver().resolve(response);
        });


        // if(environment.consoleOutputs.showAPIReponse) {
        //     result.subscribe((x)=> {
        //         console.log('Delete response => ' + actionUrl);
        //         console.log(x) ;
        //     });
        // }

        return result;
    }



      
  } 

