import { Directive , Input, ElementRef} from '@angular/core';

@Directive({
  selector: '[mwFControl]'
})
export class MwFControlDirective {
    constructor(el: ElementRef) {
      let element: HTMLElement = el.nativeElement;
      element.className = 'form-control';
    }
}
