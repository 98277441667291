import { ErrorHandler } from "@angular/core";
import { environment } from "../../environments/environment";
import { AppError } from "./app-error";
import { NotFoundError } from "./not-found-error";
import { BadInputError } from "./bad-input-error";
import { NotAuthorizedError } from "./not-authorized-error";
import { InternalServerError } from "./internal-server-error";
import { GatewayTimeoutError } from "./gateway-timeout-error";


export class AppErrorHandler implements ErrorHandler {

    handleError(error: AppError) {

        if(environment.disableGloballErrorHandler) {
            throw new Error(error.toString());
        }

        if(environment.consoleOutputs.showErrors){

            if(error instanceof NotFoundError) {
                console.log('AppErrorHandler NotFoundError=> ' + error.orginalError);
            }
            else if (error instanceof BadInputError) {
                console.log('AppErrorHandler BadInput=> ' + error.orginalError);
            }
            else if (error instanceof NotAuthorizedError) {
                console.log('AppErrorHandler NotAuthorized=> ' + error.orginalError);
            }
            else if (error instanceof InternalServerError) {
                console.log('AppErrorHandler InternalServerError=> ' + error.orginalError);
            } 
            else if (error instanceof GatewayTimeoutError) {
                console.log('AppErrorHandler GatewayTimeoutError=> ' + error.orginalError);
            }                        
            else {
                console.log('AppErrorHandler => ' + error);
                console.log('AppErrorHandler => ' + error.orginalError);
            }
        
        }

        

    }



}


