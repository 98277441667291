import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewContainerRef,
  ViewChild,
  ViewChildren,
  ContentChild,
  QueryList,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";
import {
  FormBuilder,
  FormArray,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl
} from "@angular/forms";
import {
  MwFormControlComponent,
  MwImageEditorService
} from "../../../../../shared/components";
import {
  Listing,
  DayNames,
  MasterCategory,
  Category,
  Payments,
  RG,
  IFListingTime,
  CategoryListing,
  ListingServices,
  Client
} from "../../../../../shared/models";
import { getDayName, hoursdata } from "../../../../../shared/viewmodels";
import {
  CategoriesService,
  ListingsService,
  ClientsService,
  CategoryTreeService
} from "../../../../../shared/services/api";
import { environment } from "../../../../../environments/environment";
import { MapwordsStore } from "../../../../../shared/store";
import { toJS } from "mobx";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { ListingTime } from "../../../../../shared/models/ListingTime";
import { Location } from "@angular/common";
import { User } from "../../../../../shared/models/User";
import * as _ from "lodash";
import { Http, RequestOptions, Headers, Response } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import {
  MasterCategoryRequest,
  IMasterCategoryRequest
} from "../../../../../shared/models/MasterCategoryRequest";
import { MwAgentGenerateFormComponent } from "../mw-agent-generate-form/mw-agent-generate-form.component";
import { IQuickSearchObject } from "../../../../../shared/models/QuickSearchObject";
import { isObject } from "util";
import { MatAutocomplete } from "@angular/material";
import { MAT_SELECT_SCROLL_STRATEGY } from "@angular/material";
import { Overlay, BlockScrollStrategy } from "@angular/cdk/overlay";
import { Service } from "../../../../../shared/models/Service";
import { Observable } from "rxjs";
import { MwHoursAgentComponent } from "../../../../../shared/components/mw-hours-agent/mw-hours-agent.component";

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: "mw-agent-search-form",
  templateUrl: "./mw-agent-search-form.component.html",
  styleUrls: ["./mw-agent-search-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }
  ]
})
export class MwAgentSearchFormComponent implements OnInit, AfterViewInit {
  @ViewChild("imageFile")
  public imageInput: ElementRef;

  @ViewChild("bodyContainer")
  public containerElement: ElementRef;

  @ViewChildren(MwFormControlComponent)
  public formControls: QueryList<MwFormControlComponent>;

  // @ViewChild(MwAgentGenerateFormComponent)
  // private genForm: MwAgentGenerateFormComponent;

  // sean
  @ViewChild("service")
  @ViewChild("serviceInputText")
  public serviceInputText: ElementRef;

  public serviceInput: ElementRef;


  
  @ViewChild('businessHours')
  public businessHoursElementRef: MwHoursAgentComponent;


  public listing: Listing;

  public listcnt: number;
  public dnameduplicate = false;

  public fListing: FormGroup;

  public masterCategoriesCatalog: MasterCategory[];

  public CategoryCatalog: Category[] = [];

  public newListing: Listing;

  public listings: Listing[] = [];
  public emailRelatedClient: Client;

  public get masterCategories(): FormArray {
    return <FormArray>this.fListing.get("masterCategories");
  }

  public get categories(): FormArray {
    return <FormArray>this.fListing.get("categories");
  }

  // public get listingServices(): FormArray {
  //   return <FormArray>this.fListing.get('services');
  // }

  public get hours(): FormArray {
    return <FormArray>this.fListing.get("listingHours");
  }

  public get listingHours(): ListingTime[] {
    //console.log(this.listing.listingHours);
    const list = _.orderBy(this.listing.listingHours, ["dayName"]);
    //console.log(this.listing.listingHours);
    return list;
  }

  public editorOpened = false;

  // List for fill the selects
  public hoursData: string[] = hoursdata;

  public imageOrigin = environment.imagesOrigin;

  public routerSubs: Subscription;
  public showModal = false;
  public url: SafeUrl;
  public noHours = false;
  public todayNumber;
  public today: ListingTime = new ListingTime({});
  public dayhold;
  public claimIsOpen = false;
  public fClient: FormGroup;
  // public userNameAvailable = false;
  // public emailAvailable = false;
  public user: User;
  @ViewChild("listingOverflow")
  public listingOverflowElementRef: ElementRef;

  public states: any[] = [];
  public tempClient: string;

  public serviceList: Observable<Service[]>;
  public servicesControl = new FormControl();
  public selectedServices: ListingServices[] = [];

  public noscroll = false;
  public wasInside = false;

  public hitEnterFlag = false;

  public lastValidatedEmail = "";
  public bShowAll = false;
  public toolTipForListings = "";

  public email = "";

  public lastServiceText = "";

  public loading = false;

  public showServiceErrorMessage = false;

  constructor(
    private _fb: FormBuilder,
    private _categoriesService: CategoriesService,
    public store: MapwordsStore,
    private _changeRef: ChangeDetectorRef,
    private _imageEditor: MwImageEditorService,
    private _listingsService: ListingsService,
    private _viewRef: ViewContainerRef,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _clientsService: ClientsService,
    private _router: Router,
    private location: Location,
    private http: Http,
    private _categoryTreeService: CategoryTreeService
  ) {}

  ngOnInit() {
    // this.tempClient = localStorage.getItem('clientSessionId');
    // console.log(this.tempClient);

    this.storeChanged();
    this.routerSubs = this._route.data.subscribe(x => {
      this.store.searchListing.selectListing(x["listing"]["data"]);
      this.showModal = true;
      this.store.search.hoverListing(x["listing"]["data"]["googlePlaceId"]);


    });

    this.getAllStates();

    this.listing = toJS(this.store.searchListing.listing);


    // Validation added for already claimed business
    if(this.listing.id != 0){
      this._router.navigate(["/cp/agent", { outlets: { agentListing: null } }]);
    }

    this.initForm();
    this.initSelects();
    // this.initSignupForm();
    this.isInput = true;
    this.isNewCategoryRequest = false;

    this.servicesControl.valueChanges.subscribe(x => {
      if (this.validateService(x)) {
        this.noscroll = true;
        this._categoriesService.GetbyServiceName(x).subscribe(x => {
          this.serviceList = Observable.of(
            x.data.filter(
              q =>
                this.selectedServices.findIndex(
                  s => s.service.toLowerCase() === q.name.toLowerCase()
                ) == -1
            )
          );
        });
      }
    });
  }

  public validateService(serviceName: string): boolean {
    let response = true;

    if (
      serviceName == undefined ||
      serviceName == null ||
      serviceName == "" ||
      isObject(serviceName)
    ) {
      response = false;
    } else if (serviceName.length < 2) {
      response = false;
    }

    return response;
  }

  @HostListener("document:click")
  clickout() {
    // console.log('document:click');
    if (!this.wasInside) {
      this.noscroll = false;
    } else {
      this.noscroll = true;
    }

    this.wasInside = false;
  }

  public setScroll() {
    // console.log('setScroll');
    this.wasInside = true;
    this.noscroll = false;
  }

  public enableScroll() {
    this.noscroll = false;
    this.wasInside = false;
  }

  ngAfterViewInit() {
    this.formControls.forEach(x => (x.formDirty = false));

    // console.log('this._categoryTreeStore.categoryTree');
    // console.log(this._categoryTreeStore.categoryTree);

    // if(! this._categoryTreeStore.categoryTree) {

    //   this._categoryTreeStore.fillCategoryTree();
    //   this.categoryTree = this._categoryTreeStore.categoryTree;

    // }

    this._categoryTreeService.GetCategoryTree().subscribe(x => {
      this.categoryTree1 = x.data;
      for (let i = 0; i < this.categoryTree1.length; i++)
        this.setCategoryTreeParents(this.categoryTree1[i], null);
      // console.log(this.categoryTree1);
    });
  }
  private setCategoryTreeParents(tree: any, parent: any) {
    if (tree != null) {
      {
        tree.highlited = false;
        if (parent != null) tree.parent = parent;
        if (tree.subCategories != null) {
          for (let i = 0; i < tree.subCategories.length; i++)
            this.setCategoryTreeParents(tree.subCategories[i], tree);
        }
      }
    }
  }
  ngOnDestory() {
    this.routerSubs.unsubscribe();
  }

  getTempClient(e) {
    //console.log(e);
    this.tempClient = e;
    //console.log(this.tempClient);
  }
  public goBack(): void {
    this._router.navigate(["/cp/agent", { outlets: { agentListing: null } }]);
    // this.location.back();

    // try {
    //     this._router.navigate(['/cp/agent', {
    //       outlets: {
    //         'agentSearch':
    //           [
    //             this.store.search.filter.searchterm + ' ',
    //             this.store.search.filter.latitude,
    //             this.store.search.filter.longitude,
    //             this.store.search.filter.distance,
    //             this.store.search.filter.price,
    //             this.store.search.filter.rating,
    //             this.store.search.filter.open,
    //             this.store.search.page
    //           ],
    //         'agentListing': null
    //       }
    //     }
    //     ]);
    // }
    // catch{
    //   this._router.navigate(['/cp/agent', { outlets: { agentListing: null } }]);
    // }
  }

  public storeChanged() {
    if (this.listing) {
      this.listing = this.store.searchListing.listing;
    }
  }

  public expandTab(src: IQuickSearchObject, dest: IQuickSearchObject[]) {
    console.log(src);
    if (src.subCategories !== undefined) {
      dest = src.subCategories;
    }
  }

  public displayNameExists: boolean;
  public dispName: string;
  displayNameCheck(control: FormControl) {
    this.dispName = control.value;
    // console.log(this.dispName.length);

    // console.log(control.value);
    // console.log(this.displayNameExists);
    if (control.value) {
      this.displayNameExists = true;
      // console.log(this.displayNameExists);
    }
  }

  public street: string;
  public city: string;
  public state: string;
  public zipcode: string;
  public address: string;

  changeStreet(g: AbstractControl) {
    this.street = g.value;
    this.city = this.fListing.get("address").get("city").value;
    this.state = this.fListing.get("address").get("state").value;
    this.zipcode = this.fListing.get("address").get("zip").value;
    this.address =
      this.street + " " + this.city + " " + this.state + " " + this.zipcode;
    //console.log(this.address);

    const geocoder = new google.maps.Geocoder();
    let results = "";

    geocoder.geocode({ address: this.address }, (results, status) => {
      // const latitude = results[0].geometry.location.lat();
      // const longitude = results[0].geometry.location.lng();
      // this.listing.businessAddress.latitude = results[0].geometry.location.lat();
      // this.listing.businessAddress.longitude = results[0].geometry.location.lng();
      this.setLatLong(results);
      //console.log('lat: ' + latitude + ', long: ' + longitude);
    });
  }

  changeCity(g: AbstractControl) {
    this.city = g.value;
    this.street = this.fListing.get("address").get("street").value;
    this.state = this.fListing.get("address").get("state").value;
    this.zipcode = this.fListing.get("address").get("zip").value;
    this.address =
      this.street + " " + this.city + " " + this.state + " " + this.zipcode;
    //console.log(this.address);

    const geocoder = new google.maps.Geocoder();
    const results = "";

    geocoder.geocode({ address: this.address }, (results, status) => {
      // const latitude = results[0].geometry.location.lat();
      // const longitude = results[0].geometry.location.lng();
      // this.listing.businessAddress.latitude = results[0].geometry.location.lat();
      // this.listing.businessAddress.longitude = results[0].geometry.location.lng();
      this.setLatLong(results);
      //console.log('lat: ' + latitude + ', long: ' + longitude);
    });
  }

  changeState(g: AbstractControl) {
    this.state = g.value;
    this.street = this.fListing.get("address").get("street").value;
    this.city = this.fListing.get("address").get("city").value;
    this.zipcode = this.fListing.get("address").get("zip").value;
    this.address =
      this.street + " " + this.city + " " + this.state + " " + this.zipcode;
    //console.log(this.address);

    const geocoder = new google.maps.Geocoder();
    const results = "";

    geocoder.geocode({ address: this.address }, (results, status) => {
      // const latitude = results[0].geometry.location.lat();
      // const longitude = results[0].geometry.location.lng();
      // this.listing.businessAddress.latitude = results[0].geometry.location.lat();
      // this.listing.businessAddress.longitude = results[0].geometry.location.lng();
      this.setLatLong(results);
      //console.log('lat: ' + latitude + ', long: ' + longitude);
    });
  }

  changeZipcode(g: AbstractControl) {
    this.zipcode = g.value;
    this.city = this.fListing.get("address").get("city").value;
    this.state = this.fListing.get("address").get("state").value;
    this.street = this.fListing.get("address").get("street").value;
    this.address =
      this.street + " " + this.city + " " + this.state + " " + this.zipcode;
    //console.log(this.address);

    const geocoder = new google.maps.Geocoder();
    const results = "";

    geocoder.geocode({ address: this.address }, (results, status) => {
      // const latitude = results[0].geometry.location.lat();
      // const longitude = results[0].geometry.location.lng();
      // this.listing.businessAddress.latitude = results[0].geometry.location.lat();
      // this.listing.businessAddress.longitude = results[0].geometry.location.lng();
      this.setLatLong(results);
      //console.log('lat: ' + latitude + ', long: ' + longitude);
    });
  }

  setLatLong(geoCoderResult: google.maps.GeocoderResult[]) {
    this.listing.businessAddress.latitude = 0;
    this.listing.businessAddress.longitude = 0;

    if (geoCoderResult) {
      if (geoCoderResult.length > 0) {
        this.listing.businessAddress.latitude = geoCoderResult[0].geometry.location.lat();
        this.listing.businessAddress.longitude = geoCoderResult[0].geometry.location.lng();
      }
    }

    console.log(geoCoderResult);

    console.log(this.listing.businessAddress.latitude);
    console.log(this.listing.businessAddress.longitude);
  }

  public getAllStates() {
    this._listingsService.GetAllStates().subscribe(res => {
      let statelist: any[] = [];
      statelist = res.data;

      const data = { name: "", value: "" };
      statelist.forEach(x => {
        data.name = x.name;
        data.value = x.code;
        // console.log(data);
        this.states.push({ name: x.name, value: x.code });
        // console.log(this.states);
      });
    });
  }

  public headers = new Headers({ "Content-Type": "application/json" });
  public newrequest: string;

  @ViewChild("requestNew")
  public requestNew: ElementRef;

  // public requestValue = '';
  public catReqModel: any;

  public mcrModel: IMasterCategoryRequest;

  addNewCategory(input: FormControl) {
    // this.newrequest = this.fListing.get('masterCategoryRequest').value + " (pending)";
    this.newrequest = this.requestNew.nativeElement.value + " (pending)";
    // let catReqModel = new MasterCategoryRequest(null);

    this.mcrModel = { id: null, name: this.newrequest };
    //console.log(this.mcrModel);

    if (JSON.parse(localStorage.getItem("authToken")) !== null) {
      this.headers = new Headers({
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("authToken")).access_token
      });
    }
    this.http
      .post(
        environment.apiUrl + `api/v1/requestcategory`,
        JSON.stringify(this.mcrModel),
        { headers: this.headers }
      )
      .map((resp: Response) => resp.json())
      .subscribe(x => {
        this.mcrModel = { id: x.data.id, name: x.data.name };
      });

    let list: FormArray;
    list = <FormArray>this.fListing.get("masterCategories");
    //console.log(list);

    list.push(
      this._fb.group({
        MasterCategoryId: [0],
        viewName: [this.newrequest]
      })
    );

    let mcr: string;
    mcr = this.fListing.controls["masterCategoryRequest"].get("name").value;
    mcr = this.newrequest;
    //console.log(mcr);
  }

  public claim(formListing: Listing) {
    this.loading = true;

    //console.log('claim');
    //console.log(this.fListing.value.listingHours);
    // console.log(this.listing);

    // console.log(this.dnameduplicate);
    // console.log(this.selectedCategories);

    const clientSessionUseremail = localStorage.getItem("clientSessionId");
    this.email = this.fListing.value.email;

    // console.log('localStorage.getItem(clientSessionId)');
    // console.log(clientSessionUseremail);

    if (
      this.fListing.valid &&
      !this.dnameduplicate &&
      this.SelectedCategoryTreeItems.length > 0 &&
      this.SelectedCategoryTreeItems.length < 4 &&
      this.selectedServices.length > 0 &&
      this.selectedServices.length < 11 &&
      this.listing.businessAddress.latitude != 0 &&
      this.listing.businessAddress.longitude != 0
    ) {
      const listing = new Listing(formListing);

      // listing.businessAddress = this.listing.businessAddress;
      listing.businessAddress = this.fListing.value.address;
      listing.businessAddress.latitude = this.listing.businessAddress.latitude;
      listing.businessAddress.longitude = this.listing.businessAddress.longitude;
      listing.businessAddress.country = this.listing.businessAddress.country;
      listing.businessAddress.zipCode = this.fListing.value.address.zip;
      listing.listingHours = this.listingHours;

      listing.googlePlaceId = this.listing.googlePlaceId;
      // listing.contact.contactName = this.listing.name;
      // listing.name = this.listing.name;
      listing.overallStars = this.listing.overallStars;
      listing.monthlyPrice = this.listing.monthlyPrice;
      listing.rankingOrder = this.listing.rankingOrder;
      listing.openNow = this.listing.openNow;

      listing.categories = this.SelectedCategoryTreeItems;

      listing.services = this.selectedServices;

      // if (this.mcrModel.id) {
      //   listing.masterCategoryRequest.id = this.mcrModel.id;
      //   listing.masterCategoryRequest.name = this.mcrModel.name;
      //   listing.masterCategories[0].mcrId = this.mcrModel.id;
      // }

      // sean
      if (this.catReqModel) {
        //console.log(this.catReqModel);
        // listing.masterCategories.
      }

      listing.useremailByAgent = this.email; //localStorage.getItem('clientSessionId');
      if (this.fListing.value.avatar === this.listing.avatar) {
        this.fListing.value.avatar = this.listing.avatar;
      }
      //console.log(listing);
      listing.agentId = this.store.auth.user.id;

      if (this.listing.id == 0) {
        this.store.searchListing.claimByAgent(
          listing,
          (newListing: Listing) => {
            this.loading = false;

            this.listing = newListing;
            this.initForm();

            // this.goBack();
            // localStorage.removeItem('clientSessionId');
            // location.reload();
          }
        );
      } else {
        listing.id = this.listing.id;
        listing.businessAddressId = this.listing.businessAddressId;
        listing.contactId = this.listing.contactId;
        listing.contact.id = this.listing.contactId;
        listing.listingHours = this.listingHours;

        this.store.searchListing.claimUpdateByAgent(listing, () => {
          this.loading = false;
          // this.goBack();
          // localStorage.removeItem('clientSessionId');
          // location.reload();
        });
      }
    } else {
      this.loading = false;

      this.containerElement.nativeElement.scrollTop = 0;
      this.markAllDirty(this.fListing);
      this.formControls.forEach(x => (x.formDirty = true));
    }
  }

  public getDayName(day: number) {
    return getDayName(day);
  }

  public searchRegularCategories = (term: string) => {
    return this._categoriesService.GetbyCategoryName(term).map(x => x.data);
  };

  public setClientListings(email: string): void {
    this.toolTipForListings = "";

    console.log(email);

    if (email) {
      this.emailRelatedClient = null;
      this.listings = [];

      this._clientsService.GetListingForExistingClient(email).subscribe(
        x => {
          if (x.data.listings.length < 1) {
            return;
          }

          this.listings = x.data.listings;
          this.emailRelatedClient = x.data.client;

          this.listings.forEach(element => {
            this.toolTipForListings += element.name + "  , ";
          });

          if (this.toolTipForListings.length > 0) {
            this.toolTipForListings = this.toolTipForListings.substring(
              0,
              this.toolTipForListings.length - 2
            );
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  public showAll(): void {
    this.bShowAll = !this.bShowAll;
  }

  public isNewCategoryRequest: boolean;
  public optionSelect: any;
  public getNewCategory(options) {
    //console.log(options);
    this.optionSelect = options;
    if (options[1].selected === true) {
      this.isNewCategoryRequest = true;
      //console.log(this.isNewCategoryRequest);
    } else {
      this.isNewCategoryRequest = false;
    }
  }

  public addToList(id: string, listName: string): void {
    let list: FormArray;
    switch (listName) {
      case "masterCategories":
        const mCat = this.masterCategoriesCatalog.find(x => x.id === +id);

        list = <FormArray>this.fListing.get(listName);

        const exist = list.controls.find(x => x.value.MasterCategoryId === +id);
        if (this.isNewCategoryRequest === true) {
          return;
        } else if (!exist) {
          list.push(
            this._fb.group({
              MasterCategoryId: [mCat.id],
              viewName: [mCat.description]
            })
          );
        }
        break;
      case "categories":
        const category = <FormArray>this.fListing.get("categories");
        const existCat = category.controls.find(x => x.value.viewName === id);
        if (!existCat) {
          this._categoriesService.GetbyCategoryName(id).subscribe(x => {
            const lCat = x.data.find(m => m.description === id);
            list = <FormArray>this.fListing.get(listName);
            list.push(
              this._fb.group({
                CategoryId: lCat.id,
                viewName: lCat.description
              })
            );
          });
        }
        break;
      case "service":
        const service = <FormArray>this.fListing.get("service");
        const existServ = category.controls.find(x => x.value.viewName === id);
        if (!existServ) {
          this._categoriesService.GetbyCategoryName(id).subscribe(x => {
            const lCat = x.data.find(m => m.description === id);
            list = <FormArray>this.fListing.get(listName);
            list.push(
              this._fb.group({
                CategoryId: lCat.id,
                viewName: lCat.description
              })
            );
          });
        }
        break;
      default:
        list = <FormArray>this.fListing.get("services");
        let serviceName: string = id;
        serviceName = serviceName
          .trim()
          .replace(/\s+/g, " ")
          .toLowerCase();
        const object = list.controls.find(x => x.value.Service === serviceName);
        if (object) {
          Validators.minLength(4);
        } else {
          list.push(
            this._fb.group({
              Service: serviceName
            })
          );
        }
        break;
    }
  }

  public removeFromList(categoryId: number): void {
    // const list: FormArray = <FormArray>this.fListing.get(listName);
    // list.removeAt(index);

    var x = this.findByCategoryId(this.categoryTree1, categoryId);

    this.selectService(x);
    this.clickedCategory(x);
  }

  public findByCategoryId(list: any[], id: number): any {
    let result: any;

    list.forEach(x => {
      if (x.id == id) {
        result = x;
        return result;
      } else if (x.subCategories !== undefined) {
        let tmp = this.findByCategoryId(x.subCategories, id);
        if (tmp !== undefined) {
          result = tmp;
          return result;
        }
      }
    });

    return result;
  }

  public myListFormatter(data: Category): string {
    const html = `<span>${data.description}</span>`;
    return html;
  }

  public openEditor(): void {
    this._imageEditor
      .open(this.imageOrigin + this.listing.avatar, this._viewRef)
      .subscribe(x => {
        if (x === "" || x === undefined || x === null) {
          return;
        }
        this.imageOrigin = "";
        this.fListing.value.avatar = x;
        this.fListing.get("avatar").setValue(x);
      });
  }

  public cancelEditor(): void {
    this.editorOpened = false;
    this.fListing.value.avatar = this.listing.avatar;
  }

  public validateKey(event: KeyboardEvent): boolean {
    // console.log(event.charCode) ;

    // if (String.fromCharCode(event.charCode).match(/[a-z0-9]/g) == null && event.charCode!= 0) {
    if (
      String.fromCharCode(event.charCode).match(/[a-zA-Z0-9]/g) == null &&
      event.charCode != 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  // public validateDisplayname(dName: AbstractControl): boolean {
  //   this.listcnt = 0;
  //   this.dnameduplicate = false;
  //   this._listingsService.GetByDisplayName(dName.value)
  //     .subscribe(x => {

  //       x.data.forEach(c => {
  //         this.dnameduplicate = true;
  //         this.listcnt = this.listcnt + 1;
  //       });
  //     });

  //   return this.dnameduplicate;
  // }

  public validateDisplayname(dName: AbstractControl): boolean {
    this.listcnt = 0;
    this.dnameduplicate = false;
    this._listingsService.GetByDisplayName(dName.value).subscribe(x => {
      // console.log(this.listcnt);
      // console.log(x.data);
      x.data.forEach(c => {
        // console.log(c);
        // console.log(this.listing);
        if (c.id && c.googlePlaceId === this.listing.googlePlaceId) {
          this.dnameduplicate = false;
        } else {
          this.dnameduplicate = true;
          this.listcnt = this.listcnt + 1;
        }
      });
    });
    // console.log(this.dnameduplicate);
    return this.dnameduplicate;
  }

  private initForm(): void {
    this.fListing = this._fb.group({
      email: [
        this.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      name: [this.listing.name, [Validators.required]],
      displayName: [
        this.listing.id == 0 ? "" : this.listing.displayName,
        [Validators.minLength(6)]
      ],
      avatar: [this.listing.avatar],
      // introduction: [{value: this.listing.introduction, disabled: true}, [Validators.required, Validators.minLength(50)]],
      address: this._fb.group({
        street: [this.listing.businessAddress.street, [Validators.required]],
        city: [this.listing.businessAddress.city, [Validators.required]],
        state: [this.listing.businessAddress.state, [Validators.required]],
        zip: [this.listing.businessAddress.zipCode, [Validators.required]]
      }),
      contact: this._fb.group({
        mobileNumber: [this.listing.contact.mobileNumber],
        phoneNumber: [this.listing.contact.phoneNumber, Validators.required],
        website: [this.listing.contact.website],
        tollFreeNumber: [this.listing.contact.tollFreeNumber],
        faxNumber: [this.listing.contact.faxNumber],
        publicEmail: [
          this.listing.contact.publicEmail,
          [
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
          ]
        ]
      }),
      payments: this._fb.group(
        {
          amex: [this.listing.payments ? this.listing.payments.amex : false],
          visa: [this.listing.payments ? this.listing.payments.visa : false],
          masterCard: [
            this.listing.payments ? this.listing.payments.masterCard : false
          ],
          discover: [
            this.listing.payments ? this.listing.payments.discover : false
          ],
          check: [this.listing.payments ? this.listing.payments.check : false],
          cash: [this.listing.payments ? this.listing.payments.cash : false]
        },
        { validator: this.requiredCheck }
      ),
      // masterCategoryRequest: this._fb.array(
      //   [
      //     this._fb.group({
      //       id: [''],
      //       name: ['']
      //     })
      //   ]
      // ),
      // masterCategoryRequest: this._fb.group({
      //   id: [''],
      //   name: ['']
      // }),
      categories: this._fb.array([
        this.SelectedCategoryTreeItems,
        [Validators.required]
      ]),
      // masterCategories: this._fb.array(this.setMasterCategories(), Validators.compose([Validators.minLength(1), Validators.maxLength(1)])),
      services: this._fb.array([]),
      listingHours: this._fb.array(this.setDates()),
      listingConfigs: this._fb.group({
        showAddress: false,
        homeBasedBusiness: false,
        businessClosed: false
      })
      // SelectedCategoryTreeItems: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(3)]]
      // }, {validator: this.checkNullInput});
    });

    // this.SelectedCategoryTreeItems = [];
    // if(this.listing.categories!== undefined && this.listing.categories!== null){
    //   this.SelectedCategoryTreeItems = this.listing.categories;
    // }

    // console.log(this.listing)
    // console.log(this.fListing)
    // console.log(this.fListing.value.address.street)
    // console.log(this.fListing.value.address.street.toString().length)
    // console.log(Object.keys(this.fListing.value.payments).length);
  }

  public requiredCheck(g: FormGroup) {
    let amex = g.get("amex").value,
      visa = g.get("visa").value,
      master = g.get("masterCard").value,
      discover = g.get("discover").value,
      check = g.get("check").value,
      cash = g.get("cash").value;

    if (
      amex == true ||
      visa == true ||
      master == true ||
      discover == true ||
      check == true ||
      cash == true
    ) {
      return null;
    } else {
      return { nocheck: true };
    }
  }

  public minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) {
        return null;
      }
      return { minLengthArray: { valid: false } };
    };
  }

  // get catergories() : FormArray {
  //   return this.fListing.get('categories') as FormArray;
  // }
  public servicePattern = "^[a-zA-Z]{2,20}$";

  public isInput = false;

  public checkIfInput(input: FormControl) {
    if (this.serviceInput.nativeElement.value.length > 0) {
      this.isInput = true;
    } else {
      this.isInput = false;
    }
  }

  public showInputErrorMessage() {
    this.isInput = false;
  }

  private initSelects(): void {
    this._categoriesService.GetMaster().subscribe(
      x => (this.masterCategoriesCatalog = x.data),
      err => {
        // console.log(err);
        this.store.searchListing.closeClaim();
      }
    );
  }

  private setDates(): FormGroup[] {
    const days: FormGroup[] = [];

    if (this.listing.listingHours) {
      let cnt = -1;
      this.listing.listingHours.forEach(hour => {
        cnt = cnt + 1;
        if (cnt < hour.dayName) {
          for (; cnt < hour.dayName; cnt++) {
            days.push(
              this._fb.group(<IFListingTime>{
                active: true,
                addedDate: null,
                closed: true,
                dayName: cnt,
                end: null,
                start: null,
                id: 0,
                isSpecialHour: false
              })
            );
          }
        } else {
          cnt = hour.dayName;
        }
        days.push(
          this._fb.group(<IFListingTime>{
            active: true,
            addedDate: null,
            closed: hour.closed,
            dayName: hour.dayName,
            end: hour.end,
            start: hour.start,
            id: 0,
            isSpecialHour: false
          })
        );
      });
      cnt = cnt + 1;
      if (cnt < 7) {
        for (; cnt < 7; cnt++) {
          days.push(
            this._fb.group(<IFListingTime>{
              active: true,
              addedDate: null,
              closed: true,
              dayName: cnt,
              end: null,
              start: null,
              id: 0,
              isSpecialHour: false
            })
          );
        }
      }
    } else {
      let cnt = 0;
      for (; cnt < 7; cnt++) {
        days.push(
          this._fb.group(<IFListingTime>{
            active: true,
            addedDate: null,
            closed: true,
            dayName: cnt,
            end: null,
            start: null,
            id: 0,
            isSpecialHour: false
          })
        );
      }
    }
    return days;
  }

  private setMasterCategories(): FormGroup[] {
    const cats = [];
    if (this.listing.masterCategories) {
      this.listing.masterCategories.forEach(mCat => {
        if (mCat.masterCategory.description !== "Uncategorized") {
          cats.push(
            this._fb.group({
              MasterCategoryId: [mCat.masterCategory.id],
              viewName: [mCat.masterCategory.description]
            })
          );
        }
      });
    }
    return cats;
  }

  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty("controls")) {
      control.markAsDirty({ onlySelf: true }); // mark group
      const ctrl = <any>control;
      for (const inner in ctrl.controls) {
        this.markAllDirty(ctrl.controls[inner] as AbstractControl);
      }
    } else {
      (<FormControl>control).markAsDirty({ onlySelf: true });
    }
  }

  public get weekDaysForm(): FormArray {
    return <FormArray>this.fListing.get("weekdays");
  }

  public toggleExtraHour(dayNumber: number, added: boolean): void {
    const hours = <FormArray>this.weekDaysForm.at(dayNumber).get("hours");
    const hour = hours.at(1);
    if (hour) {
      if (hour.get("isNew")) {
        if (added) {
          // hours.push(this.setDates());
        } else {
          hours.removeAt(1);
        }
      } else {
        hour.get("active").setValue(added);
      }
    } else {
      if (added) {
        // hours.push(this.setDates());
      } else {
        hours.removeAt(1);
      }
    }
  }

  public saveHours(hours: ListingTime[]): void {
    // console.log('passed data :' + hours)
    const formHours = <FormArray>this.fListing.get("listingHours");
    // tslint:disable-next-line:no-shadowed-variable
    //console.log(hours);

    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }

    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

    }

    this.listing.listingHours = hours;

    //console.log(this.businessHoursElementRef.isValid);

    let index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });

    // this.submitForm();

    // // tslint:disable-next-line:no-shadowed-variable
    // for (let index = 0; index < formHours.length; index++) {
    //   formHours.removeAt(index);
    // }
    // this.listing.listingHours = hours;
    // index = 0;
    // this.setFormHours().forEach(x => {
    //   formHours.setControl(index, x);
    //   index++;
    // });
  }


  public changeHours(hours: ListingTime[]): void {
    if (!hours.length) {
      return;
    }

    //console.log(hours);

    const formHours = <FormArray>this.fListing.get("listingHours");

    for (let index = 0; index < formHours.length; index++) {
      formHours.removeAt(index);
    }

    
    for (let index = 0; index < hours.length; index++) {

      if(hours[index].closed) {
        hours[index].start = null;
        hours[index].end = null;
      }

      // if( !(hours[index].start && hours[index].end) && !hours[index].closed){
      //   hours.splice(index,1);
      // }
    }
    
    this.listing.listingHours = hours;
    //console.log(this.listing.listingHours);

    //console.log(this.businessHoursElementRef.isValid);

    let index = 0;
    this.setFormHours().forEach(x => {
      formHours.setControl(index, x);
      index++;
    });
  }

  private setFormHours(): FormGroup[] {
    const list = [];
    if (this.listing.listingHours.length > 0) {
      this.listing.listingHours.forEach(x => {
        list.push(this.pushHours(x));
      });
    }

    console.log(list);

    return list;
  }

  private pushHours(x: any, isNew = false): FormGroup {
    return this._fb.group(<IFListingTime>{
      active: x.active,
      addedDate: x.addedDate,
      closed: x.closed,
      dayName: isNew ? +x.id : +x.dayName,
      end: x.end,
      id: isNew ? 0 : +x.id,
      isSpecialHour: x.isSpecialHour,
      listingId: this.listing.id,
      specialDate: x.specialDate,
      specialDateName: x.specialDateName,
      start: x.start
    });
  }

  // Category Tree

  public categoryTree1: IQuickSearchObject[];
  public categoryTree2: IQuickSearchObject[];
  public categoryTree3: IQuickSearchObject[];
  public categoryTree4: IQuickSearchObject[];

  // public selectedCategories: any[] = [];

  public SelectedCategoryTreeItems: CategoryListing[] = [];

  public selectService(item: any) {
    // console.log(item.id);
    // console.log(item.name);
    let found = false;
    let cat = new CategoryListing({
      category: new Category({ name: item.name, description: item.name }),
      listingId: this.listing.id,
      categoryId: item.id
    });
    //let cat = new CategoryListing({ category: item.name, listingId: this.listing.id, categoryId: item.id });
    for (let i = 0; i < this.SelectedCategoryTreeItems.length; i++) {
      // for (let i=0; i < this.selectedCategories.length ; i++) {
      if (this.SelectedCategoryTreeItems[i].categoryId == item.id) {
        //if (this.selectedCategories[i].categoryId == item.id) {
        // item.highlited = false;
        this.SelectedCategoryTreeItems.splice(i, 1);
        // this.selectedCategories.splice(i,1);
        found = true;
      }
    }

    if (!found) {
      item.highlited = true;
      this.SetSelectedTreeUp(item);
      this.SelectedCategoryTreeItems.push(cat);
      //this.selectedCategories.push(cat);
    } else if (found === true) {
      item.highlited = false;
      this.deSelectService(item);

      // this.SelectedCategoryTreeItems.pop();
      // this.selectedCategories.pop();
    }

    // console.log(this.SelectedCategoryTreeItems);

    // console.log(this.selectedCategories);
    // console.log(cat);
  }

  public SetSelectedTreeUp(item) {
    item.highlited = true;
    if (item.parent != null) {
      this.SetSelectedTreeUp(item.parent);
    }
  }

  public deSelectService(item) {
    item.highlited = false;
    if (item.parent != null) {
      this.deSelectService(item.parent);
    }
  }

  public selectedItem;

  public clickedCategory(item: any) {
    if (!item.clicked) {
      this.SetClick(item);
      item.clicked = true;
    } else if (item.clicked === true) {
      this.deClick(item);
      item.clicked = false;
    }

    if (!item.parent) {
      for (let i = 0; i < this.categoryTree1.length; i++) {
        if (item != this.categoryTree1[i]) {
          this.deClick(this.categoryTree1[i]);
        }
      }
    } else {
      for (let i = 0; i < item.parent.subCategories.length; i++) {
        if (item != item.parent.subCategories[i]) {
          item.parent.subCategories[i].clicked = false;
        }
      }
    }
    // console.log(item.clicked);
  }

  public SetClick(item) {
    item.clicked = true;
    if (item.parent != null) {
      this.SetClick(item.parent);
    }
  }

  public deClick(item) {
    item.clicked = false;
    if (item.parent != null) {
      this.deClick(item.parent);
    }
  }

  public clearCategory(i) {
    switch (i) {
      case 1:
        if (this.categoryTree2 != []) {
          this.categoryTree2 = [];
          this.categoryTree3 = [];
          this.categoryTree4 = [];
        }
      case 2:
        if (this.categoryTree3 != []) {
          this.categoryTree3 = [];
          this.categoryTree4 = [];
        }
      case 3:
        if (this.categoryTree4 != []) {
          this.categoryTree4 = [];
        }
    }
  }

  public openSubCat(i, item) {
    this.clearCategory(i);
    switch (i) {
      case 1: {
        this.categoryTree2 = item.subCategories;
        break;
      }
      case 2: {
        this.categoryTree3 = item.subCategories;
        break;
      }
      case 3: {
        this.categoryTree4 = item.subCategories;
        break;
      }
    }
    for (let j = 0; j < item.subCategories.length; j++) {
      this.deClick(item.subCategories[j]);
    }
    this.clickedCategory(item);
  }

  public displayFn(category?: Category): string | undefined {
    if (category) {
      return category.description;
    }

    return undefined;
  }

  public setServiceVariable(): void {
    this.hitEnterFlag = false;
    this.lastServiceText = "";
  }

  public addService(obj: MatAutocomplete, senderInput: number) {
    this.showServiceErrorMessage = false;

    let inputBoxText = this.serviceInputText.nativeElement.value;
    let isTextAdded =
      this.selectedServices.filter(x => x.service == this.lastServiceText)
        .length > 0;

    if (this.hitEnterFlag && senderInput == 1 && isTextAdded) {
      if (this.selectedServices.length > 0) {
        this.selectedServices.splice(this.selectedServices.length - 1, 1);
      }
    }

    this.hitEnterFlag = false;

    var selectedItem = obj.options.filter(x => x.selected == true);

    let inputLength: number = +this.serviceInputText.nativeElement.value.length;

    if (inputLength == 0) {
      return;
    }

    if (selectedItem.length == 0 && inputLength > 4) {
      if (
        this.selectedServices.filter(x => x.service == inputBoxText).length == 0
      ) {
        let item = new ListingServices({
          id: 0,
          active: true,
          service: inputBoxText,
          listingId: 0
        });

        if (!this.checkServiceIsExists(inputBoxText)) {
          this.selectedServices.push(item);
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]);
      }
    } else if (
      inputLength < 5 &&
      this.selectedServices.filter(x => x.service == inputBoxText).length == 0
    ) {
      this.showServiceErrorMessage = true;
    }

    if (selectedItem !== null && selectedItem.length != 0) {
      let description = selectedItem[0].value.description;

      if (
        this.selectedServices.filter(x => x.service == description).length == 0
      ) {
        let item = new ListingServices({
          id: 0,
          active: true,
          service: description,
          listingId: 0
        });

        if (!this.checkServiceIsExists(description)) {
          this.selectedServices.push(item);
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]);
      }
    }
    this.servicesControl.setValue("");
    this.serviceList = Observable.of([]);

    this.enableScroll();
  }

  public checkServiceIsExists(serviceName: string): boolean {
    let result = false;

    if (this.selectedServices.length > 0) {
      this.selectedServices.forEach(element => {
        if (
          element.service.toLowerCase().trim() ==
          serviceName.toLowerCase().trim()
        ) {
          result = true;
        }
      });
    }

    return result;
  }

  public removeService(index: number) {
    this.selectedServices.splice(index, 1);
  }

  public hitEnter(e) {
    //console.log(e);

    e.preventDefault();

    this.showServiceErrorMessage = false;

    this.hitEnterFlag = false;
    this.lastServiceText = "";
    let inputBoxText = this.serviceInputText.nativeElement.value;

    if (this.serviceInputText.nativeElement.value.length > 4) {
      if (
        this.selectedServices.filter(x => x.service == inputBoxText).length == 0
      ) {
        let item = new ListingServices({
          id: 0,
          active: true,
          service: inputBoxText,
          listingId: 0
        });

        if (!this.checkServiceIsExists(inputBoxText)) {
          this.selectedServices.push(item);
          this.hitEnterFlag = true;
          this.lastServiceText = item.service;
          this.enableScroll();
        }

        this.servicesControl.setValue("");
        this.serviceList = Observable.of([]);
      }
    } else if (
      this.serviceInputText.nativeElement.value.length < 5 &&
      this.selectedServices.filter(x => x.service == inputBoxText).length == 0
    ) {
      this.showServiceErrorMessage = true;
    }
  }
}
