export interface IQuickSearch {
    id?: number;
    name: string;
    description?: string;
    subCategories?: IQuickSearch[];
    isSearchArgument: boolean;
    isOpen: boolean;
    isBackClosed: boolean;
    parentCat?: IQuickSearch;
    parentId?: number;
}

// Already build in categories for the quicksearch

const autoServices: IQuickSearch[] = [
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Body'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Dealers'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Glass'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Parts'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Repair'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Brakes'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tires'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Towing'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Transmission'
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tune Up'
    }
];


export const quickSearchTerm: IQuickSearch[] = [
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Automotive',
        subCategories: [
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Manufacturer',
                subCategories: [
                    {
                        isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'All',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Acura',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'BMW',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Chevrolet',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Chrysler',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dodge',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Ford',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'GMC',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Honda',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hyundai',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Jeep',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Kia',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mercedes Benz',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Lexus',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Nissan',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Subaru',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tesla',
                        subCategories: autoServices
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Toyota',
                        subCategories: autoServices
                    },
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Repair',
                subCategories: autoServices
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Beauty & Fitness',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Fitness',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Gyms'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pilates'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Personal Trainers'
                    },
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hair & Nails',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Barber Shops'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Beauty Salons'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Beauty Supply'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hair Extensions'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hair Salons'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Nail Salon'
                    },
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Spas',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Facialists'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Massage Therapist'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Spa'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Waxing'
                    },
                ]
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Home Services',
        subCategories: [
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Cleaning Service',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Air Duct Cleaning'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Carpet Cleaners'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hauling'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'House Cleaners'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Janitorial'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pool Service'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pressure Washing'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tile & Grout'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Upholstery Cleaning'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Window Cleaning'
                    },
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Contractors',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Building Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Electrical Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Flooring Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'General Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'HVAC Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Locksmiths'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Painting Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Plumbing Contractors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Remodeling Contractors"'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Roofing Contractors'
                    },
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Doors & Windows',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Door Company'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Fences'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Garage Door Company'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Gates'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Windows'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Professionals',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Appraisers'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Architects'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Engineers'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Realtors'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mortgage'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Inspectors'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Regular Service',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Air Duct Cleaning'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dry Cleaning'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Lawn Care Service'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hauling Service'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Moving'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pool Maintenance'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pest Control'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Septic'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Storage'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tree Service'
                    }
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Repair Service',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Appliance Repair'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Electricians'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Garage Door'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Handyman'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Heating & Air'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Locksmiths'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mold Remediation'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Painters'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Plumbers'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Roofers'
                    }
                ]
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Insurance',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Auto Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Bail Bonds'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Boat Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Commercial Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'EPLI Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Health Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Home Insurance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Insurance Agency'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Life Insurance'
            }
        ]
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false,
        name: 'Legal Services',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Accounting & Tax',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Accountants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Bookkeepers'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'CPA'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Payroll Service'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tax Preperation'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Financial Planning',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: '401k'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'College'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Investments'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pension'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Retirement Planning'
                    }
                ]
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Law',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Bankruptcy Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Business Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Criminal Defense'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Divorce Lawyers'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Employment Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Estate Planning'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Family Law'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Patent Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Personal Injury'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Real Estate Attorneys'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tax Attorneys'
                    }
                ]
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Medical',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Chiropractors',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Counselors',
                subCategories: null
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Dentistry',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Cosmetic Dentistry'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dental Hygienists'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dental Implants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dentists'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Family Dentistry'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Invisilign'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Oral Surgeons'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Orthodontist'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pediatric Dentistry'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Periodontist'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Teeth Whitening'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Teeth Cleaning'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Holistic Practitioners',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Optometry',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pediatricians',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Plastic Surgeons',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Physical Therapist',
                subCategories: null
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Nightlife',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Bars'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Billiards'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Comedy Clubs'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Karaoke'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pubs'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Night Clubs'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Sports Bars'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Wine Rooms'
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Pets',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dog Parks'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dog Trainers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Kennels'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pet Groomers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pet Supply'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tack Shops'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Veternarians'
            }
        ]
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false,
        name: 'Professional Services',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Advertisers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Appraisers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Architects'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Engineers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Inspectors'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mortgage'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Photographers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Private Investigators'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Realtors'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Security Systems'
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Real Estate',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Real Estate Agents'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Real Estate Attorneys'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Commercial Real Estate'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Home Appraisers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Home Inspectors'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Land Surveyors'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mortgage Brokers'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Property Management'
            }
        ]
    },
    {
        isSearchArgument: true, isBackClosed: true, isOpen: false,
        name: 'Restaurants',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Catering'
            },
            {
                isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'Cuisine',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'American Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Chinese Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Family Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'French Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hamburger Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Indian Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Italian Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Japanese Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mediterranean Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Mexican Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pizza Restaurants'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Seafood Restaurants'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Fast Food'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Food Delivery'
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Stores',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Appliance Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Clothing Stores',
                subCategories: [
                    {
                        isSearchArgument: false, isBackClosed: true, isOpen: false, name: 'All'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Women Clothing Store'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Men Clothing Store'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Children Clothing Store'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Baby Clothing Store'
                    },
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Computer Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Convenience Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Drug Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Florists'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Furniture Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Gas Stations'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Gift Shops'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Grocery Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hardware Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Jewelry Stores'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Pawn Shops'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tattoo Parlors'
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Schools',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Child Care'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Colleges'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Dance'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Daycare'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Gymnastics'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Martial Arts'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Music'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Private'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Tutors'
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Vocational'
            }
        ]
    },
    {
        isSearchArgument: false, isBackClosed: true, isOpen: false,
        name: 'Travel',
        subCategories: [
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Lodging',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Campgrounds'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Hotels'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Motels'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Resorts'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'RV Parks'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Passport Assistance',
                subCategories: null
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Transportation',
                subCategories: [
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Car Rental'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Limousine'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Taxis'
                    },
                    {
                        isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Shuttle Service'
                    }
                ]
            },
            {
                isSearchArgument: true, isBackClosed: true, isOpen: false, name: 'Travel Agency',
                subCategories: null
            }
        ]
    }
];
