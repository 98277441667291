import {
  Component, ViewContainerRef, OnInit, HostBinding, ChangeDetectionStrategy,
  ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, Renderer, EventEmitter, Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';

import { ListingsService } from '../../../../shared/services/api';
import { Listing, ListingReview, ListingTime, ListingPhoto } from '../../../../shared/models';
import { S3filesService } from '../../../../shared/services';
import { MwClaimListingService } from '..';
import { ActionTypes } from '../../../../shared/viewmodels';
import { environment } from '../../../../environments/environment';
import { MapwordsStore } from '../../../../shared/store';
import { NgxCarousel } from 'ngx-carousel';
import { CookieService } from '../../../../../node_modules/ngx-cookie-service';

@Component({
  selector: 'mw-listing-pop',
  templateUrl: './mw-listing-pop.component.html',
  styleUrls: ['./mw-listing-pop.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
  // animations: [
  //   trigger('enterListingPop', [
  //     state('in', style({ opacity: 1, transform: 'translateX(0)' })),
  //     transition(
  //       'void => *', [
  //         style({ transform: 'translateX(-100%)', opacity: 0 }),
  //         animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1, 'left': 0 }))
  //       ])
  //   ]),
  //   trigger('parentAnimation', [
  //     transition('true => false', [
  //       style({ opacity: 0 }),
  //       animate(500, style({ opacity: 1 }))
  //     ])
  //   ])
  // ]
})  

export class MwListingPopComponent implements OnInit, OnDestroy {

  public listing: Listing;

  public listingPhoto: ListingPhoto[];

  public url: SafeUrl;

  public showModal = false;

  public imageOrigin = environment.imagesOrigin;

  public noHours = false;

  public todayNumber;

  public today: ListingTime = new ListingTime({});

  public showHours = false;

  public claimIsOpen = false;
  public verifyIsOpen = false;
  public pictureExists = false;
  public d360Exists = false;
  public menuExists = false;
  public showMenu = false;

  public reviewIsOpen = false;
  public show360 = true;
  public displayMenu = false;
  public reviewId = 0;
  public photoCount = 0;

  public shareOpen = false;
  public menuOpen = false;
  public photoURL: string;
  public d360URL: string;

  public TitleLength: number;

  public dayhold;

  public PanoStatus = '';

  public carouselConfig: NgxCarousel;

  private subscription: Subscription;

  @ViewChild('listingOverflow')
  public listingOverflowElementRef: ElementRef;

  @ViewChild('listingPop')
  public listingPopRef: ElementRef;

  public listingPopIsSelected: boolean = false;

  public get listingHours(): ListingTime[] {
    //const list = _.orderBy(this.listing.listingHours, ['dayName']);
    // list.forEach(x => {
    //   if (this.dayhold !== x.dayName) {
    //     this.dayhold = x.dayName;
    //   }
    //   else {
    //     x.dayName = 7;
    //   }
    // })
    const list = this.listing.listingHours;
    list.forEach(x => {
      if (this.dayhold !== x.dayName) {
        this.dayhold = x.dayName;
      }
      else {
        //x.dayName = 7;
        x.isSameDayName = true;
      }
    });
    return list;
  }

  public routerSubs: Subscription;
  public paymentList='';

  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 


  constructor(
    public store: MapwordsStore,
    private _route: ActivatedRoute,
    private _router: Router,
    private _TitleService: Title,
    private _sanitizer: DomSanitizer,
    private _listingsService: ListingsService,
    public _render: Renderer,
    private s3filesService: S3filesService,
    private cookieService: CookieService 
  ) { }

    
  ngOnInit() {
    // console.log(this.listing);
     this.cookieExpire.setTime(this.cookieTime);

    this.storeChanged();
        
    // console.log('this.cookieService.get)') ;
    // console.log(this.cookieService.get('review')) ;
    // console.log('d360URL') ;
    // console.log(this.d360URL) ;

    this._route.data.subscribe(x => {  
      this.listing.listingHours = x['listing']['data']['listingHours'];
      // console.log(this.listing);
    });

    this.routerSubs = this._route.data.subscribe(x => {
      this.store.searchListing.selectListing(x['listing']['data']);    
      // console.log(this.store.searchListing.selectListing(x['listing']['data'])); 
      
      this.showModal = true;
      this.store.search.hoverListing(x['listing']['data']['googlePlaceId']);
    });    

    const d = new Date();
    // console.log(d.getDay());
    this.todayNumber = d.getDay();

    if (window.innerHeight < 800) {
      this.TitleLength = 30;
    } else {
      this.TitleLength = 55;
    }

    this.carouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 700,
      interval: 4500,
      point: {
        visible: true
      },
      loop: true,
      touch: true,
      easing: 'ease'
    }
    if (this.store.myClaimListing.openClaimRequested) {
      this.store.myClaimListing.openClaimRequested = false;
      if (this.store.auth.isSignedIn)
        this.store.searchListing.openClaim();
    }

  }

  ngOnDestroy() {
    this.routerSubs.unsubscribe();
  }

  public editReview(reviewId: number): void {
    this.reviewId = reviewId;
    this.reviewIsOpen = true;
  }

  setStatus(str: string) {
    this.PanoStatus = str;

    if (this.PanoStatus === 'ZERO_RESULTS') {
      this.d360Exists = false;
    } else {
      this.d360Exists = true;
    }
  }

  public sameDayName: boolean = false;
  public notShowDayName: boolean;
  public today2nd: Array<any>;

  public storeChanged() {
    //console.log('Store has changed');  
    // console.log('this.store.searchListing.listing');
    // console.log(this.store.searchListing.listing);

    this.listing = this.store.searchListing.listing;

    if (this.listing) {

        if (this.listing.id != null) {

          // console.log('this.listing');
          // console.log(this.listing);

              if( (this.cookieService.get('review') == '1' || this.cookieService.get('claim') == '1' ) && !this.store.auth.isSignedIn ) {
                this.cookieService.set('review','0' , this.cookieExpire ,'/' );
                this.cookieService.set('claim','0' , this.cookieExpire ,'/');
              }

              if(this.cookieService.get('review') == '1') {
                this.cookieService.set('review','0' , this.cookieExpire ,'/' );

                var hasReview = this.store.searchListing.listing.reviews.filter(x => x.clientId == this.store.auth.user.id).length;
                var isOwner = this.store.searchListing.listing.ownerId == this.store.auth.user.id;

                // console.log('hasReview');
                // console.log(hasReview);
                // console.log('isOwner');
                // console.log(isOwner);
                // console.log(this.listing);
                // console.log(this.store.auth.user.id);

                if(!this.store.searchListing.isAReviewAdded && hasReview == 0 && !isOwner) {
                  this.writeReview();
                }
              }

              if(this.cookieService.get('claim') == '1') {
                this.cookieService.set('claim','0' , this.cookieExpire ,'/');

                this.claimBusiness();
              }



              if(this.listing.payments) {

                let payments = [];
                if(this.listing.payments.amex) {
                  payments.push('Amex');
                }
                if(this.listing.payments.cash) {
                  payments.push('Cash');
                }
                if(this.listing.payments.check) {
                  payments.push('Check');
                }
                if(this.listing.payments.discover) {
                  payments.push('Discover');
                }
                if(this.listing.payments.masterCard) {
                  payments.push('MasterCard');
                }
                if(this.listing.payments.visa) {
                  payments.push('Visa');
                }

                this.paymentList = payments.join(', ');
                
              }



        } 

      this.d360URL = 'https://maps.googleapis.com/maps/api/streetview?size=600x400&fov=90&heading=260&pitch=-2&location=' + this.listing.businessAddress.latitude + ',' + this.listing.businessAddress.longitude + '&sensor=false&key='+ environment.googleKey;

      // console.log(this.listing);
      // Set to False until pictureExists can be fixed
      // console.log(this.listing);
      // this.listing.masterCategories[0].masterCategoryId;
      // console.log(this.listing.masterCategories[0].masterCategoryRequest.name);
      // this.listing.masterCategories[0].masterCategory.description === "Sorry";
      // console.log(this.listing.masterCategories[0].masterCategory.description);
      
      // console.log(this.listing.masterCategories[0].masterCategoryId);
      // this.listing.masterCategories[0].mcrId;
      // console.log(this.listing.masterCategories[0].mcrId);
      let newListingHours: ListingTime[] = [];
      if (this.listing.listingHours){
        this.listing.listingHours.forEach(x => newListingHours.push(x));
      }      
      //console.log(newListingHours);

      // console.log('this.listing.photos.length') ;
      // console.log(this.listing.photos.length) ;
      // console.log(this.listing.photos) ;

      if (this.listing.photos.length > 0) { this.pictureExists = true; } else {
        this.pictureExists = false;
      }
      // console.log(this.listing.photos.length);
      if(this.listing.menus) {
        if (this.listing.menus.length > 0) { 
          this.displayMenu = true; 
        }
      }
      
      const re = /assets/gi;
      for (const picture of this.listing.photos) {
        if (picture.imageUrl === null) {
          this.d360Exists = true;
          this.d360URL = picture.imageUrl;
        } else {
          this.photoCount++;
          this.menuExists = true;


          if (picture.googleReviewId !== 0) {
            if(picture.imageUrl.indexOf('amazonaws')>0) {
              this.photoURL = this.s3filesService.getSignedUrl(picture.imageUrl);
            } else {
              this.photoURL = picture.imageUrl;
            }
          } else {
            if(picture.imageUrl.indexOf('amazonaws')>0) {
              this.photoURL = this.s3filesService.getSignedUrl(picture.imageUrl);
            } else {
              this.photoURL = environment.imagesOrigin + 'assets/images/listings/' + picture.imageUrl;
            }
          }          
        }
      }

      // tslint:disable-next-line:max-line-length
      // this.d360URL = 'https://maps.googleapis.com/maps/api/streetview?size=600x400&fov=90&heading=260&pitch=-2&location=' + this.listing.businessAddress.latitude + ',' + this.listing.businessAddress.longitude + '&sensor=false&key=' + environment.googleKey;

      if (this.listingOverflowElementRef) {
        this.listingOverflowElementRef.nativeElement.scrollTop = 0;
      }
      this._TitleService.setTitle(`${this.listing.name} - Mapwords`);     

      if (this.listing.contact.website) {
        this.url = this._sanitizer.bypassSecurityTrustUrl(this.listing.contact.website);
      } else {
        this.url = null;
      }
      if (this.listing.listingHours) {        
        //console.log(this.listing.listingHours);
        this.noHours = false;
        this.listing.listingHours.forEach(x => {
          if (this.dayhold !== x.dayName) {
            this.dayhold = x.dayName;
          } else {
            //x.dayName = 7;
            x.isSameDayName = true;
          }
        });
        // console.log(this.listing)
        this.today2nd = this.listing.listingHours.filter(x => x.dayName == this.todayNumber);
        // console.log(this.today2nd.length);
        const storeToday = this.listing.listingHours.find(x => x.dayName === this.todayNumber);
        // console.log(storeToday);
        if (storeToday) {
          this.today = storeToday;
        } else {
          this.today = new ListingTime({
            dayName: this.todayNumber,
            active: false
          });
        }
      } else {
        this.noHours = true;
      }
    
    
    }

    // tslint:disable-next-line:max-line-length
    this._listingsService.CheckPano(this.store.searchListing.listing.businessAddress.latitude, this.store.searchListing.listing.businessAddress.longitude)
      .subscribe(x => {
        this.setStatus(x)
      });
   
    return this.listing = this.store.searchListing.listing;
  }

  public goBack(): void {
    this._router.navigate(['/home', { outlets: { listing: null } }]);
    this.store.searchListing.photoIsOpen = false;
    this.store.searchListing.photoGaleryIndexUrl = '';
    this.store.searchListing.unSelectListing();
  }

  public showHoursToggle(): void {
    this.showHours = !this.showHours;
  }

  public claimBusiness(): void {
    if (this.store.auth.isSignedIn  && this.store.auth.user !== null && this.store.auth.user !== undefined ) {
      this.store.searchListing.openClaim();
    } else {
      this.store.myClaimListing=this.store.searchListing;
      this.store.myClaimListing.openClaimRequested=true;
      
      this.cookieService.set('claim','1' , this.cookieExpire,'/' );
      this.cookieService.set('redirect',this._router.url , this.cookieExpire,'/' );


      this.store.alerts.add({
        title: 'Sign into Mapwords',
        message: 'Please sign in or create a new account.',
        hasCancel: true,
        cancelLabel: 'Sign In',
        okLabel: 'Sign Up',
        execute: (x) => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-up');
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-in');
          } else if (x === ActionTypes.close) {
            localStorage.removeItem('claim');
          }
        }
      });
    }
  }

  public writeReview(): void {
    if (this.store.auth.isSignedIn  && this.store.auth.user !== null && this.store.auth.user !== undefined ) {
      this.reviewIsOpen = true;
    } else {



      this.cookieService.set('review','1' , this.cookieExpire,'/' );
      this.cookieService.set('redirect',this._router.url , this.cookieExpire,'/' );

      this.store.alerts.add({
        title: 'Sign into Mapwords',
        message: 'Please sign in or create a new account.',
        hasCancel: true,
        cancelLabel: 'Sign In',
        okLabel: 'Sign Up',
        execute: (x) => {
          if (x === ActionTypes.ok) {
            this._router.navigateByUrl('/auth/sign-up');
          } else if (x === ActionTypes.cancel) {
            this._router.navigateByUrl('/auth/sign-in');
          } else if (x === ActionTypes.close) {
            localStorage.removeItem('review');
          }
        }
      });
    }
  }

  public searchCategory(searchterm: string): void {
    if (searchterm !== 'Uncategorized') {
      const filter = this.store.search.cloneFilter;
      filter.searchterm = searchterm;
      this.store.search.updateFilter(filter);
    }
  }

  public deleteReview(reviewId: number): void {
    this.listing.reviews = this.listing.reviews.filter(
      x => x.id !== reviewId
    );

    this.listing.photos = this.listing.photos.filter(x => x.googleReviewId !== reviewId && x.listReviewId !== reviewId);  
  }

  public loadPhotos(photoGaleryIndexUrl: string, gallery: string): void {
    // console.log(this.listing);
    // console.log(this.photoURL);
    // debugger;
    if (gallery === 'D360') {
      this.show360 = true;
      this.store.searchListing.D360 = true;
      this.listingPopIsSelected = true;

      // this._router.navigate(['/home', { outlets: { 'streetview': [id, place_id] } }]);

      this.store.searchListing.d360IsOpen = true;
      this.store.searchListing.photoIsOpen = false;
      this.store.searchListing.photoGaleryIndexUrl = '';

    } else if (gallery === 'Service') {

      // this._router.navigate(['/home', { outlets: { 'photo' : [id, place_id] } }])


      // this.store.searchListing.photoIsOpen = true;
      // this.listingPopIsSelected = true;
      // this._render.setElementStyle(this.listingPopRef.nativeElement, 'max-width', '460px');
      // this._render.setElementStyle(this.listingPopRef.nativeElement, 'margin-left', '0px');
      
      // this.listing = this.store.searchListing.listing
      this.listingPopIsSelected = true;

      this.store.searchListing.photoGaleryIndexUrl = photoGaleryIndexUrl;
      this.store.searchListing.photoIsOpen = true;
      
      this.store.searchListing.d360IsOpen = false;

     

    } 
    if (this.listingPopRef) {
      
      // this._render.setElementStyle(this.listingPopRef.nativeElement, 'max-width', '460px');
      // this._render.setElementStyle(this.listingPopRef.nativeElement, 'margin-left', '0px');

      this.listingPopIsSelected = true;

    }
  }

  public loadMenus(id: number, place_id: string): void {
    this.store.searchListing.menuIsOpen = true;
    if (this.listingPopRef) {
      this._render.setElementStyle(this.listingPopRef.nativeElement, 'max-width', '460px');
      this._render.setElementStyle(this.listingPopRef.nativeElement, 'margin-left', '0px');
    }
    this._router.navigate(['/home', { outlets: { 'menu': [id, place_id] } }]);
  }

  public loadListing(id: number, place_id: string): void {
    // console.log('Load Listing');
    this._router.navigate(['/home', { outlets: { 'listing': [id, place_id] } }]);
    //console.log(this.listing);
  }


  public getAvatar(avatar: string): string {

    var result = this.s3filesService.getSignedUrl(avatar);
    return result;
  }

  public refreshReview(): void {
    console.log('refreshReview');
    this.storeChanged();
  }

}
