import { Injectable } from '@angular/core';
import { action, observable, computed, reaction } from 'mobx';

import { IQuickSearchObject } from '../models/QuickSearchObject';
import { CategoryTreeService } from '../services/api/categorytree.service';

@Injectable()
export class CategoryTreeStore {

  @observable
  public categoryTree: IQuickSearchObject[] = [];


  constructor(
    private _categoryTreeService: CategoryTreeService
   ) {
      // console.log('FILL CATEGORY TREE') ;
  }


  @action('FILL CATEGORY TREE')
  public fillCategoryTree(): void {
 
    console.log('this.categoryTree.length') ;
    console.log(this.categoryTree.length) ;

    if (this.categoryTree.length===0) {
        this._categoryTreeService.GetCategoryTree().subscribe((x) =>{
            this.categoryTree = x.data;
            console.log('FILL CATEGORY TREE - COMPLETED') ;
            console.log(x.data) ;
        });
    }
  }


}
