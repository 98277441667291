import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from '../../../../../shared/services/api';
import { Client, IFClientPlace, IFClient, ClientPlace } from '../../../../../shared/models';

@Component({
  selector: 'mw-user-places',
  templateUrl: './mw-user-places.component.html',
  styleUrls: ['./mw-user-places.component.scss']
})

export class MwUserPlacesComponent implements OnInit, OnChanges {

  @Input()
  public client: Client;

  public fPlaces: FormGroup;

  public get places(): FormArray {
    return <FormArray>this.fPlaces.get('places');
  }

  constructor(
    private _fb: FormBuilder,
    private _clientService: ClientsService
  ) { }

  public ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.client.places = changes['client'].currentValue.places || [];
    this.initForm(this.client);
  }

  public toggleInput(isSaved: boolean) {
    if (isSaved) {
      this.submit();
    } else {
      this.initForm(this.client);
    }
  }

  public validateKey(event: KeyboardEvent): boolean {
    // if (String.fromCharCode(event.keyCode).match(/[a-z0-9]/g) == null) {
    if (String.fromCharCode(event.keyCode).match(/[a-zA-Z0 ]/g) == null) {
      return false;
    }
  }

  public submit(): void {
    if (this.fPlaces.valid) {
      this._clientService.PutPlaces(this.fPlaces.value)
        .subscribe(x => {
          this.client.homeTown = x.data.homeTown;
          this.client.currentCity = x.data.currentCity;
          this.client.places = x.data.places || [];
        });
    }
  }

  public addPlace(name: string): void {

    if(!name) {
      return;
    }

    if (name.length > 0) {
      this.places.push(
        this._fb.group(<IFClientPlace>{
          name: name,
          id: 0,
          active: true
        })
      );
    }
  }

  public removePlace(index: number): void {
    const place = <ClientPlace>this.places.at(index).value;
    if (place.id) {
      place.active = false;
    } else {
      this.places.removeAt(index);
    }
  }

  private initForm(client: Client): void {
    this.client = client;
    this.fPlaces = this._fb.group(<IFClient>{
      currentCity: [this.client.currentCity, [Validators.minLength(2), Validators.maxLength(35)]],
      homeTown: [this.client.homeTown, [Validators.minLength(2), Validators.maxLength(35)]],
      places: this._fb.array(this.initPlaces())
    });
  }

  private initPlaces(): FormGroup[] {
    const list = [];
    if (this.client.places !== null) {
      this.client.places.forEach(x => {
        list.push(this._fb.group(<IFClientPlace>{
          name: [x.name],
          active: [x.active],
          addedDate: [x.addedDate],
          id: x.id
        }));
      });
      return list;
    }
  }



}
