import { Component, Input, OnInit, ViewChildren, QueryList, ViewContainerRef, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MwToggleInputsComponent, MwImageEditorService } from '../../../../../shared/components';
import { ClientsService, ListingsService } from '../../../../../shared/services/api';
import { Client, IFClient, IFAddress, Address, Gender, ListingServices } from '../../../../../shared/models';
import { environment } from '../../../../../environments/environment';
import { MapwordsStore } from '../../../../../shared/store';
import * as moment from 'moment';
import { S3filesService } from '../../../../../shared/services';

@Component({
  selector: 'mw-user-profile',
  templateUrl: './mw-user-profile.component.html',
  styleUrls: ['./mw-user-profile.component.scss']
})
export class MwUserProfileComponent implements OnInit, OnChanges {

  public imagesOrigin: string = environment.imagesOrigin;

  @ViewChildren(MwToggleInputsComponent) public toggles: QueryList<MwToggleInputsComponent>;

  public fClient: FormGroup;

  @Input()
  public client: Client;

  @Input()
  public showForFirstNameLastName: boolean;

  @Output()
  public formSaved = new EventEmitter<boolean>();

  public states: Array<any> = [
    {
      name: 'Alabama',
      value: 'AL'
    },
    {
      name: 'Alaska',
      value: 'AK'
    },
    {
      name: 'American Samoa',
      value: 'AS'
    },
    {
      name: 'Arizona',
      value: 'AZ'
    },
    {
      name: 'Arkansas',
      value: 'AR'
    },
    {
      name: 'California',
      value: 'CA'
    },
    {
      name: 'Colorado',
      value: 'CO'
    },
    {
      name: 'Connecticut',
      value: 'CT'
    },
    {
      name: 'Delaware',
      value: 'DE'
    },
    {
      name: 'District Of Columbia',
      value: 'DC'
    },
    {
      name: 'Federated States Of Micronesia',
      value: 'FM'
    },
    {
      name: 'Florida',
      value: 'FL'
    },
    {
      name: 'Georgia',
      value: 'GA'
    },
    {
      name: 'Guam',
      value: 'GU'
    },
    {
      name: 'Hawaii',
      value: 'HI'
    },
    {
      name: 'Idaho',
      value: 'ID'
    },
    {
      name: 'Illinois',
      value: 'IL'
    },
    {
      name: 'Indiana',
      value: 'IN'
    },
    {
      name: 'Iowa',
      value: 'IA'
    },
    {
      name: 'Kansas',
      value: 'KS'
    },
    {
      name: 'Kentucky',
      value: 'KY'
    },
    {
      name: 'Louisiana',
      value: 'LA'
    },
    {
      name: 'Maine',
      value: 'ME'
    },
    {
      name: 'Marshall Islands',
      value: 'MH'
    },
    {
      name: 'Maryland',
      value: 'MD'
    },
    {
      name: 'Massachusetts',
      value: 'MA'
    },
    {
      name: 'Michigan',
      value: 'MI'
    },
    {
      name: 'Minnesota',
      value: 'MN'
    },
    {
      name: 'Mississippi',
      value: 'MS'
    },
    {
      name: 'Missouri',
      value: 'MO'
    },
    {
      name: 'Montana',
      value: 'MT'
    },
    {
      name: 'Nebraska',
      value: 'NE'
    },
    {
      name: 'Nevada',
      value: 'NV'
    },
    {
      name: 'New Hampshire',
      value: 'NH'
    },
    {
      name: 'New Jersey',
      value: 'NJ'
    },
    {
      name: 'New Mexico',
      value: 'NM'
    },
    {
      name: 'New York',
      value: 'NY'
    },
    {
      name: 'North Carolina',
      value: 'NC'
    },
    {
      name: 'North Dakota',
      value: 'ND'
    },
    {
      name: 'Northern Mariana Islands',
      value: 'MP'
    },
    {
      name: 'Ohio',
      value: 'OH'
    },
    {
      name: 'Oklahoma',
      value: 'OK'
    },
    {
      name: 'Oregon',
      value: 'OR'
    },
    {
      name: 'Palau',
      value: 'PW'
    },
    {
      name: 'Pennsylvania',
      value: 'PA'
    },
    {
      name: 'Puerto Rico',
      value: 'PR'
    },
    {
      name: 'Rhode Island',
      value: 'RI'
    },
    {
      name: 'South Carolina',
      value: 'SC'
    },
    {
      name: 'South Dakota',
      value: 'SD'
    },
    {
      name: 'Tennessee',
      value: 'TN'
    },
    {
      name: 'Texas',
      value: 'TX'
    },
    {
      name: 'Utah',
      value: 'UT'
    },
    {
      name: 'Vermont',
      value: 'VT'
    },
    {
      name: 'Virgin Islands',
      value: 'VI'
    },
    {
      name: 'Virginia',
      value: 'VA'
    },
    {
      name: 'Washington',
      value: 'WA'
    },
    {
      name: 'West Virginia',
      value: 'WV'
    },
    {
      name: 'Wisconsin',
      value: 'WI'
    },
    {
      name: 'Wyoming',
      value: 'WY'
    }
  ];

  public genderValues = [
    {
      name: 'Male',
      value: 0,
    },
    {
      name: 'Female',
      value: 1,
    },
    {
      name: 'Other',
      value: 2
    }
  ];

  public selectedAvatar: any;

  constructor(
    private _clientService: ClientsService,
    private _fb: FormBuilder,
    private _editor: MwImageEditorService,
    private _viewRef: ViewContainerRef,
    public store: MapwordsStore,
    public _listingsService: ListingsService,
    private s3filesService: S3filesService

  ) { }

  public ngOnInit() {

    this.imagesOrigin = environment.imagesOrigin;


    if (this.client.address === undefined) {
      this.client.address = new Address({});
    }
    this.initForm(this.client);

    // console.log(this.fClient.valid);
    // console.log(this.fClient);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.client.address === undefined) {
      this.client.address = new Address({});
    }
    this.initForm(changes['client'].currentValue);
  }

  public editPhoto(): void {
    this._editor.open(this.imagesOrigin + this.client.avatar, this._viewRef)
      .subscribe(x => {
        if (x === '' || x === undefined || x === null) { return; }
        // this.imagesOrigin = '';
        //this.fClient.value.avatar = x.split(';')[0];
        this.fClient.get('avatar').setValue(x);
        this.fClient.get('signedAvatar').setValue(x);
        this.selectedAvatar = x;
      });
  }

  public checkAvatar(avatar: any): boolean {

    if(avatar !== null && avatar !== undefined ) {
      if(avatar.indexOf('data:image') === 0) {
        
        return true;
      }
      
    }

    return false;
  }


  public async submit(): Promise<void> {
    // console.log(this.client, this.fClient.value)
    if (this.fClient.valid) {

      var BUCKET = environment.s3.Bucket.toString();
      var IMAGEFOLDER = 'Avatar';      

      var dateTime = new Date();
      let cdateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss.SSS");

      // Emre for S3 upload
      // Sean added condition if undefined
      if(this.selectedAvatar !== null && this.selectedAvatar !== undefined) {

        var imageExtension = this.selectedAvatar.split(';')[0].split('/');
        imageExtension = imageExtension[imageExtension.length - 1];

        var imageName = cdateTime.toString() ;
        imageName = imageName.replace(/[^A-Z0-9]/ig, "") + '.' + imageExtension ;
  
        var fileName = IMAGEFOLDER + '/' + this.client.id.toString() + '/' + imageName;
        var buf = new Buffer(this.selectedAvatar.replace(/^data:image\/\w+;base64,/, ""), 'base64');

        await this._listingsService.uploadfile(buf, fileName).then( (uploadedImageUrl) => {

          // console.log('fileName');
          // console.log(fileName);

          console.log('uploadedImageUrl');
          console.log(uploadedImageUrl);

          this.fClient.value.avatar = uploadedImageUrl.toString() ;
          this.fClient.value.signedAvatar = uploadedImageUrl.toString() ;

          this.client.avatar = uploadedImageUrl.toString() ;
          this.client.signedAvatar = uploadedImageUrl.toString() ;

          this._clientService.PutProfile(this.fClient.value)
          .subscribe(x => {
            this.client = x.data;
            this.client.signedAvatar = uploadedImageUrl.toString() ;
            this.client.avatar = uploadedImageUrl.toString() ;
            this.store.auth.user.avatar = uploadedImageUrl.toString() ;
            this.store.auth.user.signedAvatar = uploadedImageUrl.toString() ;

            //
            // console.log('this._clientService.PutProfile subs');

            this.store.auth.updateUser(this.client);
          });

        });

      }
      else
      {
        this._clientService.PutProfile(this.fClient.value)
        .subscribe(x => {
          this.client = x.data;
          this.store.auth.updateUser(this.client);
        });
      }
    }
  }

  private initForm(client: Client): void {
    this.client = client;
    if (this.client.address === undefined) {
      this.client.address = new Address({});
    }
    this.fClient = this._fb.group(<IFClient>{
      firstName: [
        this.client.firstName,
        [Validators.required, Validators.minLength(2), Validators.maxLength(35), this.specialCharValidator.bind(this)]
      ],
      middleName: [this.client.middleName, this.specialCharValidator.bind(this)],
      lastName: [this.client.lastName,
        [ Validators.required, Validators.minLength(2), Validators.maxLength(35), this.specialCharValidator.bind(this)]
      ],
      gender: [this.client.gender + '', [Validators.required]],
      // username: [this.store.auth.user.username, [Validators.required, Validators.minLength(6)]],
      phone: [this.client.phone],
      mobilePhone: [this.client.mobilePhone],
      webSite: [this.client.webSite],
      avatar: [this.client.avatar],
      signedAvatar: [this.client.signedAvatar],
      addressId: [this.client.addressId || 0],
      address: this._fb.group(<IFAddress>{
        id: this.client.address.id || 0,
        country: this.client.address.country || '',
        latitude: this.client.address.latitude,
        longitude: this.client.address.longitude,
        suiteNumber: [this.client.address.suiteNumber || '', [Validators.minLength(2), Validators.maxLength(10)]],
        street: [this.client.address.street || '', [ this.showForFirstNameLastName ? Validators.nullValidator : Validators.nullValidator, Validators.minLength(3), Validators.maxLength(150)]],
        city: [this.client.address.city || '', [ this.showForFirstNameLastName ? Validators.nullValidator :Validators.nullValidator, Validators.minLength(2), Validators.maxLength(50)]],
        state: [this.client.address.state || '', [ this.showForFirstNameLastName ? Validators.nullValidator :Validators.nullValidator, Validators.minLength(2), Validators.maxLength(35)]],
        zipCode: [this.client.address.zipCode || '', [ this.showForFirstNameLastName ? Validators.nullValidator :Validators.nullValidator, Validators.minLength(5), Validators.maxLength(10)]]
      }),
      aboutMe: [this.client.aboutMe, [Validators.maxLength(300)]]
    });
  }


  public isAdressValid(): boolean {
    if(this.fClient.value.address.street && this.fClient.value.address.city && this.fClient.value.address.state && this.fClient.value.address.zipCode  ) {
      return true;
    }
    else
    {
      return false;
    }
  }

  /**
	 * Returns the gender based on the genderId from the database.
	 *
	 * @param {number} genderId The gender id.
	 * @readonly
	 * @type {string}
	 * @memberOf UserProfileComponent
	 */
  public gender(genderId: any): string {
    const genderId1 = genderId;
    let name = '';
    switch (genderId1) {
      case 0:
        name = 'Male';
        break;
      case 1:
        name = 'Female';
        break;
      default:
        name = 'Other';
        break;
    }
    return name;
  }

  public toggleInput(isSaved: boolean) {
    if (isSaved) {
      this.submit();
      this.formSaved.emit(true);
    } else {
      this.imagesOrigin = environment.imagesOrigin;
      this.initForm(this.client);
    }
  }

  /**
	 * Cancel all the current open toggle sections
	 * @memberOf ListingDetailsComponent
	 */
  public closeAll(toggle: MwToggleInputsComponent): void {
    // console.log(toggle);
    this.toggles.forEach(t => {
      t.active = false;
    });
    toggle.active = true;
  }

  public specialCharValidator(c: FormControl):  { [key: string]: any } {
    if (c.value) {
      if (!c.value.match(/[-!$%^&*()_+|~=`{}\[\]:";#@'<>?,.\/]/)) {
        return null;
      } else {
        return { 'invalidChar': true };
      }
    }
  }
}
