import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer, BehaviorSubject } from 'rxjs';

import { Listing, RG, GetListingBySearchTermRouteModel as Filter } from '../models';
import { ListingsService } from './api';
import { MapwordsStore } from '../store';

import { ErrorResolver } from '../../app/common/app-error-resolver';
import 'rxjs/add/operator/share';

@Injectable()
export class ListingResolver implements Resolve<RG<Listing>> {

  public filter$: Observable<Filter>;
  public _filterObserver: Observer<Filter>;

  constructor(
    private _listingsService: ListingsService,
    private _router: Router,
    public store: MapwordsStore) {

    this.filter$ = new Observable<Filter>(observer => this._filterObserver = observer).share();

  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // console.log('ListingResolver') ;
    // console.log(route.params['placeid']) ;

    const id = +route.params['id'];
    const place_id = route.params['placeid'];
    this.store.search.startLoading();
    const listing$ = this._listingsService.GetById(id, place_id);

    // console.log('ListingResolver Listing') ;
    // console.log(listing$) ;


    return listing$.catch(e => {
      // this.store.alerts.add({
      //   title: 'Oops',
      //   message: 'Unable to complete the operation at this time, please try again later.',
      //   execute: () => {
      //     location.reload();
      //   }
      // });
      // return Observable.empty();
      return new ErrorResolver().resolve(e);
    });





  }

  public filterUrlChange(filter: Filter): void {
    if (this._filterObserver) {
      this._filterObserver.next(filter);
    }
  }

}
