import { Pipe, PipeTransform } from '@angular/core';
import { S3filesService } from 'services';
import { MapwordsStore } from 'store';


@Pipe({
  name: 'signedurl'
})

export class SignedUrlPipe implements PipeTransform {


  constructor(public s3filesService: S3filesService){}


  transform(value: any, args: any[]): any {
  
    var result = this.s3filesService.getSignedUrl(value);
    return result;

  }
}
