import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { action, observable, computed, reaction } from 'mobx';
import { Client, User, S3SignedUrl, Listing } from '../models';
import { ActionTypes } from '../viewmodels';
import { ClientsService } from '../services/api';
import { AlertsStore } from './alerts.store';
import { ClientStore } from './client.store';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import { S3Store } from './s3.store';
import { ConstantPool } from '../../../node_modules/@angular/compiler';
import { CookieService } from '../../../node_modules/ngx-cookie-service';


@Injectable()
export class AuthStore {

  @observable
  public user: Client = new Client({});

  @observable
  public token: { access_token, token_type, expires_in } = null;

  public imageOrigin = environment.imagesOrigin;

  cookieExpire = new Date();
  cookieTime = Date.now() + ((600 * 1000) * environment.cookieExpireDuration); 

  constructor(
    public _alerts: AlertsStore,
    private _clientsService: ClientsService,
    private _http: Http,
    private _clients: ClientStore,
    private _router: Router,
    private s3store: S3Store,
    private cookieService: CookieService 

  ) {

    this.cookieExpire.setTime(this.cookieTime);
    
    //localStorage.clear();
    this.user = null;
    
    if(this.cookieService.get('authToken')) {
        this.token = JSON.parse(this.cookieService.get('authToken'));
        this.user = JSON.parse(this.cookieService.get('user'));
        if (this.token) {
          this._clientsService.GetBasicProfile().subscribe(x => {
            this.user = x.data;
          });
        }
    }
    if (this.user == null) {
      this.user = new Client({});
    }
  }

  @computed
  public get isSignedIn(): boolean {
    return this.user !== null && this.user.id !== null ? true : false;
  }

  //Sean
  @computed
  public get isAgent(): boolean {

    if(this.user === null) {
      return false;
     }

    return this.user.role !== null && this.user.role == 2 ? true : false;
  }

  @computed
  public get isClient(): boolean {
    
    if(this.user === null) {
     return false;
    }

    return this.user.role !== null && this.user.role == 0 ? true : false;
  }

  //Joel
  @computed
  public get isAdmin(): boolean {
    if(this.user === null) {
      return false;
    }
    return this.user.role !== null && this.user.role == 3 ? true : false;
  }
  
  @action('[USER][LOG IN] Try')
  public login(user: User, after): void {
    const headers = new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' });
    this._http.post(environment.apiUrl + 'token', null, {
      headers: headers,
      body: `grant_type=password&username=${user.email}&password=${user.password}`
    })
      .map(x => x.json())
      .subscribe(
      x => this.loginSuccess(x, after),
      err => this.loginFailed(err,after)
      );
  }

  @action('[USER][LOGIN] Success')
  private loginSuccess(x, after): void {
    this.token = x;
    this.cookieService.set('authToken', JSON.stringify(this.token) , this.cookieExpire,'/' );
    this._clientsService.GetBasicProfile().subscribe(
      x => {
        this.user = x.data;
        
        if(this.user) {
          if(this.user.avatar) {
            this.user.avatar.indexOf('http') > -1 ? this.user.signedAvatar =  this.getAvatar(this.user.avatar) : this.user.signedAvatar = this.imageOrigin+ this.user.avatar ;
          }

          if(this.user.relationships){
            this.user.relationships.forEach(element => {
              if(element.secondClient.avatar) {
                element.secondClient.avatar.indexOf('http') > -1 ? element.secondClient.signedAvatar =  this.getAvatar(element.secondClient.avatar) : element.secondClient.signedAvatar = this.imageOrigin+ element.secondClient.avatar ;
              }
            });

          }


        }
        

        this.cookieService.set('user', JSON.stringify(this.user), this.cookieExpire,'/' );
        sessionStorage.setItem('user', JSON.stringify(this.user)) ;
        after(true);
      }
    );
  }

  @action('[USER][LOGIN] Failed')
  private loginFailed(err: { status: number },after):void {
    // if (err.status === 400) {
    //   this._alerts.add({
    //     title: 'Login Failed',
    //     message: 'The username or password is invalid.'
    //   //   message: 'Incorrect Username or Password.'
    //   });
    //   after(false);
    //   after(
    //     {
    //       title: 'Invalid',
    //       message: 'The username or password is invalid.',
    //     }
    //   );
    // } else if (err.status !== 400) {
    //   after ({
    //     title: 'Server Error',
    //     message: 'Unable to complete the operation at this time, please try again later.',
    //   })
    // } 

    // this._alerts.add({
    //     title: 'Ooops!',
    //     message: 'Unable to complete the operation at this time, please try again later.',
    // });

    
    after(
      {
        title: 'Invalid',
        message: 'The username or password is invalid.',
      }
    )

  }




  @action('[USER][SIGN OUT] Try')
  public signOut(after): void {
    this._alerts.add({
      title: 'Are you sure?',
      message: 'You are about to sign out. Do you want to continue?',
      okLabel: 'OK',
      hasCancel: true,
      execute: (x: ActionTypes) => {
        if (x === ActionTypes.ok) {
          // console.log('Hello?')
          this.forceSignOut();
          if (after) {
            after();
          }
        }
      }
    });
  }

  @action('[USER][SIGN OUT] Forced Sign out')
  public forceSignOut(): void {
    this.user = null ; //new Client({});
    this.token = null;
    this.cookieService.deleteAll('/');
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   *
   * Verifies the email account from the user
   * @param {string} token
   * @param {any} after What happends after a succesfully verification
   * @param {any} errorAfter What happends after a error
   *
   * @memberOf AuthStore
   */
  @action('[USER][EMAIL:VERIFY] Try')
  public verify(token: string, after, errorAfter): void {
      this._clientsService.PutVerifyClient(token)
      .subscribe(
          x => {
        if (x.data.answer) {
          this.verifySuccess(after);
          // ADDED by SERGE
          if (x.data.needChangePwd)
            this._router.navigateByUrl('/auth/recover/' + token);
          else 
            this._router.navigateByUrl('/auth/sign-in');
          // -- ADDED
        } else {
          this.verifyFailed(errorAfter);
        }
      },
      err => this.verifyFailed(errorAfter)
      );
  }

  @action('[USER][EMAIL:VERIFY] Success')
  private verifySuccess(after): void {
      this._alerts.add({
      title: 'Success',
      message: 'Email verified succesfully, please log in.',
      execute: () => {
        after();
      }
    });
  }

  @action('[USER][EMAIL:VERIFY] Failed')
  private verifyFailed(errorAfter): void {
    this._alerts.add({
      title: 'Error',
      message: 'Unable to verify you account. Please try again or register a new account.',
      execute: () => {
        if (errorAfter) {
          errorAfter();
        }
      }
    });
  }

  @action('[USER][RECOVER] Try')
  public recover(email: string): void {
    this._clientsService.PutRecovery(email)
      .subscribe(
      x => {
        if (x.data.answer) {
          this.recoverSuccess();
        } else {
          this.recoverFailed();
        }
      }
      );
  }

  @action('[USER][RECOVER] Success')
  private recoverSuccess(): void {
    this._alerts.add({
      title: 'Success',
      message: 'We have sent you an email. Please follow the instructions to change your password.',
      execute: (x) => {
          this._router.navigateByUrl('/home');
      }
    });
  }

  @action('[USER][RECOVER] Failed')
  private recoverFailed(): void {
    this._alerts.add({
      title: 'Oops',
      message: 'Email not found. Please try again.'
    });
  }

  @action('[USER][RECOVER:Password] Try')
  public recoverPassword(token: string, password: string, success: (x: ActionTypes) => void, error: () => void): void {
    this._clientsService.PutRecoveryVerify(token, password)
      .subscribe(
      x => this.recoverPasswordSuccess(success),
      err => this.recoverPasswordFailed(error)
      );
  }

  @action('[USER][RECOVER:Password] Success')
  private recoverPasswordSuccess(success: (x: ActionTypes) => void): void {
    this._alerts.add({
      title: 'Success',
      message: 'Your password has been updated. Please sign in!',
      execute: success
    });
  }

  @action('[USER][RECOVER:Password] Failed')
  private recoverPasswordFailed(error: () => void): void {
    this._alerts.add({
      title: 'Oops',
      message: 'Unable to complete the operation at this time, please try again later.',
      execute: error
    });
  }

  @action('[USER][UPDATE]')
  public updateUser(user: Client): void {
    this.user = user;
    this.cookieService.set('user', JSON.stringify(this.user), this.cookieExpire,'/' );

    // console.log('updateUser');
  }


  public updateUserListing(listing: Listing, avatar : string): void {
   
    // console.log(listing);
    // console.log(avatar);
    

    if(this.user.listings) {

      let tmpListings = this.user.listings

      tmpListings.filter(x=>x.id == listing.id)[0].avatar = avatar;
      tmpListings.filter(x=>x.id == listing.id)[0].signedAvatar = avatar;

      this.user.listings = tmpListings;

      let tmpUser = this.user ;

      this.updateUser(null);
      this.updateUser(tmpUser);
    }
        
  }

  @action('[USER][SECURITY:UPDATE] Try')
  public updateSecurityUser(user: User): void {
    this._clientsService.PutClientSecurity(user)
      .subscribe(x => {
        if (x.data.answer) {
          
          if(user.email) {
            this.user.email = user.email;
          }
          if(user.username) {
            this.user.username = user.username;
          }
        } else {
          this._alerts.add({
            title: 'Error',
            message: 'Unable to complete the operation at this time, please try again later.'
          });
        }
      },
      err => {
        this._alerts.add({
          title: 'Error',
          message: 'Unable to complete the operation at this time, please try again later.'
        });
      });
  }


  private updateFromProfile(): void {
    reaction(
      () => this._clients.client,
      x => {
        this.user.username = x.username;
        this.user.email = x.email;
        this.user.avatar = x.avatar;
        this.user.signedAvatar = x.signedAvatar;
        this.user.firstName = x.firstName;
        this.user.lastName = x.lastName;
      },
      { name: '[REACTION][USER] Update from Profile' }
    );
  }

  @action('[USER][NEW PASSWORD] Try')
  public newpassword(after): void {
    this._alerts.add({
      title: 'Success',
      message: 'Your password has been updated. Please sign in!',
      okLabel: 'OK',
      hasCancel: true,
      execute: (x: ActionTypes) => {
        if (x === ActionTypes.ok) {
          // console.log('Hello?')
          this.forceSignOut();
          if (after) {
            after();
          }
        }
      }
    });
  }

  @action('[USER][EMAIL:VERIFY] Success')
  private verifyPasswordChange(after): void {
      this._alerts.add({
      title: 'Success',
      message: 'Email verified succesfully, please reset your password and log in.',
      execute: () => {
        after();
      }
    });
  }



  private getAvatar(avatar: string): string {
    var result = this.getSignedUrl(avatar);
    return result;
  }

  getSignedUrl(fileFullName:string): string {
    const bucket = new S3(environment.s3.BucketOptions);
    let fileName: string = '' ;

    // console.log('fileFullName');
    // console.log(fileFullName);

    if(fileFullName == undefined || fileFullName== null || fileFullName== ''){
      return fileFullName;
    }

    var startIndex = fileFullName.indexOf('amazonaws.com/');
    if(startIndex>-1) {
      fileName = fileFullName.substring(startIndex+14);

      if(fileName.indexOf( environment.s3.Bucket + '/' ) === 0){
        fileName = fileName.substring(environment.s3.Bucket.length + 1);
      }

    }
    else {
      return fileFullName;
    }


    var existsSign = this.s3store.signedUrlList.filter(x=> x.name === fileName && x.expires.getTime() > new Date().getTime() );
    
    // console.log('existsSign');
    // console.log(existsSign);    

    if(existsSign.length > 0) {
      
      // console.log('existsSign');
      // console.log(existsSign[0].value);    

      return existsSign[0].value;

    }
    else {

        const paramsSign = {
        Bucket: environment.s3.Bucket,
        Key: fileName,
        Expires: 60*environment.s3.SignedUrlExpires 
        };
    
        var signedUrl = bucket.getSignedUrl('getObject',paramsSign);
    
        // console.log('signedUrl');
        // console.log(signedUrl);    
    
        this.s3store.signedUrlList.push(new S3SignedUrl({name:fileName , value: signedUrl, expires: new Date( new Date().getTime() + (environment.s3.SignedUrlExpires*60*1000) ) })) ;

        // console.log('signedUrlList');
        // console.log(this.store.s3.signedUrlList);    

        return signedUrl;        
    }


  }
}
