import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer, BehaviorSubject } from 'rxjs';

import { Client, RG } from '../models';
import { ClientsService } from './api';
import { MapwordsStore } from '../store';
import { ErrorResolver } from '../../app/common/app-error-resolver';

@Injectable()
export class CpResolver implements Resolve<RG<Client>> {

  constructor(
    private store: MapwordsStore,
    private _clientsService: ClientsService,
    private _router: Router) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = this.store.auth.user.id;
    return this._clientsService.GetClientListings(id).catch(e => {
      // this.store.alerts.add({
      //   title: 'Ooops!',
      //   message: 'Unable to complete the operation at this time, please try again later.',
      //   execute: () => {
      //     location.reload();
      //   }
      // });
      // return Observable.empty();
      return new ErrorResolver().resolve(e);
    });
  }

}
