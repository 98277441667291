import { Observable } from "rxjs";
import { BadInputError } from "./bad-input-error";
import { NotAuthorizedError } from "./not-authorized-error";
import { NotFoundError } from "./not-found-error";
import { AppError } from "./app-error";
import { InternalServerError } from "./internal-server-error";
import { GatewayTimeoutError } from "./gateway-timeout-error";

export class ErrorResolver {
    resolve(response: any): Observable<any> {

        console.log('ErrorResolver Status : ' + response) ;

        if(response.status == 400 ) {
            return Observable.throw(new BadInputError(response));
        }
        else if(response.status == 401 ) {
            return Observable.throw(new NotAuthorizedError(response));
        }
        else if(response.status == 404 ) {
            return Observable.throw(new NotFoundError(response));
        }
        else if(response.status == 500 ) {
            return Observable.throw(new InternalServerError(response));
        }
        else if(response.status == 504 ) {
            return Observable.throw(new GatewayTimeoutError(response));
        }        
        else {
            return Observable.throw(new AppError(response));
        }
    }
}

// STATUS CODE	DESCRIPTION
// 200 (OK)	Request was successful.
// 400 (Bad Request)	Invalid format for your request. Make sure you're passing in the correct parameters.
// 401 (Unauthorized)	Not authorized to make this request. 
// 404 (Not Found)	The requested resource could not be found. Check spelling and review feed docs.
// 500 (Internal Server Error)	Server side error processing request. Please try again later.
// 504 (Gateway Timeout)	Server timed out trying to fulfill your request. Please try again later.

