import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { GetListingBySearchTermRouteModel as Filter } from '../../../../shared/models';
import {observable} from 'mobx-angular'

@Injectable()
export class MwSearchService {

  public loading$: Observable<boolean>;
  private _observer: Observer<boolean>;
  public filters$: Observable<Filter>;
  private _filterObserver: Observer<Filter>;
  public resultCount$: Observable<any>;

  private resultCountSubject = new Subject<any>();

  constructor() {

    this.loading$ = new Observable<boolean>(observer => this._observer = observer).share();
    this.filters$ = new Observable<Filter>(observer => this._filterObserver = observer).share();
    this.resultCount$ = this.resultCountSubject.asObservable();


  }

  public toggleLoading(state: boolean): void {
    if (this._observer) {
      this._observer.next(state);
    }
  }

  public updateFilter(filterState: Filter): void {
    if (this._filterObserver) {
      this._filterObserver.next(filterState);
    }
  }

  private data: any = undefined;

  setData(data:any){
    // this.data = data;
    this.resultCountSubject.next(data);
  }
}
