import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { MwClaimedProfileComponent } from './mw-claimed-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MwClaimedProfileRoutingModule } from './mw-claimed-profile.routing';
import {
    ListingResolver
  } from '../../../shared/services';
  import { HomeComponents, HomeServices, HomeEntryComponents } from '.';
import { AgmCoreModule } from '@agm/core';

  @NgModule({
    imports: [
      SharedModule,
      MwClaimedProfileRoutingModule,
      AgmCoreModule
    ],
    declarations: [
      MwClaimedProfileComponent
    ],
    providers: [
      ListingResolver
    ]
  })
  export class MwClaimedProfileModule { }
