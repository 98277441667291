
import { MasterCategoryListing } from './MasterCategoryListing';


export interface IMasterCategory  {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    name?: string;
    description?: string;
    masterCategories?: MasterCategoryListing[];
}
export class MasterCategory  implements IMasterCategory {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public name: string;
    public description: string;
    public masterCategories: MasterCategoryListing[];
    constructor(obj?: IMasterCategory) {
        
        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.name = obj.name !== undefined ? obj.name : null;
        this.description = obj.description !== undefined ? obj.description : null;
        this.masterCategories = obj.masterCategories !== undefined ? obj.masterCategories : null;
    }
}
// For reactive forms intended
export interface IFMasterCategory  {
    /**
    * Is number
    */
    id?: any; 
    /**
    * Is boolean
    */
    active?: any; 
    /**
    * Is Date
    */
    addedDate?: any; 
    /**
    * Is string
    */
    name?: any; 
    /**
    * Is string
    */
    description?: any; 
    /**
    * Is MasterCategoryListing[]
    */
    masterCategories?: any; 
}
