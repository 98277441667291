
import { ChallengeStatusTypes } from './ChallengeStatusTypes';
import { Listing } from './Listing';
import { Client } from './Client';
import { ReviewReply } from './ReviewReply';
//import { ThumbsUp } from './ThumbsUp';


export interface IListingReview {
    id?: number;
    active?: boolean;
    addedDate?: Date;
    message?: string;
    stars?: number;
    challengeStatus?: ChallengeStatusTypes;
    qucontact?: boolean;
    quoffer?: boolean;
    qudiscuss?: boolean;
    qurefund?: boolean;
    quresolve?: boolean;
    qupay?: number;
    qufriendemployee?: boolean;
    qugiftofferreview?: boolean;
    quencouragereview?: boolean;
    qu1stvisit?: boolean;
    imageUrl?: string;
    imageUrls?: string[];
    imageSignedUrls?: string[];    
    listing?: Listing;
    listingId?: number;
    client?: Client;
    clientId?: number;
    replies?: ReviewReply[];
    videoUrl?: string;
    videoReviewStatus?: number;
    //thumbs?: ThumbsUp;
    // listingThumbs?: ThumbsListingReview;
    likes?: number;
    dislikes?: number;
    selectedLikeType?: number;
    businessName?: string;
    addedDateText?: string;
}
export class ListingReview implements IListingReview {
    public id: number;
    public active: boolean;
    public addedDate: Date;
    public message: string;
    public stars: number;
    public challengeStatus: ChallengeStatusTypes;
    public qucontact: boolean;
    public quoffer: boolean;
    public qudiscuss: boolean;
    public qurefund: boolean;
    public quresolve: boolean;
    public qupay: number;
    public qufriendemployee: boolean;
    public qugiftofferreview: boolean;
    public quencouragereview: boolean;
    public qu1stvisit: boolean;
    public imageUrl: string;
    public imageUrls: string[];
    public imageSignedUrls: string[];
    public listing: Listing;
    public listingId: number;
    public client: Client;
    public clientId: number;
    public replies: ReviewReply[];
    public videoUrl?: string;
    public videoReviewStatus?: number;
    //public thumbs: ThumbsUp;
    // public listingThumbs: ThumbsListingReview;
    public likes: number;
    public dislikes: number;
    public selectedLikeType: number;
    public businessName?: string;
    public addedDateText?: string;

    constructor(obj?: IListingReview) {

        this.id = obj.id !== undefined ? obj.id : null;
        this.active = obj.active !== undefined ? obj.active : null;
        this.addedDate = obj.addedDate !== undefined ? obj.addedDate : null;
        this.message = obj.message !== undefined ? obj.message : null;
        this.stars = obj.stars !== undefined ? obj.stars : null;
        this.challengeStatus = obj.challengeStatus !== undefined ? obj.challengeStatus : null;
        this.qucontact = obj.qucontact !== undefined ? obj.qucontact : null;
        this.quoffer = obj.quoffer !== undefined ? obj.quoffer : null;
        this.qudiscuss = obj.qudiscuss !== undefined ? obj.qudiscuss : null;
        this.qurefund = obj.qurefund !== undefined ? obj.qurefund : null;
        this.quresolve = obj.quresolve !== undefined ? obj.quresolve : null;
        this.qupay = obj.qupay !== undefined ? obj.qupay : null;
        this.qufriendemployee = obj.qufriendemployee !== undefined ? obj.qufriendemployee : null;
        this.qugiftofferreview = obj.qugiftofferreview !== undefined ? obj.qugiftofferreview : null;
        this.quencouragereview = obj.quencouragereview !== undefined ? obj.quencouragereview : null;
        this.qu1stvisit = obj.qu1stvisit !== undefined ? obj.qu1stvisit : null;
        this.imageUrl = obj.imageUrl !== undefined ? obj.imageUrl : null;
        this.imageUrls = obj.imageUrls !== undefined ? obj.imageUrls : null;
        this.imageSignedUrls = obj.imageSignedUrls !== undefined ? obj.imageSignedUrls : null;
        this.listing = obj.listing !== undefined ? obj.listing : null;
        this.listingId = obj.listingId !== undefined ? obj.listingId : null;
        this.client = obj.client !== undefined ? obj.client : null;
        this.clientId = obj.clientId !== undefined ? obj.clientId : null;
        this.replies = obj.replies !== undefined ? obj.replies : null;
        this.videoUrl = obj.videoUrl !== undefined ? obj.videoUrl: null;
        this.videoReviewStatus = obj.videoReviewStatus !== undefined ? obj.videoReviewStatus: 0;
        //this.thumbs = obj.thumbs !== undefined ? obj.thumbs: null;
        // this.listingThumbs = obj.listingThumbs != undefined ? obj.listingThumbs : null;
        this.likes = obj.likes !== undefined ? obj.likes: null;
        this.dislikes = obj.dislikes !== undefined ? obj.dislikes: null;
        this.selectedLikeType = obj.selectedLikeType !== undefined ? obj.selectedLikeType:null;
        this.businessName = obj.businessName !== undefined ? obj.businessName: null;
        this.addedDateText = obj.addedDateText !== undefined ? obj.addedDateText: null;
    }
}
// For reactive forms intended
export interface IFListingReview {
    /**
    * Is number
    */
    id?: any;
    /**
    * Is boolean
    */
    active?: any;
    /**
    * Is Date
    */
    addedDate?: any;
    /**
    * Is string
    */
    message?: any;
    /**
    * Is number
    */
    stars?: any;
    /**
    * Is ChallengeStatusTypes
    */
    challengeStatus?: any;
    /**
    * Is boolean
    */
    qucontact?: any;
    /**
    * Is boolean
    */
    quoffer?: any;
    /**
    * Is boolean
    */
    qudiscuss?: any;
    /**
    * Is boolean
    */
    qurefund?: any;
    /**
    * Is boolean
    */
    quresolve?: any;
    /**
    * Is number
    */
    qupay?: any;
    /**
    * Is boolean
    */
    qufriendemployee?: any;
    /**
    * Is boolean
    */
    qugiftofferreview?: any;
    /**
    * Is boolean
    */
    quencouragereview?: any;
    /**
    * Is boolean
    */
    qu1stvisit?: any;
    /**
    * Is string
    */
    imageUrl?: any;
    /**
    * Is Listing
    */
   imageUrls?: any;
   imageSignedUrls?: any;   
   /**
   * Is Listing
   */
    listing?: any;
    /**
    * Is number
    */
    listingId?: any;
    /**
    * Is Client
    */
    client?: any;
    /**
    * Is number
    */
    clientId?: any;
    /**
    * Is ReviewReply[]
    */
    replies?: any;
    videoUrl?: any;
    videoReviewStatus?: number;
    //thumbs?: any;
    listingThumbs?: any;
    likes?: number;
    dislikes?: number;
    selectedLikeType?: any;
    businessName?: any;
    addedDateText?: any;
}
